import api from "api";

const BASE_API = process.env.REACT_APP_API_URL;

export const getOperationReportsId = async (id: number): Promise<any> => {
    const data = await api(`${BASE_API}/operations-reports?notification_setting_id=${id}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return data;
  };
  