

import { Icons } from "shared/components/icons";

const TableToolbar = () => {

  return (
    <div className='flex justify-between w-full bg-white p-2'>
       <div className="flex items-center">
        <div className="pr-4  border-r">
            <button className="flex items-center">
            <div className="p-1">
            <Icons.Sort className="h-4 w-4 "/>
            </div>
           <p className="text-xs font-medium text-[#5E636E]">Sort</p>
           </button>
        </div>
        <div className="px-4 border-r">
            <button className="flex items-center bg-[#F7F7F8] rounded py-1.5 pl-2 pr-1 gap-1.5">
            <div className="">
            <Icons.Filter className="h-4 w-4 "/>
            </div>
           <p className="text-xs font-medium text-[#5E636E]">(3) Filter</p>
           </button>
        </div>
        <div className="px-4 border-r">
            <button className="flex items-center gap-1.5">
            <div className="py-1">
            <Icons.Export className="h-4 w-4 "/>
            </div>
           <p className="text-xs font-medium text-[#5E636E]">Export</p>
           </button>
        </div>
        <div className="px-4 ">
            <button className="p-1">
            <Icons.HorizontalDots className="h-4 w-4" />
            </button>
        </div>
       </div>
      </div>
  );
};

export default TableToolbar;
