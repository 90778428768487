import {
  PersonalizationData,
  PersonalizationFormInput,
  PersonalizationOptionsList,
  SavePersonalizationPayload,
} from "interfaces/personalization.interfaces";

import api from "../index";

// TODO - move api logic to classes + create http-client config

const BASE_API = process.env.REACT_APP_API_URL;

export const apiGetMyPersonalizationData = async (): Promise<PersonalizationData> => {
  return api(`${BASE_API}/personalization/my-data`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiGetPersonalizationAllOptionsList = async (): Promise<PersonalizationOptionsList> => {
  return api(`${BASE_API}/personalization/options`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiSaveMyPersonalizationData = async (payload: SavePersonalizationPayload): Promise<any> => {
  return api(`${BASE_API}/personalization`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
};

export const apiSavePersonalizationUrl = async (url: string): Promise<any> => {
  return api(`${BASE_API}/companies/enrich?domain=${url}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiGetSavedPersonalizationUrl = async (url: string): Promise<any> => {
  return api(`${BASE_API}/companies/enrich?domain=${url}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apigetPersonalizationUrl = async (): Promise<any> => {
  return api(`${BASE_API}/companies/enrich`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const savePersonalizationUrl = async (data: any): Promise<any> => {
  return api(`${BASE_API}/companies`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const apiGetCompanyData = async (): Promise<any> => {
  return api(`${BASE_API}/companies`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
