import React, { useEffect, useMemo, useState } from "react";

import { useAppSelector } from "hooks/appHooks";

import { Dropdown } from "shared/components/dropdown/Dropdown";
import { Icons } from "shared/components/icons";

import { cn } from "lib/utils";

const InviteNewMembers = ({ handleAddTeamMembers, team }: any) => {
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState<{ id: string; label: string } | null>(null);

  const loading = useAppSelector(state => state.company.loading);

  useEffect(() => {
    if (!loading) {
      setEmail("");
      setPermission(null);
    }
  }, [loading]);

  const options = useMemo(() => {
    return [
      { id: "1", label: "can approve" },
      { id: "2", label: "can view" },
    ].map(item => ({
      ...item,
      action: () => setPermission(item),
    }));
  }, []);

  const isInviteDisabled = !email || !permission;

  return (
    <div className='flex items-end gap-4 w-full max-w-lg'>
      <div className='flex flex-col flex-1 min-w-[250px]'>
        <span className='text-sm font-semibold text-[#1F2228] mb-1'>Invite others to the team by email</span>
        <input
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Type email'
          className='w-full border border-[#E9EAEC] rounded-xl py-[11px] px-3 text-sm focus:outline-none '
        />
      </div>

      <div className='flex flex-col min-w-[160px]'>
        <span className='text-sm font-semibold text-[#1F2228]  mb-1'>Select permission type</span>
        <Dropdown
          items={options}
          menuBtnStyles='w-40 border border-[#E9EAEC] rounded-xl py-[11px] px-3 text-sm'
          className='border border-[#E9EAEC]'
        >
          {({ open }) => (
            <div className='flex items-center justify-between'>
              <p className={cn("text-gray-500", !permission && "opacity-50")}>{permission?.label || "Select"}</p>
              {open ? <Icons.ArrowUp className='h-4 w-4' /> : <Icons.ArrowDown className='h-4 w-4' />}
            </div>
          )}
        </Dropdown>
      </div>

      <button
        disabled={isInviteDisabled}
        onClick={() => handleAddTeamMembers(email, team?.team_id, Number(permission?.id))}
        className={cn(
          "py-[11px] px-6 rounded-xl text-sm font-semibold",
          isInviteDisabled ? "bg-gray-200 cursor-not-allowed text-[#C8CAD0]" : "bg-[#766CE4] text-white hover:bg-[#766CE4]",
        )}
      >
        Invite
      </button>
    </div>
  );
};

export default InviteNewMembers;
