import { setHeapSnapshotNearHeapLimit } from "v8";

import { HumanInterrupt } from "@langchain/langgraph/prebuilt";
import { Link2Icon } from "@radix-ui/react-icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

//import { getConnectedSourceTables } from "api/integrations";

import { generateCredentialForAppIntegration } from "api/integrations";

import { INTEGRATION_ICONS } from "constants/integrations.constants";
import { ROUTES } from "constants/routes.constants";

import { StringOrNumber } from "interfaces/shared-types.interfaces";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetCredential } from "hooks/queries/connect-source";

import {
  ConnectSources,
  getAllConnectedSources,
  getAllConnectSources,
  //getConnectedSourceTablesByConnectionId,
} from "storage/slices/integrations-slice";

import { Icons } from "shared/components/icons";
import Alert from "shared/components/toasts";

import ShopifyConnectModal from "pages/connect-sources/components/ConnectSourceList/shopify-connect-modal";

import { cn } from "lib/utils";

import { HumanResponseWithEdits, SubmitType } from "../types";



interface ConnectDataSourcesProps {
  interrupt: HumanInterrupt;
  humanResponse: HumanResponseWithEdits[];
  setHumanResponse: React.Dispatch<React.SetStateAction<HumanResponseWithEdits[]>>;
  setSelectedSubmitType: React.Dispatch<
    React.SetStateAction<SubmitType | undefined>
  >;
  setHasEdited: React.Dispatch<React.SetStateAction<boolean>>;
  onSourcesChange?: (sources: ConnectSources[]) => void;
}

type localStorageIntegrationType = {
  integration_id: number;
  display_name: string;
  // scopes: string[];
  supportsRefreshToken: boolean;
  enabled: boolean;
  logo: string;
  description: string;
  category: string;
  service_id: number | null;
};

const ConnectDataSources = ({
  interrupt,
  humanResponse,
  setHumanResponse,
  setSelectedSubmitType,
  setHasEdited,
  onSourcesChange
}: ConnectDataSourcesProps) => {
  console.log("humanResponse", humanResponse);
  const [isOpenSources, setIsOpenSources] = useState(true);
  const [contentHeight, setContentHeight] = useState("auto");
  const [integrationQueue, setIntegrationQueue] = useState(() => {
    const savedQueue = localStorage.getItem("integrationQueue");
    return savedQueue ? JSON.parse(savedQueue) : [];
  });
  const [selectedSources, setSelectedSources] = useState<ConnectSources[]>([]);

  const pollingInterval = useRef<NodeJS.Timeout | number | null>(null);
    const [selectedIntegration, setSelectedIntegration] = useState<any>(null);
      const [middleWareData, setMiddleWareData] = useState<any>({ access_token: "", credential_id: "", integration_id: "" });
  const suggestedSourceNames = interrupt.action_request.args.suggested_sources.names.map((item: string) => item.toLowerCase());
  console.log("suggestedSourceNames", suggestedSourceNames);

  const connectSourcesList = useAppSelector(state => state.integrations.connectSources);
  console.log("connectSourcesList", connectSourcesList);
  const filteredConnectSourcesList = connectSourcesList.filter(
    item => item.enabled && (suggestedSourceNames || []).includes(item?.display_name?.toLowerCase()),
  );
  const connectedSourcesList = useAppSelector(state => state.integrations.connectedSources);
  const dispatch = useAppDispatch();
    const { data: googleSheetCredential } = useGetCredential(2);
      const [openShopifyModal, setShopifyModal] = useState(false);
      const [shopifyIntegrationId, setShopifyIntegrationId] = useState<number | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getAllConnectSources());
  }, []);

  const toggleContent = () => {
    setIsOpenSources(prev => !prev);
  };

  const handleIntegrationClick = (integration: any) => {
    const updatedQueue = [
      ...integrationQueue.filter((item: any) => item.integration_id !== integration.integration_id),
      { integration_id: integration.integration_id, status: "in_progress" },
    ];

    setIntegrationQueue(updatedQueue);
    localStorage.setItem("integrationQueue", JSON.stringify(updatedQueue));

    window.open("/integrations/connect-sources", "_blank", "noopener,noreferrer");
  };

   const handleIntegration = useCallback(
      (item: localStorageIntegrationType) => {
        // console.log("Integration ID", integration_id);
        const { integration_id, display_name } = item;
        localStorage.setItem("selectedIntegration", JSON.stringify(item));
        console.log("Integration Item", item);
        //   console.log("HubSpot");
        //   const url = "http://localhost:3000/integrations/connect-sources"
        //   window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=46a9699b-6de9-4e5a-aef4-6e38aaeffb22&scope=crm.lists.read%20crm.objects.companies.read%20crm.objects.contacts.read%20crm.objects.deals.read%20crm.objects.feedback_submissions.read%20crm.objects.owners.read%20crm.objects.subscriptions.read%20crm.schemas.companies.read%20crm.schemas.contacts.read%20crm.schemas.deals.read%20oauth&redirect_uri=${url}&response_type=code&state={%22uuid%22:%22e0956e6a-891e-496f-8ba3-48ffb7c52aed%22,%22integrationId%22:%221%22,%22userId%22:5}`
        //   return
        if (display_name == "Google Sheets" && googleSheetCredential && googleSheetCredential.statusCode == 200) {
          setSelectedIntegration(item);
          setMiddleWareData({
            access_token: googleSheetCredential.data!.access_token,
            credential_id: googleSheetCredential.data!.credential_id,
            integration_id: integration_id,
          });
          return;
        }
  
        if (display_name === "Shopify") {
          // open modal
          setShopifyIntegrationId(integration_id);
          setShopifyModal(true);
          return;
        }
  
        generateCredentialForAppIntegration(integration_id)
          .then(res => {
            window.open(res.authorizationUrl, "_blank");
          })
          .catch(err => {
            Alert("error", err.message, "Error");
          });
      },
      [googleSheetCredential],
    );

  const handleToggleSelectedSource = (integration: any) => {
    const updatedSources = selectedSources.includes(integration) ? selectedSources.filter((source: any) => source !== integration) : [...selectedSources, integration];
    setSelectedSources(updatedSources);
    setHumanResponse(prev => {
      const newResponse = prev.map((response: any) => {
        if (response.type === "edit" && response.args && response.args.args) {
          response.args.args.selected_sources = updatedSources;
          response.editsMade = true;
        }
        return response;
      });
      console.log("newResponse", newResponse);
      return newResponse;
    });
    setSelectedSubmitType("edit");
    setHasEdited(true);
  };

  const pollIntegrationStatus = async () => {
    try {
      const res = await dispatch(getAllConnectedSources()).unwrap();

      const updatedQueue = integrationQueue.filter((integration: any) => {
        const status = res.find((connected: ConnectSources) => connected.integration_id === integration.integration_id);

        // Remove from queue if status is "active" or "failed"
        if (status?.status === "active" || status?.status === "failed") {
          //setIsSending(false);
          return false;
        }
        //setIsSending(true);
        return true;
      });

      setIntegrationQueue(updatedQueue);
      localStorage.setItem("integrationQueue", JSON.stringify(updatedQueue));

      // Stop polling if no integrations are left in the queue
      if (updatedQueue.length === 0 && pollingInterval.current) {
        clearInterval(pollingInterval.current);
        pollingInterval.current = null;
        //setIsSending(false);
      }
    } catch (error) {
      console.error("Error fetching integration status:", error);
    }
  };

  useEffect(() => {
    setIntegrationQueue([]);
    localStorage.removeItem("integrationQueue");
    dispatch(getAllConnectedSources());
  }, [dispatch]);

  useEffect(() => {
    if (integrationQueue.length > 0 && !pollingInterval.current) {
      pollingInterval.current = setInterval(() => {
        pollIntegrationStatus();
      }, 5000);
    }

    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current as NodeJS.Timeout);
        pollingInterval.current = null;
      }
    };
  }, [integrationQueue]);

  const showImage = (logo: any) => {
    const Icon = INTEGRATION_ICONS?.[logo as keyof typeof INTEGRATION_ICONS];
    if (!Icon) return <img src={""} alt='logo' className='w-6 h-6' />;
    return <Icon className='w-6 h-6' />;
  };

  useEffect(() => {
    if (isOpenSources && contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setContentHeight("0px");
    }
  }, [isOpenSources, filteredConnectSourcesList]);

  useEffect(() => {
    onSourcesChange?.(selectedSources);
  }, [selectedSources, onSourcesChange]);

/*   const handleGetConnectedDataSources = (id: StringOrNumber) => {
    dispatch(getConnectedSourceTablesByConnectionId(id));
  }; */

  const isConnected = (item: ConnectSources) => connectedSourcesList.some(source => source.integration_id === item.integration_id);
  
  console.log("filteredConnectSourcesList", filteredConnectSourcesList);

  const isOpen = false;

  return (
    <div className={cn("w-full", isOpen ? "min-w-24" : "min-w-[540px]")}>
      <div>
        { connectedSourcesList.length === 0 ? (
          <div>
            I can see that you haven&apos;t connected any data sources yet.  May I suggest one of the following?
          </div>
        ) : (
          <div>
            Here are the sources I would suggest.  Click any connected source to use it for this workflow.
            You can see which ones are already connected by the link icon.
            If you would like to connect another source, click it&apos;s button to connect it.
          </div>
        )}
      </div>
      <div className='flex justify-between items-center my-1 w-fll flex-1'>
        <div onClick={toggleContent} className='flex items-center gap-2 cursor-pointer flex-1'>
          <p className='text-left text-[#717684] font-medium cursor-pointer mb-1'>Suggested sources</p>
          {isOpenSources ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
        </div>
        <Link 
          to={ROUTES.connectSources}
          target="_blank" 
          rel="noopener noreferrer"
          className="text-sm font-semibold text-[#766CE4] cursor-pointer"
        >
          View All
        </Link>
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: contentHeight, transition: "max-height 0.5s ease-in-out" }}
        className={"overflow-hidden grid grid-cols-1 gap-2 md:grid-cols-2 transition-[max-height] duration-500 ease-in-out rounded-b"}
      >
        {connectSourcesList?.slice(0, 4)?.map(option => (
          <button
            disabled={!option.enabled}
            onClick={() => {
              if (isConnected(option)){
                handleToggleSelectedSource(option);
              } else {
                handleIntegration(option);
              }
            }}
            key={option.display_name}
            className={cn(
              "flex items-center cursor-pointer justify-between  rounded-[16px] border  p-3 gap-4 hover:border-[#D5D1F7] disabled:bg-gray-50",
              isConnected(option) ? "bg-[#FCFCFC] border-[#F7F7F8]" : "bg-white border-[#E9EAEC]",
            )}
          >
            <div className='flex items-center gap-x-4 flex-1'>
              <div className='border p-[10px] border-[#DEE9FF] rounded-xl'>{showImage(option.logo)}</div>
              <div>
                <p className='text-[15px] font-semibold text-[#1F2228]'>{option.display_name}</p>
              </div>
            </div>
            {isConnected(option) &&   <Link2Icon className="w-5 h-5 text-gray-600" /> }
          </button>
        ))}
      </div>
      {/*connectedSourcesList.length > 0 && (
        <div className='mt-2 mb-4 flex items-center gap-2 flex-wrap'>
          {connectedSourcesList.map((source, index) => (
            <div
              onClick={() => handleGetConnectedDataSources(source.connection_id)}
              key={index}
              className='flex cursor-pointer items-center gap-2 mt-2 bg-white rounded-xl border border-[#E9EAEC] px-4 py-[6px]'
            >
              <Icons.Check className='text-[#02A613] w-[18px] h-[18px]' />
              <p className='text-[#1F2228] font-medium text-xs'>{source.integration_data.name} connected</p>
            </div>
          ))}
        </div>
      )*/}
      {selectedSources.length === 0 ? (
        <div className='mt-2 mb-4 flex items-center gap-2 flex-wrap'>
          <p className='text-[#1F2228] font-medium text-s'>No sources selected.  Click a connected source to select it.</p>
        </div>
      ) : selectedSources.length > 0 && (
        <div className='mt-2 mb-4 flex items-center gap-2 flex-wrap'>
          {selectedSources.map((source: ConnectSources, index: number) => (
            <div
              key={index}
              className='flex cursor-pointer items-center gap-2 mt-2 bg-white rounded-xl border border-[#E9EAEC] px-4 py-[6px]'
            >
              <Icons.Check className='text-[#02A613] w-[18px] h-[18px]' />
              <p className='text-[#1F2228] font-medium text-xs'>{source.display_name} selected</p>
            </div>
          ))}
        </div>
      )}

      {openShopifyModal && (
              <ShopifyConnectModal
                onClose={() => {
                  setShopifyModal(false);
                }}
                onOpen={() => {
                  setShopifyModal(true);
                }}
                onSubmit={(shopUrl: string) => {
                  return generateCredentialForAppIntegration(shopifyIntegrationId!, shopUrl);
                }}
              />
            )}
    </div>
  );
};

export default ConnectDataSources;
