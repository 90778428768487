import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Edge,
  MarkerType,
  Position,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { twMerge } from "tailwind-merge";

import { IChannelsTransformedData } from "api/budget/types";
import { IProjectData } from "api/projects/types";

import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";
import { useLimitViewBySubscription } from "hooks/subscriptions/useLimitViewBySubscription";
import { useBoolean } from "hooks/useBoolean";

import DonutChart from "shared/components/budget/donutChart/DonutChart";
import MonthBudgetCardOverlay from "shared/components/budget/monthBudgetCard/MonthBudgetCardOverlay";
import { Typography } from "shared/components/Typography";

import { ChannelStrategyNode } from "page-components/channels/ChannelStrategyNode";

import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left-purple.svg";

import { ChannelStrategyMonths } from "./channel-strategy-months/ChannelStrategyMonths";
import classes from "./ChannelStrategy.module.scss";
import CustomControls from "./CustomControls/CustomControls";
import "reactflow/dist/style.css";
import "./reactflow.scss";

interface Props {
  data: IChannelsTransformedData[];
  activeDate?: string;
  className?: string;
  hideToolbar?: boolean;
  redirectChannelAction?: boolean;
  project?: IProjectData;
  isLoading?: boolean;
}

// Define CustomEdge outside the component
const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, style = {}, markerEnd }: any) => {
  const midX = (sourceX + targetX) / 2;
  const curveOffset = 13;
  let path;

  if (sourceY === targetY) {
    path = `M${sourceX},${sourceY} H${targetX}`;
  } else if (sourceY < targetY) {
    path = `M${sourceX},${sourceY}
            H${midX - curveOffset}
            Q${midX},${sourceY} ${midX},${sourceY + curveOffset}
            V${targetY - curveOffset}
            Q${midX},${targetY} ${midX + curveOffset},${targetY}
            H${targetX}`;
  } else {
    path = `M${sourceX},${sourceY}
            H${midX - curveOffset}
            Q${midX},${sourceY} ${midX},${sourceY - curveOffset}
            V${targetY + curveOffset}
            Q${midX},${targetY} ${midX + curveOffset},${targetY}
            H${targetX}`;
  }

  return <path id={id} style={style} className='react-flow__edge-path' d={path} markerEnd={markerEnd} />;
};

// Define edgeTypes outside the component
const edgeTypes = {
  custom: CustomEdge,
};

const ChannelStrategy: FC<Props> = ({ data = [], activeDate, className, hideToolbar, redirectChannelAction, project }) => {
  const { budget_date_from: budgetDateFrom, project_id: projectId } = project || {};
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();

  const [isDraggable, handleSetIsDraggable] = useBoolean();
  const [activeDateIndex, setActiveDateIndex] = useState(0);

  const [focusedChannelId, setFocusedChannelId] = useState<number | null | undefined>(undefined);

  const currentActiveData = data?.[activeDateIndex] ?? {};

  const { isAvailable: isCardAvailableByPlan } = useLimitViewBySubscription({
    budgetDateFrom: budgetDateFrom || null,
    currentBudgetDate: `${currentActiveData?.year} ${currentActiveData?.month}`,
  });

  const channelYOffset = 145;
  const canvasMinHeight = 500;

  const activeMonth = data?.[activeDateIndex];

  const canvasHeight =
    canvasMinHeight +
    (activeMonth?.values?.length > 2 ? activeMonth?.values?.length - 3 : 0) * channelYOffset +
    (activeMonth?.values?.length > 2 ? 50 : 0);

  const activeMonthBudget = activeMonth?.values.reduce((acc: number, item: any) => {
    acc += item?.value;
    return acc;
  }, 0);

  const handleActiveMonth = useCallback(
    (isNext = true) => {
      if ((isNext && data[activeDateIndex + 1]) || (!isNext && data[activeDateIndex - 1])) {
        const plusOrMinus = isNext ? 1 : -1;
        setActiveDateIndex(prev => prev + plusOrMinus);
      }
    },
    [data, activeDateIndex],
  );

  const parentNode = useMemo(
    () => ({
      id: "1",
      position: {
        x: window.innerWidth / 2 - 600,
        y: canvasHeight / 2 - 210,
      },
      data: {
        label: (
          <div className='flex flex-col h-full'>
            <ChannelStrategyMonths
              handleActiveMonth={handleActiveMonth}
              data={data}
              activeDateIndex={activeDateIndex}
              budgetDateFrom={budgetDateFrom}
            />
            <div className='relative'>
              {!isCardAvailableByPlan && <MonthBudgetCardOverlay />}
              <div>
                <div className='flex flex-col items-start gap-y-1 px-4 pb-0'>
                  <Typography className='text-[#717684]' variant={TypographyVariants.SM_MEDIUM}>
                    Total budget
                  </Typography>
                  <Typography variant={TypographyVariants.H4}>${activeMonthBudget?.toLocaleString("en-US")}</Typography>
                </div>
                <div className={classes.strategy__parent__donutChart}>
                  <DonutChart activeMonth={activeMonth} animation={focusedChannelId === undefined} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      className: classes.strategy__parent,
      sourcePosition: Position.Right,
    }),
    [
      canvasHeight,
      handleActiveMonth,
      data,
      activeDateIndex,
      budgetDateFrom,
      isCardAvailableByPlan,
      activeMonthBudget,
      activeMonth,
      focusedChannelId,
    ],
  );

  const childrenNodes = useMemo(
    () =>
      activeMonth?.values?.map((item: any, index: number) => ({
        id: (index + 2).toString(),
        position: { x: window.innerWidth / 2 - 100, y: channelYOffset * index + 50 },
        data: {
          label: (
            <ChannelStrategyNode
              channel={item}
              activeMonth={activeMonth}
              activeMonthBudget={activeMonthBudget}
              isCardAvailable={isCardAvailableByPlan}
              redirectOnAction={redirectChannelAction}
              handleFocusChannel={value => {
                setFocusedChannelId(value);
              }}
              focusedChannelId={focusedChannelId}
              index={index}
              currentActiveData={currentActiveData}
            />
          ),
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        className: classes.strategy__child,
      })),
    [activeMonth, activeMonthBudget, isCardAvailableByPlan, redirectChannelAction, focusedChannelId, channelYOffset],
  );

  const initialNodes = [parentNode, ...(childrenNodes ?? [])];

  const initialEdges = childrenNodes?.map((item: any, index: number) => {
    return {
      id: `e1-${index + 2}`,
      source: "1",
      target: `${index + 2}`,
      style: { stroke: "#D0D5DD", strokeWidth: 3 },
      markerEnd: {
        type: MarkerType.Arrow,
        color: "#D0D5DD",
      },
    };
  });

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params: Edge | Connection) => setEdges(eds => addEdge(params, eds)), [setEdges]);

  useEffect(() => {
    setNodes([parentNode, ...(childrenNodes ?? [])]);
  }, [activeDateIndex, projectId, childrenNodes, setNodes, parentNode]);

  useEffect(() => {
    if (!activeDate) return;

    const activeIndex = data?.findIndex(({ date }: any) => date === activeDate);

    if (activeIndex !== -1) {
      setActiveDateIndex(activeIndex);
    }
  }, [activeDate, data]);

  return (
    <div
      className={twMerge(classes.strategy, className)}
      style={{
        height: `${canvasHeight}px `,
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges?.map(edge => ({ ...edge, type: "custom" }))}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={edgeTypes}
        zoomOnScroll={false}
        preventScrolling={false}
        attributionPosition='bottom-left'
        nodesDraggable={isDraggable}
      >
        {!hideToolbar && <CustomControls isDraggable={isDraggable} handleSetIsDraggable={handleSetIsDraggable} />}
        <Background variant={BackgroundVariant.Dots} color='#D3D3D3' gap={12} size={2} style={{ background: "#F7F9FB" }} />
        {pathname.includes("workflow") && (
          <button
            className='flex items-center gap-3 font-semibold text-sm leading-default absolute top-4 left-6 z-10 text-[#766CE4]'
            onClick={() => navigate(`/budget/${id}/budget-intelligence`, { state: { view: "executive" } })}
          >
            <ArrowLeftIcon />
            Back to Marketing view
          </button>
        )}
      </ReactFlow>
    </div>
  );
};

export default ChannelStrategy;
