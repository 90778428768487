import { useQuery } from "@tanstack/react-query";

import { getAllProjects, getProjectById } from "api/projects";
import { IProjectData } from "api/projects/types";

import { ProjectListItem } from "interfaces/projects.interfaces";

export const projectQueryKeys = {
  getProject: (id?: number) => ["getProject", id] as const,
  getAllProject: (page: number) => ["getAllProject", page] as const,
};

export const useGetProject = (id?: number) =>
  useQuery({
    queryKey: projectQueryKeys.getProject(id),
    queryFn: async (): Promise<IProjectData> => {
      if (!id) {
        throw new Error("Project id is required");
      }

      return await getProjectById(id);
    },
  });

export const useGetAllProject = (page: number) =>
  useQuery({
    queryKey: projectQueryKeys.getAllProject(page),
    queryFn: async (): Promise<ProjectListItem[]> => {
      return await getAllProjects(page);
    },
  });
