import { IChatMessage } from "./provider";

export const dummyChat: IChatMessage[] = [
/*     {
        id: "9",
        sender: "assistant",
        message: "Got it!",
        data: { type: "chat" },
    }, */
    //type: "suggestion" | "action" | "question" | "approval_request" | "source" | "file_upload" | "platform_notification";
    {
        id: "10",
        sender: "assistant",
/*         message: "I can see that you haven’t connected any sources. Which source or report should I use for this workflow?", */
        message: "",
        data: { type: "suggested_integrations", options: ["shopify"] },
    },
    {
        id: "1",
        sender: "assistant",
        message: "Would like to import data from more sources?",
    },
    {
        id: "6",
        sender: "user",
        message: "",
        data: { type: "question", choices: [ { label: "Yes" }, { label: "No" } ], },
    },
/*     {
        id: "2",
        sender: "user",
        message: "No"
    }, */
    {
        id: "3",
        sender: "assistant",
        message: "Before I get to create triggers for you, I want to quickly pull some data. I've identified these [5 Signals](#) that will help guide the process.",
    },
    {
        id: "9",
        sender: "assistant",
        message: "Would you like to modify this?",
    },
    {
        id: "4",
        sender: "user",
        message: "",
        data: { type: "question", choices: [ { label: "Yes" }, { label: "No" } ], },
    },
/*     {
        id: "4",
        sender: "user",
        message: "Yes",
    }, */
/*     {
        id: "5",
        sender: "assistant",
        message: "Type what do you thinking about these signals",
    }, */
/*     {
        id: "6",
        sender: "user",
        message: "Yes"
    }, */
    {
        id: "7",
        sender: "assistant",
        message: "Ok, I got it!"
    },
    {
        id: "8",
        sender: "assistant",
        message: "I've identified the [5 key tables](#) that I'll use to create a trigger for you, ensuring accurate monitoring and timely alerts.",
        data: { type: "action", action: "create_trigger" }
    }
];
