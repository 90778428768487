import { Icons } from "shared/components/icons";

import WorkFlow from "../WorkFlow";

const AgentWorkFlow = () => {
  return (
    <div className='h-full border-l-2 border-[#f7f9fb] bg-white w-full max-w-[700px] shadow-ai-panel rounded-tl-2xl overflow-hidden'>
      <div className='border-b-2 m-0 border-[#E9EAEC]  flex justify-between items-center px-5 h-[4rem]'>
        <h4 className='text-base font-semibold text-[#333740]'>AI agent workflow</h4>
        <div className='flex items-center gap-4'>
          <button className='text-[#1F2228] bg-[#FCFCFC] font-semibold text-xs rounded-lg py-[8px] px-[18px] border border-[#D5D1F7] ml-4'>
            Add Recipients
          </button>
          <Icons.Collapse className='cursor-pointer' />
        </div>
      </div>

      <div
  style={{ height: "calc(100vh - 107px)", display: "flex", flexDirection: "column", overflowY:"auto",scrollbarWidth:"none"}}
  className='relative inset-0 w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]'
>
  <div style={{ overflowY: "auto", flex: 1,scrollbarWidth:"none" }}>
    <WorkFlow />
  </div>
  <div className='p-4 bg-white shadow-ai-panel w-full flex items-center justify-end'>
    <button className='text-[#766CE4] underline bg-white font-semibold text-sm ml-4'>Edit</button>
    <button className='text-[#1F2228] bg-white font-semibold text-sm rounded-xl py-[8px] px-11 border border-[#D5D1F7] ml-4'>
      Run test
    </button>
    <button className='text-white bg-[#766CE4] font-semibold text-sm rounded-xl py-[8px] px-11 border border-[#D5D1F7] ml-4'>
      Approve
    </button>
  </div>
</div>

      {/* <div
        style={{ height: "calc(100vh - 150px)",border:"2px solid red" }}
        className='relative inset-0   w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]  '
      >
        <WorkFlow />
        <div className='p-4 bg-white shadow-ai-panel w-full absolute bottom-0 flex items-center justify-end'>
          <button className='text-[#766CE4] underline bg-white font-semibold text-sm  ml-4'>Edit</button>
          <button className='text-[#1F2228] bg-white font-semibold text-sm rounded-xl py-[8px] px-11 border border-[#D5D1F7] ml-4'>
            Run test
          </button>
          <button className='text-white bg-[#766CE4] font-semibold text-sm rounded-xl py-[8px] px-11 border border-[#D5D1F7] ml-4'>
            Approve
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default AgentWorkFlow;
