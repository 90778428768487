import { useQuery } from "@tanstack/react-query";

import { getCredential, getConnectedData } from "api/integrations";

import { ICredentialData, IConnectedSourceData } from "interfaces/integrations.interfaces";

export const queryKeys = {
  getCredential: (id: number) => ["getCredential", id] as const,
  getConnectedSourceData: (id?: number) => ["getConnectedSourceData", id] as const,
};

export const useGetCredential = (id: number) =>
  useQuery({
    queryKey: queryKeys.getCredential(id),
    queryFn: async (): Promise<ICredentialData> => {
      return await getCredential(id);
    },
  });

export const useGetConnectedSourceData = (id?: number) =>
  useQuery({
    queryKey: queryKeys.getConnectedSourceData(id),
    queryFn: async (): Promise<{
      statusCode: number;
      data: IConnectedSourceData;
    }> => {
      if (!id) throw new Error("No id provided");

      const data = await getConnectedData(id);

      if (data.statusCode != 200) {
        throw new Error(data.data);
      }

      return data;
    },
  });
