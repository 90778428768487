import React, { useEffect, useRef, useState } from "react";

import { useAppDispatch } from "hooks/appHooks";

import { fetchTeamMembers, fetchTeamsByCompany, updateTeamDetails } from "storage/slices/company-slice";

import { Icons } from "shared/components/icons";
import Alert from "shared/components/toasts";

import { cn } from "lib/utils";

const Collapsible = ({
  children,
  title,
  count,
  onRightActionClick,
  isEditable,
  teamId = "",
  companyId,
  memberCount,
  setShowTeamMembers,
  showTeamMembers,
}: {
  children: React.ReactNode;
  title: string;
  count: number;
  onRightActionClick?: () => void;
  isEditable?: boolean;
  teamId?: string;
  companyId: string;
  memberCount: number;
  setShowTeamMembers: (value: string) => void;
  showTeamMembers: string;
}) => {
  const [teamName, setTeamName] = useState(title);
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  const dispatch = useAppDispatch();

  const toggleContent = () => {
    setShowTeamMembers(teamId && showTeamMembers === teamId ? "" : teamId);
  };

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(`${contentRef.current?.scrollHeight}px`);
    }
  }, [count, memberCount]);

  const onSave = () => {
    dispatch(updateTeamDetails({ name: teamName, teamId })).then(() => {
      dispatch(fetchTeamsByCompany(companyId));
    });
    Alert("success", "Update team successfully", "Success");
    onRightActionClick?.();
  };

  useEffect(() => {
    if (showTeamMembers === teamId) {
      dispatch(fetchTeamMembers(showTeamMembers));
    }
  }, [showTeamMembers]);

  return (
    <div className='w-full'>
      <div
        className={cn(
          "flex justify-between items-center w-full bg-company-people-gradient py-[10px] px-5 transition-all duration-500 ease-out",
          showTeamMembers === teamId ? "rounded-t-2xl" : "rounded-2xl",
        )}
      >
        <div onClick={toggleContent} className='flex items-center gap-2 cursor-pointer flex-1'>
          {showTeamMembers === teamId ? <Icons.ArrowUpFill /> : <Icons.ArrowDownFill />}
          <div
            onClick={e => e.stopPropagation()}
            className='flex items-center gap-2 border border-[#E9EAEC] rounded-full bg-white py-1 px-3'
          >
            {isEditable ? (
              <input
                type='email'
                value={teamName}
                onChange={e => setTeamName(e.target.value)}
                placeholder='Type email'
                className='w-full   rounded-xl   px-3 text-sm focus:outline-none '
              />
            ) : (
              <p className='text-[#464A53] font-bold uppercase text-xs'>
                {title} {`(${count})`}
              </p>
            )}
            <Icons.InfoCircleIcon className='w-4 h-4' />
          </div>
        </div>
        {isEditable ? (
          <div className='flex items-center gap-2'>
            <span
              className='text-sm text-gray-900 font-medium cursor-pointer'
              onClick={() => {
                onRightActionClick?.();
                setTeamName(title);
              }}
            >
              Cancel
            </span>
            <span className='text-sm text-[#766CE4] font-semibold cursor-pointer' onClick={onSave}>
              Save
            </span>
          </div>
        ) : (
          <Icons.VerticalDots onClick={onRightActionClick} className='cursor-pointer' />
        )}
      </div>
      <div
        ref={contentRef}
        className='overflow-hidden transition-[max-height] duration-300 ease-in-out rounded-b'
        style={{
          maxHeight: showTeamMembers === teamId ? maxHeight : "0px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
