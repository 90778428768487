import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { ROUTES } from "constants/routes.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { setDropdownOpenList } from "storage/slices/sidebar-slice";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";

import { cn } from "lib/utils";

import BetaLabel from "../beta-label/BetaLabel";
import { Dropdown } from "../dropdown/Dropdown";
import { Typography } from "../Typography";

type DropdownItem = {
  to: string;
  title: string;
};
interface NavButtonProps {
  to: string;
  title: string;
  classes?: string;
  isCollapsed: boolean;
  beta: boolean;
  Icon: React.ComponentType;
  ActiveIcon: React.ComponentType;
  dropdown?: DropdownItem[];
}

export default function NavButton({ to, title, Icon, beta, ActiveIcon, classes, isCollapsed, dropdown }: NavButtonProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dropdownOpenList } = useAppSelector(state => state.sidebar);
  const url = window.location.pathname;
  const toggleDropdown = () => {
    if (dropdownOpenList.includes(title)) {
      const cpyDropdownOpenList = [...dropdownOpenList].filter(item => item !== title);
      dispatch(setDropdownOpenList(cpyDropdownOpenList));
    } else {
      dispatch(setDropdownOpenList([...dropdownOpenList, title]));
    }
  };
  const isActive = url.includes(to);

  const location = useLocation();

  const handleIsActive = (to: string, isActive: boolean) => {
    if (location.pathname == ROUTES.projects && isActive == true) {
      return location.pathname + location.search == to;
    }

    return isActive;
  };

  const dropdownOptions =
    dropdown != undefined && dropdown.length > 0
      ? [
          ...dropdown.map(item => {
            return {
              label: item.title,
              id: item.title,
              action: () => {
                navigate(item.to);
              },
              to: item.to,
            };
          }),
        ]
      : [];
  return (
    <>
      <Tooltip
        id={isCollapsed ? `${title}-tooltip` : ""}
        content={title}
        place='right'
        positionStrategy='fixed'
        opacity={1}
        style={{
          borderRadius: "13px",
          background: "#2E323A",
          fontSize: "12px",
          boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
          zIndex: 1000,
        }}
      />
      {!dropdown ? (
        <NavLink
          data-tooltip-id={`${title}-tooltip`}
          to={to}
          className={({ isActive }) =>
            cn(
              "flex items-center w-full text-[#1F2228] py-1.5 px-3 gap-x-4 outline outline-1 outline-transparent hover:bg-[#F7F7F8] rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC]",
              {
                "justify-center rounded-full w-fit p-[7px] mx-auto": isCollapsed,
                "bg-[#F9F9FF] outline-[#F1F0FC]": isActive,
              },
              classes,
            )
          }
          children={({ isActive }) => (
            <div className='flex items-center w-full justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center justify-center cursor-pointer h-4 w-4 max-w-4 flex-shrink-0'>
                  {isActive ? <ActiveIcon /> : <Icon />}
                </div>{" "}
                {!isCollapsed && (
                  <Typography variant={TypographyVariants.MD_MEDIUM} className={cn({ "text-[#766CE4]": isActive })}>
                    {title}
                  </Typography>
                )}
              </div>
              {beta && !isCollapsed && !dropdown && <BetaLabel />}
            </div>
          )}
        ></NavLink>
      ) : !isCollapsed ? (
        <button
          data-tooltip-id={`${title}-tooltip`}
          onClick={toggleDropdown}
          className={cn(
            "flex items-center w-full text-[#1F2228] py-1.5 px-3 gap-x-4 outline outline-1 outline-transparent hover:bg-[#F7F7F8] rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC]",
            {
              "justify-center rounded-full w-fit p-[7px] mx-auto": isCollapsed,
              "bg-[#F9F9FF] outline-[#F1F0FC]": isActive,
            },
            classes,
          )}
        >
          <div className='flex items-center w-full justify-between'>
            <div className='flex items-center gap-4'>
              <div className='flex items-center justify-center cursor-pointer h-4 w-4 max-w-4 flex-shrink-0'>
                {isActive ? <ActiveIcon /> : <Icon />}
              </div>{" "}
              {!isCollapsed && (
                <Typography variant={TypographyVariants.MD_MEDIUM} className={cn({ "text-[#766CE4]": isActive })}>
                  {title}
                </Typography>
              )}
            </div>
            {!isCollapsed && <ArrowDownIcon className={cn("transform", { "rotate-180": dropdownOpenList.includes(title) })} />}
          </div>
        </button>
      ) : (
        <Dropdown className=' !left-10' menuBtnStyles='w-full' isNavBtn={true} placement='bottom end' items={dropdownOptions}>
          <div
            data-tooltip-id={`${title}-tooltip`}
            className={cn(
              "flex items-center w-full text-[#1F2228] py-1.5 px-3 gap-x-4 outline outline-1 outline-transparent hover:bg-[#F7F7F8] rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC]",
              {
                "justify-center rounded-full w-fit p-[7px] mx-auto": isCollapsed,
                "bg-[#F9F9FF] outline-[#F1F0FC]": isActive,
              },
              classes,
            )}
          >
            <div className='flex items-center w-full justify-between'>
              <div className='flex items-center gap-4'>
                <div className='flex items-center justify-center cursor-pointer h-4 w-4 max-w-4 flex-shrink-0'>
                  {isActive ? <ActiveIcon /> : <Icon />}
                </div>{" "}
                {!isCollapsed && (
                  <Typography variant={TypographyVariants.MD_MEDIUM} className={cn({ "text-[#766CE4]": isActive })}>
                    {title}
                  </Typography>
                )}
              </div>
              {!isCollapsed && <ArrowDownIcon className={cn("transform", { "rotate-180": dropdownOpenList.includes(title) })} />}
            </div>
          </div>
        </Dropdown>
      )}
      {dropdown && !isCollapsed && dropdownOpenList.includes(title) && (
        <div>
          {dropdown.map((item, index) => (
            <div
              key={item.to}
              className={"flex items-center w-full text-[#1F2228] pt-2 pl-[18px] outline outline-1 outline-transparent  relative"}
            >
              <div className='h-6 w-6 border-l-2 border-b-2 rounded-bl-lg border-dashed absolute transform -translate-y-3  ' />
              {index !== dropdown.length - 1 && <div className='h-6 w-1 border-l-2 border-dashed absolute transform translate-y-3  ' />}
              <NavLink
                to={item.to}
                className={({ isActive }) =>
                  cn(
                    "text-sm py-1.5 px-2 ml-6 hover:bg-[#F7F7F8] rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC] w-full",
                    handleIsActive(item.to, isActive) == true && "bg-[#f9f9ff]",
                  )
                }
              >
                {item.title}
              </NavLink>
            </div>
          ))}
        </div>
      )}

      {/* {dropdown && isCollapsed &&  dropdownOpenList.includes(title) && (
        <div className="absolute bottom-0 -right-40 z-50 bg-white p-1 shadow-allAroundGrey rounded-xl">
          {dropdown.map((item,index) => (
            <div
              key={item.to}
              className={
                "flex items-center w-full text-[#1F2228] outline outline-1 outline-transparent relative"
              }
            >    
              <NavLink
                to={item.to}
                
                className={({isActive})=> twMerge(cn("text-sm p-2 hover:bg-[#F7F7F8] text-nowrap rounded-lg focus:bg-[#E9EAEC] focus:text-[#1F2228] focus:outline-[#E9EAEC] w-full", {"bg-[#f9f9ff]":isActive}))}
              >
                {item.title}
              </NavLink>
            </div>
          ))}
        </div>
      )} */}
    </>
  );
}
