import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getUsersCompanyProfile = async (): Promise<any> => {
    return api(`${BASE_API}/companies/user`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };

  // company profile people tabs

  export const createNewTeamById = async (companyId:string, teamData:any) => {
    return api(`${BASE_API}/teams/${companyId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({name: teamData}),
    });
  };
  
  export const getTeamById = async (teamId:string) => {
    return api(`${BASE_API}/teams/${teamId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };
  
  export const getTeamsByCompanyId = async (companyId:string) => {
    return api(`${BASE_API}/teams/companies/${companyId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };
  
  export const updateTeamById = async (teamId:string, teamData:any) => {
    return api(`${BASE_API}/teams/${teamId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({name: teamData}),
    });
  };
  
  export const addTeamMember = async (teamId:string, memberData:any) => {
    return api(`${BASE_API}/teams/${teamId}/members`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(memberData),
    });
  };
  
  export const getTeamMembers = async (teamId:string) => {
    return api(`${BASE_API}/teams/${teamId}/members`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };
  
  export const inviteMember = async (teamId:string, inviteData:any) => {
    return api(`${BASE_API}/teams/${teamId}/invites`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(inviteData),
    });
  };

  export const deleteMember = async (teamId:string, member_id:string) => {
    return api(`${BASE_API}/teams/${teamId}/members/${member_id}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    });
  };

  export const updateMember = async (teamId:string, member_id:string, role_id:string) => {
    return api(`${BASE_API}/teams/${teamId}/members/${member_id}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({role_id}),
    });
  };
  
  