import { useMessage } from "@assistant-ui/react";
import { Thread, UserMessage, AssistantMessage } from "@assistant-ui/react-ui";
import "./ChatComponent.css";
import { FC, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { AgentItem } from "api/agent/type";

import { useAppSelector } from "hooks/appHooks";


import { useStreamContext } from "providers/Stream";

import { CustomComposer } from "./CustomComposer";

import { AIGenerateMessage } from ".";
// import { useStreamContext } from "@langchain/langgraph-sdk/react-ui";


interface MessageProps {
  message: { content: string };
}


const CustomUserMessage: FC = () => {
  return (
    <UserMessage.Root>
      <div className="float-right bg-[#F1F0FC] p-2 rounded-lg max-w-2xl mt-2 mr-2">
        <UserMessage.Content />
      </div>
    </UserMessage.Root>
  );
};

const toolNameToStatusMessage = (toolName: string | undefined): string => {
  switch (toolName) {
    case "transfer_to_datasourceconnectionagent":
      return "Setting things up...";
    case "get_connected_sources":
      return "Checking which data sources you have already connected...";
    case "get_available_data_sources":
      return "Checking for available data sources...";
    default:
      return "Thinking...";
  }
}

const CustomAssistantMessage: FC = () => {
  const msg = useMessage();
  
  console.log("mmm", msg);
  const toolCalls = [...msg.content].reverse().find(
    (item: { type: string }) => item.type === "tool-call"
  ) as { type: string; toolName?: string } | undefined;
  const statusMessage = toolNameToStatusMessage(toolCalls?.toolName);
  
  return (
    <div className="w-full">
      <AssistantMessage.Root>
        {msg.status?.type !== "running" ? (
          <div>
          </div>
        ) : (
          <div className="mt-3">
            <AIGenerateMessage>
              <p className="text-[#717684] text-sm font-medium">{statusMessage}</p>
            </AIGenerateMessage>
          </div>
        )}
      </AssistantMessage.Root>
    </div>
  );
};


const MyThread = () => {
    const [agentUuid] = useQueryParam("agent_uuid", StringParam);
    const agents = useAppSelector(state => state.agent.agents);
    const agent = useMemo(() => agents?.find((agent: AgentItem) => agent.uuid === String(agentUuid)), [agents, agentUuid]);

    const stream = useStreamContext();
    const messages = stream.messages;
    // const isLoading = stream.isLoading;
  return (
    <div className="w-full">
      <Thread.Root>
        <Thread.Viewport>
          <div className="max-h-[calc(100vh-375px)] overflow-y-auto flex flex-col" style={{scrollbarWidth:"none"}}>
            <Thread.Messages
              components={{
                UserMessage: CustomUserMessage,
                AssistantMessage: CustomAssistantMessage,
              }}
            />
          </div>
          <Thread.ViewportFooter>
            <CustomComposer disabled={true} />
          </Thread.ViewportFooter>
        </Thread.Viewport>
      </Thread.Root>
    </div>
  );
};

export default MyThread;
