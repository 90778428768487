import { HumanInterrupt } from "@langchain/langgraph/prebuilt";

function isHumanInterrupt(value: unknown): value is HumanInterrupt {
  const valueAsObject = Array.isArray(value) ? value[0] : value;
  return (
    valueAsObject &&
    "action_request" in valueAsObject &&
    typeof valueAsObject.action_request === "object" &&
    "args" in valueAsObject.action_request &&
    typeof valueAsObject.action_request.args === "object" &&
    "type" in valueAsObject.action_request.args &&
    typeof valueAsObject.action_request.args.type === "string" &&
    "config" in valueAsObject &&
    typeof valueAsObject.config === "object" &&
    "allow_respond" in valueAsObject.config &&
    "allow_accept" in valueAsObject.config &&
    "allow_edit" in valueAsObject.config &&
    "allow_ignore" in valueAsObject.config
  );
}

export function isAgentInboxInterruptSchema(value: unknown): value is HumanInterrupt | HumanInterrupt[] {
  const valueAsObject = Array.isArray(value) ? value[0] : value;
  return isHumanInterrupt(valueAsObject) && valueAsObject.action_request.args.type === "agent-inbox-interrupt";
}

export function isDataSourceInterruptSchema(value: unknown): value is HumanInterrupt | HumanInterrupt[] {
  const valueAsObject = Array.isArray(value) ? value[0] : value;
  return isHumanInterrupt(valueAsObject) && valueAsObject.action_request.args.type === "data-source-interrupt";
}

export function isNotificationSetupInterruptSchema(value: unknown): value is HumanInterrupt | HumanInterrupt[] {
  const valueAsObject = Array.isArray(value) ? value[0] : value;
  return isHumanInterrupt(valueAsObject) && valueAsObject.action_request.args.type === "notification-setup-interrupt";
}
