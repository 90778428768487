import { AssistantRuntimeProvider } from "@assistant-ui/react";
import { useLangGraphRuntime } from "@assistant-ui/react-langgraph";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { StringParam, useQueryParam } from "use-query-params";

import { selectUser } from "storage/selectors/user";

import { createThread, sendMessage } from "lib/chatApi";

import { useAI } from "./provider";

interface MyRuntimeProviderProps {
  children: React.ReactNode;
}

export const MyRuntimeProvider: React.FC<MyRuntimeProviderProps> = ({ children }) => {
  const { setIsBack, setDescription, setIsProcessing, description, setText } = useAI();
  const user = useSelector(selectUser);

  const [threadId, setThreadId] = useQueryParam("threadId", StringParam);
  const runtime = useLangGraphRuntime({
    threadId: threadId ?? undefined,
    stream: async (messages) => {
      setIsBack(true)
      setDescription("")
      setText("")
      
      const elements = document.querySelectorAll(".aui-assistant-message-root");
      elements.forEach((element) => {
        (element as HTMLElement).style.display = "block";
      });

      const elementDiv = document.getElementById("conctdDiv");
      if (elementDiv) {
        elementDiv.style.display = "block";
      }

      let newThreadId = threadId;
      if (!threadId) {
        const metadata = { user_id: user?.user_id };
        const { thread_id } = await createThread({metadata});
        newThreadId = thread_id;
        setThreadId(thread_id);
      }    
      setIsProcessing(true)
      const dataStr = await sendMessage({
        threadId: newThreadId || "",
        messages,
        userId: user?.user_id,
      });

      return dataStr
    },
  });

  return (
    <AssistantRuntimeProvider runtime={runtime}>
      {children}
    </AssistantRuntimeProvider>
  );
};
