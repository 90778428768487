import React, { createContext, useContext, useState, ReactNode, useMemo, useEffect, useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { AgentItem } from "api/agent/type";
import { getConversationMessages } from "api/conversation";
import { ICreateProjectData } from "api/projects/types";

import { AGENT_MOCK_RESPONSE } from "constants/agent.constants";

import { useAppSelector } from "hooks/appHooks";
import { useSocketIO } from "hooks/useSocketIO";

export interface IChatMessage {
  id: string;
  sender: "user" | "assistant";
  message: string;
  data?: {
    type:
      | "chat"
      | "suggestion"
      | "action"
      | "question"
      | "approval_request"
      | "source"
      | "file_upload"
      | "platform_notification"
      | "suggested_integrations";
    [key: string]: any;
  };
  isActionDisabled?: boolean;
}

export interface ChatHistory {
  date: string;
  chats: Array<ChatHistoryConversation>;
}

export interface ChatHistoryConversation {
  conversation_id: string;
  user_id: number;
  agent_id: string;
  status: string;
  last_interaction: Date;
  createdAt: Date;
  updatedAt: Date;
  messages: ChatHistoryMessage[];
}

export interface ChatBudgetFormDetailInput {
  description: boolean;
  revenue_range: boolean;
  product_and_service: boolean;
  defaultDescriptionValue?: string;
  defaultRevenueRangeValue?: string;
  defaultProductAndServiceValue?: string;
}

export interface ChatHistoryMessage {
  message_id: string;
  role: string;
  message: string;
  timestamp: Date;
}

type BudgetFormAction = (value: Record<string, any>) => Record<string, any>;

interface AIPanelContextProps {
  isOpen: boolean;
  showAsSheet: boolean;
  openPanel: (asSheet?: boolean) => void;
  closePanel: () => void;
  page: "active" | "history";
  switchPage: (page: "active" | "history") => void;
  sendMessage: (message: string, echo?: boolean) => void;
  sendFormInput: (inputs: Record<string, any>, isSending?: boolean) => void;
  chats: IChatMessage[];
  isSending: boolean;
  isIntelligence: boolean;
  setIntelligence: (value: boolean, context?: string[]) => void;
  maximizePanel: () => void;
  isMaximized: boolean;
  agent?: AgentItem | null;
  connecting: boolean;
  isStreaming: boolean;
  processingMessage: string;
  showWhatShouldIAsk: boolean;
  setWhatShouldIAsk: (value: boolean) => void;
  onSelectConversation: (id: string) => void;
  isCompanyInfoContext: boolean;
  showBudgetFormDetailInput: ChatBudgetFormDetailInput;
  handleShowOrHideBudgetFormDetailInput: (value: ChatBudgetFormDetailInput) => void;
  handleAddOrUpdateChat: (chat: IChatMessage) => void;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  showScrollButton: boolean;
  showTopGradient: boolean;
  scrollToBottom: () => void;
  handleScrollToBottom: () => void;
  handleUpdateUserFeedbackNeeded: (value: boolean) => void;
  isUserFeedbackNeeded: boolean;
  sendConfirmationResponse: (payload: { responses: Record<string, any>; isSending?: boolean }) => void;
  setIsBudgetIntelligence: (value: boolean) => void;
  customTextFieldQuestions: Array<{
    id: string;
    label: string;
  }>;
  handleUpdateCustomTextFieldQuestions: (value: { id: string; label: string }) => void;
  updateBudgetFormState?: React.Dispatch<React.SetStateAction<Partial<ICreateProjectData> & { isRevenueUnknown?: boolean }>>;
  messageContextInput: string;
  setMessageContextInput: React.Dispatch<React.SetStateAction<string>>;
  isReadOnly: boolean;
  setIsReadOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
  isDataPreviewOpen: boolean;
  setIsDataPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAnime: boolean;
  setIsAnime: React.Dispatch<React.SetStateAction<boolean>>;
  isProcessing: boolean;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  isBack: boolean;
  setIsBack: React.Dispatch<React.SetStateAction<boolean>>;
  frequencyNotificationPlatForm: string;
  setFrequencyNotificationPlatForm: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  isWorkflow: boolean;
  setIsWorkflow: React.Dispatch<React.SetStateAction<boolean>>;
  isFlag: boolean;
  setIsFlag: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
}

const AIPanelContext = createContext<AIPanelContextProps | undefined>(undefined);

export const AIPanelProvider = ({
  children,
  budgetFormState,
  updateBudgetFormState,
}: {
  children: ReactNode;
  budgetFormState?: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  updateBudgetFormState?: React.Dispatch<React.SetStateAction<Partial<ICreateProjectData> & { isRevenueUnknown?: boolean }>>;
}) => {
  const [searchParams] = useSearchParams();
  const preferences = useAppSelector(state => state.preferences);
  const agentUuid = searchParams.get("agent_uuid");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isDataPreviewOpen, setIsDataPreviewOpen] = useState(false);
  const [isAnime, setIsAnime] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [isWorkflow, setIsWorkflow] = useState(false);
  const [isFlag, setIsFlag] = useState(false);
  const [frequencyNotificationPlatForm, setFrequencyNotificationPlatForm] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [showAsSheet, setShowAsSheet] = useState(false);
  const [page, setPage] = useState<"active" | "history">("active");
  const [chats, setChats] = useState<IChatMessage[]>([]);
  const { socket, connecting } = useSocketIO(`${process.env.REACT_APP_WS_URL}/chat`);
  const [isSending, setIsSending] = useState(false);
  const [isIntelligence, setIsIntelligence] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const agents = useAppSelector(state => state.agent.agents);
  const conversation_id = useAppSelector(state => state.conversation.conversation_id);
  const [showWhatShouldIAsk, setShowWhatShouldIAsk] = useState(false);
  const [isCompanyInfoContext, setIsCompanyInfoContext] = useState(false);
  const [showBudgetFormDetailInput, setShowBudgetFormDetailInput] = useState<ChatBudgetFormDetailInput>({
    description: false,
    revenue_range: false,
    product_and_service: false,
    defaultDescriptionValue: "",
    defaultRevenueRangeValue: "",
    defaultProductAndServiceValue: "",
  });
  const [messageContextInput, setMessageContextInput] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [text, setText] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showTopGradient, setShowTopGradient] = useState(false);
  const [isUserFeedbackNeeded, setIsUserFeedbackNeeded] = useState(false);
  const [isBudgetIntelligence, setIsBudgetIntelligence] = useState(false);
  const [customTextFieldQuestions, setCustomTextFieldQuestions] = useState<Array<{ id: string; label: string }>>([]);

  const agent = useMemo(
    () =>
      agents?.find(
        (agent: AgentItem) => agent.uuid === String(agentUuid) || agent.uuid === preferences.preferences.agent_uuid || (!agentUuid && agent.id === 1),
      ) || null,
    [agents, agentUuid, preferences],
  );

  const formatAIMessageToJSON = (data: string): any => {
    if (!data) {
      return null;
    }

    try {
      const parsedData = JSON.parse(data);
      return parsedData;
    } catch (error) {
      // console.error("Error formatting AI message:", error);
      return null;
    }
  };

  const cleanMarkdownText = (text: string): string => {
    return text;
    // Remove extra newlines and spaces
    // .replace(/\n\s+/g, "\n")
    // Remove leading/trailing whitespace
    // .trim()
    // Ensure consistent line breaks
    //.replace(/\n{3,}/g, "\n\n")
    // Ensure proper spacing around headers
    //.replace(/\*\*(.*?)\*\*/g, "**$1**\n")
    // Add space after colons
    //.replace(/:\n/g, ": ")
  };

  const resetState = useCallback(() => {
    setIsSending(false);
    setIsStreaming(false);
    setProcessingMessage("");
  }, []);

  useEffect(() => {
    if (connecting) {
      resetState();
    }
  }, [connecting]);

  useEffect(() => {
    if (!socket) return;
    // "stream" not used anymore
    // socket.on("stream", event => {
    //   console.log("Stream", event);
    //   const formattedData = formatAIMessageToJSON(event.data);
    //   setIsStreaming(true);

    //   if (formattedData) {
    //     console.log("%s agent says: %o", formattedData?.data?.agent, formattedData.data.content);
    //     const message = formattedData.data.content;
    //     if (typeof message === "string") {
    //       handleAddOrUpdateChat({ sender: "ai", message });
    //       setIsSending(false);
    //     }
    //   }
    // });
    socket.on("intermediate_step", event => {
      console.log("Intermediate Step", event);
      if (event.content === "") {
        // Temp need to handle from backend
        handleAddOrUpdateChat({
          id: uuidv4(),
          sender: "assistant",
          message: AGENT_MOCK_RESPONSE.IrrelevantErrorMsg,
        });
      } else {
        // try {
        //   const data = JSON.parse(event.content);

        //   if (data.type != undefined) {
        //     handleProcessAdditionalData(data);
        //   } else {
        //     if (data.content != undefined) {
        //       handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: cleanMarkdownText(data.content) });
        //     }

        //     if (
        //       (data.description != undefined || data.revenue_range != undefined || data.products_and_services != undefined) &&
        //       data.formData == undefined
        //     ) {
        //       handleProcessFormData(data);
        //     }

        //     if (data.formData != undefined) {
        //       handleProcessFormData(data.formData);
        //     }
        //   }
        // } catch (err) {
        //   handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: cleanMarkdownText(event.content) });
        // }
        handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: event.content });
      }

      if (event.formData != undefined) {
        handleProcessFormData(event.formData);
      }
    });
    socket.on("final_answer", event => {
      console.log("Final Answer", event);

      if (event.questions != undefined && event.questions.length > 0 && event.questions[0]?.label != undefined) {
        event.questions.forEach((question: any) => {
          switch (question.type) {
            case "text_field":
              handleUpdateCustomTextFieldQuestions({ id: uuidv4(), label: question.label });
              break;

            case "pills":
              handleProcessSuggestion({
                content: question.label,
                choices: question.options,
                type: "suggestion",
              });
              break;

            case "suggested_integrations":
              handleProcessSuggestedIntegrations({
                content: question.label,
                choices: question.options,
                type: "suggested_integrations",
              });
              break;

            default:
              if (Object.keys(question).length > 0) {
                handleProcessAdditionalData({
                  content: question.label,
                  choices: question.options,
                  type: "question",
                });
              } else {
                handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: event.content });
              }
          }
        });
      } else if (event.content != undefined && event.content != "") {
        setChats(prevChats => [...prevChats, { id: uuidv4(), sender: "assistant", message: event.content }]);
      }

      if (event.formData != undefined) {
        handleProcessFormData(event.formData);
      }

      setIsSending(false);
      setProcessingMessage("");
      console.log("should stop streaming");
    });
    socket.on("agent_status", event => {
      console.log("Agent Status", event);

      setProcessingMessage(event.data);
      setIsSending(false);
    });

    socket.on("exception", event => {
      handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: AGENT_MOCK_RESPONSE.ErrorMsg });
      setIsSending(false);
      setIsStreaming(false);
    });

    socket.on("end", () => {
      //streamingRef.current = false;
      setIsStreaming(false);
    });

    return () => {
      //
    };
  }, [socket]);

  useEffect(() => {
    //
    if (isOpen == true) {
      if (agents.length === 0) return;

      // add ai chats
      if (chats.length === 0) {
        setChats([
          {
            sender: "assistant",
            message: "Hi",
            id: uuidv4(),
          },
          {
            id: uuidv4(),
            sender: "assistant",
            message: `I’m ${agent?.name}, ${agent?.title}. I’ll guide you through every step and make sure everything is set up perfectly. Let’s get started!`,
          },
        ]);
      }
    }
  }, [isOpen, showAsSheet]);

  const handleAddOrUpdateChat = useCallback(
    (chat: IChatMessage) => {
      setChats(prevChats => {
        const index = prevChats.findIndex(c => c.id === chat.id);
        if (index !== -1) {
          const updatedChats = [...prevChats];
          updatedChats[index] = chat;
          return updatedChats;
        }

        return [...prevChats, chat];
      });

      scrollToBottom();
    },
    [chats],
  );

  const handleProcessAdditionalData = useCallback(
    (data: any) => {
      let additional_data: { type: "suggestion" | "action" | "question" | "approval_request"; [key: string]: any } | undefined;

      let isCompanyContext = false;

      try {
        isCompanyContext = (data.content as string).includes("Company Context");

        if (isCompanyContext) {
          setIsCompanyInfoContext(true);
        }
      } catch (e) {
        //
      }

      if (data.type != undefined) {
        additional_data = { type: data.type };

        if (data.choices != undefined) {
          additional_data.choices = data.choices.map((choice: string) => {
            const label = choice.toLowerCase();

            let action = () => {
              //
            };

            if (label == "yes" || label == "no") {
              action = () => {
                switch (label) {
                  case "yes":
                    sendMessage("Yes");
                    handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: "Got it!" });
                    handleUpdateUserFeedbackNeeded(false);
                    break;
                  case "no":
                    handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: "Got it!" });
                    setTimeout(() => {
                      handleAddOrUpdateChat({
                        id: uuidv4(),
                        sender: "assistant",
                        message: AGENT_MOCK_RESPONSE.CompanyUrlNoActionResponse,
                      });
                    }, 1000);
                    break;
                }
              };
            }

            return {
              label: choice,
              value: choice,
              action: action,
            };
          });

          handleUpdateUserFeedbackNeeded(true);
        }

        // TODO: Remove this after backend is updated
        // handleAddOrUpdateChat({
        //     id: uuidv4(),
        //     sender: "assistant",
        //     message: "",
        //     data: {
        //       type: "source",
        //       sources: [
        //         {
        //           link: "https://www.google.com",
        //           description: "Google is a search engine.",
        //         },
        //         {
        //           link: "https://www.brainvoy.ai",
        //           description: "Empowering Enterprise Teams with AI-Powered Insights",
        //         },
        //       ],
        //     },
        //   },
        //);
      }

      handleAddOrUpdateChat({ id: uuidv4(), sender: "assistant", message: data.content, data: additional_data });
    },
    [chats],
  );

  const handleDisabledLastSuggestionAction = useCallback(() => {
    // find last chat with goals and disable action
    setChats(prev => {
      const lastSuggestionChat = [...prev].reverse().find(chat => chat.data?.type === "suggestion" && Array.isArray(chat.data?.goals));

      if (lastSuggestionChat) {
        const updatedChats = [...prev];
        const index = updatedChats.findIndex(chat => chat.id === lastSuggestionChat.id);

        if (index !== -1) {
          updatedChats[index] = {
            ...lastSuggestionChat,
            isActionDisabled: true,
          };
        }

        return updatedChats;
      }

      return prev;
    });
  }, [chats]);

  const handleProcessSuggestedIntegrations = useCallback(
    (suggestion: { content: string; choices: string[]; type: string }) => {
      const { content, choices } = suggestion;

      const id = uuidv4();

      const message: IChatMessage = {
        id: id,
        sender: "assistant",
        message: content,
        data: {
          type: "suggested_integrations",
          options: choices,
        },
      };

      handleAddOrUpdateChat(message);
    },
    [handleAddOrUpdateChat],
  );
  const handleProcessSuggestion = useCallback(
    (suggestion: { content: string; choices: string[]; type: string }) => {
      const { content, choices } = suggestion;

      const id = uuidv4();

      const message: IChatMessage = {
        id: id,
        sender: "assistant",
        message: content,
        data: {
          type: "suggestion",
          goals: [
            ...choices.map(item => ({
              label: item,
              isChecked: false,
              action: () => {
                if (updateBudgetFormState) {
                  updateBudgetFormState(prev => {
                    let newGoals = prev.goals ?? [];

                    if (newGoals.length > 2 && !newGoals.includes(item)) {
                      return {
                        ...prev,
                        goals: newGoals,
                      };
                    }

                    if (newGoals.includes(item)) {
                      // remove item from goals
                      newGoals = newGoals.filter((goal: string) => goal !== item);
                    } else {
                      newGoals.push(item);
                    }

                    if (newGoals.length > 0) {
                      handleAddOrUpdateChat({
                        ...message,
                        data: {
                          type: "suggestion",
                          goals:
                            message.data?.goals.map((item: any) => {
                              return {
                                ...item,
                                isChecked: newGoals.includes(item.label),
                              };
                            }) ?? [],
                        },
                      });
                    }

                    return {
                      ...prev,
                      goals: newGoals,
                    };
                  });
                }
              },
            })),
          ],
        },
      };

      handleDisabledLastSuggestionAction();

      handleAddOrUpdateChat(message);
    },
    [budgetFormState, updateBudgetFormState, handleAddOrUpdateChat],
  );

  const handleUpdateCustomTextFieldQuestions = useCallback(
    (value: { id: string; label: string }) => {
      setCustomTextFieldQuestions(prev => {
        // check if id exist and remove it question
        const index = prev.findIndex(item => item.id === value.id);

        if (index !== -1) {
          return prev.filter(item => item.id !== value.id);
        }

        return [...prev, value];
      });
    },
    [customTextFieldQuestions],
  );

  const handleProcessFormData = useCallback(
    (data: any) => {
      console.log("Form Data", data);

      if (data.description !== undefined || data.revenue_range !== undefined || data.products_and_services !== undefined) {
        handleUpdateBudgetFormDefaultValues(data.description, data.revenue_range, data.products_and_services);
      }
    },
    [chats, showBudgetFormDetailInput],
  );

  const sendMessage = (text: string, echo?: boolean) => {
    
    if (!socket) return;
    console.log("setting is sending to true A");
    setIsSending(true);
    socket.emit("user_input", {
      event: {
        type: "user_input",
        content: text,
      },
      conversation_id,
      agentName: agent?.name,
    });

    if (echo === false) return; // ie, do not echo the message to the chat, only send it to the server
    handleAddOrUpdateChat({ id: uuidv4(), sender: "user", message: text });
  };

  const sendFormInput = (inputs: Record<string, any>, isSending = true) => {
    if (!socket) return;
    setIsSending(isSending);

    socket.emit("form_input", {
      event: {
        type: "form_input",
        data: inputs,
      },
      conversation_id,
      agentName: agent?.name,
    });
  };

  const sendConfirmationResponse = ({ responses, isSending = false }: { responses: Record<string, any>; isSending?: boolean }) => {
    if (!socket) return;
    setIsSending(isSending);

    socket.emit("confirmation_response", {
      formData: responses,
      conversation_id,
      agentName: agent?.name,
    });

    //     const message = `Here are the revised details:
    // **Company Description:**
    // ${responses.description}
    // **Revenue Range:**
    // ${responses.revenue_range}
    // **Products & Services:**
    // ${responses.products_and_services}`;
    //     sendMessage(message);
  };

  const openPanel = (asSheet = false) => {
    setShowAsSheet(asSheet);
    setIsOpen(true);
  };

  const closePanel = () => {
    setIsOpen(false);
    setShowAsSheet(false);
  };

  const switchPage = (newPage: "active" | "history") => {
    setPage(newPage);
  };

  const setIntelligence = useCallback(
    (value: boolean, context?: string[]) => {
      setIsIntelligence(value);
    },
    [isIntelligence],
  );

  const maximizePanel = () => {
    setIsMaximized(prev => !prev);
  };

  const setWhatShouldIAsk = useCallback((value: boolean) => {
    setShowWhatShouldIAsk(value);
  }, []);

  const onSelectConversation = useCallback((id: string) => {
    setIsStreaming(true);
    getConversationMessages(id, 10, 0)
      .then(data => {
        setChats([
          ...data.rows.map((res: any) => {
            return {
              sender: res.role,
              message: res.message,
            };
          }),
        ]);
      })
      .catch(err => {
        handleAddOrUpdateChat({
          id: uuidv4(),
          sender: "assistant",
          message: AGENT_MOCK_RESPONSE.ErrorMsg,
        });
      })
      .finally(() => {
        setIsStreaming(false);
      });
  }, []);

  const handleUpdateBudgetFormDefaultValues = useCallback(
    (description?: string, revenue_range?: string, product_and_service?: string) => {
      setShowBudgetFormDetailInput({
        ...showBudgetFormDetailInput,
        defaultDescriptionValue: description ?? showBudgetFormDetailInput.defaultDescriptionValue,
        defaultRevenueRangeValue: revenue_range ?? showBudgetFormDetailInput.defaultRevenueRangeValue,
        defaultProductAndServiceValue: product_and_service ?? showBudgetFormDetailInput.defaultProductAndServiceValue,
      });
    },
    [showBudgetFormDetailInput],
  );

  const handleShowOrHideBudgetFormDetailInput = useCallback((value: ChatBudgetFormDetailInput) => {
    setShowBudgetFormDetailInput(value);
  }, []);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    if (chats.length > 3) {
      setShowTopGradient(true);
    }
  }, [showTopGradient, showScrollButton, chats]);

  const handleScrollToBottom = useCallback(() => {
    // if (chatContainerRef.current) {
    //   const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    //   if (scrollHeight - scrollTop === clientHeight) {
    //     setShowTopGradient(false);
    //   } else {
    //     setShowTopGradient(true);
    //   }
    // }
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      console.log("🚀 ~ handleScrollToBottom ~ scrollTop:", scrollTop);
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
      setShowScrollButton(!isNearBottom);

      if (chats.length <= 2) {
        setShowTopGradient(scrollTop > 0);
      }
    }
  }, [showTopGradient, showScrollButton, chats]);

  const handleUpdateUserFeedbackNeeded = useCallback(
    (value: boolean) => {
      setIsUserFeedbackNeeded(value);
    },
    [isUserFeedbackNeeded, setIsUserFeedbackNeeded],
  );

  const value = useMemo<AIPanelContextProps>(
    () => ({
      setIsSending,
      isReadOnly,
      setIsReadOnly,
      isOpen,
      showAsSheet,
      openPanel,
      closePanel,
      page,
      switchPage,
      sendMessage,
      chats,
      isSending,
      isIntelligence,
      setIntelligence,
      maximizePanel,
      isMaximized,
      agent,
      connecting,
      isStreaming,
      processingMessage,
      showWhatShouldIAsk,
      setWhatShouldIAsk,
      onSelectConversation,
      sendFormInput,
      isCompanyInfoContext,
      showBudgetFormDetailInput,
      handleShowOrHideBudgetFormDetailInput,
      handleAddOrUpdateChat,
      messagesEndRef,
      chatContainerRef,
      showTopGradient,
      showScrollButton,
      scrollToBottom,
      handleScrollToBottom,
      handleUpdateUserFeedbackNeeded,
      isUserFeedbackNeeded,
      sendConfirmationResponse,
      setIsBudgetIntelligence,
      handleUpdateCustomTextFieldQuestions,
      customTextFieldQuestions,
      updateBudgetFormState,
      messageContextInput,
      setMessageContextInput,
      isDataPreviewOpen,
      setIsDataPreviewOpen,
      frequencyNotificationPlatForm,
      setFrequencyNotificationPlatForm,
      description,
      setDescription,
      text,
      setText,
      isAnime,
      setIsAnime,
      isProcessing,
      setIsProcessing,
      isBack,
      setIsBack,
      isWorkflow,
      setIsWorkflow,
      isFlag,
      setIsFlag
    }),
    [
      isOpen,
      showAsSheet,
      page,
      switchPage,
      closePanel,
      sendMessage,
      chats,
      isSending,
      isIntelligence,
      setIntelligence,
      maximizePanel,
      isMaximized,
      agent,
      connecting,
      isStreaming,
      processingMessage,
      showWhatShouldIAsk,
      setWhatShouldIAsk,
      onSelectConversation,
      sendFormInput,
      isCompanyInfoContext,
      showBudgetFormDetailInput,
      handleShowOrHideBudgetFormDetailInput,
      handleAddOrUpdateChat,

      messagesEndRef,
      chatContainerRef,
      showTopGradient,
      showScrollButton,
      scrollToBottom,
      handleScrollToBottom,
      handleUpdateUserFeedbackNeeded,
      isUserFeedbackNeeded,
      sendConfirmationResponse,
      setIsBudgetIntelligence,
      isBudgetIntelligence,
      handleUpdateCustomTextFieldQuestions,
      customTextFieldQuestions,
      updateBudgetFormState,
      budgetFormState,
      messageContextInput,
      setMessageContextInput,
      isReadOnly,
      setIsReadOnly,
      setIsSending,
      isDataPreviewOpen,
      setIsDataPreviewOpen,
      frequencyNotificationPlatForm,
      setFrequencyNotificationPlatForm,
      description,
      setDescription,
      isAnime,
      setIsAnime,
      isBack,
      setIsBack,
      isWorkflow,
      setIsWorkflow,
      isFlag,
      setIsFlag
    ],
  );

  return <AIPanelContext.Provider value={value}>{children}</AIPanelContext.Provider>;
};

export const useAI = () => {
  const context = useContext(AIPanelContext);
  if (!context) {
    throw new Error("useAIPanel must be used within an AIPanelProvider");
  }
  return context;
};
