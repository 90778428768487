import cn from "classnames";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { IChannelsTransformedData, IChannelsTransformedDataValues } from "api/budget/types";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";
import { ROUTES } from "constants/routes.constants";
import { DropdownVariants } from "constants/shared/dropdown.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";
import { CreateWorkflowPayload } from "interfaces/workflows.interfaces";

import { getPerformancePlanUrlById, getWorkflowDetailsUrlById } from "utils/url.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useLoadWorkflowsStrategy } from "hooks/workflows/useLoadWorkflowsStrategy";

import { createWorkflow, setIsWorkflowsLoaded } from "storage/actions/workflows";
import { selectIsAvailableToCreateBudget } from "storage/selectors/user";
import { openPopup } from "storage/slices/popup-slice";

import BlurOverlay from "shared/components/BlurOverlay";
import { Dropdown } from "shared/components/dropdown/Dropdown";
import Alert from "shared/components/toasts";

import classes from "pages/budget/BudgetIntelligence/MarketingpView/ChannelStrategy/ChannelStrategy.module.scss";
import { ChannelStrategyCard } from "pages/budget/BudgetIntelligence/MarketingpView/ChannelStrategy/ChannelStrategyCard";

import { ReactComponent as ArrowExternalIcon } from "assets/icons/arrow-external.svg";
import { ReactComponent as MagicPenIcon } from "assets/icons/magicpen-1.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plusButton.svg";
import { ReactComponent as SearchIcon } from "assets/icons/searchGlass.svg";

interface Props {
  channel: IChannelsTransformedDataValues;
  activeMonth: IChannelsTransformedData;
  activeMonthBudget: any; // TODO - REMOVE ANY!!
  isCardAvailable?: boolean;
  index: number;
  handleFocusChannel?: (value: NumberOrNull) => void;
  focusedChannelId?: number | null;
  redirectOnAction?: boolean;
  currentActiveData?: IChannelsTransformedData;
}

export const ChannelStrategyNode = ({
  channel,
  activeMonthBudget,
  isCardAvailable,
  activeMonth,
  index,
  handleFocusChannel,
  focusedChannelId,
  redirectOnAction,
  currentActiveData,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleOpenMembershipsModal = () => dispatch(openPopup({ content: "MembershipsModal" }));
  const isAvailableToCreateBudget = useAppSelector(selectIsAvailableToCreateBudget);
  const strategies = useAppSelector(state => state.workflows.strategies);
  const { id: budgetId } = useParams();

  const {
    workflowStrategiesData: { workflowStrategies },
    handleLoadWorkflowStrategies,
  } = useLoadWorkflowsStrategy();

  const Icon = CHANNEL_FLOW_ICONS?.[channel?.icon as keyof typeof CHANNEL_FLOW_ICONS];

  const isValidData =
    typeof channel?.value === "number" && channel?.value >= 0 && typeof activeMonthBudget === "number" && activeMonthBudget > 0;

  // TODO - MOVE TO USEMEMO  + REFACTORING!!!!!!!
  let percentage = isValidData ? channel?.value / (activeMonthBudget / 100) : 0;

  const handleNavigateToWorkflow = () => {
    if (!budgetId) return;
    navigate(getWorkflowDetailsUrlById(budgetId));
  };

  const handleFocusedChannel = (isDropdownOpen?: boolean) => {
    if (!handleFocusChannel) return;

    if (isDropdownOpen) {
      handleFocusChannel(channel.channel_id);
      return;
    }

    if (!isDropdownOpen && focusedChannelId === undefined) {
      return;
    }

    handleFocusChannel(null);
  };

  if (index === activeMonth.values?.length - 1) {
    percentage =
      100 -
      activeMonth?.values.slice(0, -1).reduce((acc: number, item: any) => acc + Math.round(item.value / (activeMonthBudget / 100)), 0);
  } else {
    percentage = Math.round(percentage);
  }

  if (isNaN(percentage)) {
    percentage = 0;
  } else if (percentage < 1) {
    percentage = parseFloat(percentage.toFixed(1));
  } else {
    percentage = Math.round(percentage);
  }
  const handleCreateWorkflow = useCallback(
    ({ project_id, strategy_id, budget }: CreateWorkflowPayload & { budget: number }) => {
      if (!isAvailableToCreateBudget) {
        dispatch(
          openPopup({
            content: "MembershipsModal",
          }),
        );
        return;
      }
      dispatch(createWorkflow({ strategy_id, project_id }))
        .unwrap()
        .then(data => {
          dispatch(setIsWorkflowsLoaded(null));
          navigate(ROUTES.createPerformancePlan, {
            state: {
              budget,
              project_id: project_id,
              strategy_id: strategy_id,
              workflowData: data,
              activeDate: currentActiveData?.month,
            },
          });
        })
        .catch(error => {
          Alert("error", error.message, "Error");
          console.log("Error", error);
          if (error.message === "Your pricing plan does not allow creating new workflows") {
            handleOpenMembershipsModal();
          }
        });
    },
    [dispatch, navigate, isAvailableToCreateBudget],
  );
  const formattedDropdownStrategyOptions = useMemo(() => {
    const workflowStrategies = strategies?.filter(strategy => strategy.channel_id === channel?.channel_id);
    if (!workflowStrategies?.length) return [];

    return workflowStrategies?.map(({ strategy_id, name }) => ({
      id: String(strategy_id),
      label: name,
      icon: MagicPenIcon,
      action: () => {
        if (!budgetId) return;

        handleCreateWorkflow({ strategy_id, project_id: Number(budgetId), budget: channel?.value });
      },
      additionalIcon: ArrowExternalIcon,
    }));
  }, [budgetId, channel?.value, handleCreateWorkflow]);
  //Temp Starts Remove it later
  const availableList = ["Social Media", "Paid Ads"];
  //Temp Ends
  return (
    <div
      style={{ border: `1.5px solid ${channel?.color?.light}` }}
      className={cn(classes.strategy__child__nodeLabel, "relative bg-white transition-all duration-300 rounded-xl", {
        "opacity-40": focusedChannelId !== null && focusedChannelId !== undefined && focusedChannelId !== channel.channel_id,
      })}
    >
      <BlurOverlay className='bg-white/50' isShown={!isCardAvailable} />
      <div className={classes.strategy__child__nodeLabel__top}>
        <div className={classes.strategy__child__nodeLabel__top__leftBLock}>
          <div className={`${classes.strategy__child__nodeLabel__top__leftBLock__icon}`} style={{ backgroundColor: channel?.color?.light }}>
            {Icon ? <Icon color={channel?.color?.dark} /> : <SearchIcon color={channel?.color?.dark} />}
          </div>
          <h3 className={classes.strategy__child__nodeLabel__top__leftBLock__title}>{channel?.channel}</h3>
        </div>
        <div className={classes.strategy__child__nodeLabel__top__rightBLock}>
          {redirectOnAction ? (
            <button>
              <PlusIcon onClick={handleNavigateToWorkflow} />
            </button>
          ) : (
            <Dropdown
              className='!w-[280px]'
              placement='bottom start'
              variant={DropdownVariants.SECONDARY}
              heading='Create workflow'
              headingClass='!text-sm !leading-default'
              items={formattedDropdownStrategyOptions}
              handleIsOpen={handleFocusedChannel}
              overlay={!availableList.includes(channel?.channel ?? "")}
              category={channel?.channel ?? ""}
            >
              <PlusIcon />
            </Dropdown>
          )}
        </div>
      </div>
      <div className={twMerge(classes.strategy__child__nodeLabel__bottom, "space-x-1 !mt-2")}>
        <ChannelStrategyCard title='Strategy'>{channel?.summary}</ChannelStrategyCard>
        <ChannelStrategyCard title='Allocated Budget' className='[&>h4]:text-right [&>div]:justify-end'>
          ${channel?.value?.toLocaleString("en-US")}
          <div
            className='ml-1 py-0.5 px-1.5 rounded text-xs'
            style={{ backgroundColor: channel?.color?.light, color: channel?.color?.dark }}
          >
            {percentage}%
          </div>
        </ChannelStrategyCard>
      </div>
    </div>
  );
};
