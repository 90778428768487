import React from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { updateUserPreferences } from "storage/slices/preferences-slice";

import { ReactComponent as IntegrationsIcon } from "assets/icons/navigation-icons/integrationsIcon.svg";

const IntegrationsOnlyModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const preferences = useAppSelector(state => state.preferences);

  const handleGetAccess = () => {
    const newPrefs = { ...preferences, preferences: { ...preferences.preferences, integrationsOnlyPlanExpandedAccessRequested: true } };
    dispatch(updateUserPreferences(newPrefs));
  };

  const handleGoToIntegrations = () => {
    navigate(ROUTES.connectSources);
  };

  const handleBack = () => {
    // Simply go back to previous page
    navigate(-1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className='p-16 flex flex-col max-w-[482px] text-center justify-center items-center rounded-[25px] bg-white'
        style={{ boxShadow: "0px 4px 68.5px 0px rgba(0, 0, 0, 0.18)" }}
      >
        <h3 className='mt-4 text-[#1F2228] leading-default text-[28px] font-semibold'>
          Limited Access
        </h3>

        {preferences?.preferences?.integrationsOnlyPlanExpandedAccessRequested ? (
          <p className='font-medium mt-6 text-center text-[#5E636E] text-sm'>
            Thank you, we have let the support team know about your request for access.
            In the meantime, you can connect your data sources.
          </p>
        ) : (
          <p className='font-medium mt-6 text-center text-[#5E636E] text-sm'>
            Your plan doesn&apos;t have access to these features yet, but you can request access below.
          </p>
        )}

        <div className="flex flex-col sm:flex-row gap-4 mt-8 w-full">
          <button
            className='mt-10 bg-white border border-[#E5E7EB] rounded-xl py-[10px] px-[42px] text-[#1F2228] font-semibold text-sm leading-default'
            onClick={handleBack}
          >
            Back
          </button>

          {preferences?.preferences?.integrationsOnlyPlanExpandedAccessRequested ? (
            <button
              className='mt-10 bg-[#766CE4] rounded-xl py-[10px] px-[42px] text-white font-semibold text-sm leading-default'
              onClick={handleGoToIntegrations}
            >
              Connect Sources
            </button>
          ) : (
            <button
              className='mt-10 bg-[#766CE4] rounded-xl py-[10px] px-[42px] text-white font-semibold text-sm leading-default'
              onClick={handleGetAccess}
            >
              Request Access
            </button>
          )}

        </div>
      </div>
    </div>
  );
};

export default IntegrationsOnlyModal;
