import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PopupState {
  isOpen: boolean;
  content: string;
  data?: {
    explanation?: string;
  };
}

const initialState: PopupState = {
  isOpen: false,
  content: "",
};

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<{ content: string; data?: { explanation?: string } }>) => {
      state.isOpen = true;
      state.content = action.payload.content;
      state.data = action.payload.data || {};
    },
    closePopup: state => {
      state.isOpen = false;
      state.content = "";
      state.data = {};
    },
  },
});

export const { openPopup, closePopup } = popupSlice.actions;

export const popupReducer = popupSlice.reducer;
