import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitState {
  conversation_id: string;

  loading: boolean;
  error: string | null;
}

const initialState: InitState = {
  conversation_id: "",

  loading: false,
  error: null,
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setConversationId: (state, action: PayloadAction<string>) => {
      state.conversation_id = action.payload;
    },
  },
});

export const { setConversationId } = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;
