import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import cn from "classnames";
import dayjs from "dayjs";
import { useState } from "react";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";

import { Icons } from "shared/components/icons";

import { IndeterminateCheckbox } from "pages/budget/Budget/component/CheckBox";
import { budgetBasedContentData } from "pages/budget/ConnectDataSource/components/jsonExamples";

import BudgetBasedTable from "./component/budget-based-table/budget-based-table";
// eslint-disable-next-line import/no-unresolved
import ContentWithTooltip from "./component/content-with-tooltip/content-with-tooltip";
import SchedulePostModal from "./component/schedule-post-modal/schedule-post-modal";
import SubHeader from "./component/sub-header/sub-header";
import TableToolbar from "./component/table-toolbar/table-toolbar";

const CustomView = () => {
  const [search, setSearch] = useState("");
  const [budget] = useState(budgetBasedContentData);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [creativeId, setCreativeId] = useState<string>("");
  const columnHelper = createColumnHelper<any>();
  const mapTextToIcon: any = {
    facebook: <Icons.Facebook />,
    instagram: <Icons.Instagram />,
    twitter: <Icons.TwitterOrX />,
  };
  const columnMeta = {
    style: {
      maxWidth: "10px", // Specify your desired max width
    },
  };
  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => <></>,
      cell: ({ row }) => (
        <div className='flex items-center gap-2'>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
          <p className='text-[10px] font-bold text-[#5E636E]'>{Number(row.id) + 1}</p>
        </div>
      ),
    }),
    columnHelper.accessor("day", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5'>
            <Icons.TextBox /> Day
          </div>
        );
      },
      cell: info => {
        return <p className='text-sm font-medium text-[#2E323A]'>{dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA)}</p>;
      },
      enableResizing: true,
    }),
    columnHelper.accessor("status", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden'>
            <Icons.Pulse /> Status
          </div>
        );
      },
      cell: info => (
        <div className='text-[#717684] text-xs font-semibold flex items-center bg-[#F7F7F8] rounded-full px-3 py-[3px] overflow-hidden w-fit'>
          <div className='h-1 w-1 rounded-full bg-[#717684] mr-2' />
          {info.getValue()}
        </div>
      ),
      enableResizing: true,
    }),
    columnHelper.accessor("platforms", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden'>
            <Icons.ChatBubble /> Platforms
          </div>
        );
      },
      cell: info => {
        const value = info.getValue();
        return (
          <div className='overflow-hidden flex items-center gap-1'>
            {value.map((item: string, index: number) => {
              return mapTextToIcon[item];
            })}
          </div>
        );
      },
      enableResizing: true,
    }),
    columnHelper.accessor("postideas", {
      header: () => {
        return (
          <div className='flex items-center justify-between overflow-hidden'>
            <div className='flex items-center gap-1.5'>
              <Icons.User /> Post Idea
            </div>
            <div className='p-1 rounded bg-[#E9EAEC] ml-1.5'>
              <Icons.Reload className='' />
            </div>
          </div>
        );
      },
      cell: info => {
        return <ContentWithTooltip>{info.getValue()}</ContentWithTooltip>;
      },
      enableResizing: true,
    }),
    columnHelper.accessor("caption", {
      id: "caption",
      header: () => {
        return (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1.5'>
              <Icons.Category /> Caption
            </div>{" "}
            <div className='p-1 rounded bg-[#E9EAEC] ml-1.5'>
              <Icons.Reload className='' />
            </div>
          </div>
        );
      },
      cell: info => {
        return <ContentWithTooltip>{info.getValue()}</ContentWithTooltip>;
      },
      enableResizing: true,
    }),
    columnHelper.accessor("hashtags", {
      header: () => {
        return (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1.5'>
              <Icons.DocumentSimple /> Hashtags
            </div>{" "}
            <div className='p-1 rounded bg-[#E9EAEC] ml-1.5'>
              <Icons.Reload className='' />
            </div>
          </div>
        );
      },
      cell: info => {
        const value = info.getValue();
        return (
          <ContentWithTooltip textWrapperStyle='flex-wrap'>
            {value.map((item: string, index: number) => {
              return (
                <span key={index} className='text-purple mr-1'>
                  {item}{" "}
                </span>
              );
            })}
          </ContentWithTooltip>
        );
      },
      enableResizing: true,
    }),
    columnHelper.accessor("formatting", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5'>
            <Icons.TextT /> Formatting
          </div>
        );
      },
      cell: info => <div className='overflow-hidden text-sm font-medium text-[#2E323A]'>{info.getValue()}</div>,
      enableResizing: true,
    }),
    columnHelper.accessor("postingtime", {
      header: () => {
        return (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1.5'>
              <Icons.Calender /> Posting Time
            </div>{" "}
            <div className='p-1 rounded bg-[#E9EAEC] ml-1.5'>
              <Icons.Reload className='' />
            </div>
          </div>
        );
      },
      cell: info => <div className='overflow-hidden text-sm font-medium text-[#2E323A]'>{info.getValue()}</div>,
      enableResizing: true,
    }),
    columnHelper.accessor("budgetallocation", {
      header: () => {
        return (
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1.5'>
              <Icons.GraphUp /> Budget Allocation
            </div>
            <div className='p-1 rounded bg-[#E9EAEC] ml-1.5'>
              <Icons.Reload className='' />
            </div>
          </div>
        );
      },
      cell: info => (
        <div className='overflow-hidden flex items-center gap-1.5'>
          {info.getValue()}$ <div className='px-2 py-0.5 rounded-full border border-[#E9EAEC]'>Paid Promo</div>
        </div>
      ),
      enableResizing: true,
    }),
    columnHelper.accessor("creative", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5'>
            <Icons.Frame /> Creative
          </div>
        );
      },
      cell: info => {
        if (info.row.index === 0 || info.row.index === 4)
          return (
            <div className='flex items-center'>
              <div className='w-[14px]'>
                <Icons.Instagram className='w-[19px] bg-slate-300 border rounded-full' />
              </div>
              <div className='w-[14px]'>
                <Icons.Facebook className='w-[19px] bg-slate-300 border rounded-full' />
              </div>
              <div className='w-[14px]'>
                <Icons.TikTok className='w-[19px] bg-slate-300 border rounded-full' />
              </div>
              <div className='w-[14px]'>
                <Icons.Pinterest className='w-[19px] bg-slate-300 border rounded-full' />
              </div>
            </div>
          );
        if (info.row.index === 1)
          return (
            <ContentWithTooltip textWrapperStyle='flex-wrap' leftIcon={<Icons.Instagram className='w-5 h-5' />}>
              brainvoy_video.mp4
            </ContentWithTooltip>
          );
        return (
          <button className='overflow-hidden text-purple flex items-center gap-1.5' onClick={() => setCreativeId(info.getValue())}>
            <Icons.UploadCloud color='#766CE4' /> Upload
          </button>
        );
      },
    }),
    columnHelper.accessor("automation", {
      id: "automation",
      header: () => {
        return (
          <div className='flex items-center justify-between'>
            <div>Automation</div>
            <div className=''>
              <Icons.PlayPurple className='ml-1.5' />
            </div>
          </div>
        );
      },
      cell: info => (
        <div className='flex justify-center'>
          <button className='text-sm font-semibold text-[#1F2228] px-4 py-[3px] rounded border border-[#D5D1F7] bg-[#FCFCFC]'>
            Launch
          </button>
        </div>
      ),
      enableResizing: true,
    }),
  ];

  const table = useReactTable({
    data: budget || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onGlobalFilterChange: setSearch,
    globalFilterFn: "includesString",
  });

  const selectedRows = table.getSelectedRowModel().rows;

  return (
    <div className='h-full w-full relative bg-white'>
      {/* ------------------------------  Sub Header ------------------------------ */}
      <SubHeader search={search} updateSearch={setSearch} />
      {/*------------------------------------- Table ------------------------------ */}
      <div className='w-full'>
        <TableToolbar />
        <BudgetBasedTable table={table} selectedRows={selectedRows} />
        {creativeId && (
          <SchedulePostModal
            onClose={() => setCreativeId("")}
            onClick={() => {
              console.log("clicked");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CustomView;
