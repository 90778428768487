import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getConversationHistory = async (): Promise<any> => {
  const data = await api(`${BASE_API}/conversations/user`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const getConversationMessages = async (id: string, limit: number, offset: number): Promise<any> => {
  const data = await api(`${BASE_API}/conversations/${id}/messages?limit=${limit}&offest=${offset}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
