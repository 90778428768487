import React from "react";

import { AgentItem } from "api/agent/type";

import { AGENT_IMAGES } from "constants/agent.constants";

import { AgentCardBtn } from "pages/agent";

export default function AgentAiCard({ agent }: { agent?: AgentItem | null }) {
  if (!agent) {
    return null;
  }

  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  return (
    <div className='flex flex-col md:flex-row gap-12'>
      <img src={Image} alt='Agent' className='w-[220px] h-[262px]' />
      <div className='flex flex-col gap-6 max-w-[385px]'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col'>
            <h1 className='font-bold text-[1.75rem] text-gradient-purple-4'>Hi, I’m {agent.name}</h1>
            <h3 className='text-[#B4B4C8] font-semibold text-[1.75rem]'>{agent.title}</h3>
          </div>
          <p className='text-grey1 font-medium text-base '>{agent.description}</p>
        </div>
        <AgentCardBtn agent={agent} />
      </div>
    </div>
  );
}
