import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { IProjectData } from "api/projects/types";

import { useAppSelector } from "hooks/appHooks";

import MonthBudgetCard from "shared/components/budget/monthBudgetCard/MonthBudgetCard";
import RightArrowIcon from "shared/components/IconsComponents/RightArrowIcon";

import { SidebarsWidth } from "types";

import LoadingOverlay from "../../components/LoadingOverlay";

const { ASIDE_OPENED_WIDTH, ASIDE_CLOSED_WIDTH, CHAT_OPENED_WIDTH, CHAT_CLOSED_WIDTH, MAIN_PADDINGS } = SidebarsWidth;

export default function MonthlyStrategy({
  openChat = false,
  transformedData,
  project,
  isLoading,
}: {
  openChat: boolean;
  transformedData: any;
  project?: IProjectData;
  isLoading: boolean;
}) {
  const { id: budgetId } = useParams();

  const { isCollapsed } = useAppSelector(state => state.sidebar);
  const { budget_date_from: budgetDateFrom } = project || {};
  const { name_tech: userPlan } = useAppSelector(state => state?.auth?.user?.plan) || {};

  const [offset, setOffset] = useState(0);
  const [viewportWidth, setViewportWidth] = useState<number>(256);
  const cardWidth = 286;
  const chatWidth = openChat ? CHAT_OPENED_WIDTH : CHAT_CLOSED_WIDTH;
  const asideWidth = isCollapsed ? ASIDE_CLOSED_WIDTH : ASIDE_OPENED_WIDTH;
  const scrollWidth = window.innerWidth - document.documentElement.clientWidth || 15;
  const viewportRef = useRef<HTMLDivElement>(null);
  const visibleCards = Math.floor(viewportWidth / cardWidth);
  const totalWidth = transformedData.length * cardWidth;
  const isLastCardVisible = offset < -totalWidth + cardWidth * visibleCards;

  const handleScrollRight = () => {
    setOffset(prevOffset => {
      return prevOffset + cardWidth;
    });
  };

  const handleScrollLeft = () => {
    if (isLastCardVisible) {
      return;
    }
    setOffset(prevOffset => {
      return prevOffset - cardWidth;
    });
  };

  useEffect(() => {
    const handleViewportWidth = () => {
      if (viewportRef.current) {
        clearInterval(interval);
        setViewportWidth(viewportRef.current.getBoundingClientRect().width);
      }
      // setViewportWidth(window.innerWidth - CHAT_CLOSED_WIDTH);
      return;
    };
    const interval = setInterval(handleViewportWidth, 300);
    window.addEventListener("resize", handleViewportWidth);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleViewportWidth);
    };
  }, [viewportRef.current]);

  // const loading = false; // Temp - TODO: Connnect it with a real state
  return (
    <>
      <div className={"flex h-full flex-col col-span-2"}>
        <div className='text-xl font-bold mb-3'>Monthly Strategy</div>
        <div ref={viewportRef} className={cn("flex relative h-full w-full", { "overflow-hidden rounded-3xl": isLoading })}>
          <LoadingOverlay isShown={isLoading} />
          {transformedData.length > 4 && !isLastCardVisible && (
            <div className='h-full w-[150px] bg-gradient-custom absolute top-0 z-30 -right-[1.5rem] flex items-center justify-center'>
              <div
                onClick={handleScrollLeft}
                className='cursor-pointer w-[54px] h-[54px] p-[15px] flex items-center z-30 justify-center bg-white border border-[#E8E9F0] rounded-full shadow-custom'
              >
                <RightArrowIcon color={"#766CE4"} />
              </div>
            </div>
          )}
          {offset < 0 ? (
            <div className='h-full w-[150px] bg-gradient-custom-revert absolute top-0 z-30 -left-[1.5rem] flex items-center justify-center'>
              {transformedData.length > 4 && (
                <div
                  onClick={handleScrollRight}
                  className='cursor-pointer w-[54px] h-[54px] p-[15px] flex items-center z-30 justify-center bg-white border border-[#E8E9F0] rounded-full shadow-custom'
                >
                  <RightArrowIcon color={"#766CE4"} className='rotate-180' />
                </div>
              )}
            </div>
          ) : null}
          <div className='flex relative gap-x-4 transition-left duration-500 ease-in-out' style={{ left: `${offset}px` }}>
            {transformedData.map((data: any, i: number) => (
              <MonthBudgetCard
                userPlan={userPlan}
                budgetDateFrom={budgetDateFrom || null}
                budgetId={budgetId}
                data={data}
                key={data.date}
                prevMonth={i > 0 ? transformedData[i - 1] : ""}
                offset={offset}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
