import cn from "classnames";
import { useEffect } from "react";
import { Menu, menuClasses, MenuItem, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { openPopup } from "storage/slices/popup-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import { ReactComponent as LogoIcon } from "assets/icons/Logo.svg";
import { ReactComponent as LogoNameIcon } from "assets/icons/logotext.svg";
import { ReactComponent as RoundedPlus } from "assets/icons/roundedPlus.svg";

import { SidebarsWidth } from "types";
import { generateUniqueId } from "utils";

import { Button } from "../buttons/Button";
import NavButton from "../nav-button/NavButton";
import { TrialPeriodSection } from "../trial-period-section/TrialPeriodSection";
import { Typography } from "../Typography";
import { UserAccountControls } from "../user-acount-controls/UserAccountControls";

import { navConfig, soonPagesConfig } from "./nav-config";

import "./NavBar.scss";

const { ASIDE_CLOSED_WIDTH, ASIDE_OPENED_WIDTH } = SidebarsWidth;

type NavConfigTypes = {
  to: string;
  title: string;
  Icon: React.ComponentType;
  ActiveIcon: React.ComponentType;
  beta: boolean;
  dropdown?: { to: string; title: string }[];
};
// TODO - use tailwind instead of scss,
export default function NavBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isCollapsed } = useAppSelector(state => state.sidebar);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsCollapsed(window.innerWidth < 1440));
    };
    // handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Sidebar
        id='mySidebar'
        collapsedWidth={`${ASIDE_CLOSED_WIDTH}px`}
        width={`${ASIDE_OPENED_WIDTH}px`}
        style={{
          border: "none",
          position: "relative",
        }}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "#fff",
            padding: "16px",
            border: "none",
            height: "100%",
            overflow: "hidden",
          },
        }}
        collapsed={isCollapsed}
        className={cn(isCollapsed ? "closed-menu" : "opened-menu", "h-full overflow-hidden")}
      >
        <div className='flex flex-col justify-between h-full'>
          <div>
            <div className={`flex flex-col min-w-[32px] mb-4 ${isCollapsed ? "items-center" : ""}`}>
              <div className='flex items-center pb-4 gap-[10px] px-3'>
                <LogoIcon className='cursor-pointer h-8 w-8 flex-shrink-0' onClick={() => navigate(ROUTES.dashboard)} />
                {!isCollapsed && (
                  <LogoNameIcon
                    width='100px'
                    height='27px'
                    className='cursor-pointer mt-[5px]'
                    onClick={() => navigate(ROUTES.dashboard)}
                  />
                )}
              </div>
            </div>
            <Menu
              rootStyles={{
                [`.${menuClasses.menuItemRoot}`]: {
                  width: isCollapsed ? "48px" : "100%",
                },
              }}
            >
              <div className='flex flex-col gap-y-1 mb-10 items-center h-full w-full'>
                {navConfig.map(({ to, title, Icon, ActiveIcon, beta, dropdown }: NavConfigTypes) => (
                  <MenuItem
                    rootStyles={{
                      [`.${menuClasses.menuItemRoot}`]: {},
                    }}
                    key={generateUniqueId()}
                    component={
                      <NavButton
                        to={to}
                        title={title}
                        Icon={Icon}
                        ActiveIcon={ActiveIcon}
                        beta={beta}
                        isCollapsed={isCollapsed}
                        dropdown={dropdown}
                      />
                    }
                  />
                ))}
              </div>
              {/* {!isCollapsed && (
                <div className='flex flex-col items-center w-[220px]'>
                  <Typography className='w-full mb-3 px-3 text-[#766CE4] pointer-events-none' variant={TypographyVariants.MD_SEMIBOLD}>
                    Coming Soon
                  </Typography>
                  <div className='gap-y-1 w-full'>
                    {soonPagesConfig.map(({ to, title, Icon, beta, ActiveIcon }) => (
                      <NavButton
                        key={generateUniqueId()}
                        to={to}
                        title={title}
                        Icon={Icon}
                        beta={beta}
                        ActiveIcon={ActiveIcon}
                        classes='opacity-50 pointer-events-none text-sm'
                        isCollapsed={isCollapsed}
                      />
                    ))}
                  </div>
                </div>
              )} */}
            </Menu>
          </div>
          <div>
            <TrialPeriodSection hidden={isCollapsed} />
            <UserAccountControls hidden={isCollapsed} />
          </div>
        </div>
      </Sidebar>
      <button className='expandBtn' style={{ left: isCollapsed ? "57px" : "216px" }} onClick={() => dispatch(setIsCollapsed(!isCollapsed))}>
        <ChevronIcon style={{ rotate: isCollapsed ? "180deg" : "0deg" }} />
      </button>
    </>
  );
}
