import { useState } from "react";

import { Icons } from "shared/components/icons";

import ProductImage from "assets/png/product2.png";

import { cn } from "lib/utils";

import StatusBadge from "../Badge/StatusBadge";

const ProductTaskCard = ({ selectedWorkFlow, setSelectedWorkFlow, setProductActivity, productActivity }: any) => {
  const [selectedTab, setSelectedTab] = useState("new");
  const tabs = [
    { name: "New", value: "new" },
    { name: "Acknowledged", value: "acknowledged" },
  ];
  return (
    <div className='w-full mx-auto bg-white overflow-hidden h-full'>
      <div className='border-b-2 m-0 border-[#E9EAEC]  flex justify-between items-center px-5 h-[4rem]'>
        <h4 className='text-base font-semibold text-[#333740]'>Product Activity</h4>
        <div className='cursor-pointer bg-[#E9EAEC] rounded-md p-1' onClick={() => setProductActivity(null)}>
          <Icons.Close />
        </div>
      </div>
      <div className='flex p-4'>
        <img className='w-28 h-28 rounded-2xl' src={ProductImage} alt='Product' />
        <div className='ml-4 flex-1'>
          <div className='flex justify-between items-start'>
            <h1 className='text-lg font-semibold text-[#2E323A]'>{productActivity?.product_name}</h1>
            <StatusBadge type='pending' title={productActivity?.demand_status.toLocaleLowerCase()} />
          </div>
          <div className='text-sm text-[#717684] mt-2'>
            <p className='mb-2 font-medium text-sm text-[#717684]'>
              Product ID: <span className='font-semibold text-[#464A53]'>{productActivity?.product_id}</span>
            </p>
            <p className='flex items-center gap-2 mb-2'>
              Inventory Status:{" "}
              <div className='bg-[#F7F7F8] rounded px-2 py-1 text-[#5E636E] font-semibold text-sm '>
                {productActivity?.inventory_status}
              </div>
              <span className='font-semibold text-[#464A53] text-sm'>{productActivity?.inventory_quantity} left</span>
            </p>
            <p className='mb-2'>
              Average Sales (Monthly): <span className='font-semibold text-[#464A53] text-sm'>{productActivity?.average_sales}</span>
            </p>
          </div>
        </div>
      </div>
      <div className='px-4'>
        <div className='flex items-center gap-2 border-t border-b py-2'>
          {tabs.map((tab, i) => (
            <button
              key={i}
              className={cn(
                "w-1/2 py-2 font-semibold text-purple-600 hover:bg-purple-100 transition rounded-lg px-2 flex-1",
                selectedTab === tab.value ? "bg-[#F1F0FC] text-[#6B62CF]" : "text-[#1F2228] bg-white",
              )}
              onClick={() => setSelectedTab(tab.value)}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
      <div className='p-4'>
        {productActivity?.actions?.map((task: any, index: number) => (
          <div
            key={index}
            className={cn("rounded-2xl","border-4 border-transparent")}
          >
            <div

              className={cn(
                "cursor-pointer flex items-center justify-between p-4 bg-white border border-gray-200 rounded-xl shadow-sm",
                
              )}
            >
              <div>
                <div className='flex gap-2 items-center'>
                  <div className='bg-[#F7F7F8] flex items-center py-[2px] px-2 rounded-[24px]'>
                    <span className='text-[#5E636E] font-semibold text-xs'> {task.type}</span>
                  </div>
                  <p className='font-semibold text-sm text-[#464A53] mb-1'>Mon, 9 Dec · 3:42 PM</p>
                </div>
                <h2 className='font-bold text-sm text-[#1F2228] mt-3'>{task.name}</h2>
              </div>
              <div className='flex items-center gap-2'>
                <button
                   onClick={() => setSelectedWorkFlow(task.type)}
                  disabled={productActivity?.disabled}
                  className={cn("text-[#1F2228] bg-[#FCFCFC] font-semibold text-xs rounded-lg py-[8px] px-[18px] border border-[#D5D1F7] ml-4",
                  selectedWorkFlow === task.type ? "border-[#766CE4]" : "border-gray-200",)}

                >
                  Take Action
                </button>
                <div className='bg-[#E9EAEC] rounded-md py-[3px] px-1'>
                  <Icons.NoteBook />
                </div>
              </div>
            </div>
          </div>
        ))}
        <button className='flex my-4 gap-4 items-center px-4 py-3 text-[#717684] font-medium text-sm  cursor-pointer m-1 border border-dashed border-[#C8CAD0] rounded-lg w-full  '>
          <Icons.Plus /> Create new workflow
        </button>
      </div>
    </div>
  );
};

export default ProductTaskCard;
