import { useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "hooks/appHooks";

import { toggleIsDataPreviewOpen } from "storage/slices/integrations-slice";

import { Icons } from "shared/components/icons";

interface DataPreviewProps {
  setIsFrequencyNotification: (isOpen: boolean) => void;
}

interface TableEntry {
  title: string;
  items: string[];
}

const DataPreview = ({ setIsFrequencyNotification }: DataPreviewProps) => {
  const dispatch = useDispatch();
  const tablesData = useAppSelector(state => state.integrations.connectedSourcesTables);

  const tables: TableEntry[] = Object.keys(tablesData).map(tableName => {
    const name = tableName as string;
    return {
      title: name,
      items: tablesData[name].columns,
    };
  });
  return (
    <div className='w-[400px] h-full border-l border-[#E9EAEC]'>
      <div className='border-b-2 m-0 border-[#E9EAEC]  flex justify-between items-center px-5 h-[4rem]'>
        <h4 className='text-base font-semibold text-[#333740]'>Data preview</h4>
        <Icons.Close className='cursor-pointer' onClick={() => dispatch(toggleIsDataPreviewOpen())} />
      </div>
      <div className='overflow-y-auto h-[calc(100%-4rem)] pb-4 px-4  scrollbar-thin scrollbar-track-[#F1F0FC] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-[#9189E9] m-2'>
        {tables.map((table, index) => (
          <TableItem key={index} title={table.title} items={table.items} setIsFrequencyNotification={setIsFrequencyNotification} />
        ))}
      </div>
    </div>
  );
};

interface TableItemProps {
  title: string;
  items: string[];
  setIsFrequencyNotification: (value: boolean) => void;
}

const TableItem = ({ title, items, setIsFrequencyNotification }: TableItemProps) => {
  const [showAll, setShowAll] = useState(false);
  const additionalItems = items.slice(3);
  const displayItems = showAll ? items : items.slice(0, 3);
  return (
    <div
      onClick={() => setIsFrequencyNotification(true)}
      className='max-w-xs w-full mx-auto my-4 border rounded-lg shadow-sm cursor-pointer'
    >
      <div className='p-3 bg-[#F7F7F8] border-b rounded-t-lg'>
        <h2 className='text-xs font-semibold text-[#464A53]'>{title}</h2>
      </div>
      <div>
        {displayItems.map((item, index) => (
          <div key={index} className='w-[320px] px-4 py-2 border-b last:border-b-0'>
            <p className='text-[#717684] text-xs font-medium truncate overflow-hidden'>{item}</p>
          </div>
        ))}
        {additionalItems.length > 0 && (
          <div className='px-4 py-2' onClick={() => setShowAll(!showAll)}>
            <p className='text-[#717684] text-xs font-medium'>{showAll ? "Show less" : `+${additionalItems.length} more`}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataPreview;
