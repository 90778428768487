import cn from "classnames";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

import { IProjectData } from "api/projects/types";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";

import BlurOverlay from "shared/components/BlurOverlay";
import Checkbox from "shared/components/checkbox/Checkbox";

import LoadingOverlay from "pages/budget/BudgetIntelligence/components/LoadingOverlay";

import { ReactComponent as FilterDownIcon } from "assets/icons/downFilterIcon.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { ReactComponent as NotFoundIcon } from "assets/icons/not-found.svg";

import { generateUniqueId } from "utils";

import classes from "./Budgets.module.scss";

// TODO - remove scss!!!

interface IChannelItem {
  channel_id: number;
  name: string;
  existingBudget: number;
  newBudget: number;
  visible: boolean;
}

interface IActiveChannel {
  channel_id: number;
  name: string;
  clicked: boolean;
}

const Budgets = ({ project, isLoading }: { project?: IProjectData; isLoading: boolean }) => {
  const dispatch = useAppDispatch();
  const monthlyData = project?.project_data?.budget_data || [];
  const { data: allChannels } = useGetChannels();

  const [appliedFilter, setAppliedFilter] = useState<IActiveChannel[]>([]);
  const [changedFilter, setChangedFilter] = useState<IActiveChannel[]>([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [info, setInfo] = useState<IChannelItem[]>([]);
  const isExistingBudget = info.find(item => item.existingBudget);

  const totalYearBudget = info.reduce((acc: number, item: any) => {
    acc += item?.newBudget;
    return acc;
  }, 0);

  const totalYearVisibleBudget = info.reduce((acc: number, item: any) => {
    acc += item.visible ? item?.newBudget : 0;
    return acc;
  }, 0);

  const totalExistingBudget = info.reduce((acc: number, item: any) => {
    acc += item?.existingBudget;
    return acc;
  }, 0);

  const totalExistingVisibleBudget = info.reduce((acc: number, item: any) => {
    acc += item.visible ? item?.existingBudget : 0;
    return acc;
  }, 0);

  const visibleChannelsIds = info.filter(item => item.visible)?.map(({ channel_id }) => channel_id);

  const getChannelsWithValue = () => {
    const newChannels: IChannelItem[] = (allChannels || [])
      .filter(item => item.channel_id !== 0)
      .map(item => ({
        channel_id: item.channel_id,
        name: item.name,
        newBudget: 0,
        existingBudget: 0,
        visible: false,
      }));

    const monthlyDataItemChannels = monthlyData.map(item => item.channels).flat();

    for (const unit of monthlyDataItemChannels) {
      const channelIndex = newChannels.findIndex(i => i.channel_id === unit.channel_id);
      newChannels[channelIndex].newBudget = newChannels[channelIndex].newBudget + unit.value_new;
      newChannels[channelIndex].existingBudget = newChannels[channelIndex].existingBudget + unit?.value_existing || 0;
      newChannels[channelIndex].visible = !!unit.value_new;
    }

    const channelsForFilters = newChannels
      .filter(item => item.newBudget > 0)
      .map(item => ({
        channel_id: item.channel_id,
        clicked: false,
        name: item.name,
      }));

    setAppliedFilter(channelsForFilters);
    setChangedFilter(channelsForFilters);
    setInfo(newChannels);
  };

  const handleCheckbox = (name: string) => {
    const newChangedFilter = changedFilter.map(item => {
      return item.name === name ? { ...item, clicked: !item.clicked } : item;
    });
    setChangedFilter(newChangedFilter);
  };

  const handleApply = () => {
    const filterLength = changedFilter.filter(item => item.clicked);
    let newInfo = info.map(item => ({ ...item, visible: item.newBudget ? true : false }));
    if (filterLength.length > 0) {
      newInfo = info.map(item => ({
        ...item,
        visible: changedFilter.find(unit => unit.channel_id === item.channel_id)?.clicked || false,
      }));
    }

    setIsFilterOpen(false);
    setAppliedFilter([...changedFilter]);
    setInfo(newInfo);
  };

  const handleCancel = () => {
    setIsFilterOpen(false);
    setChangedFilter([...appliedFilter]);
  };

  const compareBlock = info.map((item, index) => {
    const { channel_id, name, existingBudget, newBudget, visible } = item;

    const newBudgetPercentage = Number(((newBudget / totalYearBudget) * 100).toFixed(1));

    const existingBudgetPercentage = Number(((existingBudget / totalExistingBudget) * 100).toFixed(1));

    const noDataProgressWidth = Math.floor(Math.random() * (80 - 50 + 1)) + 50;

    return (
      <div key={channel_id} className={twMerge(cn(classes.budgets__chart__compare, "items-center", { "mt-6": index === 0 }))}>
        <div className={classes.budgets__chart__compare__left}>
          {visible && existingBudget > 0 && existingBudgetPercentage < 95 && <span>{existingBudgetPercentage}%</span>}
          <div
            className={classes.budgets__chart__compare__left__progress}
            style={{
              width: visible && Number(existingBudgetPercentage) ? `${existingBudgetPercentage}%` : `${noDataProgressWidth}%`,
              background: existingBudget && visible ? "#f185e9" : "#E9EAEC",
            }}
          >
            {visible && existingBudget > 0 && existingBudgetPercentage >= 95 && `${existingBudgetPercentage}%`}
            {!existingBudget && "No data"}
          </div>
        </div>
        <div className={cn(classes.budgets__chart__compare__central, { "text-[#C8CAD0]": !visibleChannelsIds.includes(item?.channel_id) })}>
          {name}
        </div>
        <div className={classes.budgets__chart__compare__right}>
          <div
            className={classes.budgets__chart__compare__right__progress}
            style={{
              width: visible && Number(newBudgetPercentage) ? `${newBudgetPercentage}%` : `${noDataProgressWidth}%`,
              background: newBudget && visible ? "#7b8df4" : "#E9EAEC",
            }}
          >
            {visible && newBudget > 0 && newBudgetPercentage >= 95 && `${newBudgetPercentage}%`}
            {!newBudgetPercentage && "No data"}
          </div>
          {visible && newBudget > 0 && newBudgetPercentage < 95 && <span>{newBudgetPercentage}%</span>}
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (allChannels?.length) {
      getChannelsWithValue();
      return;
    }
  }, [allChannels, dispatch]);

  // const loading = false; // Temp - TODO: Connnect it with a real state

  return (
    <div>
      <div className={classes.budgets__top}>
        <h2 className={classes.budgets__top__title}>Budget Allocation</h2>
        <button
          className={`${classes.budgets__top__filterBlock} ${
            appliedFilter.filter(item => item.clicked).length > 0 ? classes.filledFilter : undefined
          } `}
          onClick={() => (isFilterOpen ? handleCancel() : setIsFilterOpen(true))}
        >
          <FilterIcon /> Filter{" "}
          {appliedFilter.filter(item => item.clicked).length > 0 && (
            <div className={classes.budgets__top__filterBlock__count}>{appliedFilter.filter(item => item.clicked).length}</div>
          )}
          <FilterDownIcon
            style={{
              transform: `rotate(${isFilterOpen ? "180deg" : "0"})`,
              transition: "all 0.3s linear",
            }}
          />
        </button>
        {isFilterOpen && (
          <div className='absolute flex-col text-black right-0 top-[110%] flex h-[244px] w-[216px]  p-[12px] rounded-[14px] bg-white shadow-2xl shadow-[0_4px_29px_0px_rgba(0,0,0,0.12) z-20 '>
            <div className='text-[#1A212B] text-[15px] leading-[18px] font-bold pb-[12px]'>Channels</div>
            <div className={`flex h-[146px] flex-col overflow-y-auto gap-y-3 pb-2 ${classes.filterScrollZone}`}>
              {changedFilter?.map((channel: any) => (
                <Checkbox
                  key={generateUniqueId()}
                  checked={channel.clicked}
                  setChecked={handleCheckbox}
                  title={channel.name}
                  style={{ color: "black" }}
                  checkboxWidth={5}
                  checkboxHeight={5}
                />
              ))}
            </div>
            <div className='border border-[#E9EAEC] mb-2 mt-2'></div>
            <div className='flex items-center justify-end gap-x-3'>
              <button
                className='flex items-center justify-center  w-[auto] h-[26px] px-[10px] rounded-[8px] text-[14px] font-semibold  !bg-transparent !hover:bg-transparent !text-black'
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </button>
              <button
                className='flex items-center justify-center w-[104px] h-[26px] rounded-[8px] text-[#FFF] text-[14px] font-semibold bg-[#766CE4]'
                onClick={() => {
                  handleApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
      <div className={cn(classes.budgets + " relative", { "overflow-hidden rounded-3xl": isLoading })}>
        <LoadingOverlay isShown={isLoading} />
        <div className={classes.budgets__budget}>
          <div className={classes.budgets__budget__cell}>
            <h3 className='text-xl text-[#2E323A] font-bold text-center'>${totalExistingVisibleBudget.toLocaleString("en-US")}</h3>
            <span>Existing Budget</span>
          </div>
          <div className={classes.budgets__budget__cell}>
            <h3 className='text-xl text-[#766CE4] font-bold text-center'>VS</h3>
          </div>
          <div className={classes.budgets__budget__cell}>
            <h3 className='text-xl text-[#2E323A] font-bold text-center'>${totalYearVisibleBudget.toLocaleString("en-US")}</h3>
            <span>New Budget</span>
          </div>
        </div>
        <div className={classes.budgets__chart + " min-h-[388px]"}>
          {!isExistingBudget && (
            <div className={classes.budgets__chart__noData}>
              <NotFoundIcon />
              <h3>No Data</h3>
              <p>You need to upload an existing budget to view this.</p>
            </div>
          )}

          {compareBlock}
        </div>
      </div>
    </div>
  );
};

export default Budgets;
