import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { fetchTeamsByCompany, inviteMemberToTeam, removeTeamMember, updateTeamMemberRole } from "storage/slices/company-slice";

import Alert from "shared/components/toasts";

import Collapsible from "./Collapsible";
import TeamMembersTable from "./team-members-table";

const TeamTable = ({ team, companyId, setShowTeamMembers, showTeamMembers }: any) => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useAppDispatch();
  const teamMember = useAppSelector(state => state.company.teamMembers);

  const [teamMembers, setTeamMembers] = useState([...teamMember]);

  useEffect(() => {
    setTeamMembers(teamMember);
  }, [teamMember]);

  const onRemoveTeamMember = (id: string) => {
    dispatch(removeTeamMember({ teamId: team.team_id, memberId: id }));
  };

  const handleAddTeamMembers = (email: string, team_it: string, role_id: number) => {
    dispatch(inviteMemberToTeam({ email, teamId: team_it, role_id }));
    dispatch(fetchTeamsByCompany(companyId));
    Alert("success", "Invite send successfully", "Success");
  };

  const handleUpdatePermissionType = (memberId: string, roleId: string) => {
    dispatch(updateTeamMemberRole({ teamId: team?.team_id, memberId, roleId })).then(() => {
      Alert("success", "Member role updated successfully", "Success");
    });
  };

  const onRightActionClick = () => {
    setIsEditable(prev => !prev);
  };

  return (
    <Collapsible
      isEditable={isEditable}
      onRightActionClick={onRightActionClick}
      title={team.name}
      teamId={team.team_id}
      count={team?.memberCount}
      companyId={companyId}
      memberCount={teamMembers.length}
      setShowTeamMembers={setShowTeamMembers}
      showTeamMembers={showTeamMembers}
    >
      <TeamMembersTable
        team={team}
        handleUpdatePermissionType={handleUpdatePermissionType}
        onRemoveTeamMember={onRemoveTeamMember}
        teamMembers={teamMembers}
        handleAddTeamMembers={handleAddTeamMembers}
      />
    </Collapsible>
  );
};

export default TeamTable;
