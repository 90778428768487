import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useChannelStrategy } from "hooks/channels/useChannelStrategy";
import { useGetProject } from "hooks/queries/project";

import { getWorkflowsStrategiesList } from "storage/actions/workflows";
import { setLoading } from "storage/slices/loader-slice";
import { getUserPreferences } from "storage/slices/preferences-slice";

import { DashboardLayout } from "shared/layouts/DashboardLayout";

import ChannelStrategy from "../budget/BudgetIntelligence/MarketingpView/ChannelStrategy/ChannelStrategy";

export const WorkflowDetails = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { state } = useLocation();

  const navigate = useNavigate();

  const { data: project, isLoading, isError } = useGetProject(id ? Number(id) : undefined);

  const [transformedData] = useChannelStrategy({ project });

  useEffect(() => {
    dispatch(getWorkflowsStrategiesList());
    dispatch(getUserPreferences());
    if (!id || project?.project_id === Number(id)) return;
    if (isError === true) {
      navigate(-1);
    }
  }, [dispatch, id, project?.project_id, isError]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getWorkflowsStrategiesList());

    setTimeout(() => dispatch(setLoading(false)), 800);
  });

  return (
    <DashboardLayout title='Workflow' isScrollable={false}>
      <ChannelStrategy
        data={transformedData}
        activeDate={state?.activeDate}
        className='min-h-full'
        project={project}
        isLoading={isLoading}
      />
    </DashboardLayout>
  );
};
