import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { Dropdown } from "shared/components/dropdown/Dropdown";
import { Icons } from "shared/components/icons";

import StatusBadge from "pages/operation-report/Dashboard/components/Badge/StatusBadge";

import { cn } from "lib/utils";

import InviteNewMembers from "./InviteNewMembers";

const columnHelper = createColumnHelper<TeamMembers>();

interface TeamMembers {
  id: number;
  name: string;
  email: string;
  status: string;
  role_id: number;
  role: any;
  avatar: string;
  member_id: string;
  user_id: number;
}

const TeamMembersTable = ({
  team,
  teamMembers,
  handleAddTeamMembers,
  onRemoveTeamMember,
  handleUpdatePermissionType,
}: {
  team?: any;
  teamMembers: TeamMembers[];
  handleAddTeamMembers: (email: string, team_id: string, role_id: number) => void;
  onRemoveTeamMember: (id: string) => void;
  handleUpdatePermissionType: (id: string, label: string) => void;
}) => {
  const [isEditable, setIsEditable] = useState("");
  const options = [
    { id: "1", label: "can approve" },
    { id: "2", label: "can view" },
  ];

  const columns = [
    columnHelper.accessor("name", {
      header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>FUll Name</div>,
      cell: info => (
        <div className='flex items-center gap-2'>
          <img
            className='rounded-full w-6 h-6 object-cover'
            src={info.row.original.avatar || "https://static-00.iconduck.com/assets.00/user-avatar-happy-icon-2048x2048-ssmbv1ou.png"}
          />
          <span className='font-semibold text-sm text-[#464A53]'>{info.getValue()}</span>
        </div>
      ),
    }),

    columnHelper.accessor("email", {
      header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E] text-center'>Email</div>,
      cell: info => <p className='font-medium  text-sm text-[#464A53] text-center'>{info.getValue() || "-"}</p>,
    }),

    columnHelper.accessor("status", {
      header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E] text-center'>Status</div>,
      cell: info => (
        <div className='flex justify-center'>
          <StatusBadge type='monitoring' title={info.getValue()} />
        </div>
      ),
    }),

    columnHelper.accessor("role_id", {
      header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E] text-center'>Permission Type</div>,
      cell: info => (
        <div className='font-medium flex justify-center  text-sm text-[#464A53] text-center'>
          <Dropdown
            items={options.map(item => ({
              ...item,
              action: () => {
                handleUpdatePermissionType(info.row.original.member_id, item?.id);
                setIsEditable("");
              },
            }))}
            menuBtnStyles='w-40 p-2 text-sm'
            className='border border-[#E9EAEC] flex justify-center'
          >
            {({ open }) => (
              <div className='flex items-center justify-center gap-4'>
                <p className={cn("text-[#464A53] font-medium text-sm")}>
                  {options?.find(role => role?.id === info?.getValue().toString())?.label}
                </p>
                {isEditable === info.row.original.member_id && (
                  <span>{open ? <Icons.ArrowUp className='h-4 w-4' /> : <Icons.ArrowDown className='h-4 w-4' />}</span>
                )}
              </div>
            )}
          </Dropdown>
        </div>
      ),
    }),

    columnHelper.display({
      id: "actions",
      header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E] text-center'>Actions</div>,
      cell: info => (
        <div className='flex items-center justify-center gap-4 font-medium text-xs text-[#464A53]'>
          <Icons.PenOutline className='cursor-pointer' onClick={() => setIsEditable(info.row.original.member_id)} />
          <Icons.DeleteIcon className='cursor-pointer' onClick={() => onRemoveTeamMember(info.row.original.member_id)} />
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: teamMembers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className='bg-white border border-[#E9EAEC]  rounded-b-xl p-4 w-full overflow-auto scroll-w-1'>
        <table className='min-w-full rounded-lg border-separate' style={{ borderSpacing: "0 8px" }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className='border-r border-r-[#E9EAEC]'>
                {headerGroup.headers.map((header, index) => (
                  <th key={header.id} className='relative py-3 px-5 text-left text-sm font-bold text-gray-700'>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {index !== 0 && <div className='absolute top-1/2 transform -translate-y-1/2 left-0 w-[1px] h-8 rounded bg-[#E9EAEC]' />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='gap-y-4'>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className='cursor-pointer rounded-lg shadow-allAround border-2 border-[#E9EAEC]'>
                {row.getVisibleCells().map((cell, index) => (
                  <td key={cell.id} className='py-2 relative px-4 text-sm text-gray-600 '>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {index !== 0 && <div className='absolute top-1/2 transform -translate-y-1/2 left-0 w-[1px] h-8 rounded bg-[#E9EAEC]' />}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='p-2 mt-4'>
          <InviteNewMembers team={team} handleAddTeamMembers={handleAddTeamMembers} />
        </div>
      </div>
    </div>
  );
};

export default TeamMembersTable;
