import classNames from "classnames";
import { useContext, useEffect } from "react";
import DataGrid, { RowsChangeData } from "react-data-grid";
import { v4 as uuidv4 } from "uuid";

import { IChannelData } from "api/budget/types";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";

import { Row, numberWithCommas, removeDashFromString } from "utils/budget.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";

import BudgetTableLoader from "shared/components/loader-screen/BudgetTableLoader";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";

import { ReactComponent as EyeSlashIcon } from "assets/icons/eye-closed.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plusIcon.svg";

// Only For Testing
// import { headerData, budgetData as BudgetDataa} from "./jsonExamples";
import NumberEditor from "./NumberOnlyInput";
import TextOnlyInput from "./TextOnlyInput";

// Important Notes
//  1. ParentId is required to show the relationship
//  2. expanded is required to hide/show child on click of parent.
// This is a temporary data

function CollapsibleRowTable() {
  const { data: projectChannels } = useGetChannels();
  const dispatch = useAppDispatch();
  const {
    budgetData,
    setBudgetData,
    budgetDataHeader,
    budgedDataRow,
    setDataBudgetRow,
    loadingReviewBudget,
    updateHiddenRows,
    hiddenRows,
  } = useContext(ConnectSourceContext);
  const headers = budgetDataHeader;

  // These Two Lines are for Testing Purpose Only
  // const {budgetData, budgedDataRow, setBudgetData, setDataBudgetRow, loadingReviewBudget, updateHiddenRows, hiddenRows } = useContext(ConnectSourceContext);
  // const headers = headerData;

  const keysToIgnore: string[] = ["id", "parentId", "name", "expanded", "isParent", "project_channel_id", "channel_id", "isEditableRow"];

  const renderRowNameWithIcon = (row: Row) => {
    const channel = themeMappings(row.id);
    const Icon = CHANNEL_FLOW_ICONS?.[removeDashFromString(channel?.icon) as keyof typeof CHANNEL_FLOW_ICONS];

    if (row.expanded) {
      return (
        <div className='flex items-center gap-x-2'>
          <span style={{ fontSize: 8 }}>▼</span>
          <span style={{ backgroundColor: channel?.color.light }} className='flex items-center justify-center h-4 w-4 mr-1 rounded'>
            {Icon && <Icon className='h-3 w-3' />}
          </span>
          {`${row.name}`}
        </div>
      );
    }
    return (
      <div className='flex items-center gap-x-2'>
        <span style={{ fontSize: 8 }}>▶</span>
        <span style={{ backgroundColor: channel?.color.light }} className='flex items-center justify-center h-4 w-4 mr-1 rounded'>
          {Icon && <Icon className='h-3 w-3' />}
        </span>
        {`${row.name}`}
      </div>
    );
  };

  // Function to conditionally render the textEditor
  const conditionalEditor = (props: any) => {
    const { row } = props;
    if (!row.isParent) {
      return <NumberEditor {...props} />; // Allow editing if 'editable' is true
    }
    return null; // No editor if 'editable' is false
  };

  const calculateRowTotal = (rowId: string) => {
    let total = 0;
    const row = budgedDataRow.find((item: Row) => item.id === rowId);
    for (const [key, value] of Object.entries(row as Row)) {
      if (!keysToIgnore.includes(key)) {
        total = total + parseInt(value); // TODO: parse as float and format as currency below
      }
    }
    if (Number.isNaN(total)) {
      console.log("total", row);
    }
    return "$" + numberWithCommas(total);
  };

  const addNewRow = (row: Row) => {
    // Add the row in budgetdDataRow while removing this key isEditableRow
    const newRow = { ...row };
    delete newRow.isEditableRow;
    newRow.id = uuidv4();
    setBudgetData((prevRows: any) => {
      const parentRowIndex = prevRows.findIndex((item: Row) => item.id === row.parentId);
      const prevDataCpy = JSON.parse(JSON.stringify(prevRows));
      prevDataCpy.splice(parentRowIndex + 1, 0, newRow);
      return prevDataCpy;
    });
  };
  const convertToReadableFormat = (headerString: string) => {
    const [year, month]: any = headerString.split("-");
    if (parseInt(year) && parseInt(month)) {
      const date = new Date(year, month - 1); // month is zero-based in JavaScript Date
      if (date instanceof Date) {
        return date.toLocaleString("en-US", { month: "short" }) + `'${year.slice(-2)}`;
      }
    }
    return headerString;
  };

  const columns = [
    ...headers.map((item: any) => ({
      key: item,
      name: convertToReadableFormat(item),
      minWidth: item === "Channel" ? 210 : 164,
      frozen: item === "Channel" || item === "Total" ? true : false,
      ...(item !== "Total" && item !== "Channel" && { renderEditCell: conditionalEditor }),
      getRowMetaData: (row: Row) => row,
      renderCell: ({ row, column, rowId, ...props }: { row: Row; column: any; rowId: string }) => {
        const isHiddenRow = hiddenRows.includes(row.id);
        if (row.isEditableRow) {
          return (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", color: column.idx === 0 ? "black" : "#5E636E" }}
              className={classNames("h-full w-full font-medium text-sm", { "bg-[#F7F7F8]": isHiddenRow })}
            >
              {column.name == "Total" ? (
                <span className={classNames({ "text-[#C8CAD0]": isHiddenRow })}>{calculateRowTotal(row.id)}</span>
              ) : column.idx === 0 ? (
                <span className='flex items-center'>
                  <button
                    className={classNames("h-5 w-5 ml-2 rounded flex items-center justify-center hover:bg-gray-300 ")}
                    onClick={() => {
                      console.log("Running", row);
                      if (row.name === "") return;
                      addNewRow(row);
                    }}
                  >
                    <PlusIcon className='h-3 w-3 z-50' />
                  </button>
                  <TextOnlyInput {...props} row={row} column={column} rowId={rowId} />
                </span>
              ) : (
                <span className={classNames("px-2", { "text-[#C8CAD0]": isHiddenRow })}>{"$" + numberWithCommas(row[item])}</span>
              )}
            </div>
          );
        }
        return row.isParent ? (
          <div
            aria-disabled
            style={{
              cursor: "pointer",
              backgroundColor: "#F4F7FF",
              padding: 8,
              color: column.idx === 0 ? "black" : "#1F2228",
              fontWeight: column.idx === 0 ? 600 : 500,
            }}
            className='h-full w-full text-sm'
            onClick={e => {
              e.preventDefault();
              toggleExpand(row.id);
            }}
          >
            {column.key === "Total"
              ? calculateRowTotal(row.id)
              : column.idx === 0
                ? renderRowNameWithIcon(row)
                : "$" + numberWithCommas(row[item])}
          </div>
        ) : (
          <div
            style={{ cursor: "pointer", padding: 8, color: column.idx === 0 ? "black" : "#5E636E" }}
            className={classNames("h-full w-full font-medium text-sm", { "bg-[#F7F7F8]": isHiddenRow })}
          >
            {column.name == "Total" ? (
              <span className={classNames({ "text-[#C8CAD0]": isHiddenRow })}>{calculateRowTotal(row.id)}</span>
            ) : column.idx === 0 ? (
              <span className='flex items-center'>
                <button
                  className={classNames("h-5 w-5 mr-3 rounded flex items-center justify-center ", { "bg-[#E9EAEC]": isHiddenRow })}
                  onClick={() => {
                    updateHiddenRows(row.id);
                  }}
                >
                  {isHiddenRow ? <EyeSlashIcon className='h-3 w-3 z-50' /> : <EyeIcon className='h-3 w-3 z-50' />}
                </button>
                <span className={classNames({ "text-[#C8CAD0]": isHiddenRow })}>{row.name}</span>
              </span>
            ) : (
              <span className={classNames({ "text-[#C8CAD0]": isHiddenRow })}>{"$" + numberWithCommas(row[item])}</span>
            )}
          </div>
        );
      },
      resizable: true,
    })),
  ];

  // On Click Parent Row Child Rows will expand or hide.
  const toggleExpand = (id: any) => {
    setDataBudgetRow((prevRows: Row[]) => prevRows.map((row: Row) => (row.id === id ? { ...row, expanded: !row.expanded } : row)));
  };

  const themeMappings = (id: number | string): IChannelData => {
    const currentChannel = projectChannels?.find(channel => String(channel.channel_id) == String(id));
    if (!currentChannel)
      return { icon: "icon1", name: "Unassigned", color: { primary: "#F7F7F8", light: "#F7F7F8", dark: "#F7F7F8" } } as IChannelData; // If Channel not found
    return currentChannel;
  };

  // Do the Addition of Childs data and add it in parent
  const addChildDataToParent = (totalChildRows: Row[], parentRow: Row) => {
    const keysToSum = Object.keys(parentRow).filter(key => !["id", "parentId", "name", "expanded", "isParent"].includes(key));
    const updatedParentRow = { ...parentRow };
    keysToSum.forEach(key => {
      updatedParentRow[key] = totalChildRows.reduce((acc: number, childRow: Row) => {
        if (hiddenRows.includes(childRow.id)) return acc;
        return acc + parseInt(childRow[key]);
      }, 0);
    });
    return updatedParentRow;
  };

  const filteredRows = budgedDataRow.filter(
    (row: Row) => !row.parentId || (row.parentId && budgedDataRow.find((r: Row) => r.id === row.parentId).expanded),
  );
  // On Edit of Cell Recalculate all the numbers
  const onRowsChange = (rows: unknown[], _data: RowsChangeData<unknown, unknown>) => {
    const updatedRows = rows as Row[];
    setDataBudgetRow((prevRows: Row[]) => {
      let totalChildRows: Row[] = [];
      const mergedRows = prevRows.reverse().map((row: Row) => {
        let updatedRow: Row | undefined = updatedRows.find(r => r.id === row.id);
        if (updatedRow === undefined) return row;

        if (row.isParent) {
          const newUpdateParentRow = addChildDataToParent(totalChildRows, updatedRow);
          updatedRow = { ...updatedRow, ...newUpdateParentRow };
          totalChildRows = [];
        } else {
          const updateRowExist = updatedRows.find(data => data.id === row.id);
          totalChildRows.push(updateRowExist ? updateRowExist : row);
        }
        return updatedRow ? { ...row, ...updatedRow } : row;
      });
      return mergedRows.reverse();
    });
  };

  const addEditableRow = (i: number, row: Row): Row => {
    const dateColumns: { [key: string]: number } = {};
    for (const key of Object.keys(budgetData[i])) {
      if (!keysToIgnore.includes(key)) {
        dateColumns[key] = 0;
      }
    }

    return {
      id: uuidv4(),
      parentId: budgetData[i]?.parentId ?? budgetData[i]?.id,
      isEditableRow: true,
      name: "",
      channel_id: budgetData[i]?.channel_id,
      ...dateColumns,
    };
  };
  // On Mount Add the Child Numbers To parent
  const sortData = () => {
    let totalChildRows: Row[] = [];
    const budgetDataCopy = JSON.parse(JSON.stringify(budgetData));
    const data: Row[] = [];

    for (let i = budgetDataCopy.length - 1; i >= 0; i--) {
      const row = budgetDataCopy[i];
      let newRow = row;
      if (i === budgetDataCopy.length - 1) {
        const editableRow = addEditableRow(i, row);
        data.unshift(editableRow);
      }
      if (row.isParent) {
        const newUpdateParentRow = addChildDataToParent(totalChildRows, row);
        newRow = { ...newUpdateParentRow };
        totalChildRows = [];
      } else {
        // If next row is parent then add a new Editable row
        if (budgetData[i + 1]?.isParent) {
          const editableRow = addEditableRow(i, row);
          data.unshift(editableRow);
        }

        totalChildRows.push(row);
      }
      data.unshift(newRow);
    }
    setDataBudgetRow(data);
  };
  useEffect(() => {
    sortData();
  }, [budgetData, hiddenRows]);
  return (
    <div className='datasheet-grp'>
      {loadingReviewBudget ? (
        <div className='flex justify-center items-center h-full w-full'>
          <BudgetTableLoader />
        </div>
      ) : (
        <DataGrid
          columns={columns}
          className='h-auto'
          rows={filteredRows}
          onRowsChange={onRowsChange}
          onCellClick={(_params, event) => {
            if (!keysToIgnore.includes(_params.column.key)) {
              event.preventGridDefault();
              _params.selectCell(true);
            }
            // event.preventGridDefault();
          }}
          onCellDoubleClick={(_params, _event) => {
            // event.preventGridDefault()
          }}
          //   rowKeyGetter={(row) => row.id}
        />
      )}
    </div>
  );
}

export default CollapsibleRowTable;
