import { useQuery, useQueryClient } from "@tanstack/react-query";

import { apiGetCompanyData, apigetPersonalizationUrl } from "api/personalization";

export const companyQueryKeys = {
  getPerviouslySavedData: ["getPerviouslySavedData"] as const,
  getCompanyData: ["getCompanyData"] as const,
};

export const useGetPerviouslySavedCompanyData = (onSuccess?: (response: any) => void) =>
  useQuery({
    queryKey: companyQueryKeys.getPerviouslySavedData,
    queryFn: async (): Promise<any> => {
      const response = await apigetPersonalizationUrl();

      if (onSuccess) {
        onSuccess(response);
      }

      return response;
    },
  });

export const useGetCompanyData = (onSuccess?: (response: any) => void) =>
  useQuery({
    queryKey: companyQueryKeys.getCompanyData,
    queryFn: async (): Promise<any> => {
      const response = await apiGetCompanyData();

      if (onSuccess) {
        onSuccess(response);
      }

      return response;
    },
  });
