import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import Button from "shared/components/auth-buttons/Button";
import Alert from "shared/components/toasts";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import { ReactComponent as ReviewOverlayIcon2 } from "assets/icons/Logo.svg";

interface OverlayProps {
  onClose: () => void; // TODO: can we make this take the user to the Connected Sources page?
  onOpen: () => void;
  onSubmit: (shopUrl: string) => Promise<any>;
}

const ShopifyConnectModal: React.FC<OverlayProps> = ({ onClose, onOpen, onSubmit }) => {
  const [value, setValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleOnSubmit = () => {
    onSubmit(`${value}.myshopify.com`)
      .then(res => {
        window.location.href = res.authorizationUrl;
      })
      .catch(err => {
        Alert("error", err.message, "Error");
      });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return ReactDOM.createPortal(
    <div id='overlay-general'>
      <div className='flex flex-col bg-white p-20 rounded-3xl relative'>
        <CloseWhiteBtn height={36} width={36} className='absolute top-6 right-6' onClick={onClose} />

        <div className='flex flex-col gap-6 items-center'>
          <p className='text-3xl font-semibold text-[#1F2228]'>Connection details</p>
          <p className='text-base font-medium text-[#5E636E] leading-7'>Enter the first part of your Shopify store URL.</p>
        </div>
        <div className='mt-10 max-w-486 flex flex-col gap-1'>
          <p className='text-sm font-medium text-black1'>Store URL</p>
          <div className='flex items-center relative bg-white border border-ring rounded-xl h-10 px-3.5 py-2.5'>
            <input
              ref={inputRef}
              value={value}
              onChange={handleChange}
              placeholder='my-store'
              className='placeholder:text-[#9EA2AD] placeholder:font-medium placeholder:text-sm text-black1 outline-none w-[153px] text-sm font-medium focus-visible:ring-0 bg-transparent'
            />
            <div className='absolute right-3 flex items-center text-sm font-medium text-black1'>
              {/* <span className='text-transparent absolute'>{value || "my-store"}</span> */}
              <span>.myshopify.com</span>
            </div>
          </div>
        </div>
        <div className='mt-10 flex gap-4'>
          <Button
            className='py-2 bg-white text-black1 text-sm font-semibold border border-[#afa8f4] flex-nowrap text-nowrap hover:border-[#6251f3] hover:bg-white hover:text-black1 !rounded-[0.75rem]'
            onClick={onClose}
          >
            <p>Close</p>
          </Button>
          <Button disabled={value == ""} className=' px-[42px] !rounded-[0.75rem]' onClick={handleOnSubmit}>
            <p>Continue</p>
          </Button>
        </div>
      </div>
    </div>,
    document.body, // Render in the document body to cover the entire viewport
  );
};

export default ShopifyConnectModal;
