import { ReactComponent as AnalyticsIcon } from "../../../assets/icons/navigation-icons/analyticsIcon.svg";
import { ReactComponent as ActiveHomeIcon } from "../../../assets/icons/navigation-icons/homeActiveIcon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/icons/navigation-icons/homeIcon.svg";
import { ReactComponent as IntegrationsIcon } from "../../../assets/icons/navigation-icons/integrationsIcon.svg";
import { ReactComponent as VoyagersIcon } from "../../../assets/icons/navigation-icons/voyagersIcon.svg";
import { ReactComponent as ActiveWorkflowIcon } from "../../../assets/icons/navigation-icons/workflowActiveIcon.svg";
import { ReactComponent as WorkflowIcon } from "../../../assets/icons/navigation-icons/workflowIcon.svg";
import { ROUTES } from "../../../constants/routes.constants";
import { Icons } from "../icons";

export const navConfig = [
  { to: ROUTES.dashboard, title: "Home", Icon: HomeIcon, ActiveIcon: ActiveHomeIcon, beta: false },
  { to: ROUTES.agents, title: "Agents", Icon: Icons.Agents, ActiveIcon: Icons.Agents, beta: false },
  {
    to: ROUTES.projects,
    title: "Projects",
    Icon: WorkflowIcon,
    ActiveIcon: ActiveWorkflowIcon,
    beta: true,
    dropdown: [
      { to: ROUTES.taskProjects, title: "Tasks" },
      { to: ROUTES.workflowProjects, title: "Workflows" },
    ],
  },
  {
    to: "/integrations",
    title: "Integrations",
    Icon: IntegrationsIcon,
    ActiveIcon: IntegrationsIcon,
    beta: true,
    dropdown: [
      { to: "/integrations/connect-sources", title: "Connect Sources" },
      { to: "/integrations/connected-sources", title: "Connected Sources" },
    ],
  },
];

export const soonPagesConfig = [{ to: "/analytics", title: "Analytics", Icon: AnalyticsIcon, ActiveIcon: AnalyticsIcon, beta: false }];

// Except for these routes, sidebar should be closed automatically
export const preventAutoCloseRoutes = [ROUTES.dashboard, ROUTES.budget, ROUTES.workflow, ROUTES.connectedSources];
