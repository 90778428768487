import cn from "classnames";
import React from "react";
import ReactDOM from "react-dom";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import Button from "shared/components/auth-buttons/Button";
import { Icons } from "shared/components/icons";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import TempImg from "assets/png/temp_image_remove_it.png";

import TextEditor from "../text-editor/text-editor";
interface OverlayProps {
  onClick: () => void; // TODO: can we make this take the user to the Connected Sources page?
  onClose: () => void;
}

const SchedulePostModal: React.FC<OverlayProps> = ({ onClick,onClose }) => {
  const [value, setValue] = React.useState<string>("");
  return ReactDOM.createPortal(
    <div id='overlay-general'>
      <div className='flex flex-col bg-white rounded-3xl relative'>
        {/* -----------------------------  Header  -------------------------------- */}

        <div className='flex items-center justify-between px-6 py-4'>
          <p className='text-[#1F2228] text-2xl font-semibold'>Schedule post</p>
          <CloseWhiteBtn height={36} width={36} className='' onClick={onClose} />
        </div>
        <hr />
        {/* -----------------------------  Body  -------------------------------- */}

        <div className='px-6 pt-8 pb-6 flex flex-col gap-y-8'>
          <div className='flex items-end justify-between'>
            <div className='flex gap-x-2'>
              <div className='flex items-center gap-2 px-3.5 py-2.5 rounded-3xl bg-[#F7F7F8] border border-[#E9EAEC]'>
                <Icons.TikTok />
                <p className='text-sm font-medium text-[#1F2228]'>brainvoy.ai</p>
              </div>
              <div className='flex items-center gap-2 px-3.5 py-2.5 rounded-3xl bg-white border border-[#E9EAEC]'>
                <Icons.Instagram />
                <p className='text-sm font-medium text-[#1F2228]'>brainvoy</p>
              </div>
              <div className='flex items-center gap-2 px-3.5 py-2.5 rounded-3xl bg-white border border-[#E9EAEC]'>
                <Icons.Facebook />
                <p className='text-sm font-medium text-[#1F2228]'>facbook</p>
              </div>
            </div>
            <div>
              <p className='text-sm font-semibold text-[#766CE4] underline'>Connect sources</p>
            </div>
          </div>
          <div className='flex'>
            <div>
              <p className='text-sm font-medium text-[#717684] mb-2'>Edit your media</p>
              <div className="relative">
              <TextEditor value={value} onChange={value => setValue(value)} />
                <div className="absolute top-9 w-full p-4 flex justify-center">
                  <div className="text-center w-full p-4 border border-dashed border-[#C8CAD0] rounded-xl ">
                  <div className="p-2.5 rounded-full bg-[#F2F3F5] border border-[#FBFBFB] inline-block"><Icons.UploadCloud /></div>
                  <p className="text-sm font-semibold text-[#766CE4] underline">Click to upload creative</p>
                  </div>
                  </div>
              </div>
              <div className='mt-6 flex flex-col gap-y-2'>
                <div className='flex items-center gap-2 gradient-schedule-post-tab rounded-2xl px-5 py-2.5'>
                  <Icons.ArrowDown /> <Icons.TikTok /> <p className='text-sm font-medium text-[#1F2228]'>Tiktok settings</p>
                </div>
                <div className='flex items-center gap-2 gradient-schedule-post-tab rounded-2xl px-5 py-2.5'>
                  <Icons.ArrowDown /> <Icons.Instagram /> <p className='text-sm font-medium text-[#1F2228]'>Instagram settings</p>
                </div>
                <div className='flex items-center gap-2 gradient-schedule-post-tab rounded-2xl px-5 py-2.5'>
                  <Icons.ArrowDown /> <Icons.Facebook /> <p className='text-sm font-medium text-[#1F2228]'>Facebook settings</p>
                </div>
              </div>
            </div>
            <div className='ml-20 mr-16'>
              <p className='text-sm font-medium text-[#717684] mb-2'>Preview</p>
              <img src={TempImg} className='w-[255px] h-[506px] rounded-md bg-black' />
            </div>
          </div>
        </div>
        <hr />
        {/* -----------------------------  Footer  -------------------------------- */}

        <div className='px-6 py-4 flex justify-end gap-8'>
          <div className='px-2.5 py-[9px] rounded-xl border border-[#E9EAEC] bg-[#F7F7F8] flex items-center gap-x-2.5'>
            <Icons.Clock />
            <p className="text-sm font-semibold text-[#333740]">Dec 1, 2025 10:00 AM</p>
          </div>
          <button className='bg-[#E9EAEC] px-10 py-[9px] rounded-xl' onClick={onClick}>
            <p className='text-[#C8CAD0] text-sm font-semibold'>Done</p>
          </button>
        </div>
      </div>
    </div>,
    document.body, // Render in the document body to cover the entire viewport
  );
};

export default SchedulePostModal;
