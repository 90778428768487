import { useCallback, useEffect } from "react";

import { AGENT_IMAGES } from "constants/agent.constants";

import { useAI } from "shared/components/ai-panel/provider";

const AiChatModal = () => {
  const { openPanel, closePanel, agent, showAsSheet, isOpen, setWhatShouldIAsk, setIsBudgetIntelligence } = useAI();
  const handleOpenAISheet = useCallback(() => {
    if (isOpen) {
      closePanel();
    } else {
      openPanel(true);
    }
  }, [openPanel, showAsSheet, isOpen]);

  useEffect(() => {
    if (isOpen == false) {
      setIsBudgetIntelligence(true);
      setWhatShouldIAsk(true);
      openPanel(true);
    }
  }, []);

  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  return (
    <button className='fixed right-10 bottom-10   rounded-full  gradient-box ' onClick={handleOpenAISheet}>
      <div className='bg-white flex items-center p-1 rounded-full'>
        <img src={Image} className='w-12 h-12 rounded-full' />
        <p className='text-[#1F2228] text-lg font-bold px-3'>{agent?.name}</p>
      </div>
    </button>
  );
};
export default AiChatModal;
