import React, { FC, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { refreshToken } from "api";

export const ProtectedRoute: FC<{
  isAuth: boolean | null;
  to: string;
}> = ({ isAuth, to }) => {
  const location = useLocation();
  const refreshAccessToken = async ()=>{
    try{
      await refreshToken()
    }catch(e){
      console.log(e)
    }
  }
  useEffect(()=>{
    let interval:any
    if(isAuth){
       interval = setInterval(async ()=>{
        refreshAccessToken()
      },1000*60*500) // 5 minutes
    }else{
      clearInterval(interval)
    }
    
    return ()=>clearInterval(interval)
  },[isAuth])
  const navigateState = !isAuth ? { from: location.pathname, search: location.search } : {};

  if (isAuth && location.pathname === ROUTES.home) return <Navigate to={ROUTES.dashboard} />;

  return isAuth ? <Outlet /> : <Navigate to={to} state={navigateState} replace />;
};
