import { ICredentialData } from "interfaces/integrations.interfaces";
import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";

import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getConnectSourceList = (): Promise<any[]> => {
  return api(`${BASE_API}/integrations`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedSourceList = (): Promise<{ data: any[] }> => {
  return api(`${BASE_API}/connections`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

/**
 * Fetches the list of integration categories and their associated integrations
 * @returns A promise that resolves to an object where keys are category names and values are arrays of integration names
 */
export const getIntegrationCategories = (): Promise<Record<string, string[]>> => {
  return api(`${BASE_API}/integrations/categories`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const generateCredentialForAppIntegration = (id: number, shopUrl?: string, countryCode?: string): Promise<any> => {
  let url = `${BASE_API}/credentials/auth-url/${id}`;
  const params = [];

  if (shopUrl) {
    params.push(`shop_url=${shopUrl}`);
  }

  if (countryCode) {
    params.push(`country_code=${countryCode}`);
  }

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return api(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const saveOauth2Credential = (code: string, state: string, payload?: Record<string, any>): Promise<any> => {
  const payloadString = payload ? JSON.stringify(payload) : "";
  return api(`${BASE_API}/credentials/oauth-callback?code=${code}&state=${encodeURIComponent(state)}&payload=${encodeURIComponent(payloadString)}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getCredential = (id: number): Promise<ICredentialData> => {
  return api(`${BASE_API}/credentials/refresh/${id}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const createConnection = (data: any): Promise<any> => {
  return api(`${BASE_API}/connections`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const refreshConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}/sync`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const pauseConnection = (id: StringOrNumber, data: { enabled?: boolean; account_name?: string }): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const removeConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedData = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedSourceTables = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}/schema`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

interface NotificationSettingsPayload {
  frequency: string;
  channel: string;
  recipients: string[];
  templateKey: string;
};

export const notificationSettings = (
  payload: NotificationSettingsPayload
): Promise<any> => {
  return api(`${BASE_API}/notification-settings`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
};

