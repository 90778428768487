import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { AgentItem } from "api/agent/type";
import { IChannelData } from "api/budget/types";
import { createProject, getProjectById, initializeProject, updateProject } from "api/projects";
import { ICreateProjectData } from "api/projects/types";

import { AGENT_MOCK_RESPONSE, NEW_USER_DEFAULT_AGENT_UUID } from "constants/agent.constants";
import { ROUTES } from "constants/routes.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";
import { SOCIAL_PLATFORM_ICONS } from "constants/social-icon.constants";
import { isValidUrl, REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION, REQUIRED_URL_FIELD_VALIDATION } from "constants/validation.constants";

import { formatNumberToUSLocale } from "utils/shared.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";
import { ElementSize, useElementSize } from "hooks/use-element-size";
import useIsMobile from "hooks/use-mobile";
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { useString } from "hooks/useString";

import { setConversationId } from "storage/slices/conversation-slice";
import { setLoading } from "storage/slices/loader-slice";
import { openPopup } from "storage/slices/popup-slice";
import { setUploadedPoject } from "storage/slices/project-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { useAI } from "shared/components/ai-panel/provider";
import Button from "shared/components/auth-buttons/Button";
import Popup from "shared/components/basePopup/Popup";
import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import DatePicker from "shared/components/budget/datePicker/DatePicker";
import GoalItem from "shared/components/budget/goalItem/GoalItem";
import ItemInput from "shared/components/budget/goalItem/ItemInput";
import { FormLabel } from "shared/components/form-elements/FormLabel";
import { HighlightedCountMessage } from "shared/components/form-elements/HighlightedCountMessage";
import { Input } from "shared/components/form-elements/Input";
import { MessageWithCounter } from "shared/components/form-elements/MessageWithCounter";
import { TextArea } from "shared/components/form-elements/TextArea";
import { Icons } from "shared/components/icons";
import CreateBudgetPopup from "shared/components/popups/createBudgetPopup/CreateBudgetPopup";
import TagInputComponent from "shared/components/tag-input/TagInputComponent";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { cn } from "lib/utils";

export default function NewBudgetForm() {
  const isMobile = useIsMobile(1100);
  const [setRef, size] = useElementSize();
  const [currentStep, setCurrentStep] = React.useState(1);
  const agents = useAppSelector(state => state.agent.agents);
  const preferences = useAppSelector(state => state.preferences);
  const { id } = useParams();
  const [formState, setFormState] = useState<
    Partial<ICreateProjectData> & {
      isRevenueUnknown?: boolean;
    }
  >({});

  const agent = useMemo(() => {
    if (!agents) return null;

    return agents.find((agent: AgentItem) => agent.uuid === preferences.preferences.agent_uuid || NEW_USER_DEFAULT_AGENT_UUID);
  }, [agents]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    //
    dispatch(setConversationId(uuidv4()));
  }, [dispatch, isMobile]);

  return (
    <DashboardLayout
      budgetFormState={formState}
      updateBudgetFormState={setFormState}
      titleContent={
        <div className='flex items-center gap-3 cursor-pointer'>
          <h3 className='text-[#333740] font-semibold text-base'>{agent?.title ?? "Start Your Project"}</h3>
          <Icons.ArrowDown className='text-[#333740]' />
        </div>
      }
      containerClassName='bg-white'
      isScrollable={isMobile || size.width < 705}
    >
      <BudgetForm
        isMobile={isMobile}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        id={id}
        formState={formState}
        setFormState={setFormState}
        setContainerRef={setRef}
        containerSize={size}
      />
    </DashboardLayout>
  );
}

interface FileUploadState {
  fileId: string;
  file?: File;
  fileUrl?: string;
  uploadedFileId?: string;
  progress: number;
  status: "idle" | "uploading" | "failed" | "completed" | "deleting";
  bytesUploaded: number;
  error?: string;
  abortController?: AbortController;
}

const BudgetForm = ({
  isMobile,
  currentStep,
  setCurrentStep,
  formState,
  setFormState,
  id,
  setContainerRef,
  containerSize,
}: {
  isMobile?: boolean;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  formState: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  setFormState: React.Dispatch<
    React.SetStateAction<
      Partial<ICreateProjectData> & {
        isRevenueUnknown?: boolean;
      }
    >
  >;
  id?: string;
  setContainerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  containerSize: ElementSize;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenUploadFile, setIsOpenUploadFile] = useState(false);
  const data: any = useAppSelector(state => state.project);
  const file_id: number | null = useAppSelector(state => state.project?.uploadedProject?.file_id) || null;
  const location = useLocation();
  const isEdit = location.pathname.includes("edit");
  const { data: marketingChannels } = useGetChannels();
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  const [project_id, _] = useState<string | undefined>(id);

  const [fileStates, setFileStates] = useState<Map<string, FileUploadState>>(new Map());

  const { isOpen, openPanel, sendFormInput, setWhatShouldIAsk, handleAddOrUpdateChat, setIsBudgetIntelligence } = useAI();
  const [hasPassStep2, setHasPassStep2] = useState(false);
  const [hasPassStep3, setHasPassStep3] = useState(false);

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      company_website_url: "",
      description: "",
    },
  });

  const isNextBtnDisabled = useMemo(() => {
    if (currentStep === 1) {
      const isNameInvalid = !formState.name || formState.name.trim() === "";

      const isCompanyWebsiteUrlInvalid = !formState.company_website_url || formState.company_website_url.trim() === "";

      const isGoalsLessThanOne = !formState.goals || formState.goals.length < 1;

      const isDateRangeNotSet = !formState.budget_date_from || !formState.budget_date_to;

      return isNameInvalid || isCompanyWebsiteUrlInvalid || isGoalsLessThanOne || isDateRangeNotSet;
    }

    if (currentStep === 2) {
      // check if any files is in uploading state
      const isAnyFileUploading = Array.from(fileStates.values()).some(file => file.status === "uploading");

      return isAnyFileUploading;
    }

    if (currentStep === 3) {
      const isChannelLessThanOne = formState.channels === undefined || formState.channels.length < 1;
      const isCriteriaNotSet = formState.inclusion_criteria === undefined || formState.inclusion_criteria === "";

      return isChannelLessThanOne || isCriteriaNotSet;
    }

    return false;
  }, [formState, currentStep, fileStates]);

  function validateForm(description: any, name: any): boolean {
    // const isDescriptionValid = description?.length >= 120;
    const isNameValid = name?.trim() !== "";
    //return isDescriptionValid && isNameValid;
    return isNameValid;
  }

  const updateProjectData = useCallback(
    (apiData: any) => {
      if (currentStep === 3) {
        dispatch(setLoading(true));
      }

      if (project_id) {
        updateProject(apiData, project_id)
          .then(async res => {
            //dispatch(handleProjectForm({}));
            navigate(`/budget/${project_id}/budget-intelligence`);
          })
          .catch(err => {
            Alert("error", err.message, "Error");
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      } else {
        createProject(apiData)
          .then(async res => {
            setFormState({});
            navigate(`/budget/${res.project_id}/budget-intelligence`);
          })
          .catch(err => {
            Alert("error", err.message, "Error");
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    },
    [currentStep, project_id, dispatch, navigate],
  );

  const nextStepHandler = useCallback(async () => {
    const { revenue_from, revenue_to, isRevenueUnknown, ...formValue } = formState;

    if (currentStep === 3) {
      // TODO: revisit this section for correctness
      const apiData = {
        name: formValue?.name,
        description: formValue?.description,
        audience: formValue?.audience,
        goals: formValue?.goals,
        revenue_from: revenue_from,
        revenue_to: revenue_to,
        revenue_rate: formValue?.revenue_rate,
        isRevenueUnknown: isRevenueUnknown,
        channels: formValue?.channels?.map((item: IChannelData) => item.channel_id) ?? [],
        budget_from: formValue?.budget_from,
        budget_to: formValue?.budget_to,
        budget_date_from: formValue?.budget_date_from,
        budget_date_to: formValue?.budget_date_to,
        company_website_url: formValue?.company_website_url,
        criteria: formValue?.inclusion_criteria,
        platforms: formValue?.platforms,
        inclusion_criteria: formValue?.inclusion_criteria,
        ...(file_id && { file_id: file_id }),
      };

      const { channels, inclusion_criteria, platforms } = formValue;
      sendFormInput({ channels: (channels ?? []).map((item: any) => item.name), inclusion_criteria, platforms });

      //updateProjectData(apiData);
    } else {
      if (currentStep == 1) {
        const isValidatedCorrectly = await form.trigger();
        if (!isValidatedCorrectly) return;

        if (!validateForm(formValue?.description, formValue?.name)) {
          return;
        }

        const { name, goals, budget_date_from, budget_date_to, revenue_rate, company_website_url } = formValue;
        sendFormInput({ name, goals, company_website_url, budget_date_from, budget_date_to, revenue_rate });

        if (hasPassStep2 == false) {
          setTimeout(() => {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.MoveToNextStep,
            });
          }, 1000);

          setTimeout(() => {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.InfoAboutFileCollections1,
            });
          }, 2000);

          setTimeout(() => {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.InfoAboutFileCollections2,
            });
          }, 2500);

          setTimeout(() => {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.InfoAboutFileCollections3,
            });
          }, 3000);

          setHasPassStep2(true);
        }
      }

      if (currentStep == 2) {
        //const { file_id } = formValue;

        //sendFormInput({ file_id });

        if (hasPassStep2 == false) {
          setTimeout(() => {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.MoveToStep3Response,
            });
          }, 1000);
          setHasPassStep3(true);
        }
      }

      setCurrentStep(prev => prev + 1);
    }
  }, [currentStep, formState, file_id, form, setCurrentStep, updateProjectData, hasPassStep2, hasPassStep3]);

  const skipHandler = useCallback(() => {
    setCurrentStep(prev => prev + 1);
  }, []);

  const prevStepHandler = () => {
    setCurrentStep(prev => prev - 1);
  };

  useEffect(() => {
    if (isEdit == true && data?.uploadedProject && data.method !== "manual") {
      setFormState({
        ...formState,
        budget_from: data?.uploadedProject?.budget_from,
        budget_date_to: data?.uploadedProject?.budget_date_to,
        budget_date_from: data?.uploadedProject?.budget_date_from,
        budget_to: data?.uploadedProject?.budget_to,
        revenue_from: data?.uploadedProject?.revenue_from,
        revenue_to: data?.uploadedProject?.revenue_to,
        revenue_rate: data?.uploadedProject?.revenue_rate,
        goals: [...(data?.newProjectForm?.goals || [])],
        channels: [
          ...(marketingChannels || []).filter(item => data?.uploadedProject?.channels?.find((goal: any) => goal === item.channel_id)),
        ],
      }),
        form.setValue("name", data?.newProjectForm?.name);
      form.setValue("description", data?.newProjectForm?.description);
      form.setValue("company_website_url", data?.newProjectForm?.company_website_url);
    }
  }, [data, dispatch, form, isEdit, marketingChannels]);

  const prefillProjectData = useCallback(async () => {
    if (id === undefined) return;

    setIsLoadingProject(true);

    getProjectById(Number(id))
      .then(res => {
        // TODO: revisit this section for correctness
        setFormState({
          ...res,
        }),
          form.setValue("name", res.name || "");
        form.setValue("description", res.description || "");
        form.setValue("company_website_url", res.company_website_url || "");
      })
      .catch(err => {
        Alert("error", err.message, "Error");
      })
      .finally(() => {
        setIsLoadingProject(false);
      });
  }, [dispatch, form, id]);

  const saveFileId = (apiData: any) => {
    updateProject(apiData, project_id)
      .then(res => {
        console.log("Respo", res);
      })
      .catch(res => {
        Alert("error", res.message, "Error");
      });
  };

  useEffect(() => {
    dispatch(setIsCollapsed(true));
    return () => {
      dispatch(setUploadedPoject(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      prefillProjectData();
    } else {
      form.reset();
      setFormState({});
    }
  }, [id, prefillProjectData]);

  useEffect(() => {
    if (location.state?.fileName && project_id) {
      const apiData = {
        file_id: file_id,
      };
      saveFileId(apiData);
      dispatch(setUploadedPoject(null));
    }
  }, [dispatch, file_id, location.state?.fileName, project_id, saveFileId]);

  useEffect(() => {
    if (isOpen == false) {
      setIsBudgetIntelligence(false);
      setWhatShouldIAsk(false);
      openPanel();
    }
  }, [isOpen, openPanel]);

  return (
    <div
      ref={setContainerRef}
      className={cn("relative flex flex-col py-6  pr-6", isMobile == true || containerSize.width < 705 ? "pl-6" : "h-full pl-10")}
    >
      <Breadcrumbs
        breadcrumbs={[
          {
            path: "/",
            label: "Home",
            active: false,
          },
          {
            path: "/projects",
            label: "Browse projects",
            active: false,
          },
          {
            path: "/budget/form",
            label: id ? "Update budget" : "Create new budget",
            active: true,
          },
        ]}
      />
      <div className='mt-10 flex flex-col mb-10'>
        <h1 className='text-2xl text-[#333740] font-semibold'>{id ? "Update marketing budget" : "Create new marketing budget"}</h1>
        <div className='mt-4 flex flex-wrap xl:flex-nowrap items-center flex-shrink-0 gap-2 xl:pr-4'>
          <StepBtn step={1} title='Company details' currentStep={currentStep} setCurrentStep={setCurrentStep} />
          <StepBtn
            step={2}
            title='Select source'
            currentStep={currentStep}
            disabled={hasPassStep2 == false}
            setCurrentStep={setCurrentStep}
          />
          <StepBtn step={3} title='Customize' currentStep={currentStep} disabled={hasPassStep3 == false} setCurrentStep={setCurrentStep} />
        </div>
      </div>
      <div className={cn("flex flex-col justify-between h-full w-full gap-6")}>
        {currentStep === 1 && <StepOne form={form} isMobile={isMobile} id={id} formState={formState} setFormState={setFormState} />}
        {currentStep === 2 && (
          <StepTwo
            isOpenUploadFile={isOpenUploadFile}
            setIsOpenUploadFile={setIsOpenUploadFile}
            fileStates={fileStates}
            setFileStates={setFileStates}
            formState={formState}
            setFormState={setFormState}
          />
        )}
        {currentStep === 3 && <StepThree formState={formState} setFormState={setFormState} />}
        <BtnGroup
          showSkip={currentStep > 1}
          disabledSkipHandler={currentStep == 3 || currentStep == 1}
          disabledPrevHandler={currentStep <= 1}
          disabledNextHandler={isNextBtnDisabled}
          skipHandler={skipHandler}
          nextStepHandler={nextStepHandler}
          prevStepHandler={prevStepHandler}
        />
      </div>
      {isLoadingProject && <div className='absolute bg-black/20 w-full h-full z-10 top-0 right-0'></div>}
    </div>
  );
};

const StepBtn = ({
  step,
  title,
  setCurrentStep,
  currentStep,
  disabled,
}: {
  step: number;
  title: string;
  currentStep: number;
  disabled?: boolean;
  setCurrentStep: (step: number) => void;
}) => {
  const isCurrentStep = step === currentStep;

  return (
    <button
      type='button'
      disabled={disabled == true}
      onClick={() => (disabled == true ? null : setCurrentStep(step))}
      className={cn(
        "px-5 py-2.5 rounded-[24px] text-sm font-medium  flex items-center gap-2.5 disabled:cursor-not-allowed",
        isCurrentStep ? "bg-[#F7F7F8]" : "border border-[#E9EAEC] text-[#717684]",
      )}
    >
      <span
        className={cn(
          "border w-5 h-5 rounded-full flex items-center justify-center font-semibold text-xs text-white",
          isCurrentStep ? "bg-[#333740] border-[#333740]" : "bg-[#9EA2AD]  border-[#9EA2AD]",
        )}
      >
        {step}
      </span>
      {title}
    </button>
  );
};

const StepOne = ({
  form,
  isMobile,
  id,
  formState,
  setFormState,
}: {
  form: UseFormReturn<any>;
  isMobile?: boolean;
  id?: string;
  formState: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  setFormState: React.Dispatch<
    React.SetStateAction<
      Partial<ICreateProjectData> & {
        isRevenueUnknown?: boolean;
      }
    >
  >;
}) => {
  const staticMetrics = ["Increase Sales", "Increase Traffic", "Lower CAC", "Increase CLTV", "Improve CSAT", "Increase Revenue"];

  const dispatch = useAppDispatch();

  const [revenue, setRevenue] = React.useState<boolean>(true);
  const [grow, setGrow] = React.useState<boolean>(true);

  const [audienceError, setAudienceError] = useString();

  const watchDescriptionField = form.watch("description");

  const charCount = watchDescriptionField?.length;

  const values = form.watch();

  const [isItemInputVisible, setIsItemInputVisible] = useState(false);

  const [growPercentage, setGrowPercentage] = useState<number>(0);

  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // assistant chat ids
  const chatId1 = uuidv4();
  const chatId2 = uuidv4();
  const chatId3 = uuidv4();
  const chatId4 = uuidv4();

  const { sendFormInput, handleAddOrUpdateChat, chats } = useAI();

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const selectBudgetHandler = (value: number | null, key: string) => {
    setFormState({ ...formState, [key]: value });
  };

  const growPercentageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (isNaN(value) || value < 0 || value > 100) {
      return;
    } else {
      setGrowPercentage(value);
      setFormState({ ...formState, revenue_rate: value });
    }
  };

  const goalRef = useRef<NodeJS.Timeout | null>(null);

  const selectGoalHandler = useCallback(
    (goal: any) => {
      setFormState(prev => {
        const goals = prev.goals || [];
        const isExist = goals.find(item => item === goal);
        let newGoals = goals;

        if (isExist) {
          newGoals = goals.filter(item => item !== goal);
        } else if (goals.length < 3) {
          newGoals = [...goals, goal];
        }

        // setFormState({ ...formState, goals: [...newGoals] });

        if (newGoals.length > 0) {
          if (goalRef.current !== null) {
            clearTimeout(goalRef.current);
          }

          if (newGoals.length === 3) {
            handleAddOrUpdateChat({
              id: uuidv4(),
              sender: "assistant",
              message: AGENT_MOCK_RESPONSE.StartGoalSuggestions,
            });
            sendFormInput({ goals: newGoals }, false);
          } else {
            goalRef.current = setTimeout(() => {
              handleAddOrUpdateChat({
                id: uuidv4(),
                sender: "assistant",
                message: AGENT_MOCK_RESPONSE.StartGoalSuggestions,
              });
              sendFormInput({ goals: newGoals }, false);
              if (goalRef.current !== null) {
                clearTimeout(goalRef.current);
              }
            }, 2000);
          }

          // find last chat with goals and update it
          const lastSuggestionChat = [...chats].reverse().find(chat => chat.data?.type === "suggestion" && Array.isArray(chat.data?.goals));

          if (lastSuggestionChat != undefined) {
            handleAddOrUpdateChat({
              ...lastSuggestionChat,

              data: {
                ...lastSuggestionChat.data,
                type: lastSuggestionChat.data!.type,
                goals: lastSuggestionChat.data!.goals.map((item: any) => {
                  return { ...item, isChecked: newGoals.includes(item.label) };
                }),
              },
            });
          }
        }

        return { ...formState, goals: [...newGoals] };
      });
    },
    [formState, chats],
  );

  const handleAddGoal = (goalName: string) => {
    if (Array.isArray(formState.goals)) {
      setFormState({ ...formState, goals: [...formState.goals, goalName] });
    }
    setIsItemInputVisible(false);
  };

  useEffect(() => {
    if (Object.values(values)?.length) return;
    form.reset(formState);
  }, [formState, form.reset, values, form]);

  // useDebouncedEffect(
  //   () => {
  //     if (!formState?.goals?.length) return;
  //     console.log("formState: %o", formState);
  //     sendFormInput({ goals: formState?.goals }, false);
  //   },
  //   [formState?.goals],
  //   2000,
  // );

  // useEffect(() => {
  //   const handleClickOutside = (event: any) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsDropdownVisible(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [dropdownRef]);

  useEffect(() => {
    setGrowPercentage(formState.revenue_rate || 0);
  }, []);

  const handleSetBudgetDate = useCallback(
    (from?: string | null, to?: string | null) => {
      if (
        from != undefined &&
        from != null &&
        from != undefined &&
        to != null &&
        (formState.budget_date_from != from || formState.budget_date_to != to)
      ) {
        sendFormInput({ budget_date_from: from, budget_date_to: to }, false);
      }

      setFormState({ ...formState, budget_date_from: from, budget_date_to: to });
    },
    [formState],
  );

  const uniqueGoalItems = useMemo(() => {
    const combinedItems = [...staticMetrics, ...(formState.goals || [])];
    return combinedItems.filter((item, index, self) => index === self.findIndex(t => t === item));
  }, [formState]);

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Fill company details</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some basic information to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>
      <div className='flex flex-col gap-6 mt-4'>
        <Controller
          name='name'
          control={form.control}
          rules={REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION}
          render={({ field }) => (
            <Input
              {...field}
              onChange={e => {
                field.onChange(e);
                setFormState({ ...formState, name: e.target.value });
              }}
              onBlur={e => {
                // e.preventDefault();
                // send input to Agent
                const input = formState.name;

                // check if input is valid
                if (input === null || input === undefined || input.length === 0) return;

                //if (form.getFieldState("name").isDirty) {
                //  sendFormInput({ name: input });
                //}
              }}
              label='Budget name *'
              placeholder='Type your budget name here'
              maxLength={50}
              containerClassName='flex flex-col gap-1'
              error={form.formState.errors?.name}
            />
          )}
        />

        <Controller
          name='company_website_url'
          control={form.control}
          rules={REQUIRED_URL_FIELD_VALIDATION}
          render={({ field }) => (
            <Input
              {...field}
              onChange={e => {
                field.onChange(e);
              }}
              onBlur={e => {
                // e.preventDefault();
                // send input to Agent
                const input = e.target.value;

                // check if input is valid
                if (
                  input === null ||
                  input === undefined ||
                  input.length === 0 ||
                  isValidUrl(input) == false ||
                  input == formState.company_website_url
                ) {
                  return;
                }

                setFormState({ ...formState, company_website_url: e.target.value });

                if (form.getFieldState("company_website_url").isDirty) {
                  handleAddOrUpdateChat({
                    id: uuidv4(),
                    sender: "assistant",
                    message: AGENT_MOCK_RESPONSE.StartCompanyWebsiteAnalysis,
                  });
                  sendFormInput({ company_website_url: input });
                }
              }}
              label='Company website url *'
              placeholder='www.brainvoy.ai'
              maxLength={50}
              containerClassName='flex flex-col gap-1'
              error={form.formState.errors?.company_website_url}
            />
          )}
        />

        {/* <TextArea
          rows={4}
          {...form.register("description", DESCRIBE_PROJECT_DESCRIPTION_FIELD_VALIDATION)}
          inputClassName='resize-none overflow-hidden'
          label='Project description *'
          placeholder='For example, we’re a small sized e-commerce brand selling beauty product in Europe. We have a limited annual marketing budget and want to optimize our customer acquisition cost low to drive more sales for our business. I want to know how much we should spend on marketing to yield maximum ROI.'
          error={form.formState.errors?.description}
        >
          <MessageWithCounter charsCount={charCount} maxChars={500}>
            <HighlightedCountMessage charsCount={charCount} minCount={120} />
          </MessageWithCounter>
        </TextArea>

        <TagInputComponent project={formData} projectTags={formData.audience} setError={setAudienceError} error={audienceError} /> */}

        <div className='flex flex-col gap-1'>
          <FormLabel>
            Goal *<span className='text-[#717684] text-xs ml-1'>Select up to 3 metrics </span>
          </FormLabel>
          <div className='flex w-full flex-wrap gap-1.5'>
            {uniqueGoalItems?.map((itemGoal, index) => (
              <GoalItem
                key={index}
                checked={!!formState.goals?.find(item => item === itemGoal)}
                onChecked={_ => {
                  selectGoalHandler(itemGoal);
                }}
              >
                {itemGoal}
              </GoalItem>
            ))}
            {isItemInputVisible && <ItemInput currentMetrics={uniqueGoalItems} onSubmit={handleAddGoal} />}
            <button
              disabled={Array.isArray(formState.goals) && formState.goals?.length === 3}
              onClick={() => setIsItemInputVisible(true)}
              className={cn(
                "flex gap-2 items-center justify-center border  py-2.5 pl-6 pr-4 rounded-[56px] bg-white border-[#D5D1F7] drop-shadow-sm text-[#766CE4] border-dashed text-sm font-medium disabled:cursor-not-allowed disabled:text-[#D5D1F7] disabled:border-[#D5D1F7]",
              )}
            >
              Add more <Icons.Plus className=' ' />
            </button>
          </div>
        </div>
        {/* <div className='bg-[#fafbfd] rounded-xl p-5 flex flex-col gap-4'>
          <div className='flex items-start w-full font-bold text-sm'>Estimated company revenue for the year</div>
          <div className='flex items-center justify-start w-full gap-x-5'>
            {revenue ? (
              <>
                <label className='flex flex-col max-w-[206px] w-full gap-y-1'>
                  <div className='text-sm font-semibold text-gray-400'>From</div>
                  <div className='relative flex items-center text-[#2c1a49] font-medium text-sm'>
                    <span className='flex items-center pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-5 text-sm font-medium'>
                      $
                    </span>
                    <input
                      value={formData.revenue_from || ""}
                      placeholder='0'
                      onChange={e => selectBudgetHandler(+e.target.value, "revenue_from")}
                      type='tel'
                      className={
                        "max-w-[206px] w-full h-[58px] form-input py-2.5 pr-4 pl-8 block border border-[#e6e6e6] rounded-[13px]  placeholder:text-gray-400 focus:border-[rgba(44,26,73,0.6)] focus:bg-white outline-none"
                      }
                    />
                    <span className='cursor-pointer w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-6'>USD</span>
                  </div>
                </label>
                <DashIcon />
                <label className='flex flex-col max-w-[206px] w-full gap-y-1'>
                  <div className='text-sm font-semibold text-gray-400'>To</div>
                  <div className='relative flex items-center text-[#2c1a49] font-medium text-sm'>
                    <span className='flex items-center pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-5 text-sm font-medium'>
                      $
                    </span>
                    <input
                      value={formData.revenue_to || ""}
                      onChange={e => selectBudgetHandler(+e.target.value, "revenue_to")}
                      placeholder='0'
                      type='tel'
                      className={
                        "max-w-[206px] w-full h-[58px] form-input py-2.5 pr-4 pl-8 block border border-[#e6e6e6] rounded-[13px]  placeholder:text-gray-400 focus:border-[rgba(44,26,73,0.6)] focus:bg-white outline-none"
                      }
                    />
                    <span className='cursor-pointer w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-6'>USD</span>
                  </div>
                </label>
              </>
            ) : (
              <div className='bg-[rgba(251,96,66,0.05)] rounded-[14px] py-4 px-3 flex flex-col items-start w-full'>
                <div className='font-bold text-base text-[#F88C6A] pb-2'>Basic strategy requested</div>
                <div className='text-xs font-normal text-center'>
                  Our intelligence platform might not be able to create a personalized marketing budget and strategy as revenue range is
                  missing.
                </div>
              </div>
            )}
          </div>

          <div>
            <label className='flex items-center cursor-pointer justify-start'>
              <span className='mr-3 text-sm font-medium text-gray-900'>{"I don't know"}</span>
              <input
                type='checkbox'
                value=''
                className='sr-only peer outline-none focus:outline-none'
                onChange={() => {
                  setRevenue(!revenue);
                  dispatch(handleProjectForm({ ...formData, revenue_from: null, revenue_to: null }));
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#766CE4] focus:outline-none"></div>
            </label>
          </div>
        </div> */}
        <div className={cn("grid grid-cols-5  w-full", isMobile == true ? "gap-6" : "gap-6 md:gap-2")}>
          <div className={cn(isMobile == true ? " col-span-5" : " col-span-5 md:col-span-3")}>
            <DatePicker
              className='2xl:!ml-0 !max-w-[80%]'
              popupClassName='-right-[250px] -top-[260px] xl:-right-[150px]'
              budget_date_from={formState.budget_date_from || ""}
              budget_date_to={formState.budget_date_to || ""}
              setBudgetDate={handleSetBudgetDate}
            />
          </div>
          <div className={cn(isMobile == true ? " col-span-5" : " col-span-5 md:col-span-2")}>
            <label className='flex flex-col items-start ml-0 2xl:ml-[56px] w-fit gap-y-1'>
              <div className='text-sm text-[#1F2228] font-semibold w-fit pb-1'>Revenue growth rate</div>
              <div className='relative flex flex-shrink-0 items-center text-[#2c1a49] font-medium text-sm gap-x-4'>
                <div
                  onClick={() => {
                    setGrowPercentage(prev => {
                      if (prev === 0) return prev;
                      return prev - 1;
                    });

                    setFormState({ ...formState, revenue_rate: growPercentage === 0 ? 0 : growPercentage - 1 });

                    //sendFormInput({ revenue_rate: growPercentage === 0 ? 0 : growPercentage - 1 }, false);
                  }}
                  className='flex flex-shrink-0 items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                >
                  <Icons.Minus className='text-[#14191A]' />
                </div>
                <input
                  onChange={growPercentageHandler}
                  value={formState.revenue_rate || growPercentage || ""}
                  placeholder='0'
                  type='number'
                  maxLength={3}
                  className={cn(INPUT_SHARED_CLASSNAME, "py-[9px] px-8 max-w-[100px]")}
                />
                <span className='cursor-pointer flex items-center text-[#9EA2AD] w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-[68px]  pointer-events-none'>
                  %
                </span>
                <div
                  onClick={() => {
                    setGrowPercentage(prev => {
                      if (prev === 100) return prev;
                      return prev + 1;
                    });
                    setFormState({ ...formState, revenue_rate: growPercentage + 1 });
                    //sendFormInput({ revenue_rate: growPercentage + 1 }, false);
                  }}
                  className='flex flex-shrink-0 items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                >
                  <Icons.Plus />
                </div>
              </div>
            </label>
          </div>
          {/* <div className='col-span-5'>
            <label className='inline-flex items-center cursor-pointer w-fit justify-center'>
              <span className='me-3 text-sm font-medium text-gray-900'>{"I don't know"}</span>
              <input
                type='checkbox'
                value=''
                className='sr-only peer outline-none focus:outline-none'
                onChange={() => {
                  setGrow(!grow);
                  dispatch(handleProjectForm({ ...formData, revenue_rate: null }));
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#766CE4] focus:outline-none"></div>
            </label>
          </div> */}
        </div>
        {/* <div className='flex flex-col'>
          <div className='flex items-start w-full font-bold text-sm'>Estimated marketing budget for the year *</div>
          <div className='flex pt-4 items-center w-full h-full gap-x-4'>
            <div>
              <FormLabel>From</FormLabel>
              <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
                <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                  $
                </span>
                <input
                  value={formData.budget_from ? formatNumberToUSLocale(formData.budget_from) : ""}
                  onChange={e => selectBudgetHandler(+e.target.value, "budget_from")}
                  placeholder='0'
                  type='text'
                  className={cn("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
                />
                <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>USD</span>
              </div>
            </div>
            <DashIcon className='hidden 2xl:block' />
            <div>
              <FormLabel>From</FormLabel>
              <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
                <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                  $
                </span>
                <input
                  value={formData.budget_to ? formatNumberToUSLocale(formData.budget_to) : ""}
                  onChange={e => selectBudgetHandler(+e.target.value, "budget_to")}
                  placeholder='0'
                  type='text'
                  className={cn("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
                />
                <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>USD</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const StepTwo = ({
  fileStates,
  setFileStates,
  formState,
  setFormState,
  setIsOpenUploadFile,
  isOpenUploadFile,
}: {
  fileStates: Map<string, FileUploadState>;
  setFileStates: React.Dispatch<React.SetStateAction<Map<string, FileUploadState>>>;
  formState: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  setFormState: React.Dispatch<
    React.SetStateAction<
      Partial<ICreateProjectData> & {
        isRevenueUnknown?: boolean;
      }
    >
  >;
  setIsOpenUploadFile: (value: boolean) => void;
  isOpenUploadFile: boolean;
}) => {
  const { sendFormInput, handleAddOrUpdateChat } = useAI();

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const updateFileState = (fileId: string, updates: Partial<FileUploadState>) => {
    setFileStates(prev => {
      const newMap = new Map(prev);
      const currentState = newMap.get(fileId);
      newMap.set(fileId, { ...currentState, ...updates } as FileUploadState);
      return newMap;
    });
  };

  const generateFileId = (file: File): string => {
    const fileProperties = [file.name, file.size, file.lastModified, file.type].join("-");

    return btoa(fileProperties).replace(/[/+=]/g, "");
  };

  const handleSendFileProgressToAssistant = useCallback(
    (fileId: string, name: string, size: number, progress: number, status: string) => {
      setTimeout(() => {
        handleAddOrUpdateChat({
          id: fileId,
          sender: "assistant",
          message: "",
          data: {
            type: "file_upload",
            name: name,
            size: size,
            progress: progress,
            status: status,
          },
        });
      }, 1000);
    },
    [fileStates, handleAddOrUpdateChat],
  );

  const uploadFile = async (file: File) => {
    const fileId = generateFileId(file);
    const abortController = new AbortController();

    setFileStates(prev => {
      const newMap = new Map(prev);
      newMap.set(fileId, {
        fileId,
        file,
        progress: 0,
        status: "uploading",
        bytesUploaded: 0,
        abortController,
      });
      return newMap;
    });

    handleSendFileProgressToAssistant(fileId, file.name, file.size, 0, "uploading");

    try {
      const data = new FormData();

      data.append("file", file);

      const token = localStorage.getItem("accessToken");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/projects/upload-file`, data, {
        withCredentials: true,
        signal: abortController.signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          updateFileState(fileId, {
            status: "uploading",
            progress: percentCompleted,
          });

          handleSendFileProgressToAssistant(fileId, file.name, file.size, percentCompleted, "uploading");
        },
      });
      console.log("Response", response);
      if (response.status === 200 || response.status === 201) {
        updateFileState(fileId, {
          status: "completed",
          progress: 100,
          bytesUploaded: file.size,
          uploadedFileId: response?.data?.file_id,
        });

        handleSendFileProgressToAssistant(fileId, file.name, file.size, 100, "completed");

        setFormState({ ...formState, file_id: response?.data?.file_id });
      } else {
        updateFileState(fileId, {
          status: "failed",
          progress: 100,
          error: response.data.message,
        });

        handleSendFileProgressToAssistant(fileId, file.name, file.size, 0, "failed");
      }

      return response;
    } catch (error: any) {
      if (error.name === "AbortError") {
        updateFileState(fileId, {
          status: "failed",
          error: "Upload cancelled",
        });
      } else {
        updateFileState(fileId, {
          status: "failed",
          error: error.message,
        });
      }
      handleSendFileProgressToAssistant(fileId, file.name, file.size, 0, "failed");
      throw error;
    }
  };

  const handleDeleteFile = async (state: FileUploadState) => {
    try {
      if (state.uploadedFileId) {
        // Delete the attachment from the server
        updateFileState(state.fileId, {
          status: "deleting",
        });
        handleSendFileProgressToAssistant(state.fileId, state.file!.name, state.file!.size, 0, "deleted");
        // await deleteAttachment(user, projectId, state.attachmentId);
      }
    } catch (error) {
      console.error("Error deleting attachment:", error);
    } finally {
      setFileStates(prev => {
        const newMap = new Map(prev);
        newMap.delete(state.fileId);
        return newMap;
      });
    }
  };

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];

      uploadFile(file)
        .then(response => {
          //dispatch(handleProjectForm({ ...formData, file_id: response?.data?.file_id }));
        })
        .catch(error => {
          //console.error("Error uploading file:", error);
        });
    }
  };

  const handleUploadFile = () => {
    setIsOpenUploadFile(false);

    inputRef.current?.click();
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Fill company details</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some basic information to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>

      <div className='flex flex-col mt-4 gap-2'>
        <p className='text-[#717684] font-medium text-sm'>Upload new files</p>
        <input type='file' className='hidden' ref={inputRef} onChange={onFileSelected} />
        <button onClick={() => setIsOpenUploadFile(true)} className='w-full border border-[#E9EAEC] rounded-2xl px-6 py-5 flex flex-col'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex gap-3'>
              <div className='bg-[#FAFAFA] h-10 w-10 rounded-full flex items-center justify-center  flex-shrink-0'>
                <span className='bg-[#F5F5F5] rounded-full h-8 w-8 flex items-center justify-center'>
                  <Icons.UploadCloud className='!text-[#535862]' />
                </span>
              </div>

              <div className='flex flex-col items-start'>
                <h3 className='font-semibold text-sm'>Upload a file</h3>
                <p className='text-[#5E636E] font-medium text-sm'>Upload an CSV, Excel, PDF, Word file. (Max. File size: 25 MB)</p>
              </div>
            </div>

            {/* <button className='bg-[#E9EAEC] p-1 rounded-[4px]'>
              <Icons.Trash className='text-[#717684] h-4 w-4' />
            </button> */}
          </div>
          <div></div>
        </button>
      </div>

      {fileStates.size > 0 && (
        <div className='flex flex-col mt-4 gap-2'>
          <p className='text-[#717684] font-medium text-sm'>Uploaded files</p>
          <div className='flex flex-col gap-1.5'>
            {fileStates.size > 0 &&
              Array.from(fileStates.values()).map((state, index) => {
                return <UploadedSourceFile key={index} state={state} handleDeleteFile={handleDeleteFile} />;
              })}
          </div>
        </div>
      )}

      {isOpenUploadFile && (
        <div className='size-full fixed flex flex-col justify-center items-center top-0 start-0 z-[80] overflow-x-hidden bg-[rgba(48,44,52,0.34)] '>
          <div className='flex flex-col justify-center hs-overlay-open:mt-7 hs-overlay-open:duration-500 opacity-1 ease-out transition-all max-w-[814px] w-fit h-[664px]'>
            <CreateBudgetPopup handleUploadFile={handleUploadFile} />
          </div>
        </div>
      )}
    </div>
  );
};

const StepThree = ({
  formState,
  setFormState,
}: {
  formState: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  setFormState: React.Dispatch<
    React.SetStateAction<
      Partial<ICreateProjectData> & {
        isRevenueUnknown?: boolean;
      }
    >
  >;
}) => {
  const { handleAddOrUpdateChat, sendFormInput } = useAI();

  const chatId1 = uuidv4();

  const inclusion_criterias = [
    {
      value: "overall_budget_spend",
      title: "Overall Budget Spend ",
      description: "e.g., % program, % people, % tools, % miscellaneous",
    },
    {
      value: "program_spend_only",
      title: "% Program Spend Only ",
      description: "e.g., % for social, content, paid ads, influencer, mobile, email",
    },
  ];

  const platforms = ["X/Twitter", "Facebook", "Instagram", "LinkedIn", "TikTok", "Google Ads", "Bing Ads", "Pinterest"];

  const { data: projectChannels } = useGetChannels();
  const selectableProjectChannels = (projectChannels || []).filter(channel => channel.channel_id !== 0 && channel.name !== "Other");

  const channelRef = useRef<NodeJS.Timeout | null>(null);

  const platformRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();
  const handleAddChannel = useCallback(
    (addedChannel: any) => {
      let channelItem = formState.channels ?? [];

      const channelExists = channelItem.some(channel => channel.name === addedChannel?.name);

      if (channelExists) {
        channelItem = channelItem.filter(channel => channel.name !== addedChannel?.name);
      } else {
        channelItem = [...channelItem, addedChannel];
      }

      if (Array.isArray(formState.channels)) {
        const updatedFormDataChannels = channelExists
          ? formState.channels.filter(channel => channel.name !== addedChannel?.name)
          : [...formState.channels, addedChannel];

        setFormState({ ...formState, channels: updatedFormDataChannels });
      } else {
        setFormState({ ...formState, channels: [...channelItem] });
      }

      // send channel to agent
      if (channelItem.length > 0) {
        if (channelRef.current !== null) {
          clearTimeout(channelRef.current);
        }

        channelRef.current = setTimeout(() => {
          sendFormInput({ channels: channelItem.map(e => e.name) }, false);
          if (channelRef.current !== null) {
            clearTimeout(channelRef.current);
          }
        }, 2000);
      }
    },
    [formState],
  );

  const uniqueChannels = useMemo(() => {
    const combinedChannels = [...selectableProjectChannels, ...(formState.channels || [])];

    return combinedChannels.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));
  }, [formState]);

  const selectPlatformHandler = (platform: any) => {
    setFormState(prev => {
      let platforms = prev.platforms || [];

      if (platforms.find(item => item === platform)) {
        platforms = platforms.filter(item => item !== platform);
      } else if (platforms.length < 3) {
        platforms = [...platforms, platform];
      }

      // send channel to agent
      if (platforms.length > 0) {
        if (platformRef.current !== null) {
          clearTimeout(platformRef.current);
        }

        platformRef.current = setTimeout(() => {
          sendFormInput({ platforms: platforms }, false);
          if (platformRef.current !== null) {
            clearTimeout(platformRef.current);
          }
        }, 2000);
      }

      return { ...formState, platforms: platforms };
    });
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Customize your budget strategy</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some sources to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>
      <div className='flex flex-col mt-4 gap-2'>
        <p className='text-[#717684] font-medium text-sm'>Select inclusion criteria</p>
        <div className='flex flex-col gap-1.5'>
          {inclusion_criterias.map((item, index) => (
            <InclusionCriteria
              key={index}
              checked={formState.inclusion_criteria === item.value}
              onChecked={_ => {
                if (formState.inclusion_criteria != item.value) {
                  setTimeout(() => {
                    handleAddOrUpdateChat({
                      id: chatId1,
                      sender: "assistant",
                      message: AGENT_MOCK_RESPONSE.Step3.GreatChoice,
                    });
                  }, 1000);
                }
                setFormState({ ...formState, inclusion_criteria: item.value });
              }}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
      {formState.inclusion_criteria != null && formState.inclusion_criteria != undefined && formState.inclusion_criteria != "" && (
        <>
          <div className='flex flex-col mt-4 gap-2'>
            <p className='text-[#717684] font-medium text-sm'>Select channels you want to include</p>
            <div className='flex w-full flex-wrap gap-1.5'>
              {uniqueChannels.map((item, index) => (
                <GoalItem
                  key={index}
                  checked={!!formState.channels?.find(el => el.name === item.name)}
                  onChecked={() => handleAddChannel(item)}
                >
                  {item.name}
                </GoalItem>
              ))}
            </div>
          </div>
          <div className='flex flex-col mt-4 gap-2'>
            <p className='text-[#717684] font-medium text-sm'>Select social media platforms you want to include</p>
            <div className='flex w-full flex-wrap gap-1.5'>
              {platforms.map((item, index) => {
                const iconName = item.toLowerCase();

                return (
                  <GoalItem
                    key={index}
                    checked={!!formState.platforms?.find(el => el === item)}
                    onChecked={() => selectPlatformHandler(item)}
                  >
                    {SOCIAL_PLATFORM_ICONS(iconName, "mr-1")}
                    {item}
                  </GoalItem>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const UploadedSourceFile = ({
  state,
  handleDeleteFile,
}: {
  state: FileUploadState;
  handleDeleteFile: (state: FileUploadState) => void;
}) => {
  return (
    <div className='border border-[#E9EAEC] rounded-lg p-3.5 py-2.5 flex flex-col gap-1'>
      <div className='flex justify-between items-center'>
        <div className='flex gap-3'>
          <span className='border border-[#F7F7F8] bg-[#F7F7F8] rounded-xl h-[2.25rem] w-[2.25rem] flex items-center justify-center  flex-shrink-0'>
            <Icons.Document className='h-5 w-5 !text-[#717684]' />
          </span>
          <div className='flex flex-col gap'>
            <h3 className='font-semibold text-sm'>{state.file?.name}</h3>
            <p className='text-[#A9ACB4] font-normal text-xs'>{state.file?.type}</p>
          </div>
        </div>
        <div>
          <button onClick={() => handleDeleteFile(state)}>
            <Icons.Trash2 className='w-5 h-5 !text-[#B32318]' />
          </button>
        </div>
      </div>
      {state.status === "uploading" && (
        <div className='flex items-center gap-x-3 whitespace-nowrap w-full'>
          <div
            className='flex w-[calc(100%-40px)] h-2 bg-gray-200 rounded-full overflow-hidden max-w-full'
            role='progressbar'
            aria-valuenow={1}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            <div
              className={cn(
                "flex flex-col justify-center rounded-full overflow-hidden w-[calc(100%-40px)] max-w-full text-xs text-white text-center whitespace-nowrap transition duration-500",
                state.progress === 100 ? "bg-[#35D98A]" : "bg-[#766CE4]",
              )}
              style={{ width: `${state.progress}%` }}
            ></div>
          </div>
          <div className='w-10 text-end'>
            <span className='text-sm text-gray-800'>{state.progress}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

const InclusionCriteria = ({
  checked,
  title,
  description,
  onChecked,
}: {
  checked?: boolean;
  title: string;
  description: string;
  onChecked?: (value: boolean) => void;
}) => {
  const [value, setValue] = useState(checked || false);

  const handleClick = () => {
    setValue(!value);
    onChecked && onChecked(!value);
  };

  return (
    <div onClick={handleClick} className='border border-[#E9EAEC] rounded-lg p-1.5 flex gap-3 cursor-pointer'>
      <button
        onClick={handleClick}
        type='button'
        className='h-[2.25rem] w-[2.25rem] rounded-full bg-[#F7F7F8] flex items-center justify-center'
      >
        <span
          className={cn(
            " h-5 w-5 border rounded-full flex items-center justify-center",
            checked == true ? "bg-purple border-purple " : "bg-white border-[#E9EAEC] ",
          )}
        >
          {checked == true && <Icons.Check className='text-white' />}
        </span>
      </button>
      <div className='flex flex-col gap'>
        <h3 className='font-semibold text-sm'>{title}</h3>
        <p className='text-[#A9ACB4] font-normal text-xs'>{description}</p>
      </div>
    </div>
  );
};

const BtnGroup = ({
  disabledPrevHandler,
  disabledNextHandler,
  nextStepHandler,
  prevStepHandler,
  disabledSkipHandler,
  showSkip = true,
  skipHandler,
}: {
  nextStepHandler: () => void;
  prevStepHandler?: () => void;
  skipHandler?: () => void;
  disabledNextHandler?: boolean;
  disabledPrevHandler?: boolean;
  disabledSkipHandler?: boolean;
  showSkip?: boolean;
}) => {
  return (
    <div className='w-full flex justify-between'>
      <div className='flex gap-4'>
        {showSkip == true && (
          <button
            onClick={() => (disabledSkipHandler == true ? null : skipHandler?.())}
            disabled={disabledSkipHandler == true}
            className='disabled:cursor-not-allowed disabled:text-[#C8CAD0] text-base text-[#766CE4] font-bold  flex items-center'
          >
            Skip
          </button>
        )}
      </div>
      <div className='flex gap-4'>
        <button
          onClick={() => (disabledNextHandler == true ? null : prevStepHandler?.())}
          disabled={disabledPrevHandler == true}
          className='group text-base text-purple font-bold  flex items-center disabled:cursor-not-allowed disabled:text-[#C8CAD0]'
        >
          <Icons.ArrowLeft className=' mr-2 ' />
          Back
        </button>
        <Button
          onClick={() => (disabledNextHandler == true ? null : nextStepHandler())}
          disabled={disabledNextHandler == true}
          className='!h-[32px] !rounded-lg !py-[7px] !px-[42px] !w-[120px]'
        >
          Next
        </Button>
      </div>
    </div>
  );
};
