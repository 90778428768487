import { useState, useEffect } from "react";
import { Control, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { AgentItem } from "api/agent/type";
import { notificationSettings } from "api/integrations";

import { AGENT_IMAGES } from "constants/agent.constants";
import { REQUIRED_VALIDATION_RULE } from "constants/validation.constants";

import { useAI } from "shared/components/ai-panel/provider";
import { FormLabel } from "shared/components/form-elements/FormLabel";
import { Select } from "shared/components/form-elements/select/Select";
import { Icons } from "shared/components/icons";
import MultiSelectListBox from "shared/components/list-box/MultiSelectListBox";
import { TimelineItem } from "shared/components/timeline/TimelineItem";

import NotificationChannel from "./NotificationChannel";

const Timeline = ({ agent }: { agent?: AgentItem | null }) => {
  const navigate = useNavigate();
  const { frequencyNotificationPlatForm, setFrequencyNotificationPlatForm } = useAI();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      frequency: "",
      notificationChannel: "",
      recipients: [],
    },
  });

  const [currentStep, setCurrentStep] = useState(0);

  const options = [
    { label: "Daily", value: 1 },
    { label: "Weekly", value: 2 },
    { label: "By-weekly", value: 3 },
    { label: "Monthly", value: 4 },
  ];

  const notificationChannel = [
    { name: "Email", value: "Gmail", icon: <Icons.Gmail /> },
    { name: "Slack", value: "Slack", icon: <Icons.Slack /> },
    { name: "SMS", value: "WhatsApp", icon: <Icons.WhatsApp /> },
  ];

  const recipientsOptions = [
    { id: 1, name: "#marketing_updates" },
    { id: 2, name: "#customer_experience" },
    {
      id: 3,
      name: "David Lee",
      avatar: "https://static-00.iconduck.com/assets.00/user-avatar-happy-icon-2048x2048-ssmbv1ou.png",
    },
    {
      id: 4,
      name: "Lisa Johnson",
      avatar: "https://cdn.prod.website-files.com/6365d860c7b7a7191055eb8a/65a753049744df4a8765df41_Rene%20Wells.jpg",
    },
  ];

  useEffect(() => {
    setValue("recipients", selectedOptions);
  }, [selectedOptions, setValue]);

  const triggerAdvancement = () => {
    const frequency = watch("frequency");
    const notificationChannel = watch("notificationChannel");
    const recipients = watch("recipients");

    if (currentStep === 0 && frequency) {
      setCurrentStep(1);
    }
    if (currentStep === 1 && notificationChannel && recipients.length > 0) {
      setCurrentStep(2);
    }
  };

  useEffect(() => {
    triggerAdvancement();
  }, [watch("frequency"), watch("notificationChannel"), watch("recipients")]);

  const onSubmit = async (data: any) => {
    const payload = {
      frequency: options.find(item => item.value === data.frequency)?.label || "",
      channel: data.notificationChannel,
      recipients: data.recipients.map((recipient: any) => recipient.name),
      templateKey: "low_inventory_alert",
    };

    try {
      const response = await notificationSettings(payload);
      navigate(`/operation-report/${response?.id}`);
    } catch (error) {
      console.error("Failed to update notification settings:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='relative h-full ' style={{paddingRight:"2rem"}}>
      <div>
        <div className='my-4 ml-8'>
          <h4 className='text-xl font-bold text-[#1F2228]'>Finalize workflow notification details</h4>
        </div>
        <TimelineItem active={currentStep === 0} isCompleted={currentStep > 0} title='Frequency settings'>
          <div className='bg-frequency-card-gradient px-6 py-4 rounded-2xl mt-8'>
            <FormLabel tag='legend' className='font-medium my-1' required>
              Select frequency
            </FormLabel>
            <Select
              labelClassName='font-medium my-2'
              name='frequency'
              control={control as unknown as Control}
              options={options}
              required
              validation={REQUIRED_VALIDATION_RULE}
            />
            <div className='flex items-center gap-2 mt-4'>
              <img src={Image} alt='Agent' className='w-[20px] h-[20px] rounded-full object-cover' />
              <p className='text-xs text-[#717684] font-medium'>
                <span className='font-bold'>{agent?.name}</span> is suggesting real time notifications...
              </p>
            </div>
          </div>
        </TimelineItem>
        <TimelineItem active={currentStep === 1} isCompleted={currentStep > 1} title='Notification settings'>
          <div className='bg-frequency-card-gradient px-6 py-4 rounded-2xl mt-8'>
            <FormLabel tag='legend' className='font-medium my-1' required>
              Select notification channel
            </FormLabel>
            <div className='flex items-center flex-wrap'>
              {notificationChannel.map((channel, index) => (
                <NotificationChannel
                  key={index}
                  icon={channel.icon}
                  title={channel.name}
                  isSelected={frequencyNotificationPlatForm === channel.value}
                  handleClick={() => {
                    setFrequencyNotificationPlatForm(channel.value);
                    setValue("notificationChannel", channel.value);
                  }}
                />
              ))}
            </div>
            {frequencyNotificationPlatForm && (
              <div className='mt-4'>
                <FormLabel tag='legend' className='font-medium my-1' required>
                  Select recipients
                </FormLabel>
                <MultiSelectListBox
                  options={recipientsOptions}
                  selectedOptions={selectedOptions}
                  onChange={items => setSelectedOptions(items as any)}
                />
              </div>
            )}
          </div>
        </TimelineItem>

        <TimelineItem active={currentStep === 2} isCompleted={false} title='Confirm summary'>
          <div></div>
        </TimelineItem>
      </div>
      <div className='bg-white w-full absolute bottom-0 flex items-center justify-end'>
        <button type='button' className='text-[#766CE4] underline bg-white font-semibold text-sm  ml-4'>
          Back
        </button>
        <button
          type='submit'
          className='text-white bg-[#766CE4] font-semibold text-sm rounded-xl py-[8px] px-11 border border-[#D5D1F7] ml-4 mx-8'
        >
          Finish
        </button>
      </div>
    </form>
  );
};

export default Timeline;
