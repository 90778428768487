import cn from "classnames";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { pauseConnection, refreshConnection, removeConnection } from "api/integrations";

import { FULL_DATETIME_FORMAT } from "constants/date-formats.constants";
import { INTEGRATION_ICONS } from "constants/integrations.constants";

import { IConnectedSourceData } from "interfaces/integrations.interfaces";

import { useAppDispatch } from "hooks/appHooks";

import { getAllConnectedSources } from "storage/slices/integrations-slice";

import Button from "shared/components/auth-buttons/Button";
import SpinLoader from "shared/components/loader-screen/SpinLoader";
import Alert from "shared/components/toasts";

import ConfirmOverlay from "pages/connected-sources/components/ConfirmOverlay";

import { ReactComponent as InfoIcon } from "assets/icons/infoRed.svg";
import { ReactComponent as TickIcon } from "assets/icons/purpleTick.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trashIcon.svg";

type ConnectionStatusProps = {
  connectedData: IConnectedSourceData;
  getConnectedSource: any;
};
const ConnectionStatus = ({ connectedData, getConnectedSource }: ConnectionStatusProps) => {
  const [steps, setSteps] = useState([
    {
      title: "Re-authenticate Your Shopify Account",
      description: "Check your worksheet if it contains essential months row and relevant data with numeric budget list.",
    },
    {
      title: "Check API Permissions",
      description: "Ensure that your Shopify API permissions are correctly set to allow full access to the required data.",
    },
    { title: "Verify Internet Connection", description: "Ensure your internet connection is stable and try reconnecting." },
    { title: "Contact Support", description: "If the issue persists, please contact our support team for further assistance." },
  ]);
  const [refreshing, setRefreshing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [nameEditable, setNameEditable] = useState(false);
  const [name, setName] = useState(connectedData?.account_name);
  const [savingName, setSavingName] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const removeIntegration = () => {
    const { connection_id } = connectedData;
    setDeleting(true);
    removeConnection(connection_id)
      .then(res => {
        dispatch(getAllConnectedSources());
        setShowConfirmModal(false);
        Alert("success", "Integration removed successfully", "Success");
        navigate(-1);
      })
      .finally(() => {
        setDeleting(false);
      });
  };
  const handleRefresh = () => {
    const { connection_id } = connectedData;
    setRefreshing(true);
    refreshConnection(connection_id)
      .then(res => {
        if (res) {
          getConnectedSource();
          dispatch(getAllConnectedSources());
        }
      })
      .finally(() => {
        setRefreshing(false);
      });
  };
  const handleSaveName = () => {
    const { connection_id } = connectedData;
    setSavingName(true);
    setNameEditable(false);
    pauseConnection(connection_id, { account_name: name })
      .then(res => {
        if (res) {
          getConnectedSource();
          dispatch(getAllConnectedSources());
        }
      })
      .finally(() => {
        setSavingName(false);
      });
  };
  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };
  const disableActions = refreshing || deleting || savingName;
  const Icon = INTEGRATION_ICONS?.[connectedData?.user_data?.logo as keyof typeof INTEGRATION_ICONS];
  return (
    <div className='bg-[#F7F9FB] rounded-xl flex-1 min-h-full flex flex-col items-start gap-y-6 mt-3'>
      <div className='w-full bg-white rounded-2xl p-6'>
        <div className='flex items-center flex-col md:flex-row md:justify-between'>
          <div className='flex items-center gap-x-3 w-full'>
            <Icon className='h-6 w-6' />
            <div className='w-full'>
              {nameEditable ? (
                <div className='flex items-center'>
                  <input
                    type='text'
                    value={name}
                    autoFocus
                    className='min-w-52 border border-[#807692] shadow-ring focus:outline-none px-3.5 py-2 rounded-lg'
                    onChange={handleNameChange}
                  />

                  <button onClick={handleSaveName}>
                    {" "}
                    <TickIcon className='h-5 w-5 ml-3' />
                  </button>
                </div>
              ) : (
                <button
                  className='text-lg font-bold text-[#1F2228] '
                  disabled={disableActions}
                  onClick={() => {
                    setNameEditable(true);
                  }}
                >
                  {savingName ? (
                    <div className='flex items-center'>
                      <SpinLoader className='h-4 w-4 mr-3' /> Updating...
                    </div>
                  ) : (
                    connectedData?.account_name
                  )}
                </button>
              )}
            </div>
          </div>
          <div className='flex mt-2 md:mt-0 items-center w-full md:w-auto gap-x-3'>
            <Button
              classes='py-1.5 px-6 bg-white w-full md:w-auto text-[#1F2228] border border-[#D5D1F7] disabled:text-[#000] hover:bg-[#00000010]'
              disabled={disableActions}
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              {deleting ? <SpinLoader className='h-4 w-4' /> : <TrashIcon className='h-4 w-4' />}{" "}
              <p>{deleting ? "Removing..." : "Remove"}</p>
            </Button>
            <Button
              classes='py-1.5 px-6 w-full md:w-auto disabled:bg-[#6A5EEE90] disabled:text-[#000]'
              disabled={disableActions}
              onClick={handleRefresh}
            >
              {refreshing ? <SpinLoader className='h-4 w-4' /> : <RefreshIcon className='h-4 w-4' />}
              <p>{refreshing ? "Refreshing..." : "Refresh"}</p>
            </Button>
          </div>
        </div>
        {/* If Error */}
        {connectedData?.status === "error" && (
          <div className='mt-6 bg-[#F7F1F2] p-4 rounded-xl'>
            <div className='flex items-center gap-x-2.5'>
              <InfoIcon className='h-6 w-6' />
              <p className='text-base font-bold text-[#F88C6A]'>{connectedData?.integration_data?.name} Connection Issue</p>
            </div>
            <p className='text-sm font-medium text-[#333740] mt-3'>
              Your {connectedData?.integration_data?.name} connection was interrupted on 19 August,2024 2PM. Immediate action is required to
              restore connectivity.
            </p>
          </div>
        )}
        {/* Steps */}
        {connectedData?.status === "error" && (
          <div className='mt-10 mb-10'>
            <p className='text-base font-bold text-[#000]'>Steps to Restore Connection</p>
            <div className='mt-4'>
              {steps.map((item, index) => (
                <div className='flex gap-x-2.5 relative' key={item.title}>
                  {index !== steps.length - 1 && (
                    <span className='absolute h-full w-0 border top-0 left-[9px] border-dashed border-[#D5D1F7] opacity-50' />
                  )}
                  <div className='!h-[20px] !w-[20px] rounded-full border border-[#9189E9] flex justify-center items-center bg-white z-10'>
                    <p className='text-xs p-5 text-[#6B62CF] font-semibold'>{index + 1}</p>
                  </div>
                  <div>
                    <p className='text-sm font-semibold text-[#333740]'>{item.title}</p>
                    <p className='text-sm font-medium text-[#333740] '>{item.description}</p>
                    {index !== steps.length - 1 && <div className='h-6' />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* Account Details */}
        <div className={cn("pt-10  ", { "border-t border-[#E9EAEC]": connectedData?.status === "error" })}>
          <p className='text-base font-bold text-[#000]'>Accout Details</p>
          <div className='grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-1  gap-x-4 mt-4'>
            <div>
              <table className='w-full account-details-table'>
                <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Account Name</td>
                  <td className='pb-4 text-sm text-[#333740]'>{connectedData?.account_name}</td>
                </tr>
                <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Data Source Type</td>
                  <td className='pb-4 text-sm text-[#333740] flex items-center'>
                    <Icon className='h-4 w-4 mr-2' /> {connectedData?.integration_data?.name}
                  </td>
                </tr>
                <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Credentials</td>
                  <td className='pb-4 text-sm text-[#333740]'>{connectedData?.user_data?.email}</td>
                </tr>
                <tr>
                  <td className='text-sm text-[#717684] sm:pb-4'>Status</td>
                  <td className='text-sm text-[#333740] flex items-center sm:pb-4'>
                    {connectedData?.status !== "error" ? (
                      <span className='capitalize'>{connectedData?.status}</span>
                    ) : (
                      <span className='flex items-center'>
                        <InfoIcon className='h-4 w-4 mr-2' /> {connectedData?.integration_data?.name} Connection Issue
                      </span>
                    )}
                  </td>
                </tr>
              </table>
            </div>
            <div className='mt-4 sm:mt-0'>
              <table className='w-full account-details-table'>
                <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Connected at</td>
                  <td className='pb-4 text-sm text-[#333740]'>
                    {connectedData.added_date ? dayjs(connectedData.added_date).format(FULL_DATETIME_FORMAT) : ""}
                  </td>
                </tr>
                <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Connected by</td>
                  <td className='pb-4 text-sm text-[#333740]'>{connectedData?.user_data?.connectedBy}</td>
                </tr>
                {/* <tr>
                  <td className='pb-4 text-sm text-[#717684]'>Stored Data</td>
                  <td className='pb-4 text-sm text-[#333740]'>21 Jun 2024 - 15 Aug 2024*</td>
                </tr> */}
                <tr>
                  <td className='text-sm text-[#717684]'>Last Imported</td>
                  <td className='text-sm text-[#333740]'>
                    {connectedData.last_successful_sync ? `${moment(connectedData.last_successful_sync).fromNow()}` : ""}
                  </td>
                </tr>
              </table>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmOverlay
          onCancel={() => {
            setShowConfirmModal(false);
          }}
          onClick={() => {
            removeIntegration();
          }}
          title='Are you sure?'
          description='You want to remove this integration?'
          loading={deleting ? true : false}
        />
      )}
    </div>
  );
};

export default ConnectionStatus;
