import { ReactComponent as AmazonAdsIcon } from "assets/icons/amazonAds.svg";
import { ReactComponent as AmazonSellerCentralIcon } from "assets/icons/amazonSellerCentral.svg";
import { ReactComponent as AmazonVendorCentralIcon } from "assets/icons/amazonVendorCentral.svg";
import { ReactComponent as BigCommerceIcon } from "assets/icons/bigCommerce.svg";
import { ReactComponent as Cin7Icon } from "assets/icons/cin7.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as GmailIcon } from "assets/icons/gmail.svg";
import { ReactComponent as GoogleAnalyticsIcon } from "assets/icons/googleAnalytics.svg";
import { ReactComponent as GoogleSheetsIcon } from "assets/icons/googlesheets.svg";
import { ReactComponent as HubspotIcon } from "assets/icons/hubspot.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as KatanaIcon } from "assets/icons/katana.svg";
import { ReactComponent as MicrosoftDynamicsIcon } from "assets/icons/microsoftDynamics.svg";
import { ReactComponent as OracleNetSuiteIcon } from "assets/icons/oracleNetSuite.svg";
import { ReactComponent as QuickbooksIcon } from "assets/icons/quickbooks.svg";
import { ReactComponent as SalesForceIcon } from "assets/icons/salesforce.svg";
import { ReactComponent as SapIcon } from "assets/icons/sap.svg";
import { ReactComponent as ShopifyIcon } from "assets/icons/shopify.svg";
import { ReactComponent as Slack } from "assets/icons/slack.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as WhatsAppIcon } from "assets/icons/whatsapp.svg";
import { ReactComponent as XeroIcon } from "assets/icons/xero.svg";
import { ReactComponent as ZendeskIcon } from "assets/icons/zendesk.svg";
import { ReactComponent as ZohoInventoryIcon } from "assets/icons/zohoInventory.svg";

export const INTEGRATION_ICONS = {
  amazon_ads: AmazonAdsIcon,
  amazon_seller_central: AmazonSellerCentralIcon,
  amazon_vendor_central: AmazonVendorCentralIcon,
  bigcommerce: BigCommerceIcon,
  cin7: Cin7Icon,
  facebook: FacebookIcon,
  gmail: GmailIcon,
  "google-analytics": GoogleAnalyticsIcon, //verified
  "google-sheets": GoogleSheetsIcon, //verified
  hubspot: HubspotIcon, //verified
  instagram: InstagramIcon,
  katana: KatanaIcon,
  microsoft_dynamics: MicrosoftDynamicsIcon,
  oracle_netsuite: OracleNetSuiteIcon,
  quickbooks: QuickbooksIcon,
  salesforce: SalesForceIcon,
  sap: SapIcon,
  shopify: ShopifyIcon,
  slack: Slack,
  twitter: TwitterIcon,
  whatsapp: WhatsAppIcon,
  xero: XeroIcon,
  zendesk: ZendeskIcon,
  zoho_inventory: ZohoInventoryIcon,
};
