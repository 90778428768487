import { ALLOWED_CHARACTERS_WITH_ONLY_ENGLISH_REGEX } from "./regex.constants";

export const REQUIRED_FIELD_MESSAGE = "The field is required";

export const ONLY_ENGLISH_AND_ALLOWED_CHARACTERS_MESSAGE = "Only English characters are allowed and ~`!@#$%^&*()-_=+'\";:/?.>,<\\|`) chars";

export const REQUIRED_VALIDATION_RULE = { required: REQUIRED_FIELD_MESSAGE };

export const ONLY_ENGLISH_VALIDATION_PATTERN = {
  value: ALLOWED_CHARACTERS_WITH_ONLY_ENGLISH_REGEX,
  message: ONLY_ENGLISH_AND_ALLOWED_CHARACTERS_MESSAGE,
};

export const REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION = {
  pattern: ONLY_ENGLISH_VALIDATION_PATTERN,
  ...REQUIRED_VALIDATION_RULE,
};

export const REQUIRED_URL_FIELD_VALIDATION = {
  pattern: {
    value: /^(https?:\/\/|www\.)[^ "]+$/,
    message: "Invalid URL",
  },
  ...REQUIRED_VALIDATION_RULE,
};

export const isValidUrl = (url: string) => {
  const pattern = /^(https?:\/\/|www\.)[^ "]+$/;
  return pattern.test(url);
};
