import React, { ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { ICreateProjectData } from "api/projects/types";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { setIsCollapsed } from "storage/slices/sidebar-slice";
import { RootState } from "storage/store";

import AIPanel from "shared/components/ai-panel";
import { AIPanelProvider, useAI } from "shared/components/ai-panel/provider";
import { Header } from "shared/components/Header";
import { preventAutoCloseRoutes } from "shared/components/side-navbar/nav-config";

import { cn } from "lib/utils";

import Popup from "../components/basePopup/Popup";
import SpinnerLoader from "../components/loader-screen/SpinnerLoader";
import NavBar from "../components/side-navbar/NavBar";

const MAIN_CLASSNAME = "flex w-full h-full overflow-hidden bg-[#F7F9FB]";

interface Props {
  children: ReactNode;
  title?: string;
  agentTitle?: string[];
  onTitleClick?: (value: number) => void; // Accepts a string parameter
  containerClassName?: string;
  isScrollable?: boolean;
  headerExtraContent?: ReactNode;
  titleClassName?: string;
  titleContent?: ReactNode;
  budgetFormState?: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  updateBudgetFormState?: React.Dispatch<React.SetStateAction<Partial<ICreateProjectData> & { isRevenueUnknown?: boolean }>>;
}

const Layout = ({ children, title, titleContent, containerClassName, titleClassName,agentTitle = [], isScrollable = true, onTitleClick,headerExtraContent }: Props) => {
  const popupData = useAppSelector((state: RootState) => state.popup);
  const loading = useAppSelector(state => state.loader.isLoading);
  const {
    isOpen,
    showAsSheet,
    isSending,
    isStreaming,
    processingMessage,
    showBudgetFormDetailInput,
    isUserFeedbackNeeded,
    customTextFieldQuestions,
  } = useAI();
  const dispatch = useAppDispatch();
  const location = useLocation();
  useEffect(() => {
    if (preventAutoCloseRoutes.includes(window.location.pathname)) return;
    dispatch(setIsCollapsed(true));
  }, []);

  const isBudgetForm = useMemo(() => {
    return location.pathname.includes("budget/form");
  }, [location.pathname]);


  const showOverlay = useMemo(() => {
    return (
      (isSending ||
        isStreaming ||
        processingMessage != "" ||
        showBudgetFormDetailInput.description == true ||
        showBudgetFormDetailInput.revenue_range == true ||
        showBudgetFormDetailInput.product_and_service == true ||
        customTextFieldQuestions.length > 0 ||
        isUserFeedbackNeeded == true) &&
      isBudgetForm
    );
  }, [isStreaming, isSending, isBudgetForm, processingMessage, showBudgetFormDetailInput, isUserFeedbackNeeded, customTextFieldQuestions]);

  if (loading)
    return (
      <main className={MAIN_CLASSNAME}>
        <SpinnerLoader />
      </main>
    );

  return (
    <>
      <main className={cn(MAIN_CLASSNAME, "relative", { "blur-sm": popupData?.isOpen })}>
        <NavBar />
        <div className='flex h-full w-full overflow-hidden relative'>
          <div className={cn("flex flex-col h-full overflow-hidden", isOpen == true ? "w-[calc(100%-600px)]" : "w-full")}>
            <Header titleContent={titleContent} title={title} titleClassName={titleClassName} extraContent={headerExtraContent} handleCrousel ={onTitleClick} agentTitle={agentTitle}/>
            <div
              className={cn(
                "w-full h-full",
                isScrollable == true
                  ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
                  : "overflow-hidden",
                containerClassName,
              )}
            >
              {children}
            </div>
          </div>
          {isOpen && showOverlay == false && <AIPanel asSheet={showAsSheet} />}
        </div>
        {showOverlay && <div className='absolute bg-black/20 w-full h-full z-50 top-0 right-0'></div>}
        {isOpen && showOverlay && (
          <AIPanel asSheet={showAsSheet} className='absolute top-0 right-0 z-50 h-full !w-[600px] !max-w-[650px]' />
        )}
      </main>
      {popupData.isOpen && <Popup open={popupData.isOpen} content={popupData.content} />}
    </>
  );
};

export const DashboardLayout = ({ ...props }: Props) => {
  return (
    <AIPanelProvider budgetFormState={props.budgetFormState} updateBudgetFormState={props.updateBudgetFormState}>
      <Layout {...props} />
    </AIPanelProvider>
  );
};
