import { HumanInterrupt } from "@langchain/langgraph/prebuilt";
import { useState } from "react";

import { useStreamContext } from "providers/Stream";

import { ConnectSourcesView } from "./components/connect-sources-view";


interface DataSourcesProps {
  interrupt: HumanInterrupt | HumanInterrupt[];
}

export function DataSources({ interrupt }: DataSourcesProps) {
  const interruptObj = Array.isArray(interrupt) ? interrupt[0] : interrupt;
  const thread = useStreamContext();
  const [showDescription, setShowDescription] = useState(false);
  const [showState, setShowState] = useState(false);
  const showSidePanel = showDescription || showState;

  const handleShowSidePanel = (
    showState: boolean,
    showDescription: boolean,
  ) => {
    if (showState && showDescription) {
      console.error("Cannot show both state and description");
      return;
    }
    if (showState) {
      setShowDescription(false);
      setShowState(true);
    } else if (showDescription) {
      setShowState(false);
      setShowDescription(true);
    } else {
      setShowState(false);
      setShowDescription(false);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row w-full mt-6 rounded-2xl overflow-y-scroll [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:bg-transparent">
      <ConnectSourcesView
        interrupt={interruptObj}
        handleShowSidePanel={handleShowSidePanel}
        showState={showState}
        showDescription={showDescription}
      />
    </div>
  );
}
