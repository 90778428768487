import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import api from "..";


import { IChannelData, ICreateBudget, ILabelledAuthCode, IGoalData } from "./types";

const BASE_API = process.env.REACT_APP_API_URL;

export const getGoalsData = async (): Promise<IGoalData[]> => {
  const data: IGoalData[] = await api(`${BASE_API}/projects/goals`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

export const getChannelsData = async (): Promise<IChannelData[]> => {
  const data: IChannelData[] = await api(`${BASE_API}/projects/channels`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};

// export const createBudget = async (projectData: ICreateBudget): Promise<any> => {
//   return api(`${BASE_API}/channels/generate-budget`, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(projectData),
//   });
// };

export const createGoogleCredentials = async (labelledAuthCode: ILabelledAuthCode): Promise<any> => {
  return api(`${BASE_API}/credentials/exchange/Google`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(labelledAuthCode),
  });
};

export const uploadWorkSheet = async (file: any): Promise<any> => {
  
  return api(`${BASE_API}/budget-agent/files/locate_data`, {
    method: "POST",
    body: JSON.stringify(file),
    headers: { "Content-Type": "application/json" },
  });
};

export const uploadFileForArchive = async (csvFile: any, uuid: string):Promise<any> => {
  try {
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("contextUuid", uuid);
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/projects/upload-file`, formData, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      response,
      error: null,
    };
  } catch (err) {
    return {
      response: null,
      error: err,
    };
  }
  

};

export const getWorksheetListFromGoogle = (accessToken:string)=>{
  return axios.get("https://www.googleapis.com/drive/v3/files", {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Replace with your access token
    },
    params: {
      q: "mimeType='application/vnd.google-apps.spreadsheet'", // Query to search for spreadsheets only
      // fields: "files(id, name)", // Fetch only file IDs and names
    },
  })            
}

export const getUserFromGoogle = (accessToken:string)=>{

  return axios.get(
    "https://www.googleapis.com/oauth2/v3/userinfo",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export const getWorksheetDataFromGoogle = (spreadsheetId:string, accesstoken:string)=>{
  return axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`,
    },
    params: {
      includeGridData: true,
    },
  });
}

export const extractSpreadsheetIdFromUrl = (url:string)=>{
  const regex = /\/d\/([a-zA-Z0-9-_]+)\//;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const getCategories = async (column:string[], contextUuid: string): Promise<any> => {
  const data = {
    labels: column,
    contextUuid,
  }
  return api(`${BASE_API}/budget-agent/channels/categorize`, {
    method: "POST",
    body:JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const getTimePeriods = async (column:string[], contextUuid: string): Promise<any> => {
  const data = {
    labels: column,
    contextUuid,
  }
  return api(`${BASE_API}/budget-agent/time_periods/categorize`, {
    method: "POST",
    body:JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
};

export const uploadDataCoordinates = (data:any)=>{
  return api(`${BASE_API}/budget-agent/recommend_axes`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
}

export const uploadCalendarRange = (data:any)=>{
  return api(`${BASE_API}/budget-agent/time_periods/categorize`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
}
export const uploadCustomCategories = (data:any)=>{
  return api(`${BASE_API}/budget-agent/channels/categorize/confirm`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
}

export const uploadReviewBudgetToServer = (data:any)=>{
  return api(`${BASE_API}/projects/review-budget/submit`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
}
export const getGoogleAccessToken = (id:string | number)=>{
  return api(`${BASE_API}/credentials/refresh/`+id, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}