import React from "react";

import { ModalVariants } from "constants/shared/modal.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useBoolean } from "hooks/useBoolean";

import { logOutUser } from "storage/actions/auth";

import { ReactComponent as GearIcon } from "assets/icons/gearIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";

import { cn } from "lib/utils";

import { Avatar } from "./Avatar";
import { Dropdown } from "./dropdown/Dropdown";
import { Modal } from "./modal/Modal";
import { ModalControlButtons } from "./modal/ModalControlButtons";
import { TrialPeriodSection } from "./trial-period-section/TrialPeriodSection";
import { Typography } from "./Typography";

interface Props {
  title?: string;
  agentTitle?: string[];
  handleCrousel?: (value: number) => void; // Accepts a string parameter
  extraContent?: React.ReactNode;
  titleClassName?: string;
  titleContent?: React.ReactNode;
}

export const Header = ({ title, titleContent, titleClassName,handleCrousel, extraContent,agentTitle = [] }: Props) => {
  const dispatch = useAppDispatch();

  const { isCollapsed } = useAppSelector(state => state.sidebar);
  const [isModalOpen, _, handleOpenModal, handleCloseModal] = useBoolean();

  const dropdownOptions = [{ id: "logout", label: "Log Out", action: handleOpenModal, icon: LogoutIcon }];


  const handleLogout = () => {
    dispatch(logOutUser());
  };

  return (
    <div className='w-full sticky z-[20] h-[4rem] px-5 py-5 ml-[0px] font-semibold text-2xl leading-[125%] flex items-center justify-between bg-white border-l-2 border-b-2 border-[#F7F9FB] box-border'>
      <div className='flex items-center'>
        {agentTitle.length > 0 ? (
          agentTitle.slice(0, 2).map((agent, index) => (
            <Typography
              key={index}
              className={cn(extraContent ? "text-[#9EA2AD]" : "text-[#333740] text-base cursor-pointer pointer-events-auto",
                titleClassName,
                "hover:underline hover:text-[#766CE4]",
                index === 1 ? "ml-[24px] pl-6 border-l border-[#D1D5DB]" : "" // Apply styles only to the second element)}
              )}
              variant={TypographyVariants.H5}
              tag='h3'
              onClick={() => handleCrousel?.(index)} 
              
            >
              {agent}
            </Typography>
          ))
        ) : (
          titleContent ?? (
            <Typography
              className={cn(extraContent ? "text-[#9EA2AD]" : "text-[#333740]", "text-base", titleClassName)}
              variant={TypographyVariants.H5}
              tag='h3'
            >
              {title}
            </Typography>
          )
        )}
        {extraContent && extraContent}
      </div>
      <div className='flex items-center gap-x-6'>
        <TrialPeriodSection hidden={!isCollapsed} isSimplified />
        {/* <div className='cursor-pointer'>
          <GearIcon />
        </div> */}

        {/* <Dropdown className='min-w-[200px] !top-[60px]' placement='bottom end' items={dropdownOptions}>
          <Avatar />
        </Dropdown> */}

        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title='Are you sure?'
          titleClass='!text-[28px] !font-semibold'
          popupClass='!p-16'
          description='You will be logged out from your account.'
          variant={ModalVariants.SMALL}
        >
          <ModalControlButtons
            buttonClass='px-[42px] w-fit text-nowrap'
            submitButtonText='Log out'
            handleSubmit={handleLogout}
            handleCancel={handleCloseModal}
          />
        </Modal>
      </div>
    </div>
  );
};
