import { useQuery } from "@tanstack/react-query";

import { getChannelsData } from "api/budget";
import { IChannelData } from "api/budget/types";

export const queryKeys = {
  getChannels: ["getChannels"] as const,
};

export const useGetChannels = () =>
  useQuery({
    queryKey: queryKeys.getChannels,
    queryFn: async (): Promise<IChannelData[]> => {
      return await getChannelsData();
    },
  });
