import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import { TypographyOptions, TypographyVariants } from "constants/shared/typography.constants";

import { AllowedTypographyTags } from "interfaces/shared/typograpgy.interfaces";

export interface Props {
  tag?: AllowedTypographyTags;
  variant?: TypographyVariants;
  className?: string;
  children: ReactNode | string;
  onClick?: () => void; // ✅ Add onClick prop
}

export const Typography = ({ tag, variant = TypographyVariants.BODY_LARGE, className, children,onClick  }: Props) => {
  const { tag: optionsTag, className: optionsClassName } = TypographyOptions[variant];

  const TypographyTag = tag || optionsTag || "p";

  return <TypographyTag className={twMerge("text-[#1F2228]", optionsClassName, className)} onClick={onClick} // ✅ Pass onClick prop
  style={{ cursor: onClick ? "pointer" : "default" }}>{children}</TypographyTag>;
};
