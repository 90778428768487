import { MessageContentComplex } from "@langchain/core/messages";
import { parsePartialJson } from "@langchain/core/output_parsers";
import { HumanInterrupt } from "@langchain/langgraph/prebuilt";
import { AIMessage, Checkpoint, Message } from "@langchain/langgraph-sdk";
import { LoadExternalComponent } from "@langchain/langgraph-sdk/react-ui";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { BooleanParam, useQueryParam } from "use-query-params";

import { AgentItem } from "api/agent/type";

import { AGENT_IMAGES } from "constants/agent.constants";

import { useAI } from "shared/components/ai-panel/provider";
import { Icons } from "shared/components/icons";

import { Avatar, AvatarImage, AvatarFallback } from "components/ui/avatar";
import { isDataSourceInterruptSchema, isNotificationSetupInterruptSchema } from "lib/interrupt-utils";
import { cn } from "lib/utils";
import { useStreamContext } from "providers/Stream";

import { DataSources } from "../data-sources";
import { NotificationSetup } from "../data-sources/components/notification-setup-view";
import { MarkdownText } from "../markdown-text";
import { getContentString } from "../utils";

import { BranchSwitcher, CommandBar } from "./shared";
import { ToolCalls, ToolResult } from "./tool-calls";




function CustomComponent({ message, thread }: { message: Message; thread: ReturnType<typeof useStreamContext> }) {
  const { values } = useStreamContext();
  const customComponents = values.ui?.filter(ui => ui.metadata?.message_id === message.id);

  if (!customComponents?.length) return null;
  return (
    <Fragment key={message.id}>
      {customComponents.map(customComponent => (
        <LoadExternalComponent key={customComponent.id} stream={thread} message={customComponent} meta={{ ui: customComponent }} />
      ))}
    </Fragment>
  );
}

function parseAnthropicStreamedToolCalls(content: MessageContentComplex[]): AIMessage["tool_calls"] {
  const toolCallContents = content.filter(c => c.type === "tool_use" && c.id);

  return toolCallContents.map(tc => {
    const toolCall = tc as Record<string, any>;
    let json: Record<string, any> = {};
    if (toolCall?.input) {
      try {
        json = parsePartialJson(toolCall.input) ?? {};
      } catch {
        // Pass
      }
    }
    return {
      name: toolCall.name ?? "",
      id: toolCall.id ?? "",
      args: json,
      type: "tool_call",
    };
  });
}

function getAgentImage(agent: AgentItem | undefined) {
  return AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];
}

export function AssistantMessage({
  message,
  isLoading,
  handleRegenerate,
  agent,
}: {
  message: Message;
  isLoading: boolean;
  handleRegenerate: (parentCheckpoint: Checkpoint | null | undefined) => void;
  agent?: AgentItem;
}) {

  const contentString = getContentString(message.content);
  const [hideToolCalls] = useQueryParam("hideToolCalls", BooleanParam);
  
  const thread = useStreamContext();
  const{setIsWorkflow,isFlag,setIsFlag} = useAI()
  const isLastMessage = thread.messages[thread.messages.length - 1].id === message.id;
  const meta = thread.getMessagesMetadata(message);
  const interrupt = thread.interrupt;
  const parentCheckpoint = meta?.firstSeenState?.parent_checkpoint;
  const anthropicStreamedToolCalls = Array.isArray(message.content) ? parseAnthropicStreamedToolCalls(message.content) : undefined;

  const hasToolCalls = "tool_calls" in message && message.tool_calls && message.tool_calls.length > 0;
  const toolCallsHaveContents = hasToolCalls && message.tool_calls?.some(tc => tc.args && Object.keys(tc.args).length > 0);
  const hasAnthropicToolCalls = !!anthropicStreamedToolCalls?.length;
  const isToolResult = message.type === "tool";
  const agentImage = getAgentImage(agent);

  const handleToolComponents = ()=>{
       if(isFlag){
         
       }
       else{
       setIsWorkflow(true)
       }
  }

  if (isToolResult && hideToolCalls) {
    return null;
  }

  if (contentString === "") {
    return null;
  }


  return (
    <div className='flex items-end mr-auto gap-2 group max-w-[75%]'>
      <Avatar className='mb-8'>
        {isLastMessage && (
          <>
            <AvatarImage src={agentImage} alt={agent?.name} />
            <AvatarFallback>AI</AvatarFallback>
          </>
        )}
      </Avatar>
      {isToolResult ? (
        <ToolResult message={message} />
      ) : (
        <div className='flex flex-col gap-2'>
          {contentString.length > 0 && (
            <div className='px-4 py-2'  style={{background:"#F7F7F8", borderRadius:"12px",color:"#464A53",fontSize:"14px",fontWeight:"500"}}>
              <MarkdownText>{contentString}</MarkdownText>
            </div>
          )}

          {!hideToolCalls && (
            <>
              {(hasToolCalls && toolCallsHaveContents && <ToolCalls toolCalls={message.tool_calls} />) ||
                (hasAnthropicToolCalls && <ToolCalls toolCalls={anthropicStreamedToolCalls} />) ||
                (hasToolCalls && <ToolCalls toolCalls={message.tool_calls} />)}
            </>
          )}

          <CustomComponent message={message} thread={thread} />
          {(() => {
            if (!interrupt?.value || !isLastMessage) {
              return null;
            }
            if (isDataSourceInterruptSchema(interrupt.value)) {
              return <DataSources interrupt={interrupt.value} />;
            } else if (isNotificationSetupInterruptSchema(interrupt.value)) {
              handleToolComponents();
            } else {
              return null;
            }
          })()}
          <div
            className={cn(
              "flex gap-2 items-center mr-auto transition-opacity",
              "opacity-0 group-focus-within:opacity-100 group-hover:opacity-100",
            )}
          >
            <BranchSwitcher
              branch={meta?.branch}
              branchOptions={meta?.branchOptions}
              onSelect={branch => thread.setBranch(branch)}
              isLoading={isLoading}
            />
            <CommandBar
              content={contentString}
              isLoading={isLoading}
              isAiMessage={true}
              handleRegenerate={() => handleRegenerate(parentCheckpoint)}
            />
          </div>
        
        </div>
      )}

    </div>
  );
}


export function AssistantMessageLoading({ agent }: { agent?: AgentItem }) {
        const stream = useStreamContext();
        const messages = stream.messages;

  const toolNameToStatusMessage = (toolName: string | undefined): string => {
    switch (toolName) {
      case "transfer_to_datasourceconnectionagent":
        return "Setting things up...";
      case "get_connected_sources":
        return "Checking which data sources you have already connected...";
      case "get_available_data_sources":
        return "Checking for available data sources...";
      default:
        return "Thinking...";
    }
  }  
  
  const agentImage = getAgentImage(agent);
  const toolCalls = messages.filter(item => item.type === "tool"); // Get all tool messages
  let statusMessage = "Thinking..."
  
  toolCalls.forEach(tool => {
    const latestTool = tool; // Assign the current item to latestTool
    statusMessage = latestTool ? (latestTool?.type === "tool" ? toolNameToStatusMessage(latestTool.name) : "Thinking...") : "Thinking...";
    
  });


  return (
    <>
    <div className='flex items-end mr-auto gap-2'>
      <Avatar className='mb-0'>
        <AvatarImage src={agentImage} alt={agent?.name} className='w-[32px] h-[32px]' />
        <AvatarFallback>AI</AvatarFallback>
      </Avatar>
      <div className='flex flex-1 relative'>
        <div className='relative'>
          <div className='absolute right-0 left-2 bottom-[28px] w-1.5 h-1.5 border border-[#E9EAEC] rounded-full'></div>
          <div className='absolute right-2 left-1 bottom-[26px] w-1 h-1 border border-[#E9EAEC] rounded-full'></div>
          <div className='absolute right-5 left-0 bottom-[24px] w-0.5 h-0.5 border-[0.8px] border-[#E9EAEC] rounded-full'></div>
        </div>

        <div className={cn("flex ml-3 mb-8 flex-row gap-1 items-center px-4 py-2 rounded-xl border bg-white border-[#E9EAEC] mt-[25px]" )}>
          <div className="flex items-center gap-2">
            <Icons.Spinner className='animate-spin flex-shrink-0 self-center' />
            <p className="text-[#717684] text-sm font-medium">{statusMessage}</p>
          </div>
        </div>
      </div>
    </div>
       {/* <div className="border-l-4 border-gray-300 pl-4 text-gray-700  p-4 rounded-md" style={{maxWidth:"65%",marginLeft:"2.5rem"}}>
      <p>
        The user wants to know the conditions that lead to a La Niña weather pattern. 
        So, I need to detail La Ninas characteristics, which involve cooler-than-normal 
        sea surface temperatures in the central and eastern tropical Pacific. This is 
        part of the broader El Niño Southern Oscillation (ENSO) cycle. I will focus on 
        outlining the specific conditions—like changes in trade winds and ocean currents—
        that bring about this phase. I will also be sure to provide 
        <span className="text-gray-400"> reasoning and relevant references for further understanding.</span>
      </p>
    </div> */}
    </>
  );
}
