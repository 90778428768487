import { ReactElement, useEffect, useState, type FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { ROUTES } from "constants/routes.constants";

import { ctaEvent, extractUserDetails } from "utils/analytics.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetProject } from "hooks/queries/project";

import { getWorkflowsStrategiesList } from "storage/actions/workflows";
import { setConversationId } from "storage/slices/conversation-slice";
import { getUserPreferences } from "storage/slices/preferences-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { AIButton } from "shared/components/ai-panel";
import { months } from "shared/components/budget/datePicker/DatePicker";
import { Icons } from "shared/components/icons";
import OperationalFakeDoor from "shared/components/popups/operationalFakeDoor/OperationalFakeDoor";
import { Skeleton } from "shared/components/skeleton";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-gray.svg";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as OperationalIcon } from "assets/icons/operational-view.svg";

import { cn } from "lib/utils";
import { SidebarsWidth } from "types";

import AiChatModal from "./AiChatModal/AiChatModal";
import classes from "./BudgetIntelligence.module.scss";
import CustomView from "./CustomView/CustomView";
import ExecutiveView from "./ExecutiveView/ExecutiveView";
import MarketingView from "./MarketingpView/MarketingView";
import OperationalView from "./OperationalView/OperationalView";

const BudgetIntelligence: FC<any> = () => {
  const { CHAT_OPENED_WIDTH, CHAT_CLOSED_WIDTH } = SidebarsWidth;

  const { id } = useParams();

  const { data, isLoading } = useGetProject(id ? Number(id) : undefined);

  const { budget_date_from, budget_date_to } = useAppSelector((state: any) => state.project.project || {});
  const user = useAppSelector(state => state.auth?.user);
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(setIsCollapsed(true));
    dispatch(getUserPreferences());
    dispatch(setConversationId(uuidv4()));
  }, []);

  const [isMarketingView, setIsMarketingView] = useState<boolean>(false);
  const [tabs, setTabs] = useState<{ name: string; Icon: any }[]>([
    { name: "summary", Icon: <BriefcaseIcon /> },
    { name: "operational", Icon: <OperationalIcon /> },
  ]);
  const [view, setView] = useState<string>(state?.view || "summary");
  const [openChat] = useState(false);
  const handleSwitcher = (isMarketing = true) => {
    setIsMarketingView(isMarketing);
  };
  const onEditBtnClick = () => {
    navigate(ROUTES.editBudgetForm + "/" + id);
  };

  return (
    <DashboardLayout title='Budget Intelligence' isScrollable={false}>
      <div className='flex flex-col h-full'>
        <div className='bg-white border-b border-[#F7F9FB] px-6 pt-2'>
          <div className='flex justify-between items-center'>
            <div className={"flex gap-5 items-center"}>
              {tabs.map(({ name, Icon }, index) => (
                <button
                  key={name}
                  className={cn(
                    "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm capitalize",
                    view === name ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                  )}
                  onClick={() => {
                    ctaEvent(name, extractUserDetails(user));
                    setView(name);
                  }}
                >
                  {name}
                </button>
              ))}
              <button
                className={cn(
                  "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm",
                  view === "custom" ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                )}
                onClick={() => {
                  const uuid: string = crypto.randomUUID().slice(0, 8);
                  console.log(uuid);
                  setTabs([...tabs, { name: "custom" + uuid, Icon: <OperationalIcon /> }]);
                  setView("custom" + uuid);
                }}
              >
                <Icons.Plus /> Create New
              </button>
            </div>
            {view === "executive" || view === "operational" ? (
              <div className='flex gap-2 pb-2.5 items-center text-sm leading-default font-medium text-[#464A53]'>
                Budget period:{" "}
                <div className='flex items-center gap-1'>
                  <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                    {isLoading ? (
                      <Skeleton className='w-20 h-full' />
                    ) : (
                      <>
                        {months[Number(data?.budget_date_from?.split("-")[1]) - 1]} {data?.budget_date_from?.split("-")[0]}
                      </>
                    )}
                  </div>
                  <ArrowRight />
                  <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                    {isLoading ? (
                      <Skeleton className='w-20 h-full' />
                    ) : (
                      <>
                        {months[Number(data?.budget_date_to?.split("-")[1]) - 1]} {data?.budget_date_to?.split("-")[0]}
                      </>
                    )}
                  </div>
                  <button
                    className='py-2 px-[11px] h-[34px] text-[#766CE4] underline underline-offset-3 text-sm leading-default font-semibold hover:font-bold'
                    onClick={onEditBtnClick}
                  >
                    Edit budget
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={cn(
            "flex h-full w-full bg-[#F7F9FB]",
            classes.budgetIntelligence,
            view === "executive"
              ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
              : "overflow-hidden",
          )}
        >
          {view === "operational" ? <OperationalView project={data} isLoading={isLoading} /> : null}
          {view === "summary" ? <ExecutiveView openChat={openChat} project={data} isLoading={isLoading} /> : null}
          {view.includes("custom") ? <CustomView /> : null}
        </div>
      </div>
      <AiChatModal />
    </DashboardLayout>
  );
};

export default BudgetIntelligence;
