import { AgentItem } from "api/agent/type";

import { AGENT_IMAGES } from "constants/agent.constants";

const AgentInfo = ({ agent }: { agent?: AgentItem | null }) => {
  if (!agent) {
    return null;
  }

  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];
  return (
    <div className='flex flex-col items-center md:flex-row gap-4 mt-1'>
      <img src={Image} alt='Agent' className='w-[72px] h-[72px] rounded-full object-cover' />
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col'>
            <h1 className='text-[#B4B4C8] font-bold text-[1.75rem] leading-9'>
              Hi, I’m {agent?.name} {agent?.title}{" "}
            </h1>
            <h3 className='font-semibold text-[1.75rem] text-gradient-purple-4 leading-9'>What can I help you with today?</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentInfo;
