import cn from "classnames";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { IChannelsTransformedData } from "api/budget/types";

import { TypographyVariants } from "constants/shared/typography.constants";
import { UserPlans } from "constants/user.constants";

import { StringOrNull, StringOrUndefined } from "interfaces/shared-types.interfaces";

import { convertAmountToUSFormat } from "utils/global.utils";
import { getWorkflowDetailsPath } from "utils/routes.utils";

import { useLimitViewBySubscription } from "hooks/subscriptions/useLimitViewBySubscription";

import { ReactComponent as RightArrowIcon } from "assets/icons/MarketingArrowRightIcon.svg";

import { generateUniqueId } from "utils";

import { Typography } from "../../Typography";

import classes from "./MonthBudgetCard.module.scss";
import MonthBudgetCardOverlay from "./MonthBudgetCardOverlay";

interface Props {
  budgetId: StringOrUndefined;
  // TODO - remove any
  prevMonth: any;
  data: IChannelsTransformedData;
  offset: number;
  budgetDateFrom: StringOrNull;
  userPlan?: UserPlans;
}

 function MonthBudgetCard({ data, prevMonth, budgetId, budgetDateFrom, userPlan }: Props) {
  const navigate = useNavigate();
  const { isAvailable: isCardAvailableByPlan } = useLimitViewBySubscription({
    budgetDateFrom,
    currentBudgetDate: `${data?.year} ${data?.month}`,
  });

  const totalMonthBudget = data?.values?.reduce((acc: number, item: any) => {
    acc += item.value;
    return acc;
  }, 0);

  const realDate = dayjs();
  const cardDate = dayjs(`${data?.year} ${data?.month}`);

  const isDifferenceInMonths = cardDate.diff(realDate, "month") > 0;

  const handleNavigateToWorkflowPage = () => {
    if (!isCardAvailableByPlan || isDifferenceInMonths) return;

    navigate(getWorkflowDetailsPath(budgetId), {
      state: {
        activeDate: data?.date,
      },
    });
  };
  const total = useMemo(() => {
    return data?.values?.reduce((acc: number, item: any) => {
      acc += item.value;
      return acc;
    }, 0);
  }, [data?.values]);

  return (
    <div
      className={cn(
        "flex relative flex-col bg-white px-3 pt-2 pb-3 rounded-2xl gap-y-2 items-center w-full min-w-[270px] h-full transition-transform duration-500 ease-in-out",
        classes.monthBudgetCard,
      )}
    >
      
      {!isCardAvailableByPlan && userPlan === UserPlans.FREE && <MonthBudgetCardOverlay />}
      <div className='flex justify-between items-center w-full pb-2 cursor-pointer z-20'>
        <div>
        <p className='text-black text-base font-bold flex'>
          {data?.month}, {data?.year}
        </p>
        <div className='text-[#5E636E] text-base font-normal flex mt-1'>
          <p>{"Total Budget:"}</p> <p className="font-semibold ml-1"> {convertAmountToUSFormat(total)}</p>
        </div>
        </div>
        <Tooltip
          id={`${data.month}-tooltip`}
          positionStrategy='fixed'
          place='top-end'
          opacity={1}
          style={{
            borderRadius: "13px",
            background: "#2E323A",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className='text-xs'>Go to Workflow Automation</span>
          </div>
        </Tooltip>
        <Tooltip
          id={`${data?.month}-blocked-tooltip`}
          positionStrategy='fixed'
          place='top-end'
          opacity={1}
          clickable
          style={{
            top: "-50%",
            width: "168px",
            borderRadius: "13px",
            background: "#2E323A",
            padding: "12px",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              width: "100%",
              gap: "8px",
            }}
          >
            <div className='flex justify-between items-start'>
              <span className='w-full text-xs text-color-[#FFF] font-normal'>
                Workflows will open up in{" "}
                <b>
                  {cardDate.format("MM") === "01" && !prevMonth?.month ? "December" : prevMonth?.month},{" "}
                  {cardDate.format("MM") === "01" ? Number(data?.year) - 1 : data?.year}.
                </b>{" "}
                Come back later.{" "}
              </span>
            </div>
            <button className='flex relative w-full items-center justify-center m-[0px] px-[32px] py-[4px] bg-[#766CE4] border-0 rounded-[8px] text-[12px] font-medium'>
              Set reminder
            </button>
          </div>
        </Tooltip>
        <button
          className={cn("flex", classes.monthBudgetCard__arrow, {
            "cursor-not-allowed": !isCardAvailableByPlan,
          })}
          onClick={handleNavigateToWorkflowPage}
        >
          <RightArrowIcon
            className={cn("outline-none focus:outline-none", {
              "[&_rect]:fill-[#1F2228]/30": !isCardAvailableByPlan || isDifferenceInMonths,
            })}
            data-tooltip-id={isDifferenceInMonths ? `${data.month}-blocked-tooltip` : `${data.month}-tooltip`}
          />
        </button>
      </div>
      {data?.values?.map((value: any, index: number, array: any[]) => {
        const isValidData =
          typeof value.value === "number" && value.value >= 0 && typeof totalMonthBudget === "number" && totalMonthBudget > 0;

        let percentage = isValidData ? value.value / (totalMonthBudget / 100) : 0;

        if (index === array.length - 1) {
          percentage =
            100 - array.slice(0, -1).reduce((acc: number, item: any) => acc + Math.round(item.value / (totalMonthBudget / 100)), 0);
        } else {
          percentage = Math.round(percentage);
        }

        if (isNaN(percentage)) {
          percentage = 0;
        } else if (percentage < 1) {
          percentage = parseFloat(percentage.toFixed(1));
        } else {
          percentage = Math.round(percentage);
        }

        return (
          <div
            key={generateUniqueId()}
            className='flex items-center p-2 rounded-[54px] border-[1.5px] border-[#F6F6F6] gap-x-2 w-full bg-white z-[1]'
          >
            <Tooltip
              id={`channel-tooltip-${index}`}
              positionStrategy='fixed'
              place={!index ? "bottom-start" : "top-start"}
              opacity={1}
              style={{
                borderRadius: "13px",
                padding: "8px",
                background: "#2E323A",
                fontSize: "12px",
                zIndex: 1000,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className='text-xs'>{value?.channel} Budget:</span>
                <span className='text-xs'>
                  <b>${value?.value.toLocaleString("en-US")}</b>
                </span>
              </div>
            </Tooltip>
            <div className='relative size-12 flex items-center' data-tooltip-id={`channel-tooltip-${index}`}>
              <svg className='size-full' width='36' height='36' viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg'>
                <circle
                  cx='18'
                  cy='18'
                  r='16'
                  fill='none'
                  style={{ color: value?.color?.light, backgroundColor: value?.color?.primary }}
                  className={`stroke-current text-[${value?.color?.light}]`}
                  strokeWidth='4'
                ></circle>
                <g className='origin-center -rotate-90 transform'>
                  <circle
                    cx='18'
                    cy='18'
                    r='16'
                    fill='none'
                    style={{ color: value?.color?.primary }}
                    className='stroke-current'
                    strokeWidth='4'
                    strokeDasharray='100'
                    strokeDashoffset={100 - percentage}
                    strokeLinecap='round'
                  ></circle>
                </g>
              </svg>
              <div className='absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center justify-center'>
                <Typography variant={TypographyVariants.SM_SEMIBOLD}>{percentage}%</Typography>
              </div>
            </div>
            <div className='flex flex-col'>
              <Typography variant={TypographyVariants.MD_BOLD}>{value.channel}</Typography>
              <Typography className='text-[#717684] mt-0.5 !font-medium' variant={TypographyVariants.MD_SEMIBOLD}>
                {value?.summary}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
}


export default React.memo(MonthBudgetCard);
