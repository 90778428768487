import classNames from "classnames";
import { type FC } from "react";

import { IProjectData } from "api/projects/types";

import { useChannelStrategy } from "hooks/channels/useChannelStrategy";

import { useAppSelector } from "../../../../../hooks/appHooks";
import MonthlyStrategy from "../../MarketingpView/MonthlyStrategy/MonthlyStrategy";

import classes from "./BudgetAllocation.module.scss";
import BudgetBreakdown from "./BudgetBreakdown/BudgetBreakdown";
import Budgets from "./Budgets/Budgets";

interface BudgetAllocationProps {
  openChat: boolean;
  project?: IProjectData;
  isLoading: boolean;
}

const BudgetAllocation: FC<BudgetAllocationProps> = ({ openChat = false, project, isLoading }) => {
  const [transformedData] = useChannelStrategy({ project });

  const budget_breakdown = project?.project_data?.budget_breakdown;
  // const budget_breakdown = false
  return (
    <section className={classNames(classes.allocationSection)} style={{ gridTemplateColumns: budget_breakdown ? "60% 1fr" : "30% 1fr" }}>
      {budget_breakdown && <BudgetBreakdown project={project} isLoading={isLoading} />}
      <Budgets project={project} isLoading={isLoading} />
      <div className={classNames("w-full overflow-hidden h-full", { "col-span-2": budget_breakdown })}>
        <MonthlyStrategy openChat={false} project={project} transformedData={transformedData} isLoading={isLoading} />
      </div>
    </section>
  );
};

export default BudgetAllocation;
