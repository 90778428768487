import React from "react";

export type StatusType = "notification" | "syncing" | "monitoring" | "pending" | "low-demand";

type StatusBadgeProps = {
  type: StatusType;
  title: string;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ type, title }) => {
  let bgColor, textColor, gradientBg;
  console.log(type,title,">>>>>>>>>>>>")
  switch (type) {
    case "notification":
      bgColor = "#DFFFE2";
      textColor = "#02A613";
      gradientBg = "radial-gradient(125% 125% at 50% 50%, #FAFFFB 0%, #A6F7A0 100%)";
      break;
    case "syncing":
      bgColor = "#ECF2FF";
      textColor = "#4B80FC";
      gradientBg = "radial-gradient(125% 125% at 50% 50%, #FAFFFB 0%, #A1B4FF 100%)";
      break;
    case "monitoring":
      bgColor = "#FFECD6";
      textColor = "#D17102";
      gradientBg = "radial-gradient(125% 125% at 50% 50%, #FAFFFB 0%, #FFCAA1 100%)";
      break;
    case "pending":
    case "low-demand":
      bgColor = "#FEF1F2";
      textColor = "#E36C74";
      gradientBg = "radial-gradient(125% 125% at 50% 50%, #FAFFFB 0%, #F5B8B8 100%)";
      break;
    default:
      bgColor = "#FFECD6";
      textColor = "#D17102";
      gradientBg = "radial-gradient(125% 125% at 50% 50%, #FAFFFB 0%, #FFCAA1 100%)";
  }

  return (
    <div className='flex items-center space-x-2 px-2 py-1 rounded-full' style={{ backgroundColor: bgColor }}>
      <div
        className='rounded-full p-1 animate-pulse'
        style={{
          background: gradientBg,
        }}
      >
        <div className='w-1 h-1 rounded-full' style={{ backgroundColor: textColor }} />
      </div>
      <span className='font-semibold text-xs' style={{ color: textColor,whiteSpace:"nowrap" }}>
        {title}
      </span>
    </div>
  );
};

export default StatusBadge;
