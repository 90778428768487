import { useState } from "react";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowUpDownIcon } from "assets/icons/li_arrow-up-down.svg";
import { ReactComponent as ClipboardIcon } from "assets/icons/li_clipboard-list.svg";
import { ReactComponent as FilePieIcon } from "assets/icons/li_file-pie-chart.svg";
import { ReactComponent as FileIcon } from "assets/icons/li_file.svg";
import { ReactComponent as HelpIcon } from "assets/icons/li_help-circle.svg";
import { ReactComponent as LightBulbIcon } from "assets/icons/li_lightbulb.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/li_settings.svg";
import { ReactComponent as TargetIcon } from "assets/icons/li_target.svg";
import { ReactComponent as TrendingIcon } from "assets/icons/li_trending-up.svg";

import { cn } from "lib/utils";

type Props = {
  onSelected: (question: string) => void;
  containerStyles?: string;
};
type Question = {
  id: number;
  icon: any;
  text: string;
  bg: string;
};
const SuggestedQuestions = ({ onSelected, containerStyles = "" }: Props) => {
  const [show, setShow] = useState(false);
  const handleSelected = (item: Question) => {
    setShow(false);
    onSelected(item.text);
  };
  const questions: Question[] = [
    {
      id: 1,
      icon: <HelpIcon />,
      text: "Why was this budget recommended?",
      bg: "#008EFF1A",
    },
    {
      id: 2,
      icon: <TrendingIcon />,
      text: "Why should I invest in [this] channel?",
      bg: "#45C6461A",
    },
    {
      id: 3,
      icon: <FileIcon />,
      text: "How much are my competitors spending?",
      bg: "#F239361A",
    },
    {
      id: 4,
      icon: <TargetIcon />,
      text: "What outcome can I expect from this budget?",
      bg: "#FABB181A",
    },
    {
      id: 5,
      icon: <ClipboardIcon />,
      text: "What should I be doing with this budget?",
      bg: "#9E49EE1A",
    },
    {
      id: 6,
      icon: <ArrowUpDownIcon />,
      text: "How will this budget impact my [goals]?",
      bg: "#D321D91A",
    },
    {
      id: 7,
      icon: <SettingsIcon />,
      text: "I would like to modify my budget.",
      bg: "#1BD5D21A",
    },
    {
      id: 8,
      icon: <FilePieIcon />,
      text: "What strategies would you suggest for budgeting?",
      bg: "#E489121A",
    },
  ];
  return (
    <>
      {show && (
        <div
          className={cn(" flex-col gap-y-4 absolute bottom-0 transition  w-full bg-white z-50 overflow-y-auto max-h-full", containerStyles)}
        >
          <div className='flex w-full justify-end'>
            <button
              className='flex items-center gap-x-2 bg-white rounded-xl py-1.5 px-4 border border-[#E9EAEC] mb-2'
              onClick={() => setShow(false)}
            >
              <div className='h-4 w-4'>
                <LightBulbIcon height={16} width={16} />
              </div>
              <p className='text-xs font-medium text-[#717684]'>Hide suggestions</p>
              <ArrowDownIcon className='h-4 w-4' />
            </button>
          </div>

          <div className='bg-white rounded-2xl p-4 border border-[#E9EAEC] mb-1'>
            <div className='flex items-center gap-x-2.5'>
              <div className='h-4 w-4'>
                <LightBulbIcon height={16} width={16} />
              </div>
              <p className='text-xs font-medium text-[#717684] text-wrap'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam.
              </p>
            </div>
            <div className='mt-6 flex flex-col gap-y-3'>
              {questions.map((item: any) => (
                <button
                  key={item.id}
                  className='flex items-center gap-x-2 px-4 py-3 rounded-xl border border-[#E9EAEC] hover:border-purple'
                  onClick={() => {
                    handleSelected(item);
                  }}
                >
                  <div className={" p-2 rounded-full h-8 w-8"} style={{ background: item.bg }}>
                    {item.icon}
                  </div>
                  <div className='text-sm font-medium text-[#333740]'>{item.text}</div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
      {show == false && (
        <button
          className={cn(
            "absolute bottom-4 right-6 flex items-center z-20 gap-x-2 bg-white rounded-xl py-1.5 px-4 border border-[#E9EAEC] drop-shadow-md",
          )}
          onClick={() => setShow(true)}
        >
          <div className='h-4 w-4'>
            <LightBulbIcon height={16} width={16} />
          </div>
          <p className='text-xs font-medium text-[#717684]'>What should I ask</p>
          <ArrowUpIcon className='h-4 w-4' />
        </button>
      )}
    </>
  );
};
export default SuggestedQuestions;
