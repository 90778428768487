import { useState, useRef, useEffect } from "react";

type Props = {
  question: string;
  updateText: (text: string) => void;
};

const DynamicMultiInput = ({ question,updateText }: Props) => {
  // Extract text wrapped in []
  const parts = question.split(/(\[[^\]]+\])/g);
  const initialValues = parts
    .filter((part) => part.startsWith("[") && part.endsWith("]"))
    .map((part) => part.slice(1, -1)); // Remove [ and ]

  const [inputs, setInputs] = useState<string[]>(initialValues);

  // Refs for calculating input width
  const spanRefs = useRef<(HTMLSpanElement | null)[]>([]);

  const handleChange = (index: number, value: string) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
    updateText(getFullString(newInputs));
  };

  const calculateWidth = (value: string, index: number) => {
    const span = spanRefs.current[index];
    return span ? `${span.offsetWidth + 30}px` : "auto"; // Add some padding
  };

  const getFullString = (inputs: string[]) => {
    return parts
      .map((part, index) => {
        if (part.startsWith("[") && part.endsWith("]")) {
          return inputs[initialValues.indexOf(part.slice(1, -1))] || "";
        } else {
          return part;
        }
      })
      .join("");
  };

  useEffect(() => {
    // Force update to ensure spans are measured after render
    setInputs([...inputs]);
  }, []);
  useEffect(() => {
    setInputs(initialValues);
  }, [question]);

  return (
    <div>
      <p className="">
        {parts.map((part, index) =>
          part.startsWith("[") && part.endsWith("]") ? (
            <span key={index} style={{ display: "inline-flex" }}>
              <span
                ref={(el) => (spanRefs.current[index] = el)}
                style={{
                  position: "absolute",
                  visibility: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {inputs[initialValues.indexOf(part.slice(1, -1))] || ""}
              </span>
              <input
                type="text"
                className="bg-[#F1F0FC] rounded-md px-2 mt-1 overflow-scroll text-purple outline-none"
                onFocus={(e) => e.target.select()}
                value={inputs[initialValues.indexOf(part.slice(1, -1))] || ""}
                autoFocus={index === 0}
                maxLength={40}
                onChange={(e) =>
                  handleChange(
                    initialValues.indexOf(part.slice(1, -1)),
                    e.target.value
                  )
                }
                style={{
                  width: calculateWidth(
                    inputs[initialValues.indexOf(part.slice(1, -1))] || "",
                    index
                  ),
                  marginLeft: "0px",
                  marginRight: "0px",
                  minWidth: "40px",
                }}
                placeholder=""
              />
            </span>
          ) : (
            part // Render plain text
          )
        )}
      </p>
    </div>
  );
};

export default DynamicMultiInput;