import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useGetChannels } from "hooks/queries/channels";
import { useGetWorkflowStrategyTemplate } from "hooks/queries/workflows";

import { useAI } from "shared/components/ai-panel/provider";
import { Icons } from "shared/components/icons";
import { Skeleton } from "shared/components/skeleton";

import { cn } from "lib/utils";
import { LabelTypes } from "types";

import WorkflowTemplateCard from "./WorkflowTemplateCard";



const QuickStartTemplates = () => {
  const [searchParams] = useSearchParams();
  const [selectedLabel, setSelectedLabel] = useState(LabelTypes.Inventory);
  const agentId = searchParams.get("agent_uuid");
  const { setMessageContextInput,setDescription,isAnime } = useAI();
  const handleClick = (input: string) => {
    console.log(input,"Val")
    setDescription(input)
    // setMessageContextInput(input);
  };
  const { data: channels } = useGetChannels();
  const {
    data: { data: workflowList = [] } = {},
    isLoading: workflowStateIsLoading,
    refetch,
  } = useGetWorkflowStrategyTemplate({ templateType: "", agent_uuid: String(agentId), label: selectedLabel });

  const results = useMemo(() => {
    const data = [
      ...(workflowList || []).map(workflow => {
        return {
          name: workflow.name,
          description: workflow.description,
          id: workflow.channel_id,
          type: "workflow",
          status: null,
          channels: channels?.filter(channel => channel.channel_id === workflow.channel_id) ?? [],
          strategy_id: workflow.strategy_id,
          label: workflow.label ?? null,
        };
      }),
    ];

    return data;
  }, [channels, workflowList]);

  const handleChangeLabelType = (label: LabelTypes) => {
    setSelectedLabel(label);
  };

  useEffect(() => {
    refetch();
  }, [selectedLabel]);

  return (
    <div className='flex flex-col gap-2 mt-10 w-full'>
      <div className='flex flex-col gap-3'>
        <h4 className='text-sm font-medium text-[#717684]'>Get started with an example below</h4>
        <div className='flex items-center gap-2'>
          {Object.values(LabelTypes).map(label => (
            <button
              disabled={label === selectedLabel}
              onClick={() => handleChangeLabelType(label)}
              key={label}
              className={cn(
                "inline-flex items-center gap-1 border   text-xs font-semibold py-2 px-3 rounded-full",
                selectedLabel === label ? "bg-[#1F2228] text-white" : "bg-[#FFFFFF] text-[#717684]",
              )}
            >
              {label === LabelTypes.Inventory ? (
                <Icons.CheckList color={selectedLabel === label ? "#FFFFFF" : "#9EA2AD"} className={cn("w-4 h-4 mr-1.5")} />
              ) : (
                <Icons.Customer color={selectedLabel === label ? "#FFFFFF" : "#9EA2AD"} className={cn("w-4 h-4 mr-1.5")} />
              )}

              {label}
            </button>
          ))}
        </div>
      </div>

      <div className={cn(
        "grid grid-cols-1 gap-2 md:grid-cols-3 pb-10",
        isAnime ? "animate-rotate-bounce" : ""
      )}>
        {workflowStateIsLoading ? (
          <>
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton className='rounded-[1.25rem] w-full h-[211px] bg-[#F7F7F8]' key={index} />
            ))}
          </>
        ) : (
          <>
            {results.length > 0 ? (
              results.slice(0, 6).map((item, index) => <WorkflowTemplateCard key={index} item={item} handleSelectWorkflow={handleClick} />)
            ) : (
              <div></div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default QuickStartTemplates;
