
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { AgentItem } from "api/agent/type";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import useIsMobile from "hooks/use-mobile";

import { setConversationId } from "storage/slices/conversation-slice";

import { Thread as AgentUIThread } from "shared/components/agent-chat-ui/thread";
import { Dropdown } from "shared/components/dropdown/Dropdown";
import { Icons } from "shared/components/icons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import DataPreview from "../conversation/components/DataPreview";

const Thread = () => {
  const [searchParams] = useSearchParams();
  const agentUuid = searchParams.get("agent_uuid");
  const navigate = useNavigate();
  const [isFrequencyNotification, setIsFrequencyNotification] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const isMobile = useIsMobile(1100);
  const agents = useAppSelector(state => state.agent.agents);
  const isDataPreviewOpen = useAppSelector(state => state.integrations.isDataPreviewOpen);

  const agent = useMemo(() => agents?.find((agent: AgentItem) => agent.uuid === String(agentUuid)), [agents, agentUuid]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConversationId(uuidv4()));
  }, [dispatch, isMobile]);

  const dropdownOptions = agents.map(({ id, name, title }) => ({
    id: String(id),
    label: title,
    title: name,
    action: () => console.log("action1"),
  }));

  const handleSwitchAgents = () => (
    <div className='ml-6'>
      <Dropdown
        className='min-w-[200px] ml-8 !top-[60px] !left-14'
        placement='top end'
        items={dropdownOptions}
        onSelectOption={label => handleDropdownClick(label)}
      >
        <Icons.ArrowDown />
      </Dropdown>
    </div>
  );

  const handleDropdownClick = (label: string) => {
    navigate(ROUTES.dashboard, { state: { selectedLabel: label } });
    // Perform any action you want based on the selected label
  };

  return (
    <div className='flex w-full h-full'>
      <DashboardLayout
        title='Operations Specialist'
        titleClassName='text-[#333740]'
        headerExtraContent={handleSwitchAgents()}
        containerClassName='bg-white'
      >
        <AgentUIThread />
      </DashboardLayout>
      {isDataPreviewOpen && <DataPreview setIsFrequencyNotification={setIsFrequencyNotification} />}
    </div>
  );
};

export default Thread;