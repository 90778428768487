export const removeEmptyValuesFromObject = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined && value !== ""));
};

export const generateRandomString = (length?: number) => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < (length || 20); i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return `${result}-${new Date().toISOString()}`;
};
export const convertAmountToUSFormat = (amount: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(amount);
};
