import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";

import { Icons } from "shared/components/icons";

import { updatedData } from "pages/budget/ConnectDataSource/components/jsonExamples";

import StatusBadge from "./Badge/StatusBadge";
import ProductBasedTable from "./product-based-table/product-based-table";

const CustomView = ({ data }: { data: any }) => {
  const [search, setSearch] = useState("");
  const [budget] = useState(updatedData);
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper<any>();

  const getBadgeType = (type: any) => {
    switch (type) {
      case 1:
        return "notification"
      case 2:
        return"syncing"
      case 3:
        return "monitoring"
      case 4:
        return "syncing"
      case 5:
        return "notification"
      case 6:
        return "monitoring"
      case 7:
        return "notification"
      case 8:
        return "syncing"
      case 9:
        return "monitoring"
      default:
        return "monitoring"
    }
  };
  const getBadgeTitle = (type: any) => {
    switch (type) {
      case 1:
        return "Notification Sent"
      case 2:
        return"Data Syncing"
      case 3:
        return "Monitoring"
      case 4:
        return "Data Syncing"
      case 5:
        return "Notification Sent"
      case 6:
        return "Monitoring"
      case 7:
        return "Notification Sent"
      case 8:
        return "Data Syncing"
      case 9:
        return "Monitoring"
      default:
        return "Monitoring"
    }
  };

  const getAverage = (type: any) => {
    switch (type) {
      case 1:
        return "120 Units"
      case 2:
        return"140 Units"
      case 3:
        return "80 Units"
      case 4:
        return "145 Units"
      case 5:
        return "230 Units"
      case 6:
        return "333 Units"
      case 7:
        return "120 Units"
      case 8:
        return "200 Units"
      case 9:
        return "111 Units"
      case 10:
        return "160 Units"
      case 11:
        return "201 Units"
      case 12:
        return "340 Units"
      case 13:
        return "11 Units"
      case 14:
        return "9 Units"
      case 15:
        return "400 Units"
      default:
        return "120 Units"
    }
  };

  const columns = [
    columnHelper.accessor("select", {
      header: () => <></>,
      cell: ({ row }) => (
        <div className='flex items-center gap-2'>
          <p className='text-[10px] font-bold text-[#5E636E]'>{Number(row.id) + 1}</p>
        </div>
      ),
    }),
    columnHelper.accessor("product_id", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.ProductId /> Product ID
          </div>
        );
      },
      cell: info => <span className='font-medium text-xs text-[#2E323A]'>{info.getValue()}</span>,
    }),
    columnHelper.accessor("product_name", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.Product /> Product Name
          </div>
        );
      },
      cell: info => (
        <div className='flex items-center gap-2'>
          <img src={info.row.original.productImage} alt='' className='w-5 h-5' />
          <span className='font-medium text-xs text-[#2E323A]'>{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("category", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.Categories /> Category
          </div>
        );
      },
      cell: info => <span className='font-medium text-xs text-[#2E323A]'>{info.getValue()}</span>,
    }),
    columnHelper.accessor("automation_stage", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.Automation /> Automation Stage
          </div>
        );
      },
      cell: info => {
        console.log("Cell Info:", info);
        return <StatusBadge  type={getBadgeType(info.row.index)} title={getBadgeTitle(info.row.index)}/>;
            }
    }),
    columnHelper.accessor("inventory_status", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.InventoryStatus /> Inventory Status
          </div>
        );
      },

      cell: info => <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><span className='font-medium text-xs text-[#5E636E]'style={{background:"#F7F7F8",borderRadius:"4px",fontSize:"12px",padding:"2px 4px",textAlign:"center"
      }}>{info.getValue()}</span></div>,
    }),
    columnHelper.accessor("inventory_quantity", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.InventoryCount /> Inventory Count
          </div>
        );
      },
      cell: info => <span className='font-medium text-xs text-[#2E323A]'>{info.getValue()}</span>,
    }),
    columnHelper.accessor("averageSales", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.Sales /> Average Sales (Monthly)
          </div>
        );
      },
      cell: info => <span className='font-medium text-xs text-[#2E323A]'>{getAverage(info.row.index)}</span>,
    }),
    columnHelper.accessor("demand_status", {
      header: () => {
        return (
          <div className='flex items-center gap-1.5 overflow-hidden font-bold text-xs'>
            <Icons.DemandStatus /> Demand Status
          </div>
        );
      },
      cell: info => <StatusBadge type={info.getValue()} title={info.getValue()} />,
    }),
  ];

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onGlobalFilterChange: setSearch,
    globalFilterFn: "includesString",
  });

  const selectedRows = table.getSelectedRowModel().rows;

  return (
    <div className='h-full w-full relative bg-white p-6'>
      <ProductBasedTable table={table} selectedRows={selectedRows} />
    </div>
  );
};

export default CustomView;
