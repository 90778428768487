import { Checkpoint, Message } from "@langchain/langgraph-sdk";
import { motion } from "framer-motion";
import { ArrowDown, LoaderCircle, PanelRightOpen, PanelRightClose, SquarePen } from "lucide-react";
import { useState, FormEvent , ReactNode, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { StickToBottom, useStickToBottomContext } from "use-stick-to-bottom";
import { v4 as uuidv4 } from "uuid";

import { AgentItem } from "api/agent/type";

import { useAppSelector } from "hooks/appHooks";
import { useMediaQuery } from "hooks/useMediaQuery";

import { selectUser } from "storage/selectors/user";

import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Switch } from "components/ui/switch";
import { DO_NOT_RENDER_ID_PREFIX, ensureToolCallsHaveResponses } from "lib/ensure-tool-responses";
import { cn } from "lib/utils";
import { useStreamContext } from "providers/Stream";

import ThreadHistory from "./history";
import { AssistantMessage, AssistantMessageLoading } from "./messages/ai";
import { HumanMessage } from "./messages/human";
import { TooltipIconButton } from "./tooltip-icon-button";


function StickyToBottomContent(props: { content: ReactNode; footer?: ReactNode; className?: string; contentClassName?: string }) {
  const context = useStickToBottomContext();
  return (
    <div ref={context.scrollRef} style={{ width: "100%", height: "100%" }} className={props.className}>
      <div ref={context.contentRef} className={props.contentClassName}>
        {props.content}
      </div>

      {props.footer}
    </div>
  );
}

function ScrollToBottom(props: { className?: string }) {
  const { isAtBottom, scrollToBottom } = useStickToBottomContext();

  if (isAtBottom) return null;
  return (
    <Button variant='outline' className={props.className} onClick={() => scrollToBottom()}>
      <ArrowDown className='w-4 h-4' />
      <span>Scroll to bottom</span>
    </Button>
  );
}

export function Thread() {
  const [threadId, setThreadId] = useQueryParam("threadId", StringParam);
  const [chatHistoryOpen, setChatHistoryOpen] = useQueryParam("chatHistoryOpen", BooleanParam);
  const [hideToolCalls, setHideToolCalls] = useQueryParam("hideToolCalls", BooleanParam);
  const [agentUuid] = useQueryParam("agent_uuid", StringParam);
  const [input, setInput] = useState("");
  const [firstTokenReceived, setFirstTokenReceived] = useState(false);
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const agents = useAppSelector(state => state.agent.agents);
  const agent = useMemo(() => agents?.find((agent: AgentItem) => agent.uuid === String(agentUuid)), [agents, agentUuid]);

  const stream = useStreamContext();
  const messages = stream.messages;
  const isLoading = stream.isLoading;

  console.log("messages[%d]: %o", messages.length, messages);

  const lastError = useRef<string | undefined>(undefined);

  const user = useSelector(selectUser);

  console.log("USERR",user)

  //console.log("agentUuid: %s", agentUuid);
  //console.log("agent: %o", agent);

  useEffect(() => {
    if (!stream.error) {
      lastError.current = undefined;
      return;
    }
    try {
      const message = (stream.error as any).message;
      if (!message || lastError.current === message) {
        // Message has already been logged. do not modify ref, return early.
        return;
      }

      // Message is defined, and it has not been logged yet. Save it, and send the error
      lastError.current = message;
      toast.error("An error occurred. Please try again.", {
        description: (
          <p>
            <strong>Error:</strong> <code>{message}</code>
          </p>
        ),
        richColors: true,
        closeButton: true,
      });
    } catch {
      // no-op
    }
  }, [stream.error]);

  // TODO: this should be part of the useStream hook
  const prevMessageLength = useRef(0);
  useEffect(() => {
    if (messages.length !== prevMessageLength.current && messages?.length && messages[messages.length - 1].type === "ai") {
      setFirstTokenReceived(true);
    }

    prevMessageLength.current = messages.length;
  }, [messages]);

   const handleSubmit = async (e: FormEvent) => {
    // debugger
    e.preventDefault();
    if (!input.trim() || isLoading) return;
    setFirstTokenReceived(false);

    const newHumanMessage: Message = {
      id: uuidv4(),
      type: "human",
      content: input,
    };

    let usableThreadId = threadId;

    console.log("threadId: %o", threadId);
    if (threadId === null || !!threadId || threadId === "undefined") {
      const thread = await stream.client.threads.create({ metadata: { user_id: user?.user_id } });
      console.log("created thread: %o", thread);
      usableThreadId = thread.thread_id;
      setThreadId(thread.thread_id);
    }

    const config = {
      configurable: {
        user_id: user?.user_id,
        thread_id: usableThreadId,
      },
    };

    const toolMessages = ensureToolCallsHaveResponses(stream.messages);

    stream.submit(
      {
        messages: [...toolMessages, newHumanMessage],
      },
      {
        streamMode: ["values"],
        optimisticValues: prev => ({
          ...prev,
          messages: [...(prev.messages ?? []), ...toolMessages, newHumanMessage],
        }),
        config: config,
      },
    );

    setInput("");
  };

  const handleRegenerate = (parentCheckpoint: Checkpoint | null | undefined) => {
    // Do this so the loading state is correct
    prevMessageLength.current = prevMessageLength.current - 1;
    setFirstTokenReceived(false);
    stream.submit(undefined, {
      checkpoint: parentCheckpoint,
      streamMode: ["values"],
    });
  };

  const chatStarted = !!threadId || !!messages.length;

  return (
    <div className='flex w-full h-screen overflow-hidden'>
      <div className='relative lg:flex hidden'>
        <motion.div
          className='absolute h-full border-r bg-white overflow-hidden z-20'
          style={{ width: 300 }}
          animate={isLargeScreen ? { x: chatHistoryOpen ? 0 : -300 } : { x: chatHistoryOpen ? 0 : -300 }}
          initial={{ x: -300 }}
          transition={isLargeScreen ? { type: "spring", stiffness: 300, damping: 30 } : { duration: 0 }}
        >
          <div className='relative h-full' style={{ width: 300 }}>
            <ThreadHistory />
          </div>
        </motion.div>
      </div>
      <motion.div
        className={cn("flex-1 flex flex-col min-w-0 overflow-hidden relative", !chatStarted && "grid-rows-[1fr]")}
        layout={isLargeScreen}
        animate={{
          marginLeft: chatHistoryOpen ? (isLargeScreen ? 300 : 0) : 0,
          width: chatHistoryOpen ? (isLargeScreen ? "calc(100% - 300px)" : "100%") : "100%",
        }}
        transition={isLargeScreen ? { type: "spring", stiffness: 300, damping: 30 } : { duration: 0 }}
      >
        {!chatStarted && (
          <div className='absolute top-0 left-0 w-full flex items-center justify-between gap-3 p-2 pl-4 z-10'>
            {(!chatHistoryOpen || !isLargeScreen) && (
              <Button className='hover:bg-gray-100' variant='ghost' onClick={() => setChatHistoryOpen(p => !p)}>
                {chatHistoryOpen ? <PanelRightOpen className='size-5' /> : <PanelRightClose className='size-5' />}
              </Button>
            )}
          </div>
        )}
        {chatStarted && (
          <div className='flex items-center justify-between gap-3 p-2 pl-4 z-10 relative'>
            <div className='flex items-center justify-start gap-2 relative'>
              <div className='absolute left-0 z-10'>
                {(!chatHistoryOpen || !isLargeScreen) && (
                  <Button className='hover:bg-gray-100' variant='ghost' onClick={() => setChatHistoryOpen(p => !p)}>
                    {chatHistoryOpen ? <PanelRightOpen className='size-5' /> : <PanelRightClose className='size-5' />}
                  </Button>
                )}
              </div>
              <motion.button
                className='flex gap-2 items-center cursor-pointer'
                onClick={() => setThreadId(null)}
                animate={{
                  marginLeft: !chatHistoryOpen ? 48 : 0,
                }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 30,
                }}
              >
                <span className='text-xl font-semibold tracking-tight'>Agent Chat</span>
              </motion.button>
            </div>

            <TooltipIconButton size='lg' className='p-4' tooltip='New thread' variant='ghost' onClick={() => setThreadId(null)}>
              <SquarePen className='size-5' />
            </TooltipIconButton>

            <div className='absolute inset-x-0 top-full h-5 bg-gradient-to-b from-background to-background/0' />
          </div>
        )}

        <StickToBottom className='relative flex-1 overflow-hidden'>
          <StickyToBottomContent
            className={cn(
              "absolute inset-0 overflow-y-scroll [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:bg-transparent",
              !chatStarted && "flex flex-col items-stretch mt-[25vh]",
              chatStarted && "grid grid-rows-[1fr_auto]",
            )}
            contentClassName='pt-8 pb-16  max-w-3xl mx-auto flex flex-col gap-4 w-full'
            content={
              <>
                {messages
                  .filter(m => !m.id?.startsWith(DO_NOT_RENDER_ID_PREFIX))
                  .map((message, index) =>
                    message.type === "human" ? (
                      <HumanMessage key={message.id || `${message.type}-${index}`} message={message} isLoading={isLoading} />
                    ) : (
                      <AssistantMessage
                        key={message.id || `${message.type}-${index}`}
                        message={message}
                        isLoading={isLoading}
                        handleRegenerate={handleRegenerate}
                        agent={agent}
                      />
                    ),
                  )}
                {isLoading && !firstTokenReceived && <AssistantMessageLoading agent={agent} />}
              </>
            }
            footer={
              <div className='sticky flex flex-col items-center gap-8 bottom-0 px-4 bg-white'>
                {!chatStarted && (
                  <div className='flex gap-3 items-center'>
                    <h1 className='text-2xl font-semibold tracking-tight'>Agent Chat</h1>
                  </div>
                )}

                <ScrollToBottom className='absolute bottom-full left-1/2 -translate-x-1/2 mb-4 animate-in fade-in-0 zoom-in-95' />

                <div className='bg-muted rounded-2xl border shadow-xs mx-auto mb-8 w-full max-w-3xl relative z-10'>
                  <form onSubmit={handleSubmit} className='grid grid-rows-[1fr_auto] gap-2 max-w-3xl mx-auto'>
                    <textarea
                      value={input}
                      onChange={e => setInput(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === "Enter" && !e.shiftKey && !e.metaKey) {
                          e.preventDefault();
                          const el = e.target as HTMLElement | undefined;
                          const form = el?.closest("form");
                          form?.requestSubmit();
                        }
                      }}
                      placeholder='Type your message...'
                      className='p-3.5 pb-0 border-none bg-transparent field-sizing-content shadow-none ring-0 outline-none focus:outline-none focus:ring-0 resize-none'
                    />

                    <div className='flex items-center justify-between p-2 pt-4'>
                      <div>
                        <div className='flex items-center space-x-2'>
                          <Switch id='render-tool-calls' checked={hideToolCalls ?? false} onCheckedChange={setHideToolCalls} />
                          <Label htmlFor='render-tool-calls' className='text-sm text-gray-600'>
                            Hide Tool Calls
                          </Label>
                        </div>
                      </div>
                      {stream.isLoading ? (
                        <Button key='stop' onClick={() => stream.stop()}>
                          <LoaderCircle className='w-4 h-4 animate-spin' />
                          Cancel
                        </Button>
                      ) : (
                        <Button type='submit' className='transition-all shadow-md' disabled={isLoading || !input.trim()}>
                          Send
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        </StickToBottom>
      </motion.div>
    </div>
  );
}
