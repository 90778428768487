import { Platforms } from "types";

import { IChatMessage } from "../provider";

import GmailUI from "./GmailUI";
import SlackUI from "./SlackUI";
import WhatsAppUI from "./WhatsAppUI";

// Define notification data structure
export interface NotificationData {
  logo: string;
  title: string;
  message: string;
  actionButtons: Array<{ label: string; onClick: () => void }>;
  issueSummary?: string;
  currentStock?: string;
  productId?: string;
  productName?: string;
  timestamp?: string;
}

// Extend IChatMessage to include notification data
export interface NotificationChatMessage extends IChatMessage {
  data: {
    type: "platform_notification";
    platform: Platforms;
    notification: NotificationData;
  };
}

const PlatformNotification = ({ chat, frequencyNotificationPlatForm }: { chat: any; frequencyNotificationPlatForm: string }) => {
  const exampleNotification: NotificationChatMessage = {
    id: "1",
    sender: "assistant",
    message: "platform",
    data: {
      type: "platform_notification",
      platform: frequencyNotificationPlatForm as Platforms,
      notification: {
        logo: frequencyNotificationPlatForm.toLocaleLowerCase() as Platforms,
        title: "Low Inventory Alert: High-Demand Products",
        message: "Hello Jim, The following high-demand products are running low on inventory.",
        actionButtons: [
          { label: "Action Needed", onClick: () => alert("Action Needed Clicked") },
          { label: "View Analysis", onClick: () => alert("View Analysis Clicked") },
        ],
        issueSummary: "Inventory levels critically low. Immediate restock required.",
        currentStock: "50 units",
        productId: "1234",
        productName: "High-Demand Product",
        timestamp: "11:11 AM",
      },
    },
    isActionDisabled: false,
  };
  switch (exampleNotification.data.platform) {
    case Platforms.Slack:
      return <SlackUI data={exampleNotification.data.notification} />;
    case Platforms.WhatsApp:
      return <WhatsAppUI data={exampleNotification.data.notification} />;
    case Platforms.Gmail:
      return <GmailUI data={exampleNotification.data.notification} />;
    default:
      return <p>Unsupported platform</p>;
  }
};

export default PlatformNotification;
