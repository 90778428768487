import { makeAssistantToolUI } from "@assistant-ui/react";

// Define the tool's argument and result types
type ConfirmationArgs = {
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

type ConfirmationResult = {
  confirmed: boolean;
};

export const ConfirmationToolUI = makeAssistantToolUI<ConfirmationArgs, ConfirmationResult>({
  toolName: "confirmation",
  render: ({ args, status, result, addResult }) => {
    if (!status) return null;

    // If we already have a result, show the final state
    if (result) {
      return (
        <div className="confirmation-tool-ui p-4 bg-[#F7F7F8] rounded-lg mb-4">
          <div className="flex items-center gap-2">
            <span className="text-sm text-[#1F2228]">
              {result.confirmed ? "Confirmed" : "Cancelled"}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="confirmation-tool-ui p-4 bg-[#F7F7F8] rounded-lg mb-4">
        <div className="flex flex-col gap-4">
          {/* Message */}
          <div className="text-sm text-[#1F2228]">
            {args.message}
          </div>

          {/* Buttons */}
          <div className="flex gap-3 justify-end">
            <button
              onClick={() => addResult({ confirmed: false })}
              className="px-4 py-2 rounded-lg border border-[#E9EAEC] text-[#1F2228] text-sm hover:bg-[#F1F1F3] transition-colors"
            >
              {args.cancelButtonText || "Cancel"}
            </button>
            <button
              onClick={() => addResult({ confirmed: true })}
              className="px-4 py-2 rounded-lg bg-[#766CE4] text-white text-sm hover:bg-[#5D54B6] transition-colors"
            >
              {args.confirmButtonText || "Confirm"}
            </button>
          </div>
        </div>
      </div>
    );
  }
});
