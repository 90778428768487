import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getConnectedData } from "api/integrations";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch } from "hooks/appHooks";
import { useGetConnectedSourceData } from "hooks/queries/connect-source";

import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import SpinLoader from "shared/components/loader-screen/SpinLoader";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import ConnectionStatus from "./components/ConnectionStatus";

const SourceDetails = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [connectedData, setConnectedData] = useState<any>(null);
  const getBreadCrumbs = () => [
    { path: ROUTES.connectedSources, label: "Connected Sources", active: false },
    { path: "", label: connectedData?.account_name, active: true },
  ];

  const { isLoading, data, isError, error, refetch, isRefetching } = useGetConnectedSourceData(id ? Number(id) : undefined);

  useEffect(() => {
    if (isError) {
      Alert("error", error?.message, "Error");
      console.log("Error in getConnectedData", error);
    }
  }, [id, isError, error]);

  return (
    <DashboardLayout title='Integrations'>
      <div className='py-6 px-5'>
        <Breadcrumbs breadcrumbs={getBreadCrumbs()} />
        {!isLoading && !isRefetching ? (
          <>{data && <ConnectionStatus connectedData={data?.data} getConnectedSource={refetch} />}</>
        ) : (
          <div className='w-full flex justify-center mt-10'>
            <SpinLoader className='w-8 h-8' />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default SourceDetails;
