import cn from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { closePopup, openPopup } from "storage/slices/popup-slice";
import { setDataType } from "storage/slices/upload-budget-slice";

import { Button } from "shared/components/buttons/Button";

import { ReactComponent as CSVIcon } from "assets/icons/csv.svg";
import { ReactComponent as ExcelSheetIcon } from "assets/icons/excelSheet.svg";
import { ReactComponent as GoogleSheetsIcon } from "assets/icons/googlesheets.svg";

const dataSources = [
  {
    icon: <GoogleSheetsIcon />,
    name: "Google Sheets",
    description: "Connect your Google account",
  },
  {
    icon: <CSVIcon />,
    name: "CSV file",
    description: "Upload a CSV file.  (Max. File size: 10 MB)",
  },
  {
    icon: <ExcelSheetIcon />,
    name: "Excel file",
    description: "Upload an Excel file.  (Max. File size: 10 MB)",
  },
];

const DataSource = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { dataType } = useAppSelector(state => state.uploadedBudget);

  return (
    <div className='flex flex-col items-center justify-between h-[589px] bg-[#fff] shadow-sm rounded-3xl pointer-events-auto relative py-16 px-20'>
      <div className='w-full text-center'>
        <h2 className='text-[28px] leading-default font-semibold text-[#1F2228]'>Select data source</h2>
        <p className='text-sm font-medium text-[#5E636E] mt-6'>To get started, upload your budget file to Brainvoy </p>
      </div>
      <div className='flex flex-col gap-3'>
        {dataSources.map(dataSource => (
          <div
            key={dataSource?.name}
            className={twMerge(
              cn("flex w-[635px] gap-4 items-center rounded-2xl cursor-pointer border-[1px] border-[#E9EAEC] py-5 px-6", {
                "border-[#766CE4] shadow-ring1": dataSource.name === dataType,
              }),
            )}
            onClick={() => dispatch(setDataType(dataSource?.name))}
          >
            <div className='p-[10px] border-[0.714px] border-[#DEE9FF] rounded-[11.429px]'>{dataSource?.icon}</div>
            <div className='flex flex-col gap-1'>
              <h3 className='text-[15px] leading-default font-semibold text-[#1F2228]'>{dataSource?.name}</h3>
              <p className='text-sm font-medium text-[#5E636E]'>{dataSource?.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className='flex gap-10 items-center'>
        <p
          className='text-sm leading-default font-semibold text-[#766CE4] underline cursor-pointer'
          onClick={() =>
            dispatch(
              openPopup({
                content: "CreateBudget",
              }),
            )
          }
        >
          Back
        </p>
        <Button
          className='px-[42px] h-9'
          onClick={() => {
            dispatch(closePopup());
            navigate(ROUTES.uploadBudgetForm, { replace: true, state: { dataType, key: Date.now() } });
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default DataSource;
