import React from "react";
import { Control, useForm } from "react-hook-form";

import { REQUIRED_VALIDATION_RULE } from "constants/validation.constants";

import { PersonalizationFormInput } from "interfaces/personalization.interfaces";
import { Option } from "interfaces/shared/form-elements.interfaces";

import { useAppDispatch } from "hooks/appHooks";
import { useLoadPersonalizationOptionsList } from "hooks/personalization/useLoadPersonalizationOptionsList";

import { getMyPersonalizationData, saveMyPersonalizationData } from "storage/actions/personalization";

import { Input } from "shared/components/form-elements/Input";
import { Select } from "shared/components/form-elements/select/Select";
import { SwitchableInput } from "shared/components/form-elements/switchable-input/SwitchableInput";
import { SwitchableInputsGroup } from "shared/components/form-elements/switchable-input/SwitchableInputsGroup";
import Alert from "shared/components/toasts";

import { Button } from "../../shared/components/buttons/Button";

type PersonalizationFormProps = {
  changeStep: any;
  step: number;
};
export const PersonalizationForm = ({ changeStep, step }: PersonalizationFormProps) => {
  const dispatch = useAppDispatch();

  const { personalizationOptions } = useLoadPersonalizationOptionsList();
  const [submitting, setSubmitting] = React.useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PersonalizationFormInput>({
    defaultValues: {
      role_id: "",
      heard_id: "",
      usage_ids: [],
      heard_custom: null,
      size_id: "",
    },
  });

  const watchHeardId = watch("heard_id");

  const onSubmit = handleSubmit(({ role_id, usage_ids, heard_id, heard_custom, size_id }) => {
    setSubmitting(true);
    dispatch(
      saveMyPersonalizationData({
        heard_id: Number(heard_id),
        role_id: Number(role_id),
        usage_ids: usage_ids?.map(id => Number(id)),
        heard_custom: heard_id === 5 ? heard_custom : null,
        company_size_id: Number(size_id),
      }),
    )
      .unwrap()
      .then(() => {
        changeStep(2);
      })
      .catch(err => {
        Alert("error", err?.message, "Error");
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  return (
    <form className='space-y-6 pb-16' onSubmit={onSubmit}>
      <SwitchableInputsGroup
        label='What is your role? *'
        options={personalizationOptions?.roles
          ?.filter((role: Option) => role.hidden === false)
          ?.sort((a: Option, b: Option) => (a.display_order || 0) - (b.display_order || 0))}
        error={errors?.role_id}
        renderContent={props => <SwitchableInput key={props.value} {...register("role_id", { ...REQUIRED_VALIDATION_RULE })} {...props} />}
      />

      <Select
        name='heard_id'
        control={control as unknown as Control}
        label='How did you hear about us? *'
        options={personalizationOptions?.heardSources}
        validation={REQUIRED_VALIDATION_RULE}
      />
      <Select
        name='size_id'
        control={control as unknown as Control}
        label='What size is your company? *'
        options={personalizationOptions?.companySizes}
        validation={REQUIRED_VALIDATION_RULE}
      />
      {watchHeardId === 5 && (
        <Input
          label='Other'
          placeholder='Other'
          {...register("heard_custom", { ...REQUIRED_VALIDATION_RULE })}
          error={errors?.heard_custom}
        />
      )}
      <SwitchableInputsGroup
        label='How are you planning to use Brainvoy in your workflow? *'
        description='Choose as many as you want.'
        options={personalizationOptions?.usages
          ?.filter((usage: Option) => usage.hidden === false)
          ?.sort((a: Option, b: Option) => (a.display_order || 0) - (b.display_order || 0))}
        error={errors?.usage_ids}
        renderContent={props => (
          <SwitchableInput key={props.value} type='checkbox' {...register("usage_ids", { ...REQUIRED_VALIDATION_RULE })} {...props} />
        )}
      />
      {/*TODO - create separate button component with variants*/}
      <div className='flex justify-start !mt-16'>
        <Button className='w-fit' disabled={submitting}>
          {submitting ? "Submitting..." : "Continue"}
        </Button>
        {/*<button className='py-3 px-12 bg-[#6A5EEE] rounded-2xl text-white font-bold'>Continue</button>*/}
      </div>
    </form>
  );
};
