import { useContext, useEffect, useMemo, useState } from "react";

import { Dropdown } from "shared/components/dropdown/Dropdown";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as CategoryIcon } from "assets/icons/category.svg";
import { ReactComponent as TickIcon } from "assets/icons/check-white.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross-black.svg";
import { ReactComponent as CompanyProfileIcon } from "assets/icons/editIcon.svg";

import { cn } from "lib/utils";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

import { CompanyTypeLoader } from "./loadingComponents";

const CompanyType = () => {
  const [editable, setEditable] = useState(false);
  const [companyType, setCompanyType] = useState<string | undefined>(undefined);
  const { loading, apiData, updateApiData, data, isUrlSet } = useContext(SetupCompanyProfileContext);

  const options = useMemo(() => {
    return ["B2B", "B2C", "C2C", "B2G", "C2B", "D2C", "B2B2C", "P2P"].map((item: string) => ({
      id: item,
      label: item,
      action: () => {
        setCompanyType(item);
      },
    }));
  }, []);

  const getType = useMemo(() => {
    if (companyType != undefined) {
      return companyType;
    }

    return apiData.company_type as string | null;
  }, [companyType, apiData]);

  const handleTick = () => {
    updateApiData({ ...apiData, company_type: getType });
    setEditable(false);
  };

  const handleCrossClick = () => {
    setCompanyType(apiData.company_type);
    setEditable(false);
  };

  return (
    <div className='p-6 bg-white rounded-2xl col-span-4 lg:col-span-1'>
      <div className='flex gap-x-4'>
        <div>
          <CategoryIcon />
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className='text-base font-bold text-[#2E323A]'>Company type</p>
            <div className='flex items-center gap-x-2'>
              {!editable ? (
                <button
                  className={cn("p-[7px] rounded-full border border-[#E9EAEC]", { "opacity-30": options?.length === 0 })}
                  disabled={options?.length === 0}
                  onClick={() => setEditable(true)}
                >
                  <CompanyProfileIcon className='h-[18px] w-[18px]' />
                </button>
              ) : (
                <>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC]' onClick={handleCrossClick}>
                    <CrossIcon className='h-[18px] w-[18px]' />
                  </button>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC] bg-purple' onClick={handleTick}>
                    <TickIcon className='h-[18px] w-[18px]' />
                  </button>
                </>
              )}
            </div>
          </div>
          {!loading && isUrlSet == true ? (
            <div className='mt-2'>
              {editable ? (
                <Dropdown
                  items={options}
                  menuBtnStyles='w-full text-left py-1.5 px-3.5 border border-[#E9EAEC] rounded-xl'
                  className='w-[var(--button-width)] border border-[#2C1A4999]'
                >
                  {({ open }) => (
                    <div className='flex items-center justify-between'>
                      <p>{getType}</p>
                      <span>{open ? <ArrowUpIcon className='h-[18px] w-[18px]' /> : <ArrowDownIcon className='h-[18px] w-[18px]' />}</span>
                    </div>
                  )}
                </Dropdown>
              ) : (
                <p className={cn(getType != null && getType != "" ? "text-[#14191A]" : "")}>
                  {getType != null && getType != "" ? getType : "Add type"}
                </p>
              )}
            </div>
          ) : (
            <div className='mt-2'>
              <CompanyTypeLoader isLoading={loading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyType;
