import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams, useRoutes } from "react-router-dom";

import { PerformancePlanFormValues, PerformancePlanLocationState } from "interfaces/performance-plan.interfaces";
import { GenerateWorkflowPayload, Workflow, WorkflowStrategyAdditionalFields } from "interfaces/workflows.interfaces";

import { extractUserDetails, workflowCreationEvent } from "utils/analytics.utils";

import { useCreateWorkflow, useCreateWorkflowQuery, useGetWorkflow } from "hooks/queries/workflows";

import { generateWorkflow } from "storage/actions/workflows";
import { selectWorkflow } from "storage/selectors/workflows";

import Alert from "shared/components/toasts";

import { useAppDispatch, useAppSelector } from "../appHooks";

interface PerformancePlanContextProps {
  // formMethods: ReturnType<typeof useForm>;
  formMethods: any;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  createdWorkflow: Workflow | null;
  typesToRender: WorkflowStrategyAdditionalFields[];
  workflowData: any;
  workflowId: number | undefined;
  workflowProjectId: number | undefined;
  fileId: number | null;
  setFileId: (id: number | null) => void;
  isGenerating: boolean;
}

const PerformancePlanContext = createContext<PerformancePlanContextProps | undefined>(undefined);

export const PerformancePlanProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const mutation = useCreateWorkflow();
  const { state } = useLocation();
  const { id: workflowParamsId } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const [isGenerating, setIsGenerating] = useState(false);

  const templateQueryId = queryParams.get("template_id");
  const projectQueryId = queryParams.get("project_id");
  const strategyId = queryParams.get("strategy_id");

  const initialCreateWorkflowQuery = useCreateWorkflowQuery({
    strategy_id: strategyId ? Number(strategyId) : undefined,
    project_id: projectQueryId ? Number(projectQueryId) : undefined,
  });

  const { data: previousSavedData, isLoading, refetch } = useGetWorkflow(workflowParamsId ? Number(workflowParamsId) : undefined);

  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null);

  // const {
  //   // workflow_id: workflowId,
  //   // additional_fields: additionalFields,
  //   // workflow_data: workflowData,
  //   // project_id: workflowProjectId,
  // } = useAppSelector(selectWorkflow) || {};
  const reduxCreatedWorkflow = useAppSelector(selectWorkflow);

  const createdWorkflow = useMemo(() => {
    if (selectedWorkflow != null) {
      return selectedWorkflow;
    }

    if (workflowParamsId && previousSavedData) {
      return previousSavedData;
    }

    if (initialCreateWorkflowQuery && initialCreateWorkflowQuery.data) {
      return initialCreateWorkflowQuery.data;
    }

    return reduxCreatedWorkflow;
  }, [selectedWorkflow, previousSavedData, isLoading, initialCreateWorkflowQuery.data, reduxCreatedWorkflow]);

  // TODO: check later while project is been use here
  //const { project } = useAppSelector(state => state.project);
  const project = { audience: [], goals: [] };
  const { companyDetails } = useAppSelector(state => state.company);
  const user = useAppSelector(state => state.auth?.user);
  const [isInitiallyReset, setIsInitiallyReset] = useState(false);
  const [isInitiallyValidated, setIsInitiallyValidated] = useState(false);
  const [fileId, setFileId] = useState<null | number>(null);
  const { budget: locationStateBudget, activeDate: locationStateActiveDate, ...rest } = (state || {}) as PerformancePlanLocationState;
  const formMethods = useForm<PerformancePlanFormValues>({ mode: "all" });
  const alreadyPreloaded = useRef(false);
  const { trigger, reset, handleSubmit, formState } = formMethods;

  const additionalFieldsKeysList = useMemo(
    () => Object.keys(createdWorkflow?.additional_fields || {}) as WorkflowStrategyAdditionalFields[],
    [createdWorkflow],
  );

  const isFormErrorsExists = !!Object.keys(formState?.errors)?.length;

  const isSubmitDisabled = Boolean(isFormErrorsExists || !isInitiallyValidated);
  //const isSubmitDisabled = Boolean(isFormErrorsExists || !isInitiallyValidated || (workflowId && workflowData));

  const onSubmit = handleSubmit((values: PerformancePlanFormValues) => {
    if (!createdWorkflow?.workflow_id) {
      const payload: any = {
        additional_fields: values,
      };

      if (state && state.strategy_id) {
        payload.strategy_id = state.strategy_id;
      }

      if (state && state.project_id) {
        payload.project_id = state.project_id;
      }

      if (projectQueryId && templateQueryId && strategyId) {
        payload.strategy_id = Number(strategyId);
        payload.project_id = Number(projectQueryId);
      }

      setIsGenerating(true);
      mutation
        .mutateAsync(payload)
        .then(data => {
          setSelectedWorkflow(data);
          workflowCreationEvent(extractUserDetails(user));
        })
        .catch(error => {
          Alert("error", error.message, "Error");
          console.log("Error", error);
        })
        .finally(() => {
          setIsGenerating(false);
        });

      // dispatch(createWorkflow(payload))
      //   .unwrap()
      //   .then(data => {
      //     dispatch(setIsWorkflowsLoaded(null));

      //   })
      //   .catch(error => {

      //   });

      return;
    }

    console.log("Values", createdWorkflow);

    const payload: GenerateWorkflowPayload = {
      workflow_id: createdWorkflow.workflow_id,
      additional_fields: values,
      ...(fileId && { file_id: fileId }),
    };
    console.log("File Id", payload);
    dispatch(generateWorkflow(payload));

    refetch();
  });

  // useEffect(() => {
  //   if (!workflowParamsId || Number(workflowParamsId) === workflowId) return;

  //   dispatch(getWorkflow(workflowParamsId));
  // }, [dispatch, workflowId, workflowParamsId]);

  useEffect(() => {
    const dataToReset = { ...createdWorkflow?.additional_fields };
    if (!workflowParamsId && !alreadyPreloaded.current) {
      alreadyPreloaded.current = true;
      if (locationStateBudget && additionalFieldsKeysList?.includes("budget")) {
        dataToReset.budget = locationStateBudget;
      }
      if (locationStateActiveDate && additionalFieldsKeysList?.includes("month")) {
        dataToReset.month = locationStateActiveDate;
      }
      if (project?.audience?.[0] && additionalFieldsKeysList?.includes("audience")) {
        dataToReset.audience = project?.audience?.[0];
      }
      if (project?.goals?.[0] && additionalFieldsKeysList?.includes("goals")) {
        dataToReset.goals = project?.goals?.[0];
      }
      if (companyDetails?.website_url && additionalFieldsKeysList?.includes("company_website")) {
        dataToReset.company_website = companyDetails?.website_url;
      }
    }

    console.log("Reset of Data", rest);
    reset(dataToReset);

    if (additionalFieldsKeysList?.length) {
      setIsInitiallyReset(true);
    }
  }, [additionalFieldsKeysList, createdWorkflow, locationStateBudget, reset]);

  useEffect(() => {
    if (isInitiallyReset) trigger().then(() => setIsInitiallyValidated(true));
  }, [isInitiallyReset, trigger]);

  useEffect(() => {
    // if (projectQueryId && templateQueryId && strategyId && selectedWorkflow == null) {
    //   mutation
    //     .mutateAsync({
    //       strategy_id: Number(strategyId),
    //       project_id: Number(projectQueryId),
    //     })
    //     .then(data => {
    //       setSelectedWorkflow(data);
    //     })
    //     .catch(error => {
    //       console.log("Error", error);
    //     });
    // }
  }, []);

  const value = useMemo(() => {
    return {
      formMethods,
      onSubmit,
      isSubmitDisabled,
      createdWorkflow,
      typesToRender: additionalFieldsKeysList,
      workflowData: createdWorkflow?.workflow_data,
      workflowId: createdWorkflow?.workflow_id,
      workflowProjectId: createdWorkflow?.project_id || projectQueryId ? Number(projectQueryId) : undefined,
      fileId,
      setFileId,
      isGenerating,
    };
  }, [
    formMethods,
    onSubmit,
    isSubmitDisabled,
    createdWorkflow,
    additionalFieldsKeysList,
    createdWorkflow?.workflow_data,
    createdWorkflow?.workflow_id,
    createdWorkflow?.project_id,
    projectQueryId,
    fileId,
    setFileId,
    isGenerating,
  ]);

  return <PerformancePlanContext.Provider value={value}>{children}</PerformancePlanContext.Provider>;
};

export const usePerformancePlan = () => {
  const context = useContext(PerformancePlanContext);
  if (!context) {
    throw new Error("usePerformancePlan must be used within an usePerformancePlan");
  }
  return context;
};
