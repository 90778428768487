import { Checkpoint } from "@langchain/langgraph-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { v4 as uuidv4 } from "uuid";

import "./convo.css";

import { AgentItem } from "api/agent/type";

import { AGENT_IMAGES } from "constants/agent.constants";
import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import useIsMobile from "hooks/use-mobile";

import { setConversationId } from "storage/slices/conversation-slice";

import { AssistantMessage, AssistantMessageLoading } from "shared/components/agent-chat-ui/thread/messages/ai";
import { HumanMessage } from "shared/components/agent-chat-ui/thread/messages/human";
import { MyRuntimeProvider } from "shared/components/ai-panel/MyRuntimeProvider";
import MyThread from "shared/components/ai-panel/MyThread";
import { useAI } from "shared/components/ai-panel/provider";
import { ConfirmationToolUI } from "shared/components/ai-panel/tools/ConfirmationToolUI";
import { UtilityTrayProvider } from "shared/components/ai-panel/tools/UtilityTrayProvider";
import { Dropdown } from "shared/components/dropdown/Dropdown";
import { Icons } from "shared/components/icons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { DO_NOT_RENDER_ID_PREFIX } from "lib/ensure-tool-responses";
import { cn } from "lib/utils";
import { useStreamContext } from "providers/Stream";

import cross from "../../assets/icons/histry.svg";

import AgentInfo from "./agent-info/AgentInfo";
import DataPreview from "./components/DataPreview";
import QuickStartTemplates from "./components/QuickStartTemplates";
import WorkFlowNotificationDetails from "./components/WorkFlowNotificationDetails";

const Conversation = () => {
  const [searchParams] = useSearchParams();
  const agentUuid = searchParams.get("agent_uuid");

  const navigate = useNavigate();
  const [isFrequencyNotification, setIsFrequencyNotification] = useState(false);
  const isMobile = useIsMobile(1100);
  const agents = useAppSelector(state => state.agent.agents);
  const isDataPreviewOpen = useAppSelector(state => state.integrations.isDataPreviewOpen);

  const agent = useMemo(() => agents?.find((agent: AgentItem) => agent.uuid === String(agentUuid)), [agents, agentUuid]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConversationId(uuidv4()));
  }, [dispatch, isMobile]);

  const dropdownOptions = agents.map(({ id, name, title }) => ({
    id: String(id),
    label: title,
    title: name,
    action: () => console.log("action1"),
  }));

  const handleSwitchAgents = () => (
    <div className='ml-6'>
      <Dropdown
        className='min-w-[200px] ml-8 !top-[60px] !left-14'
        placement='top end'
        items={dropdownOptions}
        onSelectOption={label => handleDropdownClick(label)}
      >
        <Icons.ArrowDown />
      </Dropdown>
    </div>
  );

  const handleDropdownClick = (label: string) => {
    navigate(ROUTES.dashboard, { state: { selectedLabel: label } });
    // Perform any action you want based on the selected label
  };

  return (
    <UtilityTrayProvider>
      <div className='flex w-full h-full'>
        <DashboardLayout
          title='Operations Specialist'
          titleClassName='text-[#333740]'
          headerExtraContent={handleSwitchAgents()}
          containerClassName='bg-white'
        >
          <ConversationContent agent={agent} isFrequencyNotification={isFrequencyNotification} />
        </DashboardLayout>
        {isDataPreviewOpen && <DataPreview setIsFrequencyNotification={setIsFrequencyNotification} />}
      </div>
    </UtilityTrayProvider>
  );
};

const ConversationContent = ({ agent, isFrequencyNotification }: { agent?: AgentItem | null; isFrequencyNotification: boolean }) => {
  const {
    isOpen,
    chats,
    openPanel,
    setWhatShouldIAsk,
    setIsBudgetIntelligence,
    setIsReadOnly,
    isBack,
    setIsBack,
    isWorkflow,
    setIsWorkflow,
    setIsFlag,
  } = useAI();
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const isThread = searchParams.get("threadId");

  // Auto-scroll when new messages arrive

  // const { openTray, closeTray, toggleTray } = useUtilityTray();
  const [agentUuid] = useQueryParam("agent_uuid", StringParam);
  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [firstTokenReceived, setFirstTokenReceived] = useState(false);
  const agents = useAppSelector(state => state.agent.agents);
  const agentL = useMemo(() => agents?.find((agent: AgentItem) => agent.uuid === String(agentUuid)), [agents, agentUuid]);
  const stream = useStreamContext();
  const messages = stream.messages;
  const isLoading = stream.isLoading;
  const messagesEndRef = useRef<HTMLDivElement>(null);

  console.log("Messages", messages, stream);

  const Image = AGENT_IMAGES?.[agentL?.image_key as keyof typeof AGENT_IMAGES];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    // Scroll whenever messages change
    scrollToBottom();
  }, [messages, isLoading]);

  useEffect(() => {
    if (chats.length > 0) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
    if (isOpen == false && isFrequencyNotification) {
      setIsBudgetIntelligence(false);
      setWhatShouldIAsk(false);
      openPanel();
    }
  }, [isOpen, openPanel, chats]);

  useEffect(() => {
    const handlePopState = () => {
      if (isBack) {
        setIsBack(false); // Reset state
        setIsWorkflow(false);
        // stream.messages = []; // Clear messages from stream
        // window.location.reload(); // Reload page
      }
    };
    if (isBack) {
      window.history.pushState(null, "", window.location.href);
    }
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location, isBack]);

  const prevMessageLength = useRef(0);
  useEffect(() => {
    if (messages.length !== prevMessageLength.current && messages?.length && messages[messages.length - 1].type === "ai") {
      setFirstTokenReceived(true);
    }

    prevMessageLength.current = messages.length;
  }, [messages]);

  const handleRegenerate = (parentCheckpoint: Checkpoint | null | undefined) => {
    // Do this so the loading state is correct
    prevMessageLength.current = prevMessageLength.current - 1;
    setFirstTokenReceived(false);
    stream.submit(undefined, {
      checkpoint: parentCheckpoint,
      streamMode: ["values"],
    });
  };

  return (
    <div
      className={
        !isBack && !isThread
          ? cn("flex w-full max-w-3xl h-full p-6 ", isWorkflow && isThread ? "justify-start" : "flex-col justify-center mx-auto")
          : isWorkflow && isThread
            ? "myCont"
            : "myContainer"
      }
    >
      {isWorkflow && isThread ? (
        <div
          className={`w-[120vw] border-r border-[#E9EAEC] transition-opacity ${
            left ? "pointer-events-none opacity-30" : "pointer-events-auto opacity-100"
          }`}
        >
          <WorkFlowNotificationDetails agent={agent} />
        </div>
      ) : (
        <div className={cn("flex items-start justify-center w-full max-w-xl mb-10")}>
          <AgentInfo agent={agent} />
        </div>
      )}
      <div className={`transition-opacity ${right ? "pointer-events-none opacity-30" : "pointer-events-auto opacity-100"} `}>
        {(isBack || isThread) && (
          <>
            {isWorkflow && (
              <div className='flex justify-between items-center border-b border-[#E9EAEC] px-6 py-4 mb-8'>
                <div className='flex items-center gap-2'>
                  <div>
                    <img src={Image} className='h-8 w-8 rounded-full object-cover' />
                  </div>
                  <div className='font-bold text-[18px] text-[#1F2228]'>{agentL?.name}</div>
                </div>
                <div className='cursor-pointer' onClick={() => setLeft(!left)}>
                  <img src={cross} alt='crs' />
                </div>
              </div>
            )}

            <div
              ref={containerRef} // Attach ref to the container
              className={`${isWorkflow ? "max-h-[calc(100vh-356px)] px-8" : "max-h-[calc(100vh-405px)]"} overflow-y-auto flex flex-col`}
              style={{ scrollbarWidth: "none" }}
            >
              {messages
                .filter(m => !m.id?.startsWith(DO_NOT_RENDER_ID_PREFIX))
                .map((message, index) =>
                  message.type === "human" ? (
                    <HumanMessage key={message.id || `${message.type}-${index}`} message={message} isLoading={isLoading} />
                  ) : (
                    <AssistantMessage
                      key={message.id || `${message.type}-${index}`}
                      message={message}
                      isLoading={isLoading}
                      handleRegenerate={handleRegenerate}
                      agent={agentL}
                    />
                  ),
                )}
              {isLoading && !firstTokenReceived && <AssistantMessageLoading agent={agentL} />}
              <div ref={messagesEndRef} />
            </div>
          </>
        )}
        {/* <UtilityTray/> */}
        <MyRuntimeProvider>
          <MyThread />
          {/*<DataSourcesToolUI />*/}
          <ConfirmationToolUI />
        </MyRuntimeProvider>
      </div>

      {!isBack && !isThread && <QuickStartTemplates />}
    </div>
  );
};

export default Conversation;
