import { useQuery } from "@tanstack/react-query";

import { getOperationReportsId } from "api/operation-report";

export const projectQueryKeys = {
  getOperationReport: (id?: number) => ["getOperationReport", id] as const,
};

export const useGetOperationReport = (id?: number) =>
  useQuery({
    queryKey: projectQueryKeys.getOperationReport(id),
    queryFn: async (): Promise<any> => {
      if (!id) {
        throw new Error("notification id is required");
      }

      const data = await getOperationReportsId(id);
      return data?.data;
    },
  });
