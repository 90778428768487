import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import remarkGfm from "remark-gfm";
import { v4 as uuidv4 } from "uuid";

import { AGENT_IMAGES, AGENT_MOCK_RESPONSE } from "constants/agent.constants";

import { useGetConversationHistory } from "hooks/queries/conversations";

import { setConversationId } from "storage/slices/conversation-slice";
import { setIsDataPreviewOpen } from "storage/slices/integrations-slice";

import { cn } from "lib/utils";

import { Button } from "../buttons/Button";
import DynamicMultiInput from "../dynamic-multi-input/dynamic-multi-input";
import { Input } from "../form-elements/Input";
import { Icons } from "../icons";
import SpinLoader from "../loader-screen/SpinLoader";
import SuggestedQuestions from "../suggested-questions/SuggestedQuestions";

import ConnectDataSources from "./ConnectDataSources";
import PlatformNotification, { NotificationChatMessage } from "./data-preview";
import { dummyChat } from "./dummyChat";
import { ChatHistory, useAI, IChatMessage, ChatHistoryConversation } from "./provider";

// import SpinLoader from "../loader-screen/SpinLoader";
// import SuggestedQuestions from "../suggested-questions/SuggestedQuestions";
// import TextTypingEffect from "../text-typing-effect/TextTypingEffect";

interface Props {
  className?: string;
  asSheet?: boolean;
  inputWrapperClassName?: string;
  isStandalone?: boolean;
}

export default function AIPanel({ ...props }: Props) {
  if (props.asSheet == true) {
    return (
      <div className='absolute top-0 right-0 z-50 h-full pt-5'>
        <AIPanelContent {...props} className={cn(props.className, "shadow-ai-panel rounded-tl-2xl overflow-hidden")} />
      </div>
    );
  }

  return <AIPanelContent {...props} />;
}

const AIPanelContent = ({ className, isStandalone = false, inputWrapperClassName }: Props) => {
  const {
    page,
    switchPage,
    showAsSheet,
    closePanel,
    chats,
    agent,
    isOpen,
    sendMessage,
    isReadOnly,
    isSending,
    connecting,
    isStreaming,
    processingMessage,
    showWhatShouldIAsk,
    showBudgetFormDetailInput,
    showScrollButton,
    showTopGradient,
    chatContainerRef,
    messagesEndRef,
    scrollToBottom,
    handleScrollToBottom,
    customTextFieldQuestions,
    messageContextInput,
    setMessageContextInput,
    frequencyNotificationPlatForm,
    handleAddOrUpdateChat,
    description, // Add this line to destructure the function,
    setDescription,
    setIsAnime,
    setIsBack
  } = useAI();


  console.log(description)

  const { data: chatHistoryData } = useGetConversationHistory();

  const [multiInputQuestion, setMultiInputQuestion] = useState("");
  const [historySearchQuery, setHistorySearchQuery] = useState("");
  const [isAnimating, setIsAnimating] = useState(false);
  const [chatSequence, setChatSequence] = useState<IChatMessage[]>(dummyChat);

  const [currentSequenceIndex, setCurrentSequenceIndex] = useState(-1);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        e.preventDefault();

        if (currentSequenceIndex < chatSequence.length - 1) {
          const nextIndex = currentSequenceIndex + 1;
          setCurrentSequenceIndex(nextIndex);

          // Add the next message from the sequence to the chat
          const nextMessage = chatSequence[nextIndex];
          handleAddOrUpdateChat(nextMessage);
        }
      }
    },
    [currentSequenceIndex, chatSequence, handleAddOrUpdateChat],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setChatSequence(prevSequence => {
      // Combine dummy chat with any additional messages you want to show
      return [
        ...dummyChat,
        // Add more predefined messages as needed
      ];
    });
  }, []);

  const groupedChats = useMemo(() => {
    if (chats.length === 0) {
      return [];
    }
    let newChats: IChatMessage[] = [];
    if (frequencyNotificationPlatForm) {
      newChats = [
        ...newChats,
        {
          id: uuidv4(),
          sender: "assistant",
          message: "",
          data: { type: "platform_notification" },
        },
      ];
    }
    return [...chats, ...newChats].reduce(
      (groups, chat, index) => {
        const prevMessage = chats[index - 1];
        if (prevMessage && prevMessage.sender === chat.sender) {
          groups[groups.length - 1].push(chat);
        } else {
          groups.push([chat]);
        }
        return groups;
      },
      [] as Array<typeof chats>,
    );
  }, [chats, frequencyNotificationPlatForm]);

  const histories = useMemo(() => {
    if (chatHistoryData == undefined || chatHistoryData.count == 0) {
      return [];
    }

    /// group by date
    const groupedHistories = chatHistoryData.rows.reduce(
      (acc, history) => {
        const date = new Date(history.createdAt);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        let group;
        if (date.toDateString() === today.toDateString()) {
          group = "Today";
        } else if (date.toDateString() === yesterday.toDateString()) {
          group = "Yesterday";
        } else if (date > new Date(today.setDate(today.getDate() - 7))) {
          group = "Previous 7 days";
        } else if (date > new Date(today.setDate(today.getDate() - 30))) {
          group = "Previous 30 days";
        } else {
          group = date.toLocaleDateString("en-US", { month: "long" });
        }

        if (!acc[group]) {
          acc[group] = [];
        }

        if (historySearchQuery.length > 0) {
          const query = historySearchQuery.toLowerCase();
          const matchesQuery = history.messages.some(message => message.message.toLowerCase().includes(query));

          if (matchesQuery) {
            acc[group].push(history);
          }
        } else {
          acc[group].push(history);
        }
        return acc;
      },
      {} as Record<string, ChatHistoryConversation[]>,
    );

    return Object.keys(groupedHistories).map(date => ({
      date,
      chats: groupedHistories[date],
    }));
  }, [chatHistoryData, historySearchQuery]);

  const handleHistorySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHistorySearchQuery(e.target.value);
  };

  const handleCloseSheet = useCallback(() => {
    closePanel();
  }, [showAsSheet]);

  const handleSwitchPage = useCallback(() => {
    switchPage(page === "active" ? "history" : "active");
  }, [switchPage, page]);

  const isConversationWithAgent = useMemo(() => {
    return location.pathname.includes("conversation");
  }, [location.pathname]);

  const triggerRotateBounce = () => {
    if (chats.length > 0) return;
    setIsAnimating(true);
    if(!description)setIsAnime(true)
    // Reset animation state after the duration (1.5s) to allow re-triggering
    setTimeout(() => {
      setIsAnimating(false);
      setIsAnime(false)
    }, 1500);
  };

  const handleSendMessage = async () => {
    // setMessageContextInput(description);
    // if (messageContextInput.trim() === "" || connecting) return;

    sendMessage(description);
    setDescription("");
    scrollToBottom();
    setIsBack(true)
    // setMessageContextInput("");
    
  };

  useEffect(() => {
    if (isConversationWithAgent && messageContextInput && !chats.length) {
      handleSendMessage();
    }
  }, [isConversationWithAgent, messageContextInput]);

  const handleQuestionSelected = (question: string) => {
    if (question.includes("[")) {
      setMessageContextInput("");
      setMultiInputQuestion(question);
    } else {
      setMultiInputQuestion("");
      setMessageContextInput(question);
    }
  };

  const isChatBudgetFormInputRequired = useMemo(() => {
    return (
      showBudgetFormDetailInput.description == true ||
      showBudgetFormDetailInput.revenue_range == true ||
      showBudgetFormDetailInput.product_and_service == true ||
      customTextFieldQuestions.length > 0
    );
  }, [showBudgetFormDetailInput, customTextFieldQuestions]);

  const getChatContentMaxHeight = useMemo(() => {
    let maxHeight = 190;

    if (showBudgetFormDetailInput.description == true) {
      maxHeight += 95;
    }

    if (showBudgetFormDetailInput.product_and_service == true) {
      maxHeight += 50;
    }

    if (showBudgetFormDetailInput.revenue_range == true) {
      maxHeight += 95;
    }

    if (customTextFieldQuestions.length > 0) {
      maxHeight += 90 * customTextFieldQuestions.length;
    }

    return maxHeight;
  }, [showBudgetFormDetailInput, customTextFieldQuestions]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const sendButtonDisabled = isSending || messageContextInput.length <= 0 || connecting || isStreaming;

  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];
  return (
    <div
      className={cn(
        "h-full  border-l-2 border-[#f7f9fb] bg-white",
        showAsSheet ? "!w-[700px] !max-w-[700px]" : "!w-[600px] !max-w-[600px]",
        className,
      )}
    >
      <div className='relative flex flex-col h-full w-full justify-between overflow-hidden'>
        <div className='absolute top-0 w-full z-50'>
          {isOpen && (
            <div className='flex flex-col flex-shrink-0 bg-white'>
              <div className='flex items-center justify-between px-6 py-3.5 h-[4rem] border-b-2 border-[#f7f9fb]'>
                <div className='flex items-center gap-2 '>
                  <img src={Image} alt={agent?.name} className='w-[32px] h-[32px] rounded-full object-cover' />
                  <h3 className='text-[#1F2228] font-bold text-lg'>{agent?.name}</h3>
                  <span className='rounded-[22px] bg-[#FFE493] font-semibold text-[#8F6E0A] px-[10px] text-[11px] uppercase flex items-center justify-center'>
                    Beta
                  </span>
                </div>
                <div className='flex flex-row items-center gap-2'>
                  <Tooltip
                    id='chat-history-tooltip'
                    positionStrategy='fixed'
                    place='top'
                    opacity={1}
                    style={{
                      borderRadius: "13px",
                      background: "#2E323A",
                      boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                      zIndex: 1000,
                      fontSize: "12px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Chat History</span>
                    </div>
                  </Tooltip>
                  <button
                    onClick={handleSwitchPage}
                    data-tooltip-id='chat-history-tooltip'
                    className={cn("px-2.5 py-2 rounded-md cursor-pointer hover:bg-[#F1F1F1]", page === "history" && "bg-[#F1F1F1]")}
                  >
                    <Icons.History className='text-[#717684] h-4 w-4' />
                  </button>

                  {showAsSheet && (
                    <>
                      <Tooltip
                        id='close-chat-tooltip'
                        positionStrategy='fixed'
                        place='top'
                        opacity={1}
                        style={{
                          borderRadius: "13px",
                          background: "#2E323A",
                          boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                          zIndex: 1000,
                          fontSize: "12px",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span>Close Chat</span>
                        </div>
                      </Tooltip>
                      <div className='h-4 w-[1px] bg-[#E9EAEC]'></div>
                      <button
                        data-tooltip-id='close-chat-tooltip'
                        onClick={handleCloseSheet}
                        className={cn("px-2.5 py-2 rounded-md cursor-pointer")}
                      >
                        <Icons.X className='text-[#717684] h-4 w-4' />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* {connecting && (
            <div className='h-[28px] flex items-center justify-center gap-4 z-50 border-b border-[#E9EAEC] bg-white'>
              <SpinLoader className='h-4 w-4' /> <p>Connecting...</p>
            </div>
          )} */}
          {page === "active" && showTopGradient && (
            <div className={cn("h-28 w-full", isOpen ? "bg-ai-panel-chat" : "bg-ai-panel-gradient")}></div>
          )}
        </div>
        {page === "active" && (
          <>
            <div
              className={cn("h-full  w-full relative")}
              style={{
                maxHeight: `calc(100% - ${getChatContentMaxHeight}px)`,
              }}
            >
              {showWhatShouldIAsk == true && <SuggestedQuestions onSelected={handleQuestionSelected} containerStyles='px-6' />}
              <div
                className={cn(
                  "w-full",
                  showTopGradient
                    ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
                    : " overflow-hidden",
                  isStandalone && chats.length > 0 ? "h-[calc(100vh-410px)]" : "h-full",
                )}
                ref={chatContainerRef}
                onScroll={handleScrollToBottom}
              >
                <div className={cn("relative flex flex-col gap-2 w-full justify-end min-h-full", showTopGradient ? "" : " ")}>
                  {showTopGradient && chats.length > 4 && <div className='h-[100px] w-full'></div>}
                  {groupedChats.map((group, groupIndex) => {
                    return (
                      <React.Fragment key={`group-${groupIndex}`}>
                        {group.map((chat, chatIndex) => {
                          const showDivider = groupIndex === groupedChats.length - 1 && group.length > 2 && chatIndex === group.length - 1;
                          return (
                            <React.Fragment key={`chat-${chat.id}`}>
                              {showDivider && (
                                <div className='flex w-full max-w-full items-end gap-2 px-6 py-2'>
                                  <div className='w-[32px] h-[2px] flex-shrink-0'></div>
                                  <div className='h-[2px] bg-gradient-to-r from-[#E534D5] via-[#E534D5] to-[#6659FF] w-full'></div>
                                </div>
                              )}
                              <ChatMessage
                                key={chat.id || `${groupIndex}-${chatIndex}`}
                                chat={chat}
                                isSuggestion={false}
                                showAvatar={chatIndex === group.length - 1 && chat.sender === "assistant"}
                              />
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}

                  {isSending && (
                    <AIGenerateMessage>
                      <p className='text-[#717684] text-sm font-medium'>{agent?.name} is thinking...</p>
                    </AIGenerateMessage>
                  )}
                  {processingMessage && (
                    <AIGenerateMessage>
                      <p className='text-[#717684] text-sm font-medium'>{processingMessage}</p>
                    </AIGenerateMessage>
                  )}
                </div>
                <div ref={messagesEndRef} />
              </div>
              {showScrollButton && (
                <Button
                  onClick={scrollToBottom}
                  className='absolute bottom-12 right-8 !h-[40px] !w-[40px] rounded-full p-2 before:!border-none'
                >
                  <Icons.ArrowDown className='h-4 w-4 text-white' />
                </Button>
              )}
            </div>
            <div className='py-3'>
              <div
                onClick={triggerRotateBounce}
                onMouseEnter={() =>description ? setIsAnime(false) : setIsAnime(true)}   // Set to true on hover
                onMouseLeave={() => setIsAnime(false)}  // Reset to false on hover release
                className={cn(
                  "mx-6 mt-2 mb-4 bg-[#F7F7F8] rounded-2xl p-6",
                  isChatBudgetFormInputRequired == true ? "h-auto" : "h-[140px]",
                  inputWrapperClassName,
                  // isAnimating ? "animate-rotate-bounce" : "",
                )}
              >
                {isChatBudgetFormInputRequired == true ? (
                  <ChatBudgetFormDetailInput />
                ) : (
                  <>
                    {multiInputQuestion ? (
                      <DynamicMultiInput question={multiInputQuestion} updateText={setMessageContextInput} />
                    ) : (
                      <textarea
                        readOnly={isReadOnly}
                        placeholder='Ask me anything...'
                        autoCorrect='false'
                        autoComplete='false'
                        data-custom='no-ai'
                        value={description}
                        // onChange={e => setDescription(e.target.value)}
                        className='relative resize-none bg-transparent w-full focus:ring-0 focus:ring-offset-0 outline-none border-none text-sm text-[#1F2228] font-medium placeholder:text-[#717684] min-h-[55px]'
                      />
                    )}
                  </>
                )}

                <div className='flex w-full justify-between'>
                  <div className='flex gap-2'>
                    <button disabled={true} className='group'>
                      <Icons.Attachment className='!text-[#717684] group-disabled:!text-[#C8CAD0]' />
                    </button>
                    <button disabled={true} className='group'>
                      <Icons.Image className='!text-[#717684] group-disabled:!text-[#C8CAD0]' />
                    </button>
                    <Tooltip
                      id='chat-question-tooltip'
                      positionStrategy='fixed'
                      place='top'
                      opacity={1}
                      style={{
                        borderRadius: "13px",
                        background: "#2E323A",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                        zIndex: 1000,
                        fontSize: "12px",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>Browse questions</span>
                      </div>
                    </Tooltip>
                    <button disabled={true} data-tooltip-id='chat-question-tooltip' className='group'>
                      <Icons.QuestionSquare className='!text-[#717684] group-disabled:!text-[#C8CAD0]' />
                    </button>
                  </div>
                  <Button
                      onClick={handleSendMessage}
                      disabled={!description}
                      className={cn(
                       "!p-[7px] !rounded-full before:!border-none !w-auto group flex items-center justify-center", 
                     {
                       "!bg-[#E9EAEC] cursor-not-allowed": !description, // Disabled state
                       "!bg-purple": description, // Enabled state
                    }
                     )}
                  >
  <Icons.Send 
    className={cn("!text-lg", { 
      "!text-[#C8CAD0]": !description, // Disabled icon color
      "!text-white": description, // Enabled icon color
    })} 
  />
</Button>
                  {/* <Button
                    onClick={handleSendMessage}
                    disabled={!description}
                    className={cn("!p-[7px] !bg-purple !rounded-full before:!border-none !w-auto group", {
                      "!bg-[#E9EAEC]": sendButtonDisabled,
                    })}
                  >
                    <Icons.Send className={cn("!text-[#C8CAD0]", { "!text-white": !sendButtonDisabled })} />
                  </Button> */}
                </div>
              </div>
            </div>
          </>
        )}
        {page === "history" && (
          <div className='h-full w-full flex flex-col gap-4 mt-20 mb-4'>
            <div className='mx-6 mt-4'>
              <Input placeholder='Search' icon={Icons.Search02} onChange={handleHistorySearch} />
            </div>
            <div className='text-[#1F2228] pb-8 w-full h-full max-h-[calc(100%-80px)] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 flex-shrink-0'>
              <div className='flex flex-col gap-3'>
                {histories.map((history, index) => {
                  return (
                    <>
                      <ChatHistoryGroup key={index} history={history} />
                      {index < histories.length - 1 && <div className='h-[2px] bg-[#F7F7F8] w-full'></div>}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ChatBudgetFormDetailInput = () => {
  const {
    showBudgetFormDetailInput,
    handleAddOrUpdateChat,
    handleShowOrHideBudgetFormDetailInput,
    handleUpdateUserFeedbackNeeded,
    updateBudgetFormState,
    sendConfirmationResponse,
    sendMessage,
    customTextFieldQuestions,
    handleUpdateCustomTextFieldQuestions,
  } = useAI();
  const [description, setDescription] = useState(showBudgetFormDetailInput.defaultDescriptionValue ?? "");
  const [revenue, setRevenue] = useState(showBudgetFormDetailInput.defaultRevenueRangeValue ?? "");
  const [productAndService, setProductAndService] = useState(showBudgetFormDetailInput.defaultProductAndServiceValue ?? "");
  const [customTextFieldQuestionsState, setCustomTextFieldQuestionsState] = useState<
    Array<{
      id: string;
      answer: string;
    }>
  >([]);

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(e.target.value);
    },
    [description],
  );

  const handleRevenueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRevenue(e.target.value);
    },
    [revenue],
  );

  const handleProductAndServiceChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setProductAndService(e.target.value);
    },
    [productAndService],
  );

  const sendAdditionalInfo = useCallback(() => {
    handleAddOrUpdateChat({
      id: uuidv4(),
      sender: "assistant",
      message: AGENT_MOCK_RESPONSE.EditCompanyInfoResponse,
    });
  }, [showBudgetFormDetailInput]);

  const sendAdditionalInfo2 = useCallback(() => {
    handleAddOrUpdateChat({
      id: uuidv4(),
      sender: "assistant",
      message: AGENT_MOCK_RESPONSE.SelectGoalResponse,
    });
  }, [showBudgetFormDetailInput]);

  const handleSendFormConfirmationResponse = useCallback(() => {
    sendConfirmationResponse({
      responses: {
        products_and_services: productAndService,
        revenue_range: revenue,
        description: description,
      },
      isSending: false,
    });
    const message = `Here are the revised details:
**Company Description:**
${description}
**Revenue Range:**
${revenue}
**Products & Services:**
${productAndService}`;
    sendMessage(message, false);
  }, [productAndService, description, revenue]);

  const handleSaveDescription = useCallback(
    (isSave: boolean) => {
      if (isSave == true) {
        // send info to the form reducer
        updateBudgetFormState?.(prev => {
          return {
            ...prev,
            product_and_service: productAndService,
            revenue,
            description,
          };
        });
      }

      if (showBudgetFormDetailInput.revenue_range == true && showBudgetFormDetailInput.product_and_service == true) {
        sendAdditionalInfo();
      }

      if (showBudgetFormDetailInput.revenue_range == false && showBudgetFormDetailInput.product_and_service == false) {
        handleUpdateUserFeedbackNeeded(false);

        //setTimeout(() => {
        //  sendAdditionalInfo2();
        //}, 1000);

        handleSendFormConfirmationResponse();
      }

      // hide the description input
      handleShowOrHideBudgetFormDetailInput({
        ...showBudgetFormDetailInput,
        description: false,
        revenue_range: showBudgetFormDetailInput.revenue_range,
        product_and_service: showBudgetFormDetailInput.product_and_service,
      });

      handleAddOrUpdateChat({
        id: uuidv4(),
        sender: "user",
        message: `**Company Description:** ${description}`,
      });
    },
    [description, showBudgetFormDetailInput],
  );

  const handleSaveRevenue = useCallback(
    (isSave: boolean) => {
      if (isSave == true) {
        // send info to the form reducer
        updateBudgetFormState?.(prev => {
          return {
            ...prev,
            product_and_service: productAndService,
            revenue,
            description,
          };
        });
      }

      if (showBudgetFormDetailInput.description == true && showBudgetFormDetailInput.product_and_service == true) {
        sendAdditionalInfo();
      }

      if (showBudgetFormDetailInput.description == false && showBudgetFormDetailInput.product_and_service == false) {
        handleUpdateUserFeedbackNeeded(false);

        //setTimeout(() => {
        //  sendAdditionalInfo2();
        //}, 1000);

        handleSendFormConfirmationResponse();
      }

      // hide the revenue input
      handleShowOrHideBudgetFormDetailInput({
        ...showBudgetFormDetailInput,
        description: showBudgetFormDetailInput.description,
        revenue_range: false,
        product_and_service: showBudgetFormDetailInput.product_and_service,
      });

      handleAddOrUpdateChat({
        id: uuidv4(),
        sender: "user",
        message: `**Revenue Range:** ${revenue}`,
      });
    },
    [revenue, showBudgetFormDetailInput],
  );

  const handleSaveProductAndService = useCallback(
    (isSave: boolean) => {
      if (isSave == true) {
        // send info to the form reducer
        updateBudgetFormState?.(prev => {
          return {
            ...prev,
            product_and_service: productAndService,
            revenue,
            description,
          };
        });
      }

      if (showBudgetFormDetailInput.revenue_range == true && showBudgetFormDetailInput.description == true) {
        sendAdditionalInfo();
      }

      if (showBudgetFormDetailInput.revenue_range == false && showBudgetFormDetailInput.description == false) {
        handleUpdateUserFeedbackNeeded(false);

        //setTimeout(() => {
        //  sendAdditionalInfo2();
        //}, 1000);

        handleSendFormConfirmationResponse();
      }

      // hide the product and service input
      handleShowOrHideBudgetFormDetailInput({
        ...showBudgetFormDetailInput,
        description: showBudgetFormDetailInput.description,
        revenue_range: showBudgetFormDetailInput.revenue_range,
        product_and_service: false,
      });

      handleAddOrUpdateChat({
        id: uuidv4(),
        sender: "user",
        message: `**Products & Services:** ${productAndService}`,
      });
    },
    [productAndService, showBudgetFormDetailInput],
  );

  const handleSaveCustomTextField = useCallback(
    (id: string, isSaved: boolean) => {
      handleUpdateCustomTextFieldQuestions({ id: id, label: "" });

      if (isSaved == true) {
        const answer = customTextFieldQuestionsState.find(item => item.id === id)?.answer;

        const question = customTextFieldQuestions.find(item => item.id === id)?.label;

        if (!answer || !question) return;

        handleAddOrUpdateChat({
          id,
          sender: "user",
          message: answer,
        });

        sendConfirmationResponse({
          responses: {
            [question]: answer,
          },
          isSending: false,
        });
      }
    },
    [customTextFieldQuestions, customTextFieldQuestionsState],
  );

  const handleSaveCustomFieldAnswer = useCallback(
    (id: string, value: string) => {
      if (!value || value.length === 0) return;

      const question = customTextFieldQuestions.find(question => question.id === id);

      if (question) {
        setCustomTextFieldQuestionsState(prev => {
          const prevAnswer = prev.find(item => item.id === id);

          if (prevAnswer) {
            return prev.map(item => {
              if (item.id === id) {
                return { id, answer: value };
              }

              return item;
            });
          }

          return [...prev, { id, answer: value }];
        });
      }
    },
    [customTextFieldQuestionsState, customTextFieldQuestions],
  );

  return (
    <div className='flex flex-col gap-4 mb-2'>
      {customTextFieldQuestions.map((question, index) => (
        <div className='flex flex-col gap-1' key={index}>
          <div className='flex items-center justify-between'>
            <div className='flex mr-auto w-full'>
              <h3 className='font-bold text-sm line-clamp-1'>{question.label}</h3>
            </div>
            <div className='flex items-center gap-2'>
              <button
                className='h-6 w-6 rounded-full bg-[#E9EAEC] inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveCustomTextField(question.id, false)}
              >
                <Icons.X className='h-[14px] w-[14px] text-[#5E636E]' />
              </button>
              <button
                className='h-6 w-6 rounded-full bg-purple inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveCustomTextField(question.id, true)}
              >
                <Icons.Check className='h-[14px] w-[14px] text-white' />
              </button>
            </div>
          </div>
          <div className='h-[66px] bg-[#ECEAFF] rounded-[16px] p-1'>
            <div className='flex items-center bg-white h-full border border-[#766CE4]  rounded-[16px] py-1.5 px-2.5'>
              <textarea
                placeholder={question.label}
                onChange={e => handleSaveCustomFieldAnswer(question.id, e.target.value)}
                className='placeholder:text-[#9EA2AD] h-full w-full placeholder:font-medium placeholder:text-sm text-[#1F2228] outline-none text-sm font-medium focus-visible:ring-0 resize-none scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600'
              />
            </div>
          </div>
        </div>
      ))}
      {showBudgetFormDetailInput.description == true && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <div className='flex mr-auto'>
              <h3 className='font-bold text-sm'>Company description </h3>
            </div>
            <div className='flex items-center gap-2'>
              <button
                className='h-6 w-6 rounded-full bg-[#E9EAEC] inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveDescription(false)}
              >
                <Icons.X className='h-[14px] w-[14px] text-[#5E636E]' />
              </button>
              <button
                className='h-6 w-6 rounded-full bg-purple inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveDescription(true)}
              >
                <Icons.Check className='h-[14px] w-[14px] text-white' />
              </button>
            </div>
          </div>
          <div className='h-[66px] bg-[#ECEAFF] rounded-[16px] p-1'>
            <div className='flex items-center bg-white h-full border border-[#766CE4]  rounded-[16px] py-1.5 px-2.5'>
              <textarea
                value={description}
                onChange={handleDescriptionChange}
                className='placeholder:text-[#9EA2AD] h-full w-full placeholder:font-medium placeholder:text-sm text-[#1F2228] outline-none text-sm font-medium focus-visible:ring-0 resize-none scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600'
              />
            </div>
          </div>
        </div>
      )}
      {showBudgetFormDetailInput.revenue_range == true && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <div className='flex mr-auto'>
              <h3 className='font-bold text-sm'>Revenue Range</h3>
            </div>
            <div className='flex items-center gap-2'>
              <button
                className='h-6 w-6 rounded-full bg-[#E9EAEC] inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveRevenue(false)}
              >
                <Icons.X className='h-[14px] w-[14px] text-[#5E636E]' />
              </button>
              <button
                className='h-6 w-6 rounded-full bg-purple inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveRevenue(true)}
              >
                <Icons.Check className='h-[14px] w-[14px] text-white' />
              </button>
            </div>
          </div>
          <div className='h-12 bg-[#ECEAFF] rounded-[16px] p-1'>
            <div className='flex items-center bg-white border border-[#766CE4] w-full rounded-[16px] h-10 pl-2.5 pr-3.5'>
              <input
                value={revenue}
                onChange={handleRevenueChange}
                className='placeholder:text-[#9EA2AD] w-full placeholder:font-medium placeholder:text-sm text-[#1F2228] outline-none text-sm font-medium focus-visible:ring-0'
              />
            </div>
          </div>
        </div>
      )}
      {showBudgetFormDetailInput.product_and_service == true && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <div className='flex mr-auto'>
              <h3 className='font-bold text-sm'>Products & Services</h3>
            </div>
            <div className='flex items-center gap-2'>
              <button
                className='h-6 w-6 rounded-full bg-[#E9EAEC] inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveProductAndService(false)}
              >
                <Icons.X className='h-[14px] w-[14px] text-[#5E636E]' />
              </button>
              <button
                className='h-6 w-6 rounded-full bg-purple inline-flex items-center justify-center'
                type='button'
                onClick={() => handleSaveProductAndService(true)}
              >
                <Icons.Check className='h-[14px] w-[14px] text-white' />
              </button>
            </div>
          </div>
          <div className='h-[66px] bg-[#ECEAFF] rounded-[16px] p-1'>
            <div className='flex items-center bg-white h-full border border-[#766CE4]  rounded-[16px] py-1.5 px-2.5'>
              <textarea
                value={productAndService}
                onChange={handleProductAndServiceChange}
                className='placeholder:text-[#9EA2AD] h-full w-full placeholder:font-medium placeholder:text-sm text-[#1F2228] outline-none text-sm font-medium focus-visible:ring-0 resize-none scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const AIGenerateMessage = ({ children }: { children?: React.ReactNode }) => {
  const { agent } = useAI();
  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  return (
    <div className={cn("flex flex-row w-full max-w-full items-center gap-[.2rem] px-6 justify-start relative")}>
      {/* Center Align Image */}
      <img 
        src={Image} 
        alt={agent?.name} 
        className='w-[32px] h-[32px] rounded-full flex-shrink-0 object-cover self-center mt-[60px]' 
      />

      <div className='flex flex-1 relative'>
        <div className='relative'>
          <div className='absolute right-0 left-2 bottom-[28px] w-1.5 h-1.5 border border-[#E9EAEC] rounded-full'></div>
          <div className='absolute right-2 left-1 bottom-[26px] w-1 h-1 border border-[#E9EAEC] rounded-full'></div>
          <div className='absolute right-5 left-0 bottom-[24px] w-0.5 h-0.5 border-[0.8px] border-[#E9EAEC] rounded-full'></div>
        </div>

        {/* Center Align Spinner and Text */}
        <div className={cn("flex ml-3 mb-8 flex-row gap-1 items-center px-4 py-2 rounded-xl border bg-white border-[#E9EAEC] mt-[25px]" )}>
          <div className="flex items-center gap-2">
            <Icons.Spinner className='animate-spin flex-shrink-0 self-center' />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};


interface ChatMessageProps {
  chat: IChatMessage;
  isSuggestion?: boolean;
  showAvatar: boolean;
}

const ChatMessage = React.memo(({ chat, isSuggestion, showAvatar }: ChatMessageProps) => {
  const {
    agent,
    isCompanyInfoContext,
    handleShowOrHideBudgetFormDetailInput,
    handleAddOrUpdateChat,
    showBudgetFormDetailInput,
    handleUpdateUserFeedbackNeeded,
    isUserFeedbackNeeded,
    frequencyNotificationPlatForm,
  } = useAI();
  const dispatch = useDispatch();
  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  const handleChoiceAction = useCallback(
    (label: string, action?: () => void) => {
      if (chat.isActionDisabled == true) {
        return;
      }

      // Call disableAction after the action is completed
      handleAddOrUpdateChat({
        ...chat,
        isActionDisabled: true,
      });

      if (action) {
        action();

        if (label.toLowerCase() === "no") {
          handleShowOrHideBudgetFormDetailInput({
            ...showBudgetFormDetailInput,
            description: true,
            revenue_range: true,
            product_and_service: true,
          });
        }

        // if (label.toLowerCase() === "yes") {
        //   handleAddOrUpdateChat({
        //     id: uuidv4(),
        //     sender: "assistant",
        //     message: AGENT_MOCK_RESPONSE.SelectGoalResponse,
        //   });
        // }
      }
    },
    [
      handleShowOrHideBudgetFormDetailInput,
      handleAddOrUpdateChat,
      showBudgetFormDetailInput,
      chat,
      handleUpdateUserFeedbackNeeded,
      isUserFeedbackNeeded,
    ],
  );

  return (
    <div
      className={cn(
        "flex flex-row w-full max-w-full items-end gap-2 px-6",
        chat.sender == "assistant" ? "justify-start" : "justify-end mt-2",
      )}
    >
      {(chat.sender == "assistant") == true && (
        <>
          {showAvatar ? (
            <img src={Image} alt={agent?.name} className='w-[32px] h-[32px] rounded-full flex-shrink-0 object-cover' />
          ) : (
            <div className='w-[32px] h-[32px] flex-shrink-0'></div>
          )}
        </>
      )}

      <div
        className={cn(
          "flex min-w-0 max-w-[calc(100%-10px)]",
          chat.data && chat.data.choices != undefined && "gap-2",
          chat.data == undefined && "flex-col",
          chat.data?.type === "source" && "flex-col gap-1",
          chat.data?.type === "suggestion" && "flex-col gap-1",
          chat.data?.type === "file_upload" && "flex-col gap-1",
        )}
      >
        {chat.message != "" && (
          <div
            className={cn(
              "relative px-4 py-2 rounded-2xl min-w-[45px] max-w-[540px] flex flex-col ",
              chat.sender == "assistant" ? "bg-[#F7F7F8]" : "bg-[#F1F0FC]",
              chat.data?.type === "approval_request" && "ml-auto",
              chat.data?.type === "question" && chat.data.choices != undefined && "ml-auto",
            )}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                //TODO: should come from the props instead of hardcoding
                a: ({ node, ...props }) => (
                  <a
                    onMouseEnter={() => chat.data?.type !== "action" && open()}
                    onMouseLeave={() => close()}
                    onClick={() => chat.data?.type === "action" && dispatch(setIsDataPreviewOpen(true))}
                    className='relative text-sm font-medium text-primary underline text-[#766CE4] group'
                    {...props}
                  ></a>
                ),
                p: ({ node, ...props }) => <p className='text-[#1F2228] text-sm font-medium' {...props} />,
                li: ({ node, ...props }) => <li className='text-sm font-medium text-text ml-2.5' {...props} />,
                ul: ({ node, ...props }) => <ul className='mt-1.5 flex flex-col gap-1.5 list-disc' {...props} />,
                br: ({ node, ...props }) => <br />,
                pre: ({ node, ...props }) => (
                  <pre
                    className='text-sm font-medium text-text overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 w-full flex'
                    {...props}
                  />
                ),
              }}
            >
              {chat.message}
            </ReactMarkdown>
            <Dialog open={isOpen} as='div' className='relative z-10 focus:outline-none ' onClose={close}>
              <div className='fixed inset-0 z-10 w-screen overflow-y-auto '>
                <div className='flex relative min-h-full items-center justify-center p-4'>
                  <DialogPanel
                    transition
                    className='w-full  max-w-[357px] rounded-xl bg-white shadow-lg p-3  backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 '
                  >
                    <DialogTitle as='h3' className='flex justify-between items-center text-base font-bold tex-[#1F2228] py-2'>
                      Signal Breakdown{" "}
                      <div className='cursor-pointer bg-[#E9EAEC] rounded-md p-1' onClick={() => close()}>
                        <Icons.Close />
                      </div>
                    </DialogTitle>
                    <div className='overflow-y-scroll max-h-[204px] scrollbar-thin pb-5 scrollbar-track-[#F1F0FC] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-[#9189E9]'>
                      <p className='mt-2 font-medium text-sm text-[#5E636E]'>
                        Brainvoy incorporates multiple data models and responds to factors like:
                      </p>
                      <ul className='list-disc pl-5 pt-5'>
                        <li className='text-[#5E636E] font-bold text-sm mb-2 '>
                          Sales Velocity: <span className='font-medium'>Products with high sales frequency over the past month.</span>{" "}
                        </li>
                        <li className='text-[#5E636E] font-bold text-sm mb-2'>
                          Seasonal Trend:{" "}
                          <span className='font-medium'>Items that have historically seen a spike during specific times of the year.</span>{" "}
                        </li>
                        <li className='text-[#5E636E] font-bold text-sm mb-2 '>
                          Inventory Turnover: <span className='font-medium'>Best-sellers that move off the shelves quickly.</span>{" "}
                        </li>
                        <li className='text-[#5E636E] font-bold text-sm mb-2'>
                          Customer Interest:{" "}
                          <span className='font-medium'>Products with frequent inquiries or reviews from customers.</span>{" "}
                        </li>
                        <li className='text-[#5E636E] font-bold text-sm mb-2'>
                          Stock Replenishment History:{" "}
                          <span className='font-medium'>Items that require frequent restocking due to rapid sales.</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className={cn("h-16 w-full absolute bottom-0 bg-text-overflow-gradient")}></div>
                  </DialogPanel>
                </div>
              </div>
            </Dialog>
          </div>
        )}

        {chat.data?.type === "suggested_integrations" && chat.sender == "assistant" && <ConnectDataSources chat={chat} />}
        {chat.message == "" && chat.sender == "assistant" && chat.data?.type === "platform_notification" && (
          <PlatformNotification frequencyNotificationPlatForm={frequencyNotificationPlatForm} chat={chat as NotificationChatMessage} />
        )}

        {chat.data && chat.data.choices !== undefined && (
          <div className='flex items-center gap-2'>
            {chat.data.choices &&
              chat.data.choices.map((choice: any, index: number) => {
                const label = choice.label as string;
                const action = choice.action as (() => void) | undefined;

                return (
                  <Button
                    key={index}
                    disabled={chat.isActionDisabled == true}
                    onClick={() => handleChoiceAction(label, action)}
                    className={cn(
                      "h-[32px] disabled:cursor-not-allowed",
                      label.toLowerCase() == "no" && "bg-white text-black hover:bg-white hover:text-black ",
                    )}
                  >
                    {label}
                  </Button>
                );
              })}
          </div>
        )}
        {chat.data && chat.data.sources && (
          <div className='flex flex-col gap-2'>
            <p className='text-[#464A53] text-xs font-medium'>Sources</p>
            <div className='grid grid-cols-2 gap-3'>
              {chat.data.sources.map((source: any, index: number) => {
                return (
                  <a
                    key={index}
                    href={source.link}
                    target='_blank'
                    rel='noreferrer'
                    className='flex flex-col justify-between gap-2 bg-[#F7F7F8] rounded-xl p-3'
                  >
                    <p className='text-[#464A53] text-xs font-semibold'>{source.description}</p>
                    <div className='flex flex-shrink-0 gap-2'>
                      <Icons.Link className='text-purple h-4 w-4' /> <p className='text-[#717684] text-xs font-semibold'>{source.link}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        )}
        {(isSuggestion == true || (chat.data && chat.data.goals != undefined)) && (
          <div className='flex flex-col gap-2'>
            <p className='text-[#464A53] text-xs font-medium'>Suggestions</p>
            <div className='flex flex-col gap-1 w-full'>
              {chat.data?.goals?.map((goal: any, index: number) => {
                const handleClick = () => {
                  if (chat.isActionDisabled == true) {
                    return;
                  }

                  if (goal.action) {
                    goal.action();
                  }
                };

                return (
                  <button
                    key={index}
                    onClick={handleClick}
                    disabled={chat.isActionDisabled == true}
                    className={cn(
                      "flex items-center text-sm font-medium border border-[#E9EAEC] py-2 pr-8 pl-4 rounded-3xl w-fit disabled:cursor-not-allowed",
                      goal.isChecked == true ? "bg-activated-btn" : "bg-white",
                    )}
                  >
                    {goal.isChecked == true ? <Icons.Check className='mr-1 text-black h-5 w-5' /> : <Icons.Plus className='mr-1 h-5 w-5' />}
                    {goal.label}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {chat.data && chat.data.type === "file_upload" && (
          <div className='border border-[#E9EAEC] rounded-lg p-3.5 py-2.5 flex flex-col gap-1'>
            <div className='flex justify-between items-center'>
              <div className='flex gap-3'>
                <span
                  className={cn(
                    "h-[2.25rem] w-[2.25rem] flex items-center justify-center",
                    chat.data.status == "completed" ? "bg-[#F0FDF4] rounded-full" : "border border-[#F7F7F8] bg-[#F7F7F8] rounded-xl",
                  )}
                >
                  {chat.data.status == "completed" ? (
                    <Icons.Check className='h-5 w-5 !text-[#22C55E]' />
                  ) : (
                    <Icons.Document className='h-5 w-5 !text-[#717684]' />
                  )}
                </span>
                <div className='flex flex-col gap'>
                  <h3 className='font-semibold text-sm'>{chat.data.name}</h3>
                  <p className='text-[#A9ACB4] font-normal text-xs'>
                    {chat.data.status === "completed" ? "Upload Successful!" : "Uploading..."}
                  </p>
                </div>
              </div>
              <div></div>
            </div>
            {chat.data.status == "uploading" && (
              <div className='flex items-center gap-x-3 whitespace-nowrap w-full'>
                <div
                  className='flex w-[calc(100%-40px)] h-2 bg-gray-200 rounded-full overflow-hidden max-w-full'
                  role='progressbar'
                  aria-valuenow={1}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className={cn(
                      "flex flex-col justify-center rounded-full overflow-hidden w-[calc(100%-40px)] max-w-full text-xs text-white text-center whitespace-nowrap transition duration-500",
                      chat.data.progress === 100 ? "bg-[#35D98A]" : "bg-[#766CE4]",
                    )}
                    style={{ width: `${chat.data.progress}%` }}
                  ></div>
                </div>
                <div className='w-10 text-end'>
                  <span className='text-sm text-gray-800'>{chat.data.progress}%</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

ChatMessage.displayName = "ChatMessage";

const ChatHistoryGroup = ({ history }: { history: ChatHistory }) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [showMore, setShowMore] = useState(false);
  const dispatch = useDispatch();

  const { onSelectConversation, switchPage } = useAI();

  const histories = useMemo(() => {
    return history.chats.filter(chat => chat.messages.length > 0);
  }, [history]);

  const filterHistories = useMemo(() => {
    return histories.slice(0, showMore ? histories.length : 3);
  }, [histories, showMore]);

  const handleConversationClick = (conversationId: string) => {
    dispatch(setConversationId(conversationId));
    onSelectConversation(conversationId);
    switchPage("active");
  };

  return (
    <div className='flex flex-col gap-3 mx-6'>
      <h3 className='text-sm font-medium '>{history.date}</h3>
      <ul className='flex flex-col relative'>
        {filterHistories.map((chat, index) => (
          <li
            key={index}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
            onClick={() => handleConversationClick(chat.conversation_id)}
            className='relative z-10 cursor-pointer font-normal text-xs h-[32px] justify-center flex flex-col pl-1.5 hover:bg-[#F7F7F8] hover:rounded-lg w-full'
          >
            {chat.messages[0].message}
          </li>
        ))}
        {filterHistories.length > 3 && (
          <div>
            <button onClick={() => setShowMore(!showMore)} className='inline-flex items-center justify-center'>
              <p className='text-sm font-semibold text-purple underline underline-offset-2'>Show {showMore ? "less" : "more"}</p>
              {showMore ? (
                <Icons.ArrowUp className='w-4 h-4 ml-2.5 text-[#766CE4]' />
              ) : (
                <Icons.ArrowDown className='w-4 h-4 ml-2.5 text-[#766CE4]' />
              )}
            </button>
          </div>
        )}
      </ul>
    </div>
  );
};

export const AIButton = () => {
  const { openPanel, closePanel, agent, showAsSheet, isOpen, chats, setWhatShouldIAsk } = useAI();
  const handleOpenAISheet = useCallback(() => {
    if (isOpen) {
      closePanel();
    } else {
      openPanel(true);
    }
  }, [openPanel, showAsSheet, isOpen]);

  useEffect(() => {
    if (isOpen == false) {
      setWhatShouldIAsk(true);
      openPanel(true);
    }
  }, []);

  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  return (
    <Button
      onClick={handleOpenAISheet}
      className='fixed z-50 bottom-4 right-4 bg-primary text-white w-[136px] h-[56px] bg-white rounded-[37px] before:border-none justify-between gap-x-0 !p-0'
      style={{
        boxShadow: "0px 8px 55px 0px rgba(137, 18, 229, 0.24)",
        border: "1px solid transparent",
        backgroundImage: "linear-gradient(white, white), linear-gradient(to right, rgba(229, 52, 213, 1), rgba(102, 89, 255, 1))",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
      }}
    >
      <div className='relative w-full h-full flex items-center justify-center'>
        {chats.length > 0 && (
          <span
            className='absolute -mt-10 right-0 px-2 py-1 text-xs font-medium text-white rounded-full'
            style={{ backgroundColor: "#E36C74" }}
          >
            {chats.length}
          </span>
        )}
        <div className='flex justify-between items-center w-full pl-2 py-2 pr-4'>
          <img src={Image} alt={agent?.name} className='w-[48px] h-[48px] rounded-full object-cover' />
          <h3 className='text-[#1F2228] font-bold text-lg'>{agent?.name}</h3>
        </div>
      </div>
    </Button>
  );
};
