import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addTeamMember, createNewTeamById, deleteMember, getTeamById, getTeamMembers, getTeamsByCompanyId, getUsersCompanyProfile, inviteMember, updateMember, updateTeamById } from "api/companies";
 

interface CompanyState {
  companyDetails: any;
  teams: any[];
  teamDetails: any | null;
  teamMembers: any[];
  loading: boolean;
  error: string | null;
}

const initialState: CompanyState = {
  companyDetails: null,
  teams: [],
  teamDetails: null,
  teamMembers: [],
  loading: false,
  error: null,
};

// Fetch company profile
export const getCompanyProfile = createAsyncThunk(
  "company/getCompanyProfile",
  async (_, { rejectWithValue }) => {
    try {
      return await getUsersCompanyProfile();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch teams by company ID
export const fetchTeamsByCompany = createAsyncThunk(
  "company/fetchTeamsByCompany",
  async (companyId: string, { rejectWithValue }) => {
    try {
      return await getTeamsByCompanyId(companyId);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch single team by ID
export const fetchTeamById = createAsyncThunk(
  "company/fetchTeamById",
  async (teamId: string, { rejectWithValue }) => {
    try {
      return await getTeamById(teamId);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Create a new team
export const createNewTeam = createAsyncThunk(
  "company/createNewTeam",
  async ({ companyId, name }: { companyId: string; name: string }, { rejectWithValue }) => {
    try {
      return await createNewTeamById(companyId, name);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Update team details
export const updateTeamDetails = createAsyncThunk(
  "company/updateTeamDetails",
  async ({ teamId, name }: { teamId: string; name: string }, { rejectWithValue }) => {
    try {
      return await updateTeamById(teamId, name);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Add a member to a team
export const addMemberToTeam = createAsyncThunk(
  "company/addMemberToTeam",
  async ({ teamId, memberData }: { teamId: string; memberData: any }, { rejectWithValue }) => {
    try {
      return await addTeamMember(teamId, memberData);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Fetch team members
export const fetchTeamMembers = createAsyncThunk(
  "company/fetchTeamMembers",
  async (teamId: string, { rejectWithValue }) => {
    try {
      return await getTeamMembers(teamId);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Invite a member to a team
export const inviteMemberToTeam = createAsyncThunk(
  "company/inviteMemberToTeam",
  async ({ teamId, email, role_id }: { teamId: string; email: string; role_id: number }, { rejectWithValue }) => {
    try {
      return await inviteMember(teamId, {email, role_id});
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);


// Update team member
export const updateTeamMemberRole = createAsyncThunk(
  "company/updateMemberRole",
  async ({ teamId, memberId, roleId }: { teamId: string; memberId: string, roleId:string }, { rejectWithValue }) => {
    try {
      return await updateMember(teamId, memberId, roleId);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete team member
export const removeTeamMember = createAsyncThunk(
  "company/removeTeamMember",
  async ({ teamId, memberId }: { teamId: string; memberId: string}, { rejectWithValue }) => {
    try {
      await deleteMember(teamId, memberId);
      return  { memberId };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Company Profile
      .addCase(getCompanyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.companyDetails = action.payload;
      })
      .addCase(getCompanyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch Teams by Company ID
      .addCase(fetchTeamsByCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeamsByCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.teams = action.payload as any;
      })
      .addCase(fetchTeamsByCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch Single Team by ID
      .addCase(fetchTeamById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeamById.fulfilled, (state, action) => {
        state.loading = false;
        state.teamDetails = action.payload;
      })
      .addCase(fetchTeamById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Create a New Team
      .addCase(createNewTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.teams.push(action.payload);
      })
      .addCase(createNewTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Update Team Details
      .addCase(updateTeamDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTeamDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.teamDetails = action.payload;
      })
      .addCase(updateTeamDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Add Member to Team
      .addCase(addMemberToTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(addMemberToTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.teamMembers.push(action.payload);
      })
      .addCase(addMemberToTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch Team Members
      .addCase(fetchTeamMembers.pending, (state) => {
        state.loading = true;
        state.teamMembers = [];
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.teamMembers = action.payload as any;
      })
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Invite Member to Team
      .addCase(inviteMemberToTeam.pending, (state) => {
        state.loading = true;
      })
      .addCase(inviteMemberToTeam.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(inviteMemberToTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      //  Handle delete team member
      .addCase(removeTeamMember.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeTeamMember.fulfilled, (state, action) => {
        state.loading = false;
        const { memberId } = action.payload as { memberId: string };
      
        state.teamMembers = state.teamMembers.filter((member) => member.member_id !== memberId);
      })
      
      .addCase(removeTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Handle update member role
      .addCase(updateTeamMemberRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeamMemberRole.fulfilled, (state, action) => {
        state.loading = false;
        const { member_id, role_id } = action.payload as { member_id: number; role_id: string };
      
        state.teamMembers = state.teamMembers.map((member) =>
          member.member_id === member_id ? { ...member, role_id: role_id } : member
        );
      })
      
      .addCase(updateTeamMemberRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const companyReducer = companySlice.reducer;
