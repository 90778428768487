import { useAppSelector, useAppDispatch } from "hooks/appHooks";

import { RootState } from "storage/store";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeButton.svg";
import { closePopup } from "../../../../storage/slices/popup-slice";

export default function RevenueExplanationPopup() {
  const popupData = useAppSelector((state: RootState) => state.popup);
  const dispatch = useAppDispatch();

  if (!popupData.data || !popupData.data.explanation) {
    return <></>;
  }

  return (
    <div className='flex flex-col items-center bg-[#fff] shadow-sm rounded-3xl pointer-events-auto relative py-16 px-20 gap-y-10'>
      <button
        type='button'
        className='absolute top-3 right-3 flex justify-center items-center rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
        data-hs-overlay='#hs-static-backdrop-modal'
        onClick={() => dispatch(closePopup())}
      >
        <CloseIcon />
      </button>

      <p className='font-medium text-sm text-[#1F2228] line-clamp-6'>{popupData.data.explanation}</p>
    </div>
  );
}
