import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { createNewTeam, fetchTeamsByCompany } from "storage/slices/company-slice";

import { Icons } from "shared/components/icons";
import { Modal } from "shared/components/modal/Modal";
import Alert from "shared/components/toasts";

import { ReactComponent as CompanyPeopleProfile } from "assets/icons/people.svg";

import { cn } from "lib/utils";

import TeamTable from "./company-team";

const CompanyTeamMembers = ({ companyId }: { companyId: string }) => {
  const [teamName, setTeamName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showTeamMembers, setShowTeamMembers] = useState("");
  const dispatch = useAppDispatch();
  const teams = useAppSelector(state => state.company.teams);
  const handleCreateTeam = () => {
    dispatch(createNewTeam({ companyId: companyId, name: teamName }));
    dispatch(fetchTeamsByCompany(companyId));
    setIsOpen(false);
    Alert("success", "New team created successfully", "Success");
  };

  useEffect(() => {
    dispatch(fetchTeamsByCompany(companyId));
  }, []);

  return (
    <div className='flex flex-col gap-4'>
      <div className='p-6 bg-white rounded-2xl flex gap-x-16 flex-col gap-y-4 lg:flex-row lg:gap-y-0'>
        <div className='flex items-center gap-x-4 '>
          <CompanyPeopleProfile className='h-10 w-10' />
          <div>
            <p className='text-lg font-bold text-[#14191A]'>Your Organization’s People</p>
            <p className='text-base font-medium text-[#717684]'>Manage your team members, their roles, and approval levels.</p>
          </div>
        </div>
      </div>
      <Modal className='max-w-lg' isOpen={isOpen} onClose={() => setIsOpen(false)} title={"Create team"}>
        <div className='flex flex-col gap-4 items-end'>
          <div className={cn("w-full bg-white px-[14px] py-2 rounded-xl flex items-center border relative")}>
            <input
              type={"text"}
              value={teamName}
              className={"w-full focus:outline-none"}
              onChange={e => setTeamName(e.target.value)}
              placeholder={"Example: Marketing Team "}
            />
          </div>
          <button onClick={handleCreateTeam} className='w-[200px] px-6 py-2 text-base text-white bg-[#766CE4] rounded-xl'>
            Create new team
          </button>
        </div>
      </Modal>

      {teams.length > 0 &&
        teams.map(team => (
          <TeamTable
            showTeamMembers={showTeamMembers}
            setShowTeamMembers={setShowTeamMembers}
            companyId={companyId}
            team={team}
            key={team.team_id}
          />
        ))}

      <button
        onClick={() => setIsOpen(true)}
        className='flex items-center gap-2 rounded-2xl py-[10px] px-5 border border-dashed border-[#C8CAD0] text-[#717684] font-medium'
      >
        <Icons.Plus />
        Add new team
      </button>
    </div>
  );
};

export default CompanyTeamMembers;
