import { Icons } from "shared/components/icons";

import plus from "assets/icons/addIc.svg"
import AgentDave from "assets/png/agent/image2.png";

import { cn } from "lib/utils";

type TimelineEvent = {
  id: number;
  title: string;
  name: string;
  image: string;
  stakeholders: string[];
  icon: JSX.Element;
};

const events: TimelineEvent[] = [
  {
    id: 1,
    title: "Identify low stock levels",
    name: "Dave",
    image: AgentDave,
    stakeholders: [],
    icon: <span className='text-blue-500 bg-blue-100 p-1.5 rounded-full'>🔔</span>,
  },
  {
    id: 2,
    title: "Notify internal stakeholders",
    name: "Dave",
    image: AgentDave,
    stakeholders: ["Supply chain manager", "Operations team", "+1"],
    icon: <span className='text-blue-500 bg-blue-100 p-1.5 rounded-full'>⚙️</span>,
  },
  {
    id: 3,
    title: "Contact vendor and create PO",
    name: "Dave",
    image: AgentDave,
    stakeholders: ["Supplier", "Finance team"],
    icon: <span className='text-blue-500 bg-blue-100 p-1.5 rounded-full'>⚙️</span>,
  },
  {
    id: 4,
    title: "Notify warehouse team",
    name: "Dave",
    image: AgentDave,
    stakeholders: ["Warehouse team", "Operations team"],
    icon: <span className='text-blue-500 bg-blue-100 p-1.5 rounded-full'>⚙️</span>,
  },
  {
    id: 5,
    title: "Track shipment",
    name: "Dave",
    image: AgentDave,
    stakeholders: ["Supply chain team", "Warehouse team"],
    icon: <span className='text-blue-500 bg-blue-100 p-1.5 rounded-full'>⚙️</span>,
  },
];

const WorkFlow: React.FC = () => {
  return (
    <div className='max-w-xl mx-auto py-6'>
      <TimeLineItem step={events[0]} icon={<Icons.Check className='text-[#02A613]' />} />
      <TimeLineItem step={events[1]} icon={<Icons.Check className='text-[#02A613]' />}  />
      <TimeLineItem step={events[2]} iconWrapperClassName='bg-[#A4BDF9]' icon={<Icons.SettingIcon />} />
      <TimeLineItem step={events[3]} isReverse iconWrapperClassName='bg-[#A4BDF9]' icon={<Icons.BellIcon />} />
      <TimeLineItem step={events[4]} iconWrapperClassName='bg-[#A4BDF9]' icon={<Icons.LoopIcon />} />
      <div className="my-4">
        <div className="border-2 border-dashed border-[#9189E9] rounded-lg flex justify-center items-center w-9 h-9 mx-auto">
          <img src={plus} alt="pls" />
        </div>
      </div>
    </div>
  );
};

const TimeLineItem = ({ step, iconWrapperClassName, icon, isReverse = false }: any) => {
  return (
    <div className='relative'>

      <div className='grid grid-cols-[3fr_auto_3fr] grid-flow-dense items-center'>
        <div className={cn(isReverse ? "order-3" : "order-1 text-end")}>
          <p  className={`text-sm font-bold text-[#1F2228] ${
              step.title === "Contact vendor and create PO" ? "cursor-pointer hover:text-[#766CE4]" : ""
          }`}>{step.title}</p>
        </div>
        <div className='flex justify-center w-24 order-2'>
          <div
            className={cn(
              "flex justify-center items-center  w-10 h-10 rounded-[10px] border border-[#E9EAEC] bg-white",
              iconWrapperClassName,
            )}
          >
            {icon}
          </div>
        </div>
        <div className={cn("flex items-center space-x-3 py-4 rounded-lg", isReverse ? "order-1 justify-end" : "order-3")}>
          <img className='w-6 h-6 rounded-full object-cover' src={step.image} alt={step.name} />
          <span className='text-[#717684] font-medium text-sm'>{step.name}</span>
        </div>
      </div>
      <div className='flex items-center justify-center gap-4 mb-4'>
        {step.stakeholders.map((stakeholder: string, index: number) => (
          <button 
            key={index}
            className='bg-[#F7F7F8] flex items-center gap-2 px-[6px] py-[2px] rounded-[5px] text-[#5E636E] font-medium text-sm'
          >
            {stakeholder} <Icons.Pencil className='text-[#717684]' />
          </button>
        ))}
      </div>
      <div className='m-auto my-2 w-0.5 h-12 bg-[#9EA2AD]' />
    </div>
  );
};

export default WorkFlow;
