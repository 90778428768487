import { useCallback, useEffect, useMemo, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { createConnection, generateCredentialForAppIntegration, getCredential, getIntegrationCategories, saveOauth2Credential } from "api/integrations";

import { ROUTES } from "constants/routes.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";


import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetCredential } from "hooks/queries/connect-source";
import { useString } from "hooks/useString";

import { getAllConnectSources } from "storage/slices/integrations-slice";

import Alert from "shared/components/toasts";

import AmazonSellerConnectModal from "./ConnectSourceList/amazon-seller-connect-modal";
import ConnectedOverlay from "./ConnectSourceList/ConnectedOverlay";
import PopupConfrimOverlay from "./ConnectSourceList/PopupComfirmOverlay";
import ShopifyConnectModal from "./ConnectSourceList/shopify-connect-modal";
import SourceCards from "./ConnectSourceList/SourceCards";

type localStorageIntegrationType = {
  integration_id: number;
  display_name: string;
  scopes: string[];
  supportsRefreshToken: boolean;
  enabled: boolean;
  logo: string;
  description: string;
  category: string;
  service_id: number | null;
};

const ConnectSourceList = () => {
  const [search, setSearch] = useString("");
  const [showConnectedOverlay, setShowConnectedOverlay] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<any>(null);
  const [connecting, setConnecting] = useState(false);
  const [middleWareData, setMiddleWareData] = useState<any>({ access_token: "", credential_id: "", integration_id: "" });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const connectSourcesList = useAppSelector(state => state.integrations.connectSources);
  const [categories, setCategories] = useState<Record<string, string[]>>({});
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [openPicker, authResponse] = useDrivePicker();
  const [openShopifyModal, setShopifyModal] = useState(false);
  const [shopifyIntegrationId, setShopifyIntegrationId] = useState<number | null>(null);
  const [openAmazonSellerModal, setAmazonSellerModal] = useState(false);
  const [amazonSellerIntegrationId, setAmazonSellerIntegrationId] = useState<number | null>(null);

  const { data: googleSheetCredential } = useGetCredential(2);

  const handleIntegration = useCallback(
    (item: localStorageIntegrationType) => {
      const { integration_id, display_name } = item;
      localStorage.setItem("selectedIntegration", JSON.stringify(item));
      console.log("Integration Item", item);
      if (display_name == "Google Sheets" && googleSheetCredential && googleSheetCredential.statusCode == 200) {
        setSelectedIntegration(item);
        setMiddleWareData({
          access_token: googleSheetCredential.data!.access_token,
          credential_id: googleSheetCredential.data!.credential_id,
          integration_id: integration_id,
        });
        return;
      }

      if (display_name === "Shopify") {
        // open modal
        setShopifyIntegrationId(integration_id);
        setShopifyModal(true);
        return;
      }

      if (display_name === "Amazon Seller Central") {
        // open Amazon Seller modal
        setAmazonSellerIntegrationId(integration_id);
        setAmazonSellerModal(true);
        return;
      }

      generateCredentialForAppIntegration(integration_id)
        .then(res => {
          window.location.href = res.authorizationUrl;
        })
        .catch(err => {
          Alert("error", err.message, "Error");
        });
    },
    [googleSheetCredential],
  );

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const filteredSearchList = useMemo(() => {
    const data: Record<string, any[]> = {};
    categoryList?.forEach(category => {
      data[category] = [];
    });
    connectSourcesList?.forEach(item => {
      if (item.enabled && item.display_name.toLowerCase().includes(search.toLowerCase())) {
        data[item.category] = data[item.category] || [];
        data[item.category].push(item);
      }
    });

    const newdata = categoryList?.map(item => {
      return {
        type: item,
        options: (data[item] || []).map((item: any) => {
          let isConnected = false;

          if (item.display_name == "Google Sheets" && googleSheetCredential && googleSheetCredential.statusCode == 200) {
            isConnected = true;
          }

          return { ...item, logo: item.logo, onConnect: () => handleIntegration(item), disabled: false, isConnected };
        }),
      };
    });
    return newdata || [];
  }, [search, connectSourcesList, googleSheetCredential, categoryList]);

  const queryToObject = (query: string) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
  };

  const createAirbyteConnection = (apiData: any) => {
    setConnecting(true);
    createConnection(apiData)
      .then(_ => {
        // refresh the list
        dispatch(getAllConnectSources());
      })
      .catch(err => {
        console.log("Something went wrong in createConnection", err);
        Alert("error", err.message, "Error");
        setShowConnectedOverlay(false);
      })
      .finally(() => {
        setConnecting(false);
      });
  };

  const openGoogleSheetPicker = () => {
    if (!process.env.REACT_APP_OAUTH_CLIENT_ID || !process.env.REACT_APP_GOOGLE_API_KEY) {
      Alert("error", "There seems to be a problem. Please try again later", "Error");
      return;
    }
    openPicker({
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
      viewId: "SPREADSHEETS",
      token: middleWareData.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: data => {
        console.log("Data", data);
        if (data.action === "cancel") {
        }
        if (data.action === "picked") {
          setShowConnectedOverlay(true);
          const apiData = {
            credential_id: middleWareData.credential_id,
            integration_id: middleWareData.integration_id,
            integration_data: { spreadsheetId: data.docs?.map(item => item.url).at(0) },
          };
          createAirbyteConnection(apiData);
        }
      },
    });
  };

  const checkIntegrationType = (res: any) => {
    const selectedIntegration: string | null = localStorage.getItem("selectedIntegration");
    console.log("Selected Integration", selectedIntegration);
    // Continue Work here
    if (selectedIntegration) {
      const selectedIntegrationData: localStorageIntegrationType = JSON.parse(selectedIntegration);
      switch (selectedIntegrationData.display_name) {
        case "Google Sheets":
          setSelectedIntegration(selectedIntegrationData);
          setMiddleWareData({
            access_token: res.data.access_token,
            credential_id: res.data.credential_id,
            integration_id: res.data.integration_id,
          });
          break;

        default:
          setSelectedIntegration(selectedIntegrationData);
          setShowConnectedOverlay(true);
          const apiData = { credential_id: res.data.credential_id, integration_id: res.data.integration_id };
          createAirbyteConnection(apiData);
          break;
      }
    }
  };
  
  useEffect(() => {
    const payload = queryToObject(location.search.split("?")[1]);
    console.log("Payload", payload);
    const { code, state, error, ...extraPayload } = payload || {};
    const usableCode = code || payload.spapi_oauth_code;
    console.log("State", state);
    console.log("Error", error);
    if (usableCode && state) {
      saveOauth2Credential(usableCode, state, extraPayload)
        .then(res => {
          // this should either return the credential or create the connection itself on the backend
          window.history.replaceState(null, "Brainvoy", ROUTES.connectedSources);

          checkIntegrationType(res);
        })
        .catch(err => {
          console.log("Something went wrong in saveOauth2Credential", err);
          Alert("error", err.message, "Error");
        });
    }
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const categoriesData = await getIntegrationCategories();
        setCategories(categoriesData);
        setCategoryList(Object.keys(categoriesData));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
    dispatch(getAllConnectSources());
  }, []);

  return (
    <div className='flex-1 p-6'>
      <p className='text-2xl font-semibold text-[#333740] '>Connect Sources</p>
      <div className='relative mt-3'>
        <div className='absolute cursor-pointer inset-y-0 start-0 flex items-center ps-3 '>
          <svg style={{ cursor: "pointer" }} width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
              stroke='#2C1A49'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path opacity='0.4' d='M22 22L20 20' stroke='#2C1A49' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
        <input
          type='search'
          className={twMerge("max-w-[325px] py-[9px] pl-10 pr-3.5 ", INPUT_SHARED_CLASSNAME)}
          placeholder='Search data sources'
          required
          value={search}
          onChange={handleSearch}
        />
      </div>
      {filteredSearchList?.map((item: any) => (item.options.length > 0 ? <SourceCards item={item} key={item.type} /> : null))}
      {showConnectedOverlay && (
        <ConnectedOverlay
          onClick={() => {
            setShowConnectedOverlay(false);
          }}
          selectedIntegration={selectedIntegration}
          connecting={connecting}
        />
      )}
      {middleWareData?.access_token && (
        <PopupConfrimOverlay
          onClose={() => {
            setMiddleWareData(null);
          }}
          onOpen={() => {
            openGoogleSheetPicker();
            setMiddleWareData(null);
          }}
        />
      )}

      {openShopifyModal && (
        <ShopifyConnectModal
          onClose={() => {
            setShopifyModal(false);
          }}
          onOpen={() => {
            setShopifyModal(true);
          }}
          onSubmit={(shopUrl: string) => {
            return generateCredentialForAppIntegration(shopifyIntegrationId!, shopUrl);
          }}
        />
      )}

      {openAmazonSellerModal && (
        <AmazonSellerConnectModal
          onClose={() => {
            setAmazonSellerModal(false);
          }}
          onOpen={() => {
            setAmazonSellerModal(true);
          }}
          onSubmit={(country: "US" | "IN") => {
            // Pass the selected country as a parameter to the API
            return generateCredentialForAppIntegration(amazonSellerIntegrationId!, undefined, country);
          }}
        />
      )}
    </div>
  );
};

export default ConnectSourceList;
