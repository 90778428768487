import { useState, type FC } from "react";
import { useParams } from "react-router-dom";

import { useGetOperationReport } from "hooks/queries/operation-report";

import { DashboardLayout } from "shared/layouts/DashboardLayout";

import AiChatModal from "pages/budget/BudgetIntelligence/AiChatModal/AiChatModal";

import { cn } from "lib/utils";

import ExecutiveView from "./components/ExecutiveView";
import Header from "./components/Header";
import ProductsView from "./components/ProductsView";
import ReviewNeededTable from "./components/review-needed/ReviewNeededTable";
import WorkFlowAutomation from "./components/workflow-automation/WorkFlowAutomation";

const OperationReport: FC<any> = () => {
  const [productActivity, setProductActivity] = useState(null);

  const { id } = useParams();

  const [searchQuery, setSearchQuery] = useState(""); // Search state

  const { data, isLoading } = useGetOperationReport(id ? Number(id) : undefined);

  const categories = ["All", ...Object.keys(data?.category_summary || {})];

  const [view, setView] = useState<string>("All");

  // 🔍 Filter products based on category and search input
  const filteredProducts = (data?.product_catalog || []).filter((product: any) => {
    const matchesCategory = view === "All" || product.category === view;
    const matchesSearch = searchQuery ? product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) : true;
    return matchesCategory && matchesSearch;
  });

  const filteredReviewNeededProducts = (data?.review_needed_items || []).filter((product: any) => {
    const matchesCategory = view === "All" || product.category === view;
    const matchesSearch = searchQuery ? product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) : true;
    return matchesCategory && matchesSearch;
  });

  return (
    <DashboardLayout title='Operations specialist'>
      <div className='flex flex-col h-full'>
        <div className='bg-white border-b border-[#F7F9FB] pt-2'>
          <Header setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
          <div className='flex relative justify-between items-center border-b border-[#F7F9FB]'>
            <div className={" flex gap-5 items-center  px-6"}>
              {categories.map(name => (
                <button
                  key={name}
                  className={cn(
                    "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm capitalize",
                    view === name ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                  )}
                  onClick={() => {
                    setView(name);
                  }}
                >
                  {name}
                </button>
              ))}
            </div>
            <div className={cn("absolute right-0 w-[100px] h-10 bg-ai-panel-gradient")}></div>
          </div>
        </div>
        <div
          className={cn(
            " h-full w-full bg-white",
            view === "All" ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600" : "overflow-hidden",
          )}
        >
          <ExecutiveView data={data?.analytics.data} />

          {data?.review_needed_items?.length > 0 && (
            <div className='p-6'>
              <ReviewNeededTable data={filteredReviewNeededProducts} setProductActivity={setProductActivity} />
            </div>
          )}

          <ProductsView data={filteredProducts} />
        </div>
      </div>
      {productActivity && <WorkFlowAutomation productActivity={productActivity} setProductActivity={setProductActivity} />}
      {/* <AiChatModal /> */}
    </DashboardLayout>
  );
};

export default OperationReport;
