import { Icons } from "shared/components/icons";

import { cn } from "lib/utils";

const ExecutiveView = ({ data }: { data: any }) => {
  const revenueImpact = Math.floor(Number(data?.revenueImpact) / 1000) || 0; // Convert to k and fallback to 0
  const dailyPurchaseOrders = Number(data?.dailyOrders) || 0; // Fallback to 0
  const inventoryTurnoverRate = Math.floor(Number(data?.inventoryTurnover)) || 0; // Remove decimals, fallback to 0
  const reorderAlerts = Number(data?.reorderAlerts) || 0; // Fallback to 0

  return (
    <div className={cn("!py-4 !px-6 flex flex-col overflow-x-hidden w-full")}>
      <div className='relative w-full grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-4'>
        {/* Revenue Impact */}
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Revenue</p>
              <p className='text-[#14191A] text-2xl font-semibold '>${revenueImpact}k</p>
            </div>
            <Icons.DemoChart />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous month</p>
              <p className='flex items-center gap-1 text-[#26BD6C] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> +1.2%
              </p>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous year</p>
              <p className='flex items-center gap-1 text-[#26BD6C] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> +1.2%
              </p>
            </div>
          </div>
        </AnalyticCard>

        {/* Daily Purchase Orders */}
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Daily Purchase Orders</p>
              <p className='text-[#14191A] text-2xl font-semibold'>11020</p>
            </div>
            <Icons.DemoChart2 />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous month</p>
              <p className='flex items-center gap-1 text-[#26BD6C] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> +0.3%
              </p>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous year</p>
              <p className='flex items-center gap-1 text-[#26BD6C] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> +1.1%
              </p>
            </div>
          </div>
        </AnalyticCard>

        {/* Inventory Turnover Rate */}
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Inventory Turnover Rate</p>
              <p className='text-[#14191A] text-2xl font-semibold'>{inventoryTurnoverRate} days</p>
            </div>
            <Icons.DemoChart3 />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous month</p>
              <p className='flex items-center gap-1 text-[#E53E3E] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> -2.3%
              </p>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous year</p>
              <p className='flex items-center gap-1 text-[#26BD6C] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> +7.8%
              </p>
            </div>
          </div>
        </AnalyticCard>

        {/* Reorder Alerts */}
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Reorder Alerts</p>
              <p className='text-[#14191A] text-2xl font-semibold'>{reorderAlerts}</p>
            </div>
            <Icons.DemoChart4 />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous month</p>
              <p className='flex items-center gap-1 text-[#E53E3E] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> -3.1%
              </p>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous year</p>
              <p className='flex items-center gap-1 text-[#E53E3E] text-[11px] font-semibold'>
                <Icons.ArrowGreen /> -1.6%
              </p>
            </div>
          </div>
        </AnalyticCard>
      </div>
    </div>
  );
};

const AnalyticCard = ({ className, children }: { children: React.ReactNode; className?: string }) => {
  return (
    <div className={cn("flex flex-col h-[140px] justify-between bg-white border border-[#E9EAEC] rounded-2xl pt-3 px-6 pb-2.5", className)}>
      {children}
    </div>
  );
};

const AnalyticCardRuler = () => {
  return <div className=' h-[1px] bg-[#F7F7F8] w-full'></div>;
};

export default ExecutiveView;
