import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { titleCase } from "tiny-case";

import { BoxVariants } from "constants/shared/box.constants";
import { ButtonVariants } from "constants/shared/button.constants";

import { usePerformancePlan } from "hooks/performance-plans/usePerformancePlan";

import { Accordion } from "shared/components/accordion/Accordion";
import { Box } from "shared/components/Box";
import { Button } from "shared/components/buttons/Button";
import { CheckMarkToggle } from "shared/components/CheckMarkToggle";
import Alert from "shared/components/toasts";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";

import { PerformancePlanFormField } from "../performance-plan-form/PerformancePlanFormField";

import FileLoader from "./component/FileLoader";

const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB

export const PerformancePlanSidebar = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<"select" | "uploading" | "done">("select");

  const handleUpload = async () => {
    console.log("selectedFile", selectedFile?.type);
    if (
      selectedFile?.type !== "text/csv" &&
      selectedFile?.type !== "application/vnd.ms-excel" &&
      selectedFile?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      selectedFile?.type !== "application/pdf"
    ) {
      Alert("info", "File must be only Excel, CSV, PDF", "Invalid file type");
      setSelectedFile(null);
      setProgress(0);
      return;
    }

    try {
      setUploadStatus("uploading");

      const formData = new FormData();
      if (selectedFile) {
        formData.append("file", selectedFile);
      }
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/projects/upload-file`, formData, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });
      console.log("Response", response);
      if (response.status === 200 || response.status === 201) {
        setUploadStatus("done");
        setFileId(response?.data?.file_id);
      } else {
        Alert("error", "Something went wrong", "Error");
        clearFileInput();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        Alert("error", error.message, "Error");
      }
      clearFileInput();
    }
  };

  const clearFileInput = () => {
    setSelectedFile(null);
    setProgress(0);
    setUploadStatus("select");
    setFileId(null);
  };
  const {
    workflowId,
    workflowProjectId,
    workflowData,
    formMethods,
    typesToRender,
    isSubmitDisabled,
    fileId,
    onSubmit,
    setFileId,
    isGenerating,
  } = usePerformancePlan();

  const { watch, formState } = formMethods;

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      const file = event.dataTransfer.items[0].getAsFile();
      if (file && file.size <= MAX_FILE_SIZE) {
        setSelectedFile(file);
      } else {
        Alert("error", "File is too large. Maximum file size is 10MB.", "Error");
      }
    }
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        Alert("error", "File is too large. Maximum file size is 10MB.", "Error");
        return;
      }
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleUpload();
    }
  }, [selectedFile]);

  return (
    <Box
      variant={BoxVariants.SIMPLIFIED}
      className='w-full min-w-[282px] max-w-[282px] sticky top-0 max-h-[calc(100vh-60px)] overflow-y-auto border-t-2 border-l-2 border-[#F7F9FB] p-0'
      childrenClassName='flex flex-col justify-between h-full'
    >
      <FormProvider {...formMethods}>
        <div className='h-[90%] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 pt-4 px-4'>
          <form onSubmit={onSubmit}>
            <button
              className='mb-8 flex items-center text-[#766CE4] text-sm leading-tight font-semibold transform [&_svg]:hover:-translate-x-0.5 [&_svg]:transition-all [&_svg]:hover:duration-400'
              onClick={handleNavigateBack}
              type='button'
            >
              <ArrowDownIcon className='mr-2 rotate-90 text-[#766CE4]' />
              Back
            </button>
            <div className='space-y-2'>
              {typesToRender?.map(name => (
                <Accordion
                  key={name}
                  label={titleCase(name)}
                  additionalButtonNode={<CheckMarkToggle filled={Boolean(watch(name) && !formState?.errors?.[name])} />}
                >
                  <PerformancePlanFormField type={name} disabled={false} />
                  {/*<PerformancePlanFormField type={name} disabled={Boolean(workflowId && workflowData)} />*/}
                </Accordion>
              ))}
            </div>
          </form>
          {/*<div className="mt-6">
            <p className="text-sm text-[#5E636E] font-medium">Attachment (optional)</p>
            {selectedFile? 
            <FileLoader
            name={selectedFile?.name}
            format={selectedFile?.name?.split(".").pop()}
            progress={progress}
            status={uploadStatus}
            handleUpload={handleUpload}
            clearFileInput={clearFileInput}
             
          />
            
            :<div onDrop={handleDrop} onDragOver={handleDragOver} className='border border-dashed border-gray-300 rounded-xl mt-2'>
              <div className='pt-6 flex flex-wrap justify-center text-sm leading-6 text-gray-600 align-center'>
                <p className='pe-1 text-base font-bold text-[#464A53]'>
                  Drag and drop <span className='font-medium'>or</span>
                </p>
                <label className='relative cursor-pointer bg-white font-bold text-base text-[#766CE4] hover:text-[#766CE4] rounded-lg decoration-2 hover:underline outline-none'>
                  <span>Click to Upload</span>
                  <input ref={inputRef} type='file' onChange={handleFileChange} className='sr-only' accept='.csv, .xls, .xlsx, excel/*' />
                </label>
              </div>
              <p className='text-[#9EA2AD] text-xs mt-2 text-center'>Supported formats: Excel, CSV, PDF</p>
              <p className='text-[#9EA2AD] text-xs mt-2 mb-10 text-center'>(Max. File size: 25 MB)</p>
            </div>}
          </div>*/}
        </div>
        <div className='pb-4 px-4'>
          <Button onClick={onSubmit} variant={ButtonVariants.MEDIUM} disabled={isSubmitDisabled || isGenerating}>
            Generate
          </Button>
        </div>
      </FormProvider>
    </Box>
  );
};
