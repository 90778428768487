import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { Pagination } from "flowbite-react";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { IChannelData } from "api/budget/types";
import { deleteMultiProjects } from "api/projects";
import { TaskItem } from "api/task/type";
import { deleteWorkflow } from "api/workflows";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";
import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";
import { ROUTES } from "constants/routes.constants";
import { ModalVariants } from "constants/shared/modal.constants";
import { SOCIAL_PLATFORM_ICONS } from "constants/social-icon.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { getPerformancePlanUrlById } from "utils/url.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";
import { useGetAllProject } from "hooks/queries/project";
import { useGetAllWorkflow, useGetWorkflowStrategyTemplate } from "hooks/queries/workflows";
import { useBoolean } from "hooks/useBoolean";
import { useSorting } from "hooks/useSorting";

import { getAllWorkflows } from "storage/actions/workflows";
import { selectWorkflowsWithIsLoaded } from "storage/selectors/workflows";

import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import { Input } from "shared/components/form-elements/Input";
import { Icons } from "shared/components/icons";
import { Modal } from "shared/components/modal/Modal";
import { ModalControlButtons } from "shared/components/modal/ModalControlButtons";
import { Skeleton } from "shared/components/skeleton";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { BudgetsTable } from "page-components/budgets/budgets-table/BudgetsTable";

import { Table } from "pages/budget/Budget/BudgetListTable";
import ChannelTagsWrapper from "pages/budget/Budget/ChannelTagsWrapper";
import { IndeterminateCheckbox } from "pages/budget/Budget/component/CheckBox";

import { cn } from "lib/utils";

type QueryFilter = "all" | "task" | "workflow" | "created";

export default function Projects() {
  const dispatch = useAppDispatch();
  const { preferences } = useAppSelector(state => state.preferences);
  const [selectedBudgetId, setSelectedBudgetId] = useState<NumberOrNull>(null);
  const [selectedStrategyId, setSelectedStrategyId] = useState<NumberOrNull>(null);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<NumberOrNull>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sorting, setSorting] = useState<SortingState>([]);
  const sortingData = useSorting();
  const [deleting, setDeleting] = useState<boolean>(false);
  const columnHelper = createColumnHelper<any>();
  const [isModalOpen, _, handleOpenModal, handleCloseModal] = useBoolean();
  const [isDeleteModalOpen, __, handleOpenDeleteModal, handleCloseDeleteModal] = useBoolean();

  const [search, setSearch] = useState<string>("");
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();
  const [debouncedSearch] = useDebounce(search, 300);
  const [projectPage, setProjectPage] = useState(1);

  const { data: workflows, refetch: refreshWorkflowState } = useGetAllWorkflow({
    sort_order: sortingData.sorting.sort_order,
    sort_by: sortingData.sorting.sort_by,
    search: debouncedSearch,
  });

  const { data: channels } = useGetChannels();
  const { data: projectsState, isLoading: projectStateIsLoading, refetch: refreshProjectState } = useGetAllProject(projectPage);

  const queryParams = new URLSearchParams(location.search);

  const queryFilter = queryParams.get("filter");

  const [filter, setFilter] = useState<QueryFilter>((queryFilter as QueryFilter | undefined) ?? "all");

  const {
    data: taskList,
    isLoading: taskStateIsLoading,
    refetch: reFetchTasks,
  } = useGetWorkflowStrategyTemplate({
    templateType: "task",
    agent_uuid: preferences.agent_uuid,
    page: currentPage,
    enabled: filter === "task",
    search: debouncedSearch,
  });

  const {
    data: workflowList,
    isLoading: workflowStateIsLoading,
    refetch: reFetchWorkFlow,
  } = useGetWorkflowStrategyTemplate({
    templateType: "workflow",
    agent_uuid: preferences.agent_uuid,
    page: currentPage,
    enabled: filter === "workflow",
    search: debouncedSearch,
  });

  const {
    data: workflowAndTaskList,
    isLoading: workflowAndTaskListStateIsLoading,
    refetch: reFetchWorkflowAndTaskList,
  } = useGetWorkflowStrategyTemplate({
    templateType: "",
    page: currentPage,
    enabled: filter === "all",
    search: debouncedSearch,
  });

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRefetchTasksAndWorkList = () => {
    if (filter === "task") {
      reFetchTasks();
    } else if (filter === "workflow") {
      reFetchWorkFlow();
    } else {
      reFetchWorkflowAndTaskList();
    }
  };

  useEffect(() => {
    handleRefetchTasksAndWorkList();
  }, [currentPage, reFetchTasks, reFetchWorkFlow, debouncedSearch]);

  const handleSetBudgetId = (value: number) => setSelectedBudgetId(value);

  const handleProceed = () => {
    if (!selectedBudgetId) return;
    navigate(
      `${ROUTES.createPerformancePlan}?template_id=${selectedWorkflowId}&project_id=${selectedBudgetId}&strategy_id=${selectedStrategyId}`,
    );
  };

  const handleSelectWorkflow = useCallback(
    (workflowId: number, strategyId: number) => {
      setSelectedWorkflowId(workflowId);
      setSelectedStrategyId(strategyId);

      handleOpenModal();
    },
    [selectedWorkflowId],
  );

  const results = useMemo(() => {
    let data = [
      ...(projectsState ?? []).map(project => {
        return {
          name: project.name != "" ? project.name : "Untitled",
          description: project.description ?? "",
          id: project.project_id,
          type: "created",
          status: project.status,
          channels: project.channels,
          label: null,
        };
      }),
      ...(workflows ?? []).map((workflow: any) => {
        return {
          name: workflow.name,
          description: workflow.structured_data?.summary ?? "",
          id: workflow.workflow_id,
          platform: "",
          type: "created_workflow",
          status: workflow.status ?? null,
          channels: workflow.project_channel ? [workflow.project_channel] : [],
          label: null,
          agent_id: workflow.agent_id,
        };
      }),
      ...(taskList?.data ?? []).map((task: any) => {
        return {
          name: task.name,
          description: task.description,
          id: task.channel_id,
          type: "task",
          /// platform: task?.additional_fields?.seo_platform || task?.additional_fields?.social_platform,
          platform: "",
          status: null,
          channels: channels?.filter(channel => channel.channel_id === task.channel_id) ?? [],
          strategy_id: task.strategy_id,
          label: task.label ?? null,
          slug: task.slug,
          agent_id: task.agent_id,
        };
      }),
      ...(workflowList?.data || []).map(workflow => {
        return {
          name: workflow.name,
          description: workflow.description,
          id: workflow.channel_id,
          type: "workflow",
          status: null,
          channels: channels?.filter(channel => channel.channel_id === workflow.channel_id) ?? [],
          strategy_id: workflow.strategy_id,
          label: workflow.label ?? null,
        };
      }),
      ...(workflowAndTaskList?.data || []).map(workflow => {
        return {
          name: workflow.name,
          description: workflow.description,
          id: workflow.channel_id,
          type: "all",
          status: null,
          channels: channels?.filter(channel => channel.channel_id === workflow.channel_id) ?? [],
          strategy_id: workflow.strategy_id,
          label: workflow.label ?? null,
        };
      }),
    ];

    if (filter !== "all") {
      data = data.filter(task => {
        if (filter === "task") return task.type === "task";
        if (filter === "workflow") return task.type === "workflow";
        if (filter === "created") return task.type === "created";
        return true;
      });
    } else {
      data = data.filter(task => task.type !== "task" && task.type !== "workflow");
    }

    return data;
  }, [taskList, filter, projectsState, workflows, channels, queryFilter, queryParams, preferences]);

  const tableData = useMemo(() => {
    // join projects and workflows
    const projectsData = (projectsState ?? []).map(project => {
      return {
        name: project.name != "" ? project.name : "Untitled",
        description: project.description ?? "",
        id: project.project_id,
        type: "project",
        date_created: project.date_created,
        last_modified: project.lastModified,
        status: project.status,
        channels: project.channels,
      };
    });

    const workflowsData = (workflows ?? []).map(workflow => {
      return {
        name: workflow.name,
        description: "",
        id: workflow.workflow_id,
        platform: "",
        type: "workflow",
        date_created: workflow.date_created,
        last_modified: workflow.last_modified,
        status: workflow.status ?? "active",
        channels: workflow.project_channel ? [workflow.project_channel] : [],
      };
    });

    let data = [...projectsData, ...workflowsData];

    if (search !== "") {
      data = data.filter(task => task.name.toLowerCase().includes(search.toLowerCase()));
    }

    return data;
  }, [projectsState, workflows, search]);

  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    }),
    columnHelper.accessor("name", {
      header: "Project Name",
      cell: info => <p className='text-sm font-medium text-[#1A212B] capitalize'>{info.getValue() ? info.getValue() : "Untitled"}</p>,
    }),
    columnHelper.accessor("channels", {
      header: "Channels",
      cell: info => <ChannelTagsWrapper value={info.getValue()} />,
    }),
    columnHelper.accessor("last_modified", {
      header: "Last modified",
      cell: info => {
        const value = info.getValue();

        if (!value || value == null) {
          return null;
        }

        return dayjs(value).format(FULL_DATE_FORMAT_WITH_COMMA);
      },
    }),
    columnHelper.accessor("date_created", {
      header: "Created",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: info => {
        const value = info.getValue();

        return (
          <div
            className={"text-xs font-semibold px-3 py-1 rounded-full w-fit flex items-center capitalize"}
            style={{ backgroundColor: value === "active" ? "#DFFFE2" : "#F2F9FF", color: value === "active" ? "#02A613" : "#5C8DFF" }}
          >
            <div className='w-1 h-1 mr-1.5 rounded-full' style={{ backgroundColor: value === "active" ? "#02A613" : "#5C8DFF" }} />{" "}
            {value ? value : "Unknown"}
          </div>
        );
      },
    }),
    columnHelper.accessor("workflow_id", {
      id: "action",
      header: "Action",
      enableSorting: true,
      cell: info => (
        <button className='rounded-xl text-sm font-semibold text-[#766CE4]' onClick={() => handleViewBudget(info.row.original)}>
          View
        </button>
      ),
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      globalFilter: search,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onGlobalFilterChange: setSearch,
    globalFilterFn: "includesString",
  });

  const handleViewBudget = async (rowData: any) => {
    const id = rowData.id;
    const type = rowData.type;

    if (type === "workflow") {
      navigate(getPerformancePlanUrlById(id));
      return;
    }

    if (rowData.status !== "active") {
      navigate(ROUTES.editBudgetForm + "/" + id);
    } else {
      navigate(`/budget/${id}/budget-intelligence`);
    }
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleSortButtonClick = () => {
    setSortOrder(prevSortOrder => (prevSortOrder === "desc" ? "asc" : "desc"));
    setSorting([{ id: "date_created", desc: sortOrder === "asc" }]);
  };

  const handleCreateNewBudget = () => {
    // if (!isAvailableToCreateBudget) {
    //   dispatch(
    //     openPopup({
    //       content: "MembershipsModal",
    //     }),
    //   );
    //   return;
    // }
    // dispatch(
    //   openPopup({
    //     content: "CreateBudget",
    //   }),
    // );
  };
  const deleteSelectedProjects = () => {
    const projectIds: number[] = [];
    const workflowIds: number[] = [];
    selectedRows.forEach(row => {
      if (row.original.id && row.original.type === "project") {
        projectIds.push(row.original.id);
      }

      if (row.original.id && row.original.type === "workflow") {
        workflowIds.push(row.original.id);
      }
    });

    if (projectIds.length === 0 && workflowIds.length === 0) {
      Alert("error", "No projects selected", "Error");
      return;
    }

    const promise = Promise.all([
      projectIds.length > 0 ? deleteMultiProjects({ project_ids: projectIds }) : Promise.resolve(),
      workflowIds.map(id => deleteWorkflow(id)),
    ]);

    setDeleting(true);

    promise
      .then(_ => {
        table.resetRowSelection();
        refreshProjectState();
        refreshWorkflowState();
        handleCloseDeleteModal();
      })
      .catch(err => {
        Alert("error", err?.message, "Error");
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const selectedRows = table.getSelectedRowModel().rows;
  const selectedRowsLength = selectedRows?.length;

  const handleShowSearchInput = () => {
    setShowSearchInput(!showSearchInput);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <DashboardLayout title='Browse templates' containerClassName='bg-white'>
      <div className='px-6 pt-6 pb-10'>
        <Breadcrumbs
          breadcrumbs={[
            {
              path: "/",
              label: "Home",
              active: false,
            },
            {
              path: "",
              label: "Browse templates",
              active: true,
            },
          ]}
        />
        <div className='mt-2 flex flex-col mb-4'>
          <h1 className='text-2xl text-[#333740] font-semibold'>Browse all templates</h1>
          <div className='flex justify-between items-center mt-6 '>
            <div className='flex items-center gap-x-2 pr-4 '>
              <FilterBtn label='All' active={filter === "all"} value='all' onClick={setFilter} />
              <FilterBtn label='Tasks' active={filter === "task"} value='task' onClick={setFilter} />
              <FilterBtn label='Workflows' active={filter === "workflow"} value='workflow' onClick={setFilter} />
              <FilterBtn label='Created' active={filter === "created"} value='created' onClick={setFilter} />
              <div className='px-2 flex gap-1 border-l border-[#E9EAEC]'>
                {showSearchInput && (
                  <Input
                    onChange={handleSearchInputChange}
                    placeholder='Search templates'
                    maxLength={50}
                    containerClassName='flex flex-col gap-1'
                    className='!rounded-[24px]'
                  />
                )}
                <button
                  onClick={handleShowSearchInput}
                  className='border border-[#E9EAEC] p-2.5 rounded-[24px] text-sm font-medium text-[#717684]'
                >
                  <Icons.Search02 className='' />
                </button>
                {selectedRowsLength > 0 && (
                  <button onClick={handleOpenDeleteModal} className='border border-[#E9EAEC] p-2.5 rounded-[24px] text-sm font-medium '>
                    <Icons.Trash2 className='h-5 w-5 !text-[#B32318]' />
                  </button>
                )}
              </div>
            </div>

            <div className='flex overflow-x-auto justify-end sm:justify-center px-4'>
              <Pagination
                currentPage={currentPage}
                layout='navigation'
                totalPages={
                  filter === "workflow"
                    ? workflowList?.totalPages || 0
                    : filter === "all"
                      ? workflowAndTaskList?.totalPages || 0
                      : taskList?.totalPages || 0
                }
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
        {filter == "created" ? (
          <Table table={table} selectedRows={selectedRows} />
        ) : (
          <div className='grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-4 '>
            {workflowStateIsLoading || taskStateIsLoading || projectStateIsLoading || workflowAndTaskListStateIsLoading ? (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton className='rounded-[1.25rem] w-full h-[211px] bg-[#F7F7F8]' key={index} />
                ))}
              </>
            ) : (
              <>
                {results.length > 0 ? (
                  results.map((item, index) => <TaskCard key={index} item={item} handleSelectWorkflow={handleSelectWorkflow} />)
                ) : (
                  <div></div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        title='Select budget to start'
        description='Our AI intelligence platform can create a new marketing channel budget or help you optimize your existing budget for the year.'
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <BudgetsTable
          data={(projectsState ?? []).filter(project => project.status == "active")}
          selectedId={selectedBudgetId}
          handleSelectBudgetId={handleSetBudgetId}
        />
        <ModalControlButtons
          primaryButtonDisabled={!selectedBudgetId}
          buttonClass='px-[42px] w-fit'
          submitButtonText='Proceed'
          handleSubmit={handleProceed}
        />
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        title='Are you sure?'
        titleClass='!text-[28px] !font-semibold'
        popupClass='!p-16'
        description={`Deleting ${selectedRowsLength > 1 ? "these" : "this"} ${selectedRowsLength} item${selectedRowsLength > 1 ? "s" : ""} is permanent and cannot be undone.`}
        variant={ModalVariants.FIT_CONTENT}
      >
        <ModalControlButtons
          buttonClass='px-[42px] w-fit text-nowrap'
          submitButtonText='Yes, Delete'
          handleSubmit={deleteSelectedProjects}
          handleCancel={handleCloseDeleteModal}
        />
      </Modal>
    </DashboardLayout>
  );
}

const FilterBtn = ({
  label,
  active,
  onClick,
  value,
}: {
  label: string;
  active: boolean;
  value: "all" | "task" | "workflow" | "created";
  onClick: (value: "all" | "task" | "workflow" | "created") => void;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick(value);
    navigate(ROUTES.projects + "?" + qs.stringify({ filter: value }));
  };

  return (
    <button
      onClick={handleClick}
      className={cn(" px-5 py-2.5 rounded-[24px] text-sm font-medium", active ? "bg-[#F7F7F8]" : "border border-[#E9EAEC] text-[#717684]")}
    >
      {label}
    </button>
  );
};

const TaskCard = ({
  item,
  handleSelectWorkflow,
}: {
  item: {
    name: string;
    description: string;
    id: number;
    type: string;
    platform?: string;
    status: string | null;
    channels: IChannelData[] | null;
    strategy_id?: number;
    label: string | null;
    slug?: string;
  };
  handleSelectWorkflow: (workflowId: number, strategyId: number) => void;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    switch (item.type) {
      case "created_workflow":
        navigate(getPerformancePlanUrlById(item.id));
        return;
      case "created":
        if (item.status != "active") {
          navigate(ROUTES.editBudgetForm + "/" + item.id);
        } else {
          navigate(`/budget/${item.id}/budget-intelligence`);
        }
        return;

      case "task":
      default:
        if (item.type === "task" && (item.strategy_id == 100 || item.slug === "create_new_marketing_budget")) {
          navigate(ROUTES.budgetForm + "?task_id=" + item.id);
          return;
        }

        handleSelectWorkflow(item.id, item.strategy_id ?? 0);
    }
  };

  return (
    <button onClick={handleClick} className='bg-[#F7F7F8] rounded-[1.25rem] p-6 gap-x-6 w-full overflow-hidden'>
      <div className='flex flex-col justify-between items-start h-full w-full gap-4'>
        <div className='flex flex-col gap-4 w-full'>
          <div className='flex gap-2'>
            {item.type == "task" ? (
              <Icons.Calender className='w-4 h-4 text-[#9EA2AD]' />
            ) : item.type == "workflow" ? (
              <Icons.Data2 className='w-4 h-4 text-[#9EA2AD]' />
            ) : (
              <Icons.Document className='w-4 h-4 text-[#9EA2AD]' />
            )}
            <p className='text-[#717684] font-medium text-xs capitalize'>{item.type}</p>
          </div>
          <div className='flex flex-col items-start gap-2 w-full text-left'>
            <h1 className='font-semibold text-base text-black1'>{item.name ?? "Untitled"}</h1>
            <p className='w-full font-medium text-sm text-grey1 line-clamp-1 break-words'>{item.description}</p>
          </div>
        </div>
        <div className='flex flex-wrap justify-start gap-y-0.5'>
          {item.platform && (
            <>
              {item.platform.split(",").map((platform, index) => {
                const iconName = platform.toLowerCase();

                return (
                  <span
                    key={index}
                    className='inline-flex items-center gap-1 border border-[#E9EAEC] text-xs font-semibold text-[#464A53] bg-white py-2 px-3 rounded-full'
                  >
                    {SOCIAL_PLATFORM_ICONS(iconName)}
                    {platform}
                  </span>
                );
              })}
            </>
          )}

          {item.channels &&
            item.channels.map((item, index: number) => {
              let Icon = null;

              if (item.channel_id === 0) {
                return null;
              }

              if (item && item?.icon) {
                Icon = CHANNEL_FLOW_ICONS?.[item?.icon as keyof typeof CHANNEL_FLOW_ICONS];
              }

              return (
                <div
                  key={index}
                  className='inline-flex items-center gap-1 border border-[#E9EAEC] text-xs font-semibold text-[#464A53] bg-white py-2 px-3 rounded-full'
                >
                  {Icon && <Icon className='w-4 h-4 mr-1.5' />}
                  {item?.name}
                </div>
              );
            })}

          {(item.strategy_id == 100 || item.strategy_id == 101) && item.label != null && (
            <div className='inline-flex items-center gap-1 border border-[#E9EAEC] text-xs font-semibold text-[#464A53] bg-white py-2 px-3 rounded-full'>
              {item.label}
            </div>
          )}
        </div>
      </div>
    </button>
  );
};
