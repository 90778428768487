import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from "@headlessui/react";
import cn from "classnames";
import React, { ReactNode } from "react";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";

interface Props {
  children: ReactNode;
  label: string;
  additionalButtonNode?: ReactNode;
}

export const Accordion = ({ children, label, additionalButtonNode }: Props) => {
  return (
    <div className='mx-auto w-full max-w-lg bg-white border border-[#E9EAEC] rounded-xl'>
      <Disclosure>
        {({ open }) => (
          <>
            <DisclosureButton className='group flex w-full items-center justify-between py-3.5 px-4'>
              <>
                <div className='flex items-center'>
                  {additionalButtonNode}
                  <p className='text-sm font-medium text-[#5E636E]'>{label}</p>
                </div>
                <ArrowDownIcon className={cn("w-5 transition duration-200", { "rotate-180": open })} />
              </>
            </DisclosureButton>
            <Transition
              unmount={false}
              enterFrom='transform max-h-0'
              enterTo='transform opacity-100 max-h-[1000px]'
              leaveFrom='transform opacity-100 max-h-[1000px]'
              leaveTo='transform opacity-0 max-h-0'
            >
              <DisclosurePanel unmount={false} className='origin-top transition-all duration-200 mt-1 px-4 pb-3.5'>
                {children}
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};
