import { HumanInterrupt } from "@langchain/langgraph/prebuilt";
import { toast } from "sonner";
import { StringParam, useQueryParam } from "use-query-params";

import { ButtonVariants } from "constants/shared/button.constants";

import { Button } from "shared/components/buttons/Button";

import { cn } from "lib/utils";

import useInterruptedActions from "../hooks/use-interrupted-actions";

import ConnectDataSources from "./ConnectDataSources";
//import { InboxItemInput } from "./inbox-item-input";
import { ThreadIdCopyable } from "./thread-id";

interface ConnectSourcesViewProps {
  interrupt: HumanInterrupt;
  handleShowSidePanel: (showState: boolean, showDescription: boolean) => void;
  showState: boolean;
  showDescription: boolean;
}

/*
function ButtonGroup({
  handleShowState,
  handleShowDescription,
  showingState,
  showingDescription,
}: {
  handleShowState: () => void;
  handleShowDescription: () => void;
  showingState: boolean;
  showingDescription: boolean;
}) {
  return (
    <div className="flex flex-row gap-0 items-center justify-center">
      <Button
        variant="outline"
        className={cn(
          "rounded-l-md rounded-r-none border-r-[0px]",
          showingState ? "text-black" : "bg-white",
        )}
        size="sm"
        onClick={handleShowState}
      >
        State
      </Button>
      <Button
        variant="outline"
        className={cn(
          "rounded-l-none rounded-r-md border-l-[0px]",
          showingDescription ? "text-black" : "bg-white",
        )}
        size="sm"
        onClick={handleShowDescription}
      >
        Description
      </Button>
    </div>
  );
}
*/

export function ConnectSourcesView({
  interrupt,
  handleShowSidePanel,
  showDescription,
  showState,
}: ConnectSourcesViewProps) {

  console.log("interrupt", interrupt);

  const [threadId] = useQueryParam("threadId", StringParam);
  const {
    acceptAllowed,
    hasEdited,
    hasAddedResponse,
    streaming,
    supportsMultipleMethods,
    streamFinished,
    loading,
    handleSubmit,
    handleIgnore,
    handleResolve,
    setSelectedSubmitType,
    setHasAddedResponse,
    setHasEdited,
    humanResponse,
    setHumanResponse,
    initialHumanInterruptEditValue,
  } = useInterruptedActions({
    interrupt,
  });
  const [apiUrl] = useQueryParam("apiUrl", StringParam);

  const handleOpenInStudio = () => {
    if (!apiUrl) {
      toast.error("Error", {
        description: "Please set the LangGraph deployment URL in settings.",
        duration: 5000,
        richColors: true,
        closeButton: true,
      });
      return;
    }

    const studioUrl = ""; //constructOpenInStudioURL(apiUrl, threadId ?? undefined);
    window.open(studioUrl, "_blank");
  };

  const threadTitle = interrupt.action_request.action || "Unknown";
  const actionsDisabled = loading || streaming;

  const interruptConfig = interrupt.config;

  return (
    <div className='flex flex-col min-h-full w-full gap-9'>
      <div id='conctdDiv' style={{ float: "left", marginTop: "20px" }}>
        <ConnectDataSources
          interrupt={interrupt}
          humanResponse={humanResponse}
          setHumanResponse={setHumanResponse}
          setHasEdited={setHasEdited}
          setSelectedSubmitType={setSelectedSubmitType}
          onSourcesChange={sources => {
            if (sources.length > 0) {
              setHasAddedResponse(true);
            } else {
              setHasAddedResponse(false);
            }
          }}
        />
      </div>

      <div>
        {!hasAddedResponse ? (
          <>
            Once you have selected a source, we can proceed.
          </>
        ) : (
          <>
            When you have finished selecting sources, you can click Done to proceed.
          </>
        )
      }
      </div>
      <div className='flex flex-row gap-2 items-center justify-end w-full'>
        {/*interruptConfig.allow_accept && (
          <Button
            variant='outline'
            className='text-gray-800 border-gray-500 font-normal bg-white'
            onClick={handleResolve}
            disabled={actionsDisabled}
          >
            Mark as Resolved
          </Button>
        )*/}
        {/*interruptConfig.allow_ignore && (
          <Button
            variant='outline'
            className='text-gray-800 border-gray-500 font-normal bg-white'
            onClick={handleIgnore}
            disabled={actionsDisabled}
          >
            Ignore
          </Button>
        )*/}
        {(interruptConfig.allow_edit && hasAddedResponse) && (
          <Button
            variant={ButtonVariants.MEDIUM}
            onClick={handleSubmit}
            disabled={actionsDisabled}
            className="flex flex-row-reverse text-white bg-[#766CE4] w-[72px] py-[7px] text-sm"
          >
            Done
          </Button>
        )}
      </div>

      {/* Actions */}
      {/*
        <InboxItemInput
          acceptAllowed={acceptAllowed}
          hasEdited={hasEdited}
          hasAddedResponse={hasAddedResponse}
          interruptValue={interrupt}
          humanResponse={humanResponse}
          initialValues={initialHumanInterruptEditValue.current}
          setHumanResponse={setHumanResponse}
          streaming={streaming}
          streamFinished={streamFinished}
          supportsMultipleMethods={supportsMultipleMethods}
          setSelectedSubmitType={setSelectedSubmitType}
          setHasAddedResponse={setHasAddedResponse}
          setHasEdited={setHasEdited}
          handleSubmit={handleSubmit}
        />
      */}
    </div>
  );
}
