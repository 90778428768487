import React from "react";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import { Icons } from "shared/components/icons";

import { NotificationData } from ".";

interface WhatsAppUIProps {
  data: NotificationData;
}

const WhatsAppUI: React.FC<WhatsAppUIProps> = ({ data }) => {
  const { logo = "gmail", title, message, issueSummary, currentStock, productId, productName, timestamp } = data;

  const showImage = (logo: string) => {
    const Icon = INTEGRATION_ICONS?.[logo as keyof typeof INTEGRATION_ICONS];
    if (!Icon) return <img src={""} alt='logo' className='w-6 h-6' />;
    return <Icon className='w-4 h-4' />;
  };
  return (
    <div className='mx-auto flex-1 bg-[#E7DCD4] border border-gray-200 rounded-2xl w-full max-w-[504px] my-2'>
      <div className='flex items-center mb-2 bg-[#054D47] rounded-t-2xl px-4 py-2 gap-2'>
        {showImage(logo)}
        <span className='font-semibold text-white text-sm '>#logistics</span>
      </div>

      <div className='w-full max-w-[345px] mx-2'>
        <div className='relative bg-white py-4 px-2 rounded'>
          <div className='absolute left-[-6px] top-[0px] '>
            <div className='w-0 h-0 border-l-[15px]   border-l-transparent border-t-[15px] border-t-white border-r-[15px] border-r-transparent'></div>
          </div>
          <h3 className='text-base font-extrabold text-black  flex items-center mb-5'>⚡{title}⚡</h3>
          <p className='  text-[#414141] font-semibold text-sm mb-5'>
            👋 <span className='ml-1'> Hello Jim</span>
          </p>
          <p className='mb-4 text-[#303030] font-medium text-sm '>{message}</p>
          <ol className='list-decimal list-inside mb-2'>
            <li>
              <span className='border-2 border-[#DEDEDE] text-[#0096DE] px-[6px] py-[2px] rounded font-semibold text-sm'>
                {productName}
              </span>
            </li>
          </ol>
          <div className='ml-5'>
            <p className='mb-2 text-[#464646] text-sm font-semibold mt-2'>
              Product ID:{" "}
              <span className='border-2 border-[#DEDEDE]  text-[#0096DE] px-[6px] py-[2px] rounded font-semibold text-sm'>{productId}</span>{" "}
            </p>
            <p className='mb-2 text-[#464646] text-sm font-semibold mt-2'>
              - Current Stock:
              <span className='border-2 border-[#DEDEDE] text-[#0096DE] px-[6px] py-[2px] rounded font-semibold text-sm'>
                {currentStock}
              </span>
            </p>
            <p className='mt-2 text-[#444444] text-sm font-semibold'>
              Issue Summary: <span className='text-[#303030] text-sm font-normal'>{issueSummary}</span>
            </p>
          </div>
          <p className='text-[#9DA3A7] text-sm text-end m-1'>{timestamp}</p>
        </div>

        <div className='flex w-full gap-2 rounded my-1'>
          <button className='flex items-center gap-2 justify-center w-full bg-white text-[#0096DE] p-2  rounded text-base '>
            <Icons.ExternalLink /> Action Needed
          </button>
          <button className='flex items-center gap-2 justify-center w-full bg-white text-[#0096DE]  p-2 rounded border text-base '>
            <Icons.ExternalLink /> View Analysis
          </button>
        </div>
        <div className='relative flex justify-between items-center w-full bg-white rounded my-1 px-2 py-1'>
          <div className='absolute left-[-6px] top-[0px] '>
            <div className='w-0 h-0 border-l-[15px]   border-l-transparent border-t-[15px] border-t-white border-r-[15px] border-r-transparent'></div>
          </div>
          <p className='text-sm text-left text-[#303030] font-medium'>Was this update helpful?</p>
          <p className='text-[#9DA3A7] text-sm text-end m-1'>{timestamp}</p>
        </div>

        <div className='flex w-full gap-2 rounded my-1'>
          <button className=' w-full bg-white text-[#0096DE] p-2  rounded text-base '>Yes, keep going</button>
          <button className=' w-full bg-white text-[#0096DE]  p-2 rounded border text-base '>Yes, keep going</button>
        </div>

        <button className='  w-full bg-white text-[#0096DE] mb-5  p-2 rounded-md   text-base whitespace-nowrap'>
          Manage notifications
        </button>
      </div>
    </div>
  );
};

export default WhatsAppUI;
