import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react";
import { FC } from "react";

import { CheckboxPurple } from "../dropdown-checkbox/CheckboxPurple";
import { Icons } from "../icons";

interface Option {
  id: number;
  name: string;
  avatar?: string;
}

interface MultiSelectListBoxProps {
  options: Option[];
  selectedOptions: Option[];
  onChange: (selected: Option[]) => void;
}

const MultiSelectListBox: FC<MultiSelectListBoxProps> = ({ options, selectedOptions, onChange }) => {
  return (
    <div className='w-full relative'>
      <Listbox value={selectedOptions} onChange={onChange} multiple>
        {({ open }) => (
          <>
            <ListboxButton className='w-full bg-white border border-gray-300 rounded-xl h-10 py-2 px-3 flex justify-between items-center text-gray-700 focus:ring-2 focus:ring-blue-500'>
              <div className='text-[#5E636E] text-sm font-medium overflow-hidden flex items-center space-x-1'>
                {selectedOptions.length > 0 ? (
                  <>
                    {selectedOptions.slice(0, 3).map(opt => (
                      <button
                        type='button'
                        key={opt.name}
                        className='bg-[#F7F7F8] rounded-md px-2 py-1.5 text-xs font-medium text-[#2C1A49] inline-flex items-center justify-center'
                      >
                        {opt.name}{" "}
                        <Icons.Close
                          onClick={() => onChange(selectedOptions.filter(option => option.name !== opt.name))}
                          className='h-4 w-4 ml-1.5 cursor-pointer'
                        />
                      </button>
                    ))}
                    {selectedOptions.length > 3 && (
                      <span className='text-xs font-medium text-[#2C1A49]'>{selectedOptions.length - 3}+ more</span>
                    )}
                  </>
                ) : (
                  "Search to select groups, channels or slack users"
                )}
              </div>
              {open ? <Icons.ArrowUp className='w-5 h-5 text-gray-500' /> : <Icons.ArrowDown className='w-5 h-5 text-gray-500' />}
            </ListboxButton>
            {open && (
              <ListboxOptions className='absolute z-50 mt-2 w-full bg-white border border-gray-200 rounded-[13px] shadow-lg max-h-32 py-2 px-2 overflow-y-auto scrollbar-track-[#F1F0FC] scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-[#9189E9] focus:outline-none'>
                {options.map(option => (
                  <ListboxOption
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      `cursor-pointer select-none relative py-1 pl-10 pr-4 my-2 flex items-center gap-2 ${active ? "bg-[#F9F9FF] rounded-xl" : ""}`
                    }
                  >
                    {({ selected }) => (
                      <>
                        {option.avatar && typeof option.avatar === "string" && option.avatar && (
                          <img src={option.avatar} alt={option.name} className='w-[18px] h-[18px] rounded-full' />
                        )}
                        <span className='block truncate text-sm font-medium text-[#2C1A49]'>{option.name}</span>
                        <div className='absolute left-2 w-4 h-4'>
                          <CheckboxPurple readOnly checked={selected} />
                        </div>
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MultiSelectListBox;
