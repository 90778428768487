import cn from "classnames";

import { BoxVariants } from "constants/shared/box.constants";

import { StringOrNumber } from "interfaces/shared-types.interfaces";

import { getWorkflowDetailsUrlById } from "utils/url.utils";

import { useAppSelector } from "hooks/appHooks";
import { usePerformancePlan } from "hooks/performance-plans/usePerformancePlan";

import { Box } from "shared/components/Box";
import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";

import { ReactComponent as CheckmarkIcon } from "assets/icons/editor-checkmark.svg";

import { PerformancePlanContentWrapper } from "./PerformancePlanContentWrapper";

// TODO - connect use-react-breadcrumbs-router and config breadcrumbs
const getBreadCrumbs = (budgetId: StringOrNumber, channelName: string, workflow: string) => [
  { path: getWorkflowDetailsUrlById(budgetId), label: channelName },
  { path: getWorkflowDetailsUrlById(budgetId), label: workflow },
  // { path: getWorkflowDetailsUrlById(budgetId), label: "Performance Plan", active: true },
];

export const PerformancePlanContentBlock = () => {
  const { unsaved } = useAppSelector(state => state.editor);

  const { createdWorkflow, isGenerating } = usePerformancePlan();

  const { project_id: workflowProjectId, name: workflowName, project_channel: projectChannel } = createdWorkflow || {};

  return (
    <div className='w-full max-h-[calc(100vh-148px)]'>
      <div className='py-4 pr-6 flex items-center justify-between'>
        {workflowProjectId && (
          <Breadcrumbs breadcrumbs={getBreadCrumbs(workflowProjectId, projectChannel?.name || "", workflowName || "")} />
        )}
        {createdWorkflow?.workflow_data == null || createdWorkflow?.workflow_data == undefined ? (
          <></>
        ) : unsaved ? (
          <p className='text-sm font-medium leading-default text-[#9EA2AD]'>Unsaved</p>
        ) : (
          <div className='flex items-center text-sm font-medium text-[#9EA2AD] leading-default gap-[6px]'>
            <CheckmarkIcon />
            Saved
          </div>
        )}
      </div>
      <Box
        variant={BoxVariants.SHADOWED}
        className={cn("mr-6 overflow-y-auto h-full relative", { "p-0": createdWorkflow?.workflow_data })}
        childrenClassName='h-full overflow-hidden'
      >
        <PerformancePlanContentWrapper hidden={!workflowProjectId} isLoading={isGenerating} content={createdWorkflow?.workflow_data} />
      </Box>
    </div>
  );
};
