import { flexRender, Row, RowData } from "@tanstack/react-table";
import React from "react";

import { TableRow } from "shared/components/table/TableRow";

type TableRowComponentProps = {
  row: Row<RowData>;
  isSelected: boolean;
};

const TableRowComponent = ({ row, isSelected }: TableRowComponentProps) => {
  return (
    <TableRow isSelected={isSelected ? true : undefined} key={row.id}>
      {row.getVisibleCells().map(cell => (
        <td
          key={cell.id}
          className={`text-[#1A212B] text-sm font-medium leading-[18px] py-2.5 ${cell.column.id === "channels" ? "pb-1.5" : ""} max-w-64  bg-white ${cell.column.id === "select" ? "w-6 px-3 text-center" : "px-3"}`}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </TableRow>
  );
};

export default React.memo(TableRowComponent);
