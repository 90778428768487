import cn from "classnames";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import Button from "shared/components/auth-buttons/Button";
import Alert from "shared/components/toasts";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import { ReactComponent as IndiaFlagIcon } from "assets/icons/flags/india-flag.svg";
import { ReactComponent as USFlagIcon } from "assets/icons/flags/us-flag.svg";

interface OverlayProps {
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (country: "US" | "IN") => Promise<any>;
}

const AmazonSellerConnectModal: React.FC<OverlayProps> = ({ onClose, onOpen, onSubmit }) => {
  const [selectedCountry, setSelectedCountry] = useState<"US" | "IN" | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCountrySelect = (country: "US" | "IN") => {
    setSelectedCountry(country);
  };

  const handleOnSubmit = () => {
    if (!selectedCountry) return;
    
    setIsLoading(true);
    onSubmit(selectedCountry)
      .then(res => {
        window.location.href = res.authorizationUrl;
      })
      .catch(err => {
        Alert("error", err.message, "Error");
        setIsLoading(false);
      });
  };

  return ReactDOM.createPortal(
    <div id="overlay-general">
      <div className="flex flex-col bg-white p-20 rounded-3xl relative">
        <CloseWhiteBtn height={36} width={36} className="absolute top-6 right-6" onClick={onClose} />

        <div className="flex flex-col gap-6 items-center">
          <p className="text-3xl font-semibold text-[#1F2228]">Select Amazon Marketplace</p>
          <p className="text-base font-medium text-[#5E636E] leading-7">
            Choose the Amazon marketplace you want to connect to.
          </p>
        </div>
        
        <div className="mt-10 flex flex-col gap-6">
          <div className="grid grid-cols-2 gap-4">
            {/* US Button */}
            <button
              onClick={() => handleCountrySelect("US")}
              className={cn(
                "flex items-center gap-3 p-4 border rounded-xl transition-all",
                selectedCountry === "US"
                  ? "border-[#6251f3] bg-[#F7F5FF]"
                  : "border-[#E5E7EB] hover:border-[#afa8f4]"
              )}
            >
              <div className="w-8 h-8 flex items-center justify-center">
                <USFlagIcon className="w-6 h-6" />
              </div>
              <span className="text-base font-medium">United States</span>
            </button>
            
            {/* India Button */}
            <button
              onClick={() => handleCountrySelect("IN")}
              className={cn(
                "flex items-center gap-3 p-4 border rounded-xl transition-all",
                selectedCountry === "IN"
                  ? "border-[#6251f3] bg-[#F7F5FF]"
                  : "border-[#E5E7EB] hover:border-[#afa8f4]"
              )}
            >
              <div className="w-8 h-8 flex items-center justify-center">
                <IndiaFlagIcon className="w-6 h-6" />
              </div>
              <span className="text-base font-medium">India</span>
            </button>
          </div>
          
          <p className="text-sm text-[#5E636E] mt-2">
            Note: You will be redirected to Amazon to authorize the connection.
          </p>
        </div>
        
        <div className="mt-10 flex gap-4">
          <Button
            className="py-2 bg-white text-black1 text-sm font-semibold border border-[#afa8f4] flex-nowrap text-nowrap hover:border-[#6251f3] hover:bg-white hover:text-black1 !rounded-[0.75rem]"
            onClick={onClose}
          >
            <p>Cancel</p>
          </Button>
          <Button 
            disabled={!selectedCountry || isLoading} 
            className="px-[42px] !rounded-[0.75rem]" 
            onClick={handleOnSubmit}
          >
            <p>{isLoading ? "Connecting..." : "Continue"}</p>
          </Button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default AmazonSellerConnectModal;
