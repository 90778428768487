import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { isValidUrl } from "constants/validation.constants";

import { Icons } from "shared/components/icons";
import Alert from "shared/components/toasts";

import { ReactComponent as TickIcon } from "assets/icons/check-white.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross-black.svg";
import { ReactComponent as CompanyProfileIcon } from "assets/icons/editIcon.svg";

import { cn } from "lib/utils";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

import { CompanyToneLoader } from "./loadingComponents";

const CompanyCompetitorLinks = () => {
  const [editable, setEditable] = useState(false);
  const [selectedData, setSelectedData] = useState<string[] | undefined>(undefined);
  const { data, loading, apiData, updateApiData, isUrlSet } = useContext(SetupCompanyProfileContext);
  const [showInput, setShowInput] = useState(false);

  const competitiorLinks = useMemo(() => {
    if (selectedData != undefined) {
      return selectedData;
    }

    return (apiData.competitor_links as string[] | undefined) || [];
  }, [selectedData, apiData.competitor_links, data]);

  const handleTick = () => {
    updateApiData({ ...apiData, competitor_links: selectedData });
    setShowInput(false);
    setEditable(false);
  };

  const handleCrossClick = () => {
    setSelectedData(apiData.competitor_links);
    setShowInput(false);
    setEditable(false);
  };
  const updateSelectedData = useCallback(
    (id: string, isChecked: boolean) => {
      setSelectedData(prev => {
        const data = prev || competitiorLinks;

        if (isChecked && data.includes(id) == false) {
          return [...data, id];
        } else if (isChecked == false && data.includes(id) == true) {
          return data.filter(item => item !== id);
        } else {
          return data;
        }
      });
    },
    [selectedData, competitiorLinks],
  );

  const handleAddCompetitorLink = useCallback((value: string) => {
    updateSelectedData(value, true);
    setShowInput(false);
  }, []);

  return (
    <div className='p-6 bg-white rounded-2xl col-span-4'>
      <div className='flex gap-x-4'>
        <div>
          <Icons.Link className='text-[#717684]' />
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className='text-base font-bold text-[#2E323A]'>Competitors links</p>
            <div className='flex items-center gap-x-2'>
              {!editable ? (
                <button
                  disabled={isUrlSet == false}
                  className={cn("p-[7px] rounded-full border border-[#E9EAEC]", isUrlSet == false && "opacity-30")}
                  onClick={() => setEditable(true)}
                >
                  <CompanyProfileIcon className='h-[18px] w-[18px]' />
                </button>
              ) : (
                <>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC]' onClick={handleCrossClick}>
                    <CrossIcon className='h-[18px] w-[18px]' />
                  </button>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC] bg-purple' onClick={handleTick}>
                    <TickIcon className='h-[18px] w-[18px]' />
                  </button>
                </>
              )}
            </div>
          </div>
          {!loading && isUrlSet == true ? (
            <div className='relative flex flex-wrap items-center gap-2'>
              {competitiorLinks.map(link => (
                <CompetitorLinkItem
                  editable={editable}
                  key={link}
                  checked={(selectedData ?? competitiorLinks).includes(link)}
                  onChecked={value => updateSelectedData(link, value)}
                >
                  {link}
                </CompetitorLinkItem>
              ))}
              {showInput && <AddCompetitorLinkInput onAdd={handleAddCompetitorLink} />}
              <button
                onClick={() => setShowInput(true)}
                type='button'
                disabled={showInput || editable == false}
                className={cn(
                  "flex gap-2 items-center justify-center border-[1.6px]  py-2.5 pl-6 pr-4 rounded-[56px] h-10 bg-white border-[#D5D1F7] drop-shadow-sm text-[#766CE4] border-dashed text-sm font-medium disabled:cursor-not-allowed disabled:text-[#D5D1F7] disabled:border-[#D5D1F7]",
                )}
              >
                Add more <Icons.Plus className=' ' />
              </button>
            </div>
          ) : (
            <div className='mt-2'>
              <CompanyToneLoader isLoading={loading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AddCompetitorLinkInput = ({ onAdd, onCancel }: { onAdd: (value: string) => void; onCancel?: () => void }) => {
  const [value, setValue] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleOnAdd = () => {
    if (!isValidUrl(value)) {
      Alert("error", "Invalid url", "Error");
      return;
    }
    onAdd(value);
    setValue("");
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className='h-12 bg-[#ECEAFF] flex items-center justify-center rounded-3xl p-1'>
      <div className='flex items-center bg-white border border-[#766CE4]  rounded-3xl h-10 pl-2.5 pr-3.5'>
        <Icons.Globe className={cn("h-5 w-5 mr-3", value === "" || value.length < 3 ? "text-[#9EA2AD]" : " text-[#A39DED]")} />
        <input
          ref={inputRef}
          value={value}
          onChange={handleChange}
          placeholder='Paste social media URL'
          className='placeholder:text-[#9EA2AD] placeholder:font-medium placeholder:text-sm
        text-[#1F2228] outline-none w-[153px] text-sm font-medium focus-visible:ring-0
        '
        />
        <button
          disabled={value === "" || value.length < 3}
          type='button'
          onClick={handleOnAdd}
          className='ml-6 underline text-sm font-semibold text-purple disabled:text-[#C8CAD0]'
        >
          Add
        </button>
      </div>
    </div>
  );
};

interface ICompetitorLinkItem {
  children: React.ReactNode;
  className?: string;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
  editable: boolean;
}

function CompetitorLinkItem({ children, className, checked, onChecked, editable }: ICompetitorLinkItem) {
  const [value, setValue] = useState(checked || false);

  const handleClick = () => {
    setValue(!value);
    onChecked && onChecked(!value);
  };

  return (
    <div
      className={cn(
        "flex gap-3 items-center justify-center border  py-2.5 pl-4 pr-6 rounded-[56px] bg-white h-10",
        checked == true ? "border-[#A39DED] drop-shadow-sm" : "border-[#E8E9F0]",
        className,
      )}
    >
      <Icons.Globe />
      {children}
      {editable && (
        <button type='button' onClick={editable ? handleClick : undefined}>
          <Icons.Trash2 className='!text-[#B32318]' />
        </button>
      )}
    </div>
  );
}

export default CompanyCompetitorLinks;
