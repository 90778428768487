import React, { useCallback, useState } from "react";

import { TypographyVariants } from "constants/shared/typography.constants";

import { Typography } from "shared/components/Typography";

import { PersonalizationForm } from "page-components/personalization/PersonalizationForm";
import PersonalizationURLForm from "page-components/personalization/PersonalizationUrlForm";

import { ReactComponent as LogoIcon } from "assets/icons/full-logo.svg";
import { ReactComponent as PersonalizeBg } from "assets/icons/personalize_bg.svg";
import { ReactComponent as PersonalizeBgUrl } from "assets/icons/personalize_bg_url.svg";

export const Personalize = () => {
  const [step, setStep] = useState<number>(1);
  const changeStep = useCallback((value: number) => {
    setStep(value);
  }, []);
  return (
    <main className='flex min-h-full bg-[#F7F9FB] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-400 h-screen'>
        {/* Left Body */}
      <div className='w-full md:w-1/2 px-3 flex flex-col bg-white'>
      <div className='py-4 px-7 h-fit cursor-pointer'>
            <LogoIcon />
          </div>
        <div className='h-fit w-full flex flex-col mx-auto mt-[10%] px-10'>
          <div className='mb-10'>
            <div>
              <div className='text-start'>
                <Typography className='text-base font-normal text-[#2C1A49B2]'>Step {step} of 2</Typography>
                <Typography variant={TypographyVariants.H1} className='my-4'>
                  {step === 1 ? "Setup" : "Personalize"} <span className='text-[#9A91A8]'>your account</span>
                </Typography>
                <Typography className='text-[#2C1A49] font-normal text-base opacity-60 text-wrap' variant={TypographyVariants.LG_MEDIUM}>
                  Help us customize your experience by telling us a bit about yourself and your goals.
                </Typography>
              </div>
              <div className='mt-10'>
                {step === 1 && <PersonalizationForm changeStep={changeStep} step={step} />}
                {step === 2 && <PersonalizationURLForm changeStep={changeStep} step={step} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Right Body */}
      <div className='hidden md:flex md:w-1/2 flex-col items-center justify-center'>
        <div className='flex flex-col bg-[#F7F9FB] w-full rounded-r-[36px] relative items-end justify-center'>
          {step ===1 ? <PersonalizeBg className="w-full flex"/>:<PersonalizeBgUrl className="w-full flex"/>}
        </div>
      </div>

    </main>
  );
};
