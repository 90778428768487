import { IChannelData } from "api/budget/types";

import { Icons } from "shared/components/icons";

const WorkflowTemplateCard = ({
  item,
  handleSelectWorkflow,
}: {
  item: {
    name: string;
    description: string;
    id: number;
    type: string;
    platform?: string;
    status: string | null;
    channels: IChannelData[] | null;
    strategy_id?: number;
    label: string | null;
    slug?: string;
  };
  handleSelectWorkflow: (input: string) => void;
}) => {
  return (
    <button
      onClick={() => handleSelectWorkflow(item.description)}
      // className='bg-white rounded-2xl border border-[#E9EAEC] p-3 gap-x-6 w-full h-[90px] hover:border-[#D5D1F7]'
      className='bg-white rounded-2xl border border-[#E9EAEC] p-3 gap-x-6 w-full h-[90px] hover:border-[#D5D1F7] hover:animate-rotate-bounce'
    >
      <div className='flex justify-between items-start h-full w-full gap-3'>
        <div className='mt-1'>
          <Icons.Sparkle className='w-4 h-4 text-[#9EA2AD]' />
        </div>
        <div className='w-full text-left'>
          <p className='w-full font-medium text-sm text-grey1 line-clamp-3 break-words'>{item.description}</p>
        </div>
      </div>
    </button>
  );
};

export default WorkflowTemplateCard;
