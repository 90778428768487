import { createColumnHelper, useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";

import { Icons } from "shared/components/icons";

import StatusBadge, { StatusType } from "../Badge/StatusBadge";

const columnHelper = createColumnHelper<Data>();

type Data = {
  product_id: string;
  product_name: string;
  category: string;
  automation_stage: string;
  actions: any[];
  inventory_status: string;
  inventory_quantity: string;
  average_sales: string;
  demand_status: string;
};

const getAverage = (type: any) => {
  switch (type) {
    case 1:
      return "120 Units"
    case 2:
      return"140 Units"
    case 3:
      return "80 Units"
    case 4:
      return "145 Units"
    case 5:
      return "230 Units"
    case 6:
      return "333 Units"
    case 7:
      return "120 Units"
    case 8:
      return "200 Units"
    case 9:
      return "111 Units"
    case 10:
      return "160 Units"
    case 11:
      return "201 Units"
    case 12:
      return "340 Units"
    case 13:
      return "11 Units"
    case 14:
      return "9 Units"
    case 15:
      return "400 Units"
    default:
      return "120 Units"
  }
};

const columns = [
  columnHelper.accessor("product_id", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Product ID</div>,
    cell: info => <span className='font-semibold text-xs text-[#464A53]'>{info.getValue()}</span>,
  }),
  columnHelper.accessor("product_name", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Product Name</div>,
    cell: info => <span className='font-medium text-xs text-[#464A53]'>{info.getValue()}</span>,
  }),
  columnHelper.accessor("category", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Category</div>,
    cell: info => <span className='font-medium text-xs text-[#464A53]'>{info.getValue()}</span>,
  }),
  columnHelper.accessor("automation_stage", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Automation Stage</div>,
    cell: info => <StatusBadge type='pending' title={"Review Needed"} />,
  }),
  columnHelper.accessor("actions", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Actions</div>,
    cell: info => <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"8px"}}>
      <span className='font-medium text-xs text-[#464A53]' style={{background:"#F7F7F8",padding:"2px 6px",display:"flex",
      justifyContent:"center",alignItems:"center",borderRadius:"4px",fontSize:"12px",border:"1px solid #E9EAEC"
    }}>{info.row.original.actions.length}</span>
      <span style={{color:"#E36C74",fontSize:"12px",fontWeight:"500"}}>New</span>
      </div>,
  }),
  columnHelper.accessor("inventory_status", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Inventory Status</div>,
    cell: info => <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><span className='font-medium text-xs text-[#5E636E]'style={{background:"#F7F7F8",borderRadius:"4px",fontSize:"12px",padding:"2px 4px",textAlign:"center"
    }}>{info.getValue()}</span></div>,
  }),
  columnHelper.accessor("inventory_quantity", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Inventory Count</div>,
    cell: info => <span className='font-medium text-xs text-[#464A53]'>{info.getValue()}</span>,
  }),
  columnHelper.accessor("average_sales", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Average Sales (Monthly)</div>,
    cell: info => <span className='font-medium text-xs text-[#464A53]'>{getAverage(info.row.index)}</span>,
  }),
  columnHelper.accessor("demand_status", {
    header: () => <div className='font-semibold text-[10px] uppercase text-[#5E636E]'>Demand Status</div>,
    cell: info => <StatusBadge type={info.getValue().toLocaleLowerCase() as StatusType} title={info.getValue()} />,
  }),
];

const ReviewNeededTable = ({ data, setProductActivity }: any) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className='bg-white border border-[#E9EAEC]  border-l-[16px] border-l-[#F48E2F] rounded-xl p-4 w-full overflow-auto scroll-w-1'>
      <div className='flex items-center space-x-2 mb-4'>
        <Icons.ExclamationIcon />
        <span className='text-lg font-semibold text-[#2E323A]'>Review needed</span>
      </div>
      <table className='min-w-full rounded-lg border-separate' style={{ borderSpacing: "0 8px" }}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className='border-r border-r-[#E9EAEC]'>
              {headerGroup.headers.map((header, index) => (
                <th key={header.id} className='relative py-3 px-5 text-left text-sm font-bold text-gray-700'>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {index !== 0 && <div className='absolute top-1/2 transform -translate-y-1/2 left-0 w-[1px] h-8 rounded bg-[#E9EAEC]' />}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className='gap-y-4'>
          {table.getRowModel().rows.map(row => (
            <tr
              onClick={() => setProductActivity(row.original)}
              key={row.id}
              className='cursor-pointer rounded-lg shadow-allAround border border-[#E9EAEC]'
            >
              {row.getVisibleCells().map((cell, index) => (
                <td key={cell.id} className='py-2 relative px-5 text-sm text-gray-600 '>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  {index !== 0 && <div className='absolute top-1/2 transform -translate-y-1/2 left-0 w-[1px] h-8 rounded bg-[#E9EAEC]' />}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td className='text-center text-base font-medium leading-[18px] py-3' colSpan={table.getHeaderGroups()[0].headers.length}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReviewNeededTable;
