import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getConnectedSourceList, getConnectedSourceTables, getConnectSourceList } from "api/integrations";

import { StringOrNumber } from "interfaces/shared-types.interfaces";

export interface ConnectSources {
  integration_id: number;
  display_name: string;
  description: string;
  logo: string;
  category: string;
  scope: string[];
  enabled: boolean;
  supportsRefreshToken: boolean;
  service_id: number | null;
}
interface ConnectedSources {
  connection_id: number;
  user_id: number;
  integration_id: number;
  credential_id: number;
  status: string;
  enabled: true;
  integration_external_id: string;
  integration_data: {
    name: string;
    s3_path: string;
    refresh_token: string;
  };
}
interface Table {
  columns: string[];
}

export interface TablesData {
  [tableName: string]: Table;
}
interface IntegrationStates {
  connectSources: ConnectSources[];
  connectedSources: ConnectedSources[];
  connectedSourcesTables: TablesData
  isDataPreviewOpen: boolean;
  loading: boolean;
  error: null | string;
}

const initialState: IntegrationStates = {
  connectSources: [],
  connectedSources: [],
  isDataPreviewOpen: false,
  connectedSourcesTables: {},
  loading: false,
  error: null,
};
export const getAllConnectSources = createAsyncThunk<any>("integrations/getAllConnectSources", async () => {
  try {
    const response = await getConnectSourceList();
    return response;
  } catch (error: any) {
    console.error(error);
    return;
  }
});
export const getAllConnectedSources = createAsyncThunk<any>("integrations/getAllConnectedSources", async () => {
  try {
    const response: { data: any[] } = await getConnectedSourceList();
    return response.data;
  } catch (error: any) {
    console.error(error);
    return;
  }
});

export const getConnectedSourceTablesByConnectionId = createAsyncThunk<TablesData[], StringOrNumber>(
  "integrations/getConnectedSourceTablesByConnectionId",
  async (id: StringOrNumber, { rejectWithValue }) => {
    try {
      const response: { tables: TablesData[] } = await getConnectedSourceTables(id);
      return response.tables;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setIsDataPreviewOpen: (state, action: PayloadAction<boolean>) => {
      state.isDataPreviewOpen = action.payload;
    },
    toggleIsDataPreviewOpen: state => {
      state.isDataPreviewOpen = !state.isDataPreviewOpen;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllConnectSources.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConnectSources.fulfilled, (state, action: any) => {
        state.loading = false;
        state.connectSources = action.payload;
      })
      .addCase(getAllConnectSources.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      })

      .addCase(getAllConnectedSources.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllConnectedSources.fulfilled, (state, action: any) => {
        state.loading = false;
        state.connectedSources = action.payload;
      })
      .addCase(getAllConnectedSources.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      })

      .addCase(getConnectedSourceTablesByConnectionId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getConnectedSourceTablesByConnectionId.fulfilled, (state, action: any) => {
        state.loading = false;
        state.connectedSourcesTables = action.payload;
      })
      .addCase(getConnectedSourceTablesByConnectionId.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      });
  },
});

export const { setIsDataPreviewOpen, toggleIsDataPreviewOpen } = integrationsSlice.actions;


export const integrationsReducer = integrationsSlice.reducer;
