import { ReactNode } from "react";

import { cn } from "lib/utils";

interface Props {
  children?: ReactNode;
  isSelected?: boolean;
  onClick?: VoidFunction;
  hover?: boolean;
}

export const TableRow = ({ children, isSelected, onClick, hover }: Props) => {
  return (
    <tr
      onClick={onClick}
      className={cn(
        "[&_td]:border-b border-[#EAECF0] first:[&_td]:border-l last:[&_td]:border-r [&:last-child_td:last-child]:rounded-br-2xl [&:last-child_td:first-child]:rounded-bl-2xl",
        {
          "[&_td]:border-[#766CE4] [&_td]:border-t-2 [&_td]:border-b-2 first:[&_td]:rounded-bl-md first:[&_td]:rounded-tl-md last:[&_td]:rounded-br-md last:[&_td]:rounded-tr-md  first:[&_td]:border-l-2 last:[&_td]:border-r-2":
            isSelected,
          "[&_td]:hover:bg-[#F1F0FC]": hover,
        },
      )}
    >
      {children}
    </tr>
  );
};
