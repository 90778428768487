import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/closeButton.svg";
import { useAppDispatch } from "../../../../hooks/appHooks";
import { closePopup, openPopup } from "../../../../storage/slices/popup-slice";
import Button from "../../auth-buttons/Button";

export default function UploadNewBudgetPopup() {
  const dispatch = useAppDispatch();

  const uploadNewBudget = () => {
    dispatch(closePopup());
    dispatch(
      openPopup({
        content: "UploadBudget",
      }),
    );
  };

  return (
    <div className='flex flex-col bg-white border shadow-sm rounded-3xl pointer-events-auto relative p-9 gap-y-12'>
      <button
        type='button'
        className='absolute top-3 right-3 flex justify-center items-center rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none'
        data-hs-overlay='#hs-static-backdrop-modal'
        onClick={() => dispatch(closePopup())}
      >
        <CloseIcon />
      </button>
      <div className='flex flex-col justify-between items-center gap-y-9'>
        <h3 className='font-semibold text-[32px] text-gray-800 '>Are you sure?</h3>
        <div className='flex flex-col rounded-2xl gap-y-6'>
          <div className='flex items-center font-medium text-base gap-x-2.5 text-center'>
            By uploading a new budget file you will restart the process and will lose the <br /> progress so far.
          </div>
          <div className='flex items-center justify-center font-semibold text-sm gap-x-4'>
            <Button
              onClick={uploadNewBudget}
              classes='max-w-[150px] px-[14px] py-5 !font-bold !text-[#1F2228] rounded-2xl bg-transparent text-[#1F2228] !border-2 !border-[#D7DBFE] hover:bg-transparent'
            >
              Start Over
            </Button>
            <Button classes='max-w-[150px] px-[14px] py-5 !font-bold' onClick={() => dispatch(closePopup())}>
              Stay
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
