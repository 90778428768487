import { cn } from "lib/utils";

export const DescriptionLoader = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <div className={cn("flex flex-col gap-y-3", isLoading == true && "animate-pulse")}>
      <div className=' bg-[#F7F7F8] h-4 w-4/6 rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-4 w-3/6 rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-2 w-1/3 rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-2 w-1/3 rounded-[19px]'></div>
    </div>
  );
};

export const CompanyTypeLoader = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <div className={cn(isLoading == true && "animate-pulse")}>
      <div className=' bg-[#F7F7F8] h-6 w-2/5 rounded-[19px]'></div>
    </div>
  );
};

export const CompanyIndustryLoader = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <div className={cn(isLoading == true && "animate-pulse")}>
      <div className=' bg-[#F7F7F8] h-6 w-full rounded-[19px]'></div>
    </div>
  );
};

export const CompanyToneLoader = ({ isLoading = false }: { isLoading?: boolean }) => {
  return (
    <div className={cn("flex items-center gap-x-2", isLoading == true && "animate-pulse")}>
      <div className=' bg-[#F7F7F8] h-8 w-full rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-8 w-full rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-8 w-full rounded-[19px]'></div>
      <div className=' bg-[#F7F7F8] h-8 w-full rounded-[19px]'></div>
    </div>
  );
};
