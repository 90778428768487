import cn from "classnames";
import React, { useMemo } from "react";
import { Tooltip } from "react-tooltip";

import { IBudgetBreakdown, IBurgetBreakdownData } from "api/budget/types";
import { IProjectData } from "api/projects/types";

import { convertAmountToUSFormat } from "utils/global.utils";

import { useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";

import LoadingOverlay from "pages/budget/BudgetIntelligence/components/LoadingOverlay";

import classes from "./BudgetBreakdown.module.scss";

const BudgetBreakdown = ({ project, isLoading }: { project?: IProjectData; isLoading: boolean }) => {
  const budget_breakdown = project?.project_data?.budget_breakdown;
  const { data: channels } = useGetChannels();
  //   const loading = false; // Temp - TODO: Connnect it with a real state

  const mappableChannels = useMemo(() => {
    const mappableObj: any = {};
    (channels || []).forEach(channel => {
      mappableObj[channel.channel_id] = channel;
    });
    return mappableObj;
  }, [channels]);

  const sortedChannels = channels;

  function aggregateValues(data: { channel_id: any; value_new: number }[]) {
    const result = data.reduce((acc: { channel_id: number; value_new: number }[], item) => {
      // Check if the channel_id is already in the accumulator
      const existing: any = acc.find((obj: { channel_id: number }) => obj.channel_id === item.channel_id);
      if (existing) {
        // Add the value_new to the existing entry
        existing.value_new += item.value_new;
      } else {
        // Add a new entry for this channel_id
        acc.push({
          channel_id: item.channel_id,
          value_new: item.value_new,
        });
      }
      return acc;
    }, []);

    return result;
  }
  const floatToInt = (num: number) => {
    return Math.round(num) || 0;
  };
  const getChannels = (data: IBurgetBreakdownData[]) => {
    return aggregateValues(data?.map(item => item.channels).flat());
  };
  const programSpendChannels: { channels: any; total: number } = useMemo(() => {
    if (!budget_breakdown?.program_spend?.data) return { channels: [], total: 0 };
    const channels = getChannels(budget_breakdown?.program_spend?.data).reverse();
    const total = channels?.reduce((acc: number, item: any) => acc + item.value_new, 0);
    return { channels, total };
  }, [budget_breakdown]);
  const toolingChannels: { channels: any; total: number } = useMemo(() => {
    if (!budget_breakdown?.tooling?.data) return { channels: [], total: 0 };
    const channels = getChannels(budget_breakdown?.tooling?.data).reverse();
    const total = channels?.reduce((acc: number, item: any) => acc + item.value_new, 0);
    return { channels, total };
  }, [budget_breakdown]);
  const otherMiscellaneousChannels: { channels: any; total: number } = useMemo(() => {
    if (!budget_breakdown?.other_miscellaneous?.data) return { channels: [], total: 0 };
    const channels = getChannels(budget_breakdown?.other_miscellaneous?.data).reverse();
    const total = channels?.reduce((acc: number, item: any) => acc + item.value_new, 0);
    return { channels, total };
  }, [budget_breakdown]);
  const hiringPeopleSpendChannels: { channels: any; total: number } = useMemo(() => {
    if (!budget_breakdown?.hiring_people_spend?.data) return { channels: [], total: 0 };
    const channels = getChannels(budget_breakdown?.hiring_people_spend?.data).reverse();
    const total = channels?.reduce((acc: number, item: any) => acc + item.value_new, 0);
    return { channels, total };
  }, [budget_breakdown]);
  const total = programSpendChannels?.total + toolingChannels?.total + otherMiscellaneousChannels?.total + hiringPeopleSpendChannels?.total;
  return (
    <div className='h-full flex flex-col'>
      <div className={classes.budgets__top}>
        <h2 className={classes.budgets__top__title}>Budget Breakdown</h2>
      </div>
      <div className={cn("bg-white rounded-[19px] px-4 py-3 relative flex-1 flex flex-col", { "overflow-hidden rounded-3xl": isLoading })}>
        <LoadingOverlay isShown={isLoading} />
        <div className='flex flex-col h-full gap-6'>
          <div>
            <p className='text-[28px] font-semibold leading-9 text-[#1F2228] mt-1'>{convertAmountToUSFormat(total)}</p>
            <p className='text-sm font-medium text-[#717684]'>Total Budget</p>
          </div>
          <div className='flex gap-[22px] flex-wrap'>
            {sortedChannels?.map(channel => {
              if (channel.channel_id === 0) return null;
              return (
                <div key={channel.channel_id} className='flex items-center text-xs font-semibold'>
                  <div className='h-3 w-3 mr-3 rounded-full' style={{ backgroundColor: channel.color?.primary }} />
                  {channel.name}
                </div>
              );
            })}
          </div>
          <div className='flex gap-2 w-full justify-between items-stretch flex-grow mt-4'>
            <div className='bg-grey-100 w-full flex flex-col justify-end gap-[2px]'>
              <div style={{ height: "5%" }} className='flex justify-center items-center text-sm font-bold text-[#1F2228]'>
                {floatToInt((programSpendChannels?.total / total) * 100)}%
              </div>

              {programSpendChannels?.channels?.map((channel: { channel_id: number; value_new: number }, index: number) => {
                return (
                  <div
                    data-tooltip-id={`${channel.value_new}-tooltip`}
                    key={index + channel.value_new + channel.channel_id}
                    style={{
                      height: `${(channel.value_new / programSpendChannels?.total) * 100}%`,
                      backgroundColor: mappableChannels[channel.channel_id]?.color?.primary,
                    }}
                    className={"rounded-md opacity-50 hover:opacity-100 "}
                  >
                    <Tooltip
                      id={`${channel.value_new}-tooltip`}
                      content={mappableChannels[channel.channel_id].name + ": " + convertAmountToUSFormat(channel.value_new)}
                      place='right'
                      positionStrategy='fixed'
                      opacity={1}
                      style={{
                        borderRadius: "13px",
                        background: "#2E323A",
                        fontSize: "12px",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                        zIndex: 1000,
                      }}
                    />
                  </div>
                );
              })}

              <div style={{ height: "10%" }} className='flex justify-center items-center text-sm text-[#717684]'>
                Program Spend
              </div>
            </div>

            <div className='bg-grey-100 w-full flex flex-col justify-end gap-[2px]'>
              <div style={{ height: "5%" }} className='flex justify-center items-center text-sm font-bold text-[#1F2228]'>
                {floatToInt((toolingChannels?.total / total) * 100)}%
              </div>
              {toolingChannels?.channels?.map((channel: { channel_id: number; value_new: number }, index: number) => {
                return (
                  <div
                    data-tooltip-id={"tooling-tooltip"}
                    key={index + channel.value_new + channel.channel_id}
                    style={{
                      height: `${(channel.value_new / total) * 100}%`,
                    }}
                    className={"rounded-md opacity-50 hover:opacity-100 tooling-bg"}
                  >
                    <Tooltip
                      id={"tooling-tooltip"}
                      content={"Tooling: " + convertAmountToUSFormat(channel.value_new)}
                      place='right'
                      positionStrategy='fixed'
                      opacity={1}
                      style={{
                        borderRadius: "13px",
                        background: "#2E323A",
                        fontSize: "12px",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                        zIndex: 1000,
                      }}
                    />
                  </div>
                );
              })}
              <div style={{ height: "10%" }} className='flex justify-center items-center text-sm text-[#717684]'>
                Tooling
              </div>
            </div>

            <div className='bg-grey-100 w-full flex flex-col justify-end gap-[2px]'>
              <div style={{ height: "5%" }} className='flex justify-center items-center text-sm font-bold text-[#1F2228]'>
                {floatToInt((otherMiscellaneousChannels?.total / total) * 100)}%
              </div>
              {otherMiscellaneousChannels?.channels?.map((channel: { channel_id: number; value_new: number }, index: number) => {
                return (
                  <div
                    data-tooltip-id={"other-tooltip"}
                    key={index + channel.value_new + channel.channel_id}
                    style={{
                      height: `${(channel.value_new / total) * 100}%`,
                    }}
                    className={"rounded-md opacity-50 hover:opacity-100 tooling-bg"}
                  >
                    <Tooltip
                      id={"other-tooltip"}
                      content={"Other " + convertAmountToUSFormat(channel.value_new)}
                      place='right'
                      positionStrategy='fixed'
                      opacity={1}
                      style={{
                        borderRadius: "13px",
                        background: "#2E323A",
                        fontSize: "12px",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                        zIndex: 1000,
                      }}
                    />
                  </div>
                );
              })}
              <div style={{ height: "10%" }} className='flex justify-center items-center text-sm text-[#717684]'>
                Other/Miscellaneous
              </div>
            </div>

            <div className='bg-grey-100 w-full flex flex-col justify-end gap-[2px]'>
              <div style={{ height: "5%" }} className='flex justify-center items-center text-sm font-bold text-[#1F2228]'>
                {floatToInt((hiringPeopleSpendChannels?.total / total) * 100)}%
              </div>
              {hiringPeopleSpendChannels?.channels?.map((channel: { channel_id: number; value_new: number }, index: number) => {
                return (
                  <div
                    data-tooltip-id={"hiringpeople-tooltip"}
                    key={index + channel.value_new + channel.channel_id}
                    style={{
                      height: `${(channel.value_new / total) * 100}%`,
                    }}
                    className={"rounded-md opacity-50 hover:opacity-100 tooling-bg"}
                  >
                    <Tooltip
                      id={"hiringpeople-tooltip"}
                      content={"Hiring: " + convertAmountToUSFormat(channel.value_new)}
                      place='right'
                      positionStrategy='fixed'
                      opacity={1}
                      style={{
                        borderRadius: "13px",
                        background: "#2E323A",
                        fontSize: "12px",
                        boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                        zIndex: 1000,
                      }}
                    />
                  </div>
                );
              })}
              <div style={{ height: "10%" }} className='flex justify-center items-center text-sm text-[#717684]'>
                Hiring/People Spend
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BudgetBreakdown;
