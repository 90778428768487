import { useContext } from "react";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { openPopup } from "storage/slices/popup-slice";

import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";

import { ConnectSourceContext } from "../ConnectDataSource";

import DataSheetTableGRP from "./components/DataSheetTable_GRP";

const ReviewBudget = () => {
  const { dataType } = useAppSelector(state => state.uploadedBudget);
  const dispatch = useAppDispatch();
  const { reviewBudgetError, fetchBudgetTableData, fileName } = useContext(ConnectSourceContext);
  return (
    <>
      <div className='px-6 py-4'>
        <div className='flex flex-col gap-6 p-6 rounded-[22px] bg-white'>
          <div className=''>
            {!reviewBudgetError ? (
              <div className='flex flex-row gap-4 flex-wrap'>
                <div className='flex-1 flex-nowrap'>
                  <div className=''>
                    <p className='text-base font-bold text-[#2E323A]'>Review a budget</p>
                  </div>
                  <div className=''>
                    <p className='text-sm font-medium text-[#333740] mt-4 leading-5 min-w-96'>
                      Please verify that your uploaded budget is correct to proceed with strategy generation.
                    </p>
                    <div className='mt-4 min-w-96 flex items-center'>
                      <EyeIcon className='h-5 w-5 mr-2' />
                      <p className='text-sm font-medium text-[#333740]  leading-5'>
                        You can click on the eye icon to hide certain channels from your budget.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='flex-1 min-w-96 text-end '>
                  <button
                    onClick={() => {
                      dispatch(
                        openPopup({
                          content: "CreateBudget",
                        }),
                      );
                    }}
                    className=' text-sm font-semibold md:text-start  lg:text-end'
                  >
                    Uploaded File: <span className='text-[#766CE4] underline underline-offset-4'>{fileName}</span>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className='text-base font-bold text-[#2E323A]'>Something went wrong! Things you need to check.</p>
                <div className='items-center mt-2'>
                  <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                    1. Check your worksheet if it contains essential months row and relevant data with numeric budget list.
                  </p>
                  <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                    2. Go back and check if you have selected correct coordinates in Locate Data page.
                  </p>
                  <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>3. Check your internet connection.</p>
                </div>
                <div>
                  <button
                    className='p-4 rounded-xl bg-purpleP0 mt-4'
                    onClick={() => {
                      fetchBudgetTableData();
                    }}
                  >
                    <p className='text-sm font-medium'>Click here to try again</p>
                  </button>
                </div>
              </div>
            )}
          </div>
          {!reviewBudgetError && <DataSheetTableGRP />}
        </div>
      </div>
    </>
  );
};

export default ReviewBudget;
