import React from "react";
import { Icons } from "react-toastify";

import { cn } from "lib/utils";

interface NotificationChannelProps {
  title: string;
  icon: React.ReactNode;
  isSelected: boolean;
  handleClick: () => void;
}

const NotificationChannel: React.FC<NotificationChannelProps> = ({ title, icon, isSelected, handleClick }) => {
  return (
    <div className={cn("w-44 rounded-[20px] p-0 m-0 ", isSelected ? "border-4 border-[#ECEAFF]" : "border-4 border-transparent")}>
      <div
        onClick={handleClick}
        className={cn(
          "bg-white rounded-2xl border border-[#E9EAEC] p-3 gap-x-6 w-full hover:border-[#D5D1F7] cursor-pointer",
          isSelected ? "border border-[#766CE4] " : "border border-[#E9EAEC]",
        )}
      >
        <div className='flex justify-between items-center h-full w-full gap-3'>
          <div className='bg-[#F7F7F8] w-8 h-8 p-2 rounded-md'>{icon}</div>
          <div className='w-full text-left'>
            <p className='font-semibold text-sm text-[#1F2228]'>{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationChannel;
