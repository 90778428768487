import {
  ComposerPrimitive
  } from "@assistant-ui/react";
  import { Message } from "@langchain/langgraph-sdk";
  import "./ChatComponent.css"
  import { useEffect, useState } from "react";
  import { useSelector } from "react-redux";
  import { useSearchParams } from "react-router-dom";
  import { v4 as uuidv4 } from "uuid";

  import { selectUser } from "storage/selectors/user";

  import { ensureToolCallsHaveResponses } from "lib/ensure-tool-responses";
  import { useStreamContext } from "providers/Stream";
  
  import attch  from "../../../assets/icons/atch.svg";
  import imgd  from "../../../assets/icons/imgd.svg";
  import ques  from "../../../assets/icons/quest.svg";
  import { Icons } from "../icons";
  
  import { useAI } from "./provider";
  
  

  interface CustomComposerProps {
    disabled?: boolean; // Boolean prop
  }
  
  export const CustomComposer = ({ disabled = false }: CustomComposerProps) => {
      const [thread, setThread] = useState<string | null>(null);
      const {description,setIsAnime,isBack,setIsBack,setDescription,text,setText,setIsFlag,isWorkflow} = useAI()
      const [firstTokenReceived, setFirstTokenReceived] = useState(false);
        const [searchParams] = useSearchParams();
        const isThread = searchParams.get("threadId");
        const user = useSelector(selectUser);
        const stream = useStreamContext();
        const messages = stream.messages;
        const isLoading = stream.isLoading;

    const handleSubmit = async () => {
        if (!text.trim() || isLoading) return;
        setFirstTokenReceived(false);
    
        const newHumanMessage: Message = {
          id: uuidv4(),
          type: "human",
          content: text,
        };
    
        let usableThreadId = thread;
    
        if (thread === null || !!thread || thread === "undefined" || !isBack) {
          const thread = await stream.client.threads.create({ metadata: { user_id: user?.user_id } });
          usableThreadId = thread.thread_id;
          setThread(thread.thread_id);
        }
    
        const config = {
          configurable: {
            user_id: user?.user_id,
            thread_id: usableThreadId,
          },
        };
    
        const toolMessages = ensureToolCallsHaveResponses(stream.messages);
    
        stream.submit(
          {
            messages: [...toolMessages, newHumanMessage],
          },
          {
            streamMode: ["values"],
            optimisticValues: prev => ({
              ...prev,
              messages: [...(prev.messages ?? []), ...toolMessages, newHumanMessage],
            }),
            config: config,
          },
        );
        setDescription("")
        setText("");
        setIsFlag(false)
        setIsBack(true)
      };

      const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault(); // Prevents new line
          handleSubmit(); // Calls the submit function
        }
      };

    const imgarry = [
      {id:1,image:attch},
      {id:2,image:imgd},
      {id:3,image:ques},
    ]

    useEffect(()=>{
      if(description?.length > 0){
        setTimeout(() => {
          const inputElement = document.querySelector(".custom-textarea") as HTMLTextAreaElement;
          
          if (inputElement) {
            inputElement.removeAttribute("disabled"); // Temporarily enable
            inputElement.focus(); // Focus the textarea
    
            // Simulate typing like a real user
            inputElement.setRangeText(description); 
            inputElement.selectionStart = inputElement.selectionEnd = description.length;
            inputElement.dispatchEvent(new Event("input", { bubbles: true }));
    
            inputElement.setAttribute("disabled", "true"); // Re-disable after setting text
            
          }
        }, 100);      
      }
      else{
        const inputElement = document.querySelector(".custom-textarea") as HTMLTextAreaElement;
        inputElement.removeAttribute("disabled"); // Temporarily enable

      }

       setText("")
    },[description,isBack])

    

    return (
      <ComposerPrimitive.Root 
       className={(isWorkflow && isThread) ? "cont" :"container"}
       onMouseEnter={() =>description ? setIsAnime(false) : setIsAnime(true)}   // Set to true on hover
       onMouseLeave={() => setIsAnime(false)}  
       >
        <textarea 
           className={`custom-textarea ${!description.length ? ((!isBack && !isThread)  ? "disabled" : "") : ""}`}  
           value={text} 
           onChange={(e)=>setText(e.target.value)} 
           placeholder="How can I help you today?"
           onKeyDown={(e) => handleKeyPress(e)}
           />
        <div className="btnFlx">
          <div className="dflex">
            {
              imgarry?.map((itm,ix)=>{
                  return(
                    <div key={ix}>
                      <img src={itm?.image} alt={"attch"}/>
                    </div>
                  )
              })
            }
          </div>
          <div
            className={`custom-send-button ${!description.length ? ((!isBack && !isThread ) ? "disabled" : (!text.length ? "disabled" : "")) : ""}`}
            onClick={handleSubmit}
            >
            <Icons.Send/>
          </div>

        </div>
      </ComposerPrimitive.Root>
    );
  };
  
