import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";

type Item = { channel_id: number; name: string; icon: string };

type Props = {
    children: React.ReactNode;
  textWrapperStyle?:string;
  leftIcon?:React.ReactNode
};

const ContentWithTooltip = ({ children, textWrapperStyle="", leftIcon }: Props) => {
  const [hidden, setIsHidden] = useState(true);
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef.current.scrollWidth > textRef.current.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);
  return (
    <div className={"flex flex-row flex-wrap"}>

      <div
        className={"relative flex-nowrap justify-center w-full"}
        onMouseEnter={() => {
            isOverflowing &&
          setIsHidden(false);
        }}
        onMouseLeave={() => {
          setIsHidden(true);
        }}
      >
        <div className="flex items-center gap-x-1">
            
            {leftIcon && leftIcon}
      <div ref={textRef} className={twMerge("overflow-hidden text-sm font-medium text-[#2E323A] text-ellipsis")}>{children}</div>
      </div>
        {!hidden && (
          <div className={"absolute -top-9 flex bg-white rounded-lg shadow-lg z-10 border-2 border-[#766CE4]"}>
            <div
              className={twMerge(
                "w-fit select-none font-plusJakartaSans whitespace-nowrap  px-3 py-1.5 flex items-center"+textWrapperStyle,
              )}
            >
             {leftIcon && <span className="mr-1">{leftIcon}</span>} {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentWithTooltip;
