import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AgentItem } from "api/agent/type";

import { MARKETING_AGENT_UUID, NEW_USER_DEFAULT_AGENT_UUID } from "constants/agent.constants";
import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { usePaymentStatus } from "hooks/payments/usePaymentStatus";
import { useGetWorkflowStrategyTemplate } from "hooks/queries/workflows";
import { useInitializePendo } from "hooks/useInitializePendo";

import { getAgentsList } from "storage/actions/agent";
import { getTasksList } from "storage/actions/task";

import { Icons } from "shared/components/icons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import AgentAiCard from "page-components/dashboard/agent-ai-card";
import InsightCard from "page-components/dashboard/dashboard-insights/InsightCard";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import bgDot from "assets/icons/bg-dot.svg";
import BrandCategories from "assets/png/brand-categories.png";
import Integration from "assets/png/integration.png";

import { cn } from "lib/utils";

import "./dashboard.css";

export default function Dashboard() {
  usePaymentStatus();
  useInitializePendo();

  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();
  const selectedLabel = location.state?.selectedLabel || "";

  const navigate = useNavigate();

  const emblaOptions: EmblaOptionsType = {};
  const agents = useAppSelector(state => state.agent.agents);

  const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions);

  const agent = useMemo(() => {
    if (!agents) return null;

    return agents.find((agent: AgentItem) => agent.name === "Dave");
  }, [agents]);

  const agentTitles = useMemo(() => {
    if (!agents) return [];
  
    return agents.map((agent: AgentItem) => agent.title);
  }, [agents]);

  const sortedAgents = useMemo(() => {
    if (!agents) return [];
    return [...agents].sort((a, b) => a.name.localeCompare(b.name));
  }, [agents]);

  // Get the current visible agent using activeTab
  const currentAgent = sortedAgents[activeTab];

  // Determine the correct agent ID
  const agentUuid = currentAgent?.uuid;
  const label = currentAgent?.name === "Dave" ? "Inventory" : undefined;

  const { data: { data: taskList = [] } = {}, refetch } = useGetWorkflowStrategyTemplate({
    templateType: "task",
    agent_uuid: agentUuid,
    label,
  });

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext();
    } else {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi]);

  const handleTitleClick = (agentName: number) => {
    if (!emblaApi) return;
    if(agentName === 0 ){
      emblaApi.scrollTo(0);
    }
    if(agentName === 1){
      emblaApi.scrollNext();
    }
      // Handle logic (e.g., update state or trigger a carousel action)
    };

  const handleGoToTasks = () => {
    navigate(ROUTES.conversation);
  };

  const handleGoToIntegrations = () => {
    navigate(ROUTES.connectSources);
  };

  const handleGoToBrandkit = () => {
    navigate(ROUTES.setupCompanyProfile);
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    emblaApi?.scrollTo(index);
  };

  // Update activeTab on swipe
  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        setActiveTab(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    refetch();
  }, [activeTab]);

  useEffect(() => {
    dispatch(getAgentsList({ page: 1 }));
    dispatch(getTasksList({ page: 1 }));
  }, []);

  useEffect(()=>{
    
    if(selectedLabel){
      if(selectedLabel === "Operations Specialist"){
        if(emblaApi) emblaApi.scrollTo(0);
      }
      else{
        if(emblaApi) emblaApi.scrollNext();
      }

    }
  },[emblaApi])
  

  

  return (
    <DashboardLayout
      title={agent?.title ?? "Home"}
      agentTitle = {agentTitles ?? "Home"}
      titleClassName='text-[#1F2228]'
      headerExtraContent={
        agent ? (
          <div className='ml-6 flex items-center gap-6'>
            <div className='h-[26px] w-[1px] bg-[#E9EAEC]'></div>
            <Link 
              to={ROUTES.agents}
              className="custom-text"
              >
              Browse Agents
            </Link>
          </div>
        ) : null
      }
      onTitleClick={handleTitleClick}
      containerClassName='bg-white bg-bg-gradient'
    >
      <div className=' m-auto'>
        <div className='w-full h-full p-6 m-auto '>
          <div className='flex gap-6 items-center justify-center ml-[99px] mr-[99px] '>
            <div className='embla__viewport max-w-7xl h-[362px] relative' ref={emblaRef}>
            <div className='embla__container'>
                {sortedAgents.map((agent, index) => {
                  return (
                    <div className='embla__slide' key={index}>
                      <div className='md:mt-20 flex flex-col md:flex-row w-full md:justify-between gap-2'>
                        <AgentAiCard agent={agent} />
                        <div className='flex flex-col gap-4 relative w-full md:w-[36%]'>
                          <div className={cn("flex flex-col gap-4 w-full h-full")}>
                            {agent?.tasks?.map((task, i) => <SampleTask key={i} task={task} agent_uuid={agent.uuid} />)}
                          </div>
                          <button onClick={handleGoToTasks} className='text-[#766CE4] font-bold text-base flex items-center gap-1'>
                            <span className='underline'>Browse all tasks</span>
                            <Icons.ArrowTopRight className='text-[#766CE4] h-[14px] w-[14px]' />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='h-full w-[120px] top-0 z-30 right-0 flex items-center justify-center'>
              <div
                style={{
                  background: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%)",
                  backdropFilter: "blur(6px)",
                  WebkitBackdropFilter: "blur(6px)",
                  boxShadow: "-6px 0px 54px 0px #5649F329",
                }}
                onClick={onNextButtonClick}
                className='cursor-pointer w-[54px] h-[54px] p-[15px] flex items-center z-30 justify-center bg-white border border-[#E8E9F0] rounded-full shadow-custom '
              >
                <ArrowDownIcon className='-rotate-90' />
              </div>
            </div>
          </div>

          <div className='max-w-7xl m-auto'>
            <div className='tab-indicator flex justify-center mt-12'>
              {sortedAgents.map((_, index) => (
                <div
                  key={index}
                  className={`h-2  rounded-full cursor-pointer transition-all ease-in ${activeTab === index ? "bg-[#766CE4] w-12" : "w-4 bg-[#E8E9F0]"}`}
                  onClick={() => handleTabClick(index)}
                ></div>
              ))}
            </div>


            <div className='md-5 mt-5 md:mt-28 grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-10'>
              <InsightCard
                className='lg:!w-full overflow-hidden !h-[17.5rem]'
                title={"Setup Company Profile "}
                description={"Set up your profile for personalized insights and optimized marketing strategies."}
                bg={bgDot}
                onButtonClick={handleGoToBrandkit}
                icon={
                  <div className='flex gap-1 items-center'>
                    <Icons.Brand className='w-4 h-4' />
                    <p className='text-[#717684] text-xs font-medium'>Brandkit</p>
                  </div>
                }
              >
                <div className='top-[10px] absolute'>
                  <img src={BrandCategories} alt='Brand Categories' className='' />
                </div>
              </InsightCard>
              <InsightCard
                className='lg:!w-full overflow-hidden !h-[17.5rem]'
                title={"Transform your data into insights  "}
                description={"Easily connect your marketing platforms now in just a few clicks to get your personalized  insights."}
                bg={bgDot}
                onButtonClick={handleGoToIntegrations}
                icon={
                  <div className='flex gap-1 items-center'>
                    <Icons.Integration className='w-4 h-4' />
                    <p className='text-[#717684] text-xs font-medium'>Integrations</p>
                  </div>
                }
              >
                <div className='top-[10px] absolute mx-4'>
                  <img src={Integration} alt='Integration' className='' />
                </div>
              </InsightCard>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

const SampleTask = ({ task, agent_uuid }: { task: any; agent_uuid: string }) => {
  return (
    <Link
      to={`${agent_uuid === MARKETING_AGENT_UUID ? ROUTES.budgetForm : ROUTES.conversation}?task_id=${task.strategy_id}&agent_uuid=${agent_uuid}&hideToolCalls=1`}
      className='bg-[#F7F7F8] rounded-2xl p-5'
    >
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Icons.Calender className='w-4 h-4 text-[#9EA2AD]' />
          <p className='text-[#717684] font-medium text-xs'>Sample task</p>
        </div>
        <p className='text-base font-semibold text-[#14191A]'>{task.name}</p>
      </div>
    </Link>
  );
};
