import cn from "classnames";
import React from "react";
import ReactDOM from "react-dom";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import Button from "shared/components/auth-buttons/Button";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import { ReactComponent as ReviewOverlayIcon2 } from "assets/icons/Logo.svg";
interface OverlayProps {
  onClick: () => void; // TODO: can we make this take the user to the Connected Sources page?
  selectedIntegration:any;
  connecting:boolean
}

const ConnectedOverlay: React.FC<OverlayProps> = ({ onClick,selectedIntegration, connecting }) => {
  const Icon = INTEGRATION_ICONS?.[selectedIntegration?.logo as keyof typeof INTEGRATION_ICONS];
  return ReactDOM.createPortal(
    <div id="overlay-general">
       
      <div className='flex flex-col items-center justify-center bg-white p-20 rounded-3xl relative'>
      <CloseWhiteBtn height={36} width={36}  className='absolute top-6 right-6' onClick={onClick}/>
        <div className="flex flex-row items-center">
            <div className={cn("p-3 border rounded-2xl border-[#DEE9FF]",{"animate-pulse":connecting})}>
            <ReviewOverlayIcon2 className="h-10 w-10" />
            </div>
            <span className="w-14 h-0 border border-dashed border-[##766CE4]" />
            <div className={cn("p-3 border rounded-2xl border-[#DEE9FF]",{"animate-pulse":connecting})}>
            <Icon className="h-10 w-10 " />
            </div>
        </div>
        <div className="mt-10 ">
            <p className="text-3xl font-semibold text-[#1F2228]">{selectedIntegration?.display_name} account {connecting?"is connecting":"was connected"}</p>
        </div>
        <Button classes="!w-fit mt-10 px-[42px] rounded-xl" onClick={onClick} disabled={connecting}>
            <p>Continue</p>
        </Button>
      </div>
    </div>,
    document.body // Render in the document body to cover the entire viewport
  );
};

export default ConnectedOverlay;
