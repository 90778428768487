import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { getConnectedSourceTables } from "api/integrations";

import { INTEGRATION_ICONS } from "constants/integrations.constants";
import { ROUTES } from "constants/routes.constants";

import { StringOrNumber } from "interfaces/shared-types.interfaces";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import {
  ConnectSources,
  getAllConnectedSources,
  getAllConnectSources,
  getConnectedSourceTablesByConnectionId,
} from "storage/slices/integrations-slice";

import { cn } from "lib/utils";

import { Icons } from "../icons";

import { IChatMessage, useAI } from "./provider";

interface ConnectDataSourcesProps {
  chat: IChatMessage;
}

const ConnectDataSources = ({ chat }: ConnectDataSourcesProps) => {
  const [isOpenSources, setIsOpenSources] = useState(true);
  const [contentHeight, setContentHeight] = useState("auto");
  const { setIsSending, isOpen,setDescription } = useAI();
  const [integrationQueue, setIntegrationQueue] = useState(() => {
    const savedQueue = localStorage.getItem("integrationQueue");
    return savedQueue ? JSON.parse(savedQueue) : [];
  });

  const pollingInterval = useRef<NodeJS.Timeout | number | null>(null);

  const connectSourcesList = useAppSelector(state => state.integrations.connectSources);
  const filteredConnectSourcesList = connectSourcesList.filter(
    item => item.enabled && (chat.data?.options || []).includes(item?.display_name?.toLowerCase()),
  );
  const connectedSourcesList = useAppSelector(state => state.integrations.connectedSources);
  const dispatch = useAppDispatch();

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(getAllConnectSources());
  }, []);

  const toggleContent = () => {
    setIsOpenSources(prev => !prev);
  };

  const handleIntegrationClick = (integration: any) => {
    const updatedQueue = [
      ...integrationQueue.filter((item: any) => item.integration_id !== integration.integration_id),
      { integration_id: integration.integration_id, status: "in_progress" },
    ];

    setIntegrationQueue(updatedQueue);
    localStorage.setItem("integrationQueue", JSON.stringify(updatedQueue));

    window.open("/integrations/connect-sources", "_blank", "noopener,noreferrer");
  };

  const pollIntegrationStatus = async () => {
    try {
      const res = await dispatch(getAllConnectedSources()).unwrap();

      const updatedQueue = integrationQueue.filter((integration: any) => {
        const status = res.find((connected: ConnectSources) => connected.integration_id === integration.integration_id);

        // Remove from queue if status is "active" or "failed"
        if (status?.status === "active" || status?.status === "failed") {
          setIsSending(false);
          return false;
        }
        setIsSending(true);
        return true;
      });

      setIntegrationQueue(updatedQueue);
      localStorage.setItem("integrationQueue", JSON.stringify(updatedQueue));

      // Stop polling if no integrations are left in the queue
      if (updatedQueue.length === 0 && pollingInterval.current) {
        clearInterval(pollingInterval.current);
        pollingInterval.current = null;
        setIsSending(false);
      }
    } catch (error) {
      console.error("Error fetching integration status:", error);
    }
  };

  useEffect(() => {
    setIntegrationQueue([]);
    localStorage.removeItem("integrationQueue");
    dispatch(getAllConnectedSources());
  }, [dispatch]);

  useEffect(() => {
    if (integrationQueue.length > 0 && !pollingInterval.current) {
      pollingInterval.current = setInterval(() => {
        pollIntegrationStatus();
      }, 5000);
    }

    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current as NodeJS.Timeout);
        pollingInterval.current = null;
      }
    };
  }, [integrationQueue]);

  const showImage = (logo: any) => {
    const Icon = INTEGRATION_ICONS?.[logo as keyof typeof INTEGRATION_ICONS];
    if (!Icon) return <img src={""} alt='logo' className='w-6 h-6' />;
    return <Icon className='w-6 h-6' />;
  };

  useEffect(() => {
    if (isOpenSources && contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setContentHeight("0px");
    }
  }, [isOpenSources, filteredConnectSourcesList]);

  const handleGetConnectedDataSources = (id: StringOrNumber) => {
    dispatch(getConnectedSourceTablesByConnectionId(id));
  };

  const isConnected = (item: ConnectSources) => connectedSourcesList.some(source => source.integration_id === item.integration_id);
  

  return (
    <div className={cn("w-full", isOpen ? "min-w-24" : "min-w-[540px]")}>
      <div className='flex justify-between items-center my-1 w-fll flex-1 max-w-[540px]'>
        <div onClick={toggleContent} className='flex items-center gap-2 cursor-pointer flex-1'>
          <p className='text-left text-[#717684] font-medium cursor-pointer mb-1'>Suggested sources</p>
          {isOpenSources ? <Icons.ArrowUp /> : <Icons.ArrowDown />}
        </div>
        <Link 
          to={ROUTES.connectSources}
          target="_blank" 
          rel="noopener noreferrer"
          className="text-sm font-semibold text-[#766CE4] cursor-pointer"
        >
          View All
        </Link>
      </div>

      <div
        ref={contentRef}
        style={{ maxHeight: contentHeight, transition: "max-height 0.5s ease-in-out" }}
        className={"overflow-hidden grid grid-cols-1 gap-2 md:grid-cols-2 transition-[max-height] duration-500 ease-in-out rounded-b"}
      >
        {filteredConnectSourcesList.map(option => (
          <button
            disabled={!option.enabled || isConnected(option)}
            onClick={() => {
              // option.onConnect();
              handleIntegrationClick(option);
              // handleConnectSources(option);
            }}
            key={option.display_name}
            className={cn(
              "flex items-center cursor-pointer justify-between  rounded-[16px] border  p-3 gap-4 hover:border-[#D5D1F7] disabled:bg-gray-50",
              isConnected(option) ? "bg-[#FCFCFC] border-[#F7F7F8]" : "bg-white border-[#E9EAEC]",
            )}
          >
            <div className='flex items-center gap-x-4 flex-1'>
              <div className='border p-[10px] border-[#DEE9FF] rounded-xl'>{showImage(option.logo)}</div>
              <div>
                <p className='text-[15px] font-semibold text-[#1F2228]'>{option.display_name}</p>
              </div>
            </div>
            {isConnected(option) && <Icons.Check className='text-[#02A613]' />}
          </button>
        ))}
      </div>
      <div className="h-8 w-[110px] bg-[#766CE4] text-white rounded-[12px] flex justify-center items-center cursor-pointer mt-3"
          onClick={()=>setDescription("Done adding data sources")}      
      >
          Done
      </div>
      {/*connectedSourcesList.length > 0 && (
        <div className='mt-2 mb-4 flex items-center gap-2 flex-wrap'>
          {connectedSourcesList.map((source, index) => (
            <div
              onClick={() => handleGetConnectedDataSources(source.connection_id)}
              key={index}
              className='flex cursor-pointer items-center gap-2 mt-2 bg-white rounded-xl border border-[#E9EAEC] px-4 py-[6px]'
            >
              <Icons.Check className='text-[#02A613] w-[18px] h-[18px]' />
              <p className='text-[#1F2228] font-medium text-xs'>{source.integration_data.name} connected</p>
            </div>
          ))}
        </div>
      )*/}
    </div>
  );
};

export default ConnectDataSources;
