import React, { useState } from "react";

import { cn } from "lib/utils";

import { Icons } from "../icons";

interface TimelineItemProps {
  title: string;
  children: React.ReactNode;
  active?: boolean;
  isCompleted?: boolean;
  containerClasses?: string;
  markerClasses?: string;
  lineClasses?: string;
  titleClasses?: string;
  childrenWrapperClasses?: string;
}

export const TimelineItem: React.FC<TimelineItemProps> = ({
  title,
  children,
  active = false,
  isCompleted = false,
  containerClasses = "",
  markerClasses = "",
  lineClasses = "",
  titleClasses = "",
  childrenWrapperClasses = "",
}) => {
  const [isOpen, setIsOpen] = useState(active);

  return (
    <div className='relative pl-8 sm:pl-20 py-4 group'>
      <div className='flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-[#D7DBFE] sm:before:ml-[2.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3  after:rounded-full sm:after:ml-[2.5rem] after:-translate-x-1/2 after:translate-y-1.5'>
        <div className={cn("absolute  z-50", isCompleted ? "left-6 top-[18px]" : "left-5 top-[18px]")}>
          {active && !isCompleted && (
            <div className='bg-white rounded-full p-2'>
              <div className='flex items-center justify-center  border-4 border-[#ECEAFF] rounded-full  '>
                <div className='w-4 h-4 bg-white border-2 border-[#9189E9] rounded-full' />
              </div>
            </div>
          )}

          {isCompleted && (
            <div className='bg-white rounded-full p-2'>
              <div className='flex items-center justify-center'>
                <Icons.CircleCheck />
              </div>
            </div>
          )}
        </div>
        <div className={cn("absolute  z-50 left-[28px] top-[18px]")}>
          {!active && !isCompleted && (
            <div className='bg-white rounded-full p-2'>
              <div className='w-2 h-2 bg-white border-2 border-[#C0BBF3] rounded-full' />
            </div>
          )}
        </div>
      </div>

      <div className='w-full relative  cursor-pointer flex items-center justify-between' onClick={() => setIsOpen(!isOpen)}>
        <div className={cn("text-base font-bold text-[#1F2228]", titleClasses)}>{title}</div>
        <div className={cn("transform transition-transform duration-300", { "rotate-180": isOpen })}>
          <Icons.ArrowDown />
        </div>
      </div>

      <div
        className={cn("transition-[max-height]  duration-300", childrenWrapperClasses, {
          "max-h-0": !isOpen,
          "max-h-screen": isOpen,
          "overflow-hidden": !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};
