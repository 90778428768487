import { useQuery } from "@tanstack/react-query";

import { getConversationHistory, getConversationMessages } from "api/conversation";

import { ChatHistoryConversation } from "shared/components/ai-panel/provider";

export const conversationQueryKeys = {
  getConversationHistory: ["getConversationHistory"] as const,
  getConversationMessages: (id: string, limit: number, offset: number) => ["getConversationMessages", id, limit, offset] as const,
};

export const useGetConversationHistory = () =>
  useQuery({
    queryKey: conversationQueryKeys.getConversationHistory,
    queryFn: async (): Promise<{
      count: number;
      rows: Array<ChatHistoryConversation>;
    }> => {
      return await getConversationHistory();
    },
  });

export const useGetConversationMessages = (id: string, limit: number, offset: number) => {
  return useQuery({
    queryKey: conversationQueryKeys.getConversationMessages(id, limit, offset),
    queryFn: async () => {
      return await getConversationMessages(id, limit, offset);
    },
  });
};
