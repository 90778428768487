import { useState } from "react";

import { apiSavePersonalizationUrl } from "api/personalization";

import { isValidURL } from "utils/onboarding.utils";

import { useAppDispatch } from "hooks/appHooks";

import { getMyPersonalizationData } from "storage/actions/personalization";

import { FormLabel } from "shared/components/form-elements/FormLabel";

import { Button } from "../../shared/components/buttons/Button";

type PersonalizationURLFormProps = {
  changeStep: any;
  step: number;
};
const PersonalizationURLForm = ({ changeStep, step }: PersonalizationURLFormProps) => {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState<string>("");
  const [checkedOnce, setCheckedOnce] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const uploadUrlToServer = async () => {
    try {
      dispatch(getMyPersonalizationData());
      const response = await apiSavePersonalizationUrl(url.trim());
      if (response.status === 200 || response.status === 201) {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleSubmit = () => {
    setCheckedOnce(true);
    if (url.length === 0) {
      setError("URL is required!");
      return;
    }
    if (isValidURL(url.trim())) {
      // Hit Api here
      uploadUrlToServer();
    } else {
      setError("Invalid URL! Please enter a valid URL.");
    }
  };
  const handleUrlChange = (e: any) => {
    setError("");
    setUrl(e.target.value);
  };
  return (
    <div>
      <div className=''>
        <fieldset>
          <FormLabel className={""} tag='legend'>
            Website URL
          </FormLabel>
          <div className='w-full bg-white px-4 py-3 rounded-xl flex items-center mr-1 border'>
            <p className='text-sm font-bold text-[#766CE4] mr-3'>URL</p>
            <input
              type={"text"}
              value={url}
              className={"w-full focus:outline-none"}
              onChange={handleUrlChange}
              placeholder={"Example: brainvoy.ai"}
            />
          </div>
          <p className={`text-sm font-normal text-[#9EA2AD] mt-2 ${checkedOnce && error ? "text-[#E36C74]" : "text-[#9EA2AD]"}`}>
            {" "}
            {checkedOnce && error ? error : "Enter the URL of the website you want to power up"}
          </p>
          <div className='flex justify-start pt-16'>
            <Button className='w-fit' onClick={handleSubmit}>
              Continue
            </Button>
            {/*<button className='py-3 px-12 bg-[#6A5EEE] rounded-2xl text-white font-bold'>Continue</button>*/}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default PersonalizationURLForm;
