import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { MenuButtonProps } from "@headlessui/react/dist/components/menu/menu";
import cn from "classnames";
import React, { useRef } from "react";

import { DropdownItem } from "interfaces/shared/dropdown.interfaces";

import { IndustryDropdownType } from "pages/setup-company-profile/components/company-industry";

import { Icons } from "../icons";

import { CheckboxPurple } from "./CheckboxPurple";

interface Props {
  children?: MenuButtonProps<"button">["children"];
  items?: DropdownItem[];
  selectedData?: string[];
  enabled: boolean;
  updateSelectedData?: (id: string, isChecked: boolean) => void;
  addDropdownOptions?: (newOption: IndustryDropdownType) => void;
}

export const Dropdown = ({ children, items, selectedData, updateSelectedData, addDropdownOptions, enabled }: Props) => {
  const [search, setSearch] = React.useState("");
  const [addText, setAddText] = React.useState("");
  const [showAddInput, setShowAddInput] = React.useState(false);

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleAdd = () => {
    if (addText) {
      addDropdownOptions?.({ id: addText, label: addText });
      setAddText("");
      setTimeout(() => {
        scrollViewRef.current?.scrollTo({ top: scrollViewRef.current?.scrollHeight, behavior: "smooth" });
      }, 100);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAdd();
    }
  };
  const filteredItems = items?.filter(item => item.label.toLowerCase().includes(search.toLowerCase()));

  return (
    <Menu>
      {({ open }) => {
        return (
          <>
            <MenuButton className={"w-full"} disabled={!enabled}>
              {children}
            </MenuButton>
            <MenuItems
              anchor='bottom'
              className={cn("bg-white rounded-xl p-2 mb-8 shadow-[0px_4px_29.6px_0px_#00000012] z-[100] w-[var(--button-width)]", {
                "border border-[#807692]": open,
              })}
            >
              <div className='gap-y-2'>
                <div className='border border-[#E9EAEC] h-[36px] rounded-xl px-[14px] flex justify-center'>
                  <input
                    type='search'
                    className='w-full focus:outline-none'
                    placeholder='Search...'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                </div>
                <div
                  ref={scrollViewRef}
                  className='max-h-32 min-h-20 overflow-y-scroll scrollbar-thin scrollbar-track-[#F1F0FC] scrollbar-thumb-purple'
                >
                  {filteredItems?.map(item => (
                    <div key={item.id} className='flex items-center px-2.5 py-1 gap-x-3'>
                      <CheckboxPurple
                        checked={selectedData?.includes(item.id)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          updateSelectedData?.(item.id, e.currentTarget.checked);
                        }}
                      />
                      <p>{item.label}</p>
                    </div>
                  ))}
                  {search && !filteredItems?.length && <p className='text-center mt-3 text-sm font-medium '>No results found!</p>}
                </div>

                {showAddInput ? (
                  <div className='h-12 bg-[#ECEAFF] flex items-center justify-center rounded-3xl w-full p-1'>
                    <div className='flex items-center bg-white border w-full border-[#766CE4]  rounded-3xl h-10 pl-2.5 pr-3.5'>
                      <input
                        type='text'
                        placeholder='Add new...'
                        value={addText}
                        onKeyDown={handleKeyDown}
                        onChange={e => setAddText(e.target.value)}
                        className='placeholder:text-[#9EA2AD] placeholder:font-medium placeholder:text-sm
                    text-[#1F2228] outline-none w-full text-sm font-medium focus-visible:ring-0
                    '
                      />
                      <button
                        disabled={addText === ""}
                        type='button'
                        onClick={handleAdd}
                        className='ml-6 underline text-sm font-semibold text-purple disabled:text-[#C8CAD0]'
                      >
                        Add
                      </button>
                    </div>
                  </div>
                ) : (
                  // <div className='flex items-center justify-between border border-[#E9EAEC] rounded-xl px-[14px] py-[9px]'>
                  //   <input
                  //     type='text'
                  //     className='w-full focus:outline-none'
                  //     placeholder='Add new...'
                  //     value={addText}
                  //     onKeyDown={handleKeyDown}
                  //     onChange={e => setAddText(e.target.value)}
                  //   />

                  //   <button onClick={handleAdd}>
                  //     <p className='text-sm font-semibold text-purple underline underline-offset-2'>Add</p>
                  //   </button>
                  // </div>
                  <div className='flex items-start'>
                    <button onClick={() => setShowAddInput(true)} className='inline-flex items-center justify-center'>
                      <Icons.Plus className='w-4 h-4 mr-2.5 text-[#766CE4]' />
                      <p className='text-sm font-semibold text-purple underline underline-offset-2'>Add</p>
                    </button>
                  </div>
                )}
              </div>
            </MenuItems>
          </>
        );
      }}
    </Menu>
  );
};
