import { useEffect, useState } from "react";

import { useDebouncedEffect } from "./useDebouncedEffect";

const useIsMobile = (threshold = 768, debounceDelay = 300): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = (): void => {
    setIsMobile(window.innerWidth <= threshold);
  };

  useDebouncedEffect(handleResize, [], debounceDelay);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threshold, debounceDelay]);

  return isMobile;
};

export default useIsMobile;
