import { LangChainMessage } from "@assistant-ui/react-langgraph";
import { ThreadState, Client, Metadata, OnConflictBehavior } from "@langchain/langgraph-sdk";

const apiUrl = process.env.REACT_APP_OPS_AGENT_API_URL;
if (!apiUrl) {
  throw new Error("REACT_APP_OPS_AGENT_API_URL not set");
}

const createClient = (): Client => {
  console.log("setting up ops agent client: %s", apiUrl);
  return new Client({ apiUrl });
};

export const createAssistant = async (graphId: string) => {
  const client = createClient();
  return client.assistants.create({ graphId });
};

export const createThread = async (payload?: { metadata?: Metadata; threadId?: string; ifExists?: OnConflictBehavior; } | undefined) => {
  const client = createClient();
  return client.threads.create(payload);
};

export const getThreadState = async (
  threadId: string
): Promise<ThreadState<Record<string, any>>> => {
  const client = createClient();
  return client.threads.getState(threadId);
};

export const updateState = async (
  threadId: string,
  fields: {
    newState: Record<string, any>;
    asNode?: string;
  }
) => {
  const client = createClient();
  return client.threads.updateState(threadId, {
    values: fields.newState,
    asNode: fields.asNode,
  });
};

export const sendMessage = async (params: {
  threadId: string;
  messages: LangChainMessage[];
  userId?: string | number;
}) => {
  const client = createClient();

  const input: Record<string, any> = {
    messages: params.messages,
  };
  const config = {
    configurable: {
      model_name: "openai",
      user_id: params.userId,
    },
  };

  return client.runs.stream(
    params.threadId,
    "ops_alert_setup_agent",
    {
      input,
      config,
      streamMode: "messages",
    }
  );
};
