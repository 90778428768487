import { useCallback, useContext, useMemo, useState } from "react";

import { SOCIAL_PLATFORM_ICONS } from "constants/social-icon.constants";

import { Icons } from "shared/components/icons";

import { ReactComponent as TickIcon } from "assets/icons/check-white.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross-black.svg";
import { ReactComponent as CompanyProfileIcon } from "assets/icons/editIcon.svg";

import { cn } from "lib/utils";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

import { CompanyToneLoader } from "./loadingComponents";

export type IndustryDropdownType = {
  id: string;
  label: string;
};

const platforms = ["Twitter/X", "Facebook", "Instagram", "LinkedIn", "TikTok", "Google Ads", "Bing Ads", "Pinterest"];

const CompanyPlatform = () => {
  const [editable, setEditable] = useState(false);
  const [selectedData, setSelectedData] = useState<string[] | undefined>(undefined);
  const { loading, apiData, updateApiData, data, isUrlSet } = useContext(SetupCompanyProfileContext);

  const handleTick = () => {
    updateApiData({ ...apiData, platforms: selectedData });
    setEditable(false);
  };
  const handleCrossClick = () => {
    setSelectedData(apiData.platforms);
    setEditable(false);
  };

  const filterPlatforms = useMemo(() => {
    if (selectedData != undefined) {
      return selectedData;
    }

    const userSelectedPlatforms = apiData.platforms as string[] | undefined;

    if (userSelectedPlatforms && userSelectedPlatforms?.length > 0) {
      return userSelectedPlatforms;
    }

    if (data != null && data.platforms != undefined && data.platforms != null && data.platforms?.length > 0) {
      return data.platforms as string[];
    }

    return [];
  }, [selectedData, apiData, data]);

  const handleConnectPlatform = (platform: string) => {
    //
    updateSelectedData(platform, (selectedData ?? filterPlatforms).includes(platform) == false);
  };

  const updateSelectedData = useCallback(
    (id: string, isChecked: boolean) => {
      setSelectedData(prev => {
        const data = prev || filterPlatforms;

        if (isChecked) {
          return [...data, id];
        } else {
          return data.filter(item => item !== id);
        }
      });
    },
    [selectedData, filterPlatforms],
  );

  return (
    <div className='p-6 bg-white rounded-2xl col-span-4'>
      <div className='flex gap-x-4'>
        <div>
          <Icons.Platform className='text-[#717684]' />
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className='text-base font-bold text-[#2E323A]'>Platforms</p>
            <div className='flex items-center gap-x-2'>
              {!editable ? (
                <button
                  className={cn("p-[7px] rounded-full border border-[#E9EAEC]", isUrlSet == false && "opacity-30")}
                  disabled={isUrlSet == false}
                  onClick={() => setEditable(true)}
                >
                  <CompanyProfileIcon className='h-[18px] w-[18px]' />
                </button>
              ) : (
                <>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC]' onClick={handleCrossClick}>
                    <CrossIcon className='h-[18px] w-[18px]' />
                  </button>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC] bg-purple' onClick={handleTick}>
                    <TickIcon className='h-[18px] w-[18px]' />
                  </button>
                </>
              )}
            </div>
          </div>
          {!loading && isUrlSet == true ? (
            <div className='flex w-full flex-wrap gap-1.5'>
              {platforms.map((item, index) => {
                const iconName = item.toLowerCase();

                return (
                  <PlatformItem
                    key={index}
                    checked={(selectedData || filterPlatforms).includes(item)}
                    onChecked={() => handleConnectPlatform(item)}
                    editable={editable}
                  >
                    {SOCIAL_PLATFORM_ICONS(iconName, "mr-1")}

                    <span className=' font-semibold'>{item}</span>
                  </PlatformItem>
                );
              })}
            </div>
          ) : (
            <div className='mt-2'>
              <CompanyToneLoader isLoading={loading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface IPlatformItemProps {
  children: React.ReactNode;
  className?: string;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
  editable: boolean;
}

function PlatformItem({ children, className, checked, onChecked, editable }: IPlatformItemProps) {
  const [value, setValue] = useState(checked || false);

  const handleClick = () => {
    if (!editable) {
      return;
    }
    setValue(!value);
    onChecked && onChecked(!value);
  };

  return (
    <button
      onClick={handleClick}
      type='button'
      disabled={editable == false}
      className={cn(
        "flex gap-3 items-center justify-center border  py-2.5 pl-4 pr-6 rounded-[56px] h-10 bg-white disabled:cursor-not-allowed",
        checked == true ? "border-[#A39DED] drop-shadow-sm" : "border-[#E8E9F0]",
        className,
      )}
    >
      <span
        className={cn(
          " h-5 w-5 border rounded-full flex items-center justify-center",
          checked == true ? "bg-purple border-purple " : "bg-white border-[#E9EAEC] ",
        )}
      >
        {checked == true && <Icons.Check className='text-white' />}
      </span>
      {children}
      {/* <span className='underline text-purple font-semibold'>Connect</span> */}
    </button>
  );
}

export default CompanyPlatform;
