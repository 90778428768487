import { Icons } from "shared/components/icons";

import { ReactComponent as SearchIcon } from "assets/icons/searchGlass.svg";

const Header = ({ searchQuery, setSearchQuery }: { searchQuery: string; setSearchQuery: (value: string) => void }) => {
  return (
    <div className=' justify-between w-full px-6 py-4 bg-white  md:block lg:flex'>
      <div className='flex gap-4 items-center '>
        <p className='font-bold text-2xl text-[#333740]'>High-demand products overview</p>
      </div>
      <div className='flex gap-4 items-center sm:mt-3 md:mt-4 lg:mt-0'>
        <div className='relative flex items-center gap-x-2 bg-white rounded-xl w-[280px] px-3 py-2 border border-[#E9EAEC]'>
          <SearchIcon className='text-[#322D60]/70' />
          <input
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
            type='search'
            className='w-full outline-none'
            placeholder='Search'
            required
          />
        </div>
        <div className='relative max-w-sm'>
          <div className='absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none'>
            <Icons.DateIcon />
          </div>
          <input
            id='default-datepicker'
            type='text'
            value='December 2024'
            className='border border-[#E9EAEC] text-gray-900 text-sm rounded-xl  block w-full ps-10 p-2.5'
            placeholder='Select date'
          />
          <div className='absolute right-5  inset-y-0 flex items-center ps-3.5 pointer-events-none'>
            <Icons.ArrowDownFill />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
