import { INTEGRATION_ICONS } from "constants/integrations.constants";

import { NotificationData } from ".";

interface SlackUIProps {
  data: NotificationData;
}

const SlackUI: React.FC<SlackUIProps> = ({ data }) => {
  const { logo = "slack", title, message, actionButtons, issueSummary, currentStock, productId, productName, timestamp } = data;

  const showImage = (logo: any) => {
    const Icon = INTEGRATION_ICONS?.[logo as keyof typeof INTEGRATION_ICONS];
    if (!Icon) return <img src={""} alt='logo' className='w-6 h-6' />;
    return <Icon className='w-4 h-4' />;
  };

  return (
    <div className='mx-auto bg-[#F7F7F8] border border-gray-200 rounded-2xl w-full max-w-[504px] my-2'>
      <div className='flex items-center mb-2 bg-[#5A0E5B] rounded-t-2xl px-4 py-2 gap-2'>
        {showImage(logo)}
        <span className='font-semibold text-white text-sm '>#logistics</span>
      </div>

      <div className='bg-[#F7F7F8] p-4 rounded-lg'>
        <h3 className='text-base font-extrabold text-[#414141]  flex items-center mb-5'>
          <span className='mr-1'>⚡</span>
          {title}
          <span className='ml-1'>⚡</span>
        </h3>
        <p className='  text-[#414141] font-semibold text-sm mb-5'>
          👋 <span className='ml-1'> Hello Jim</span>
        </p>
        <p className='mb-4 text-[#303030] font-medium text-sm '>{message}</p>
        <ul className='list-disc list-inside mb-2'>
          <li>
            <span className='border-2 border-[#DEDEDE] text-[#E35F81] px-[6px] py-[2px] rounded font-semibold text-sm'>{productName}</span>
          </li>
        </ul>
        <div className='ml-5'>
          <p className='mb-2 text-[#464646] text-sm font-semibold mt-2'>
            Product ID:{" "}
            <span className='border-2 border-[#DEDEDE]  text-[#E35F81] px-[6px] py-[2px] rounded font-semibold text-sm'>{productId}</span> -
            Current Stock:
            <span className='border-2 border-[#DEDEDE] text-[#E35F81] px-[6px] py-[2px] rounded font-semibold text-sm'>{currentStock}</span>
          </p>
          <p className='mt-2 text-[#444444] text-sm font-semibold'>
            Issue Summary: <span className='text-[#303030] text-sm font-normal'>{issueSummary}</span>
          </p>

          <div className='flex space-x-2 mt-4'>
            <button className='bg-[#9189E9] hover:bg-purple-600 text-white  py-2 px-4 rounded-lg text-sm font-semibold'>
              Action Needed
            </button>
            <button className='bg-white hover:bg-purple-600 text-[#333740]  py-2 px-4 rounded-lg border border-[#C8CAD0] text-sm font-semibold'>
              View Analysis
            </button>
          </div>
        </div>
      </div>

      <div className='text-center mt-4 p-4'>
        <p className='text-sm text-left text-[#444444] font-medium'>Was this update helpful?</p>
        <div className='flex items-center gap-2 mt-2 flex-wrap'>
          <button className='border border-[#C8CAD0] text-black py-1 px-3 rounded-md font-bold text-xs whitespace-nowrap'>
            👍 Yes, keep going
          </button>
          <button className='border border-[#C8CAD0] text-black py-1 px-3 rounded-md font-bold text-xs whitespace-nowrap'>
            🔴 No, turn it off
          </button>
          <button className='border border-[#C8CAD0] text-black py-1 px-3 rounded-md font-bold text-xs whitespace-nowrap'>
            ⚙️ Manage notifications
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlackUI;
