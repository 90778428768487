import cn from "classnames";
import React, { SelectHTMLAttributes } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import { default as ReactSelect } from "react-select";
import { twMerge } from "tailwind-merge";

import { SELECT_SHARED_STYLES } from "constants/shared/select.constants";

import { Option, SharedFormElementsProps } from "interfaces/shared/form-elements.interfaces";

import { ErrorMessage } from "../ErrorMessage";
import { FormLabel } from "../FormLabel";

import { DropdownIndicator } from "./DropdownIndicator";

interface Props extends SelectHTMLAttributes<HTMLSelectElement>, Pick<SharedFormElementsProps, "label"> {
  options: Option[];
  control: Control;
  name: string;
  validation?: RegisterOptions;
  labelClassName?: string;
  required?: boolean;
  placeholder?: string;
}

export const Select = ({ name, options, label, control, validation, disabled, labelClassName, required, placeholder }: Props) => {
  return (
    <div>
      <FormLabel className={labelClassName} required={required}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={validation}
        render={({ field: { name, onChange, value, ...field }, fieldState: { error } }) => (
          <>
            <ReactSelect
              {...field}
              name={name}
              placeholder={placeholder || label}
              value={options.find(option => option?.value === value)}
              onChange={option => onChange(option?.value)}
              menuPlacement='bottom'
              menuPosition='fixed'
              isDisabled={disabled}
              styles={SELECT_SHARED_STYLES}
              classNames={{
                control: ({ isDisabled }) =>
                  twMerge(
                    cn(
                      "!rounded-xl !border-[1.2px] !border-[#E9EAEC] !py-[3px] !pl-1 !pr-1.5 !text-sm !text-[#2C1A49] !shadow-none hover:!border-[#C3BBD1] !transition-all !duration-300",
                      {
                        "!bg-[#F7F7F8]": isDisabled,
                      },
                    ),
                  ),
                input: () => "!text-[#1F2228] !font-medium",
                singleValue: ({ isDisabled }) => cn("!font-medium", { "!text-[#C8CAD0]": isDisabled }),
                placeholder: ({ isDisabled }) => cn("!font-medium !text-[#9EA2AD]", { "!text-[#C8CAD0]": isDisabled }),
                menuList: () => "!py-0",
                menu: () => "!border-[1.2px] !border-[#2C1A4999] !rounded-xl !py-2 !px-1 !shadow-none !mt-1",
                option: ({ isSelected, isFocused }) =>
                  cn("!text-sm !font-medium !text-[#1F2228] !px-2 !py-2.5 !rounded-xl", {
                    "!bg-[#F9F9FF]": isSelected,
                    "!bg-[#F1F0FC]": isFocused,
                  }),
              }}
              components={{ DropdownIndicator, IndicatorSeparator: () => null }}
              options={options}
            />
            <ErrorMessage error={error?.message} />
          </>
        )}
      />
    </div>
  );
};
