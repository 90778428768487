import { useState } from "react";

import { cn } from "lib/utils";

import AgentWorkFlow from "./AgentWorkFlow";
import ProductTaskCard from "./ProductTaskCard";

const WorkFlowAutomation = ({ setProductActivity, productActivity }: any) => {
  const [selectedWorkFlow, setSelectedWorkFlow] = useState("");
  return (
    <div className='absolute top-5 right-0 z-[999] h-full rounded-tl-2xl shadow-ai-panel'>
      <div className='relative flex h-full overflow-auto'>
        {selectedWorkFlow && (
          <div className={cn("h-full border-l-2 border-[#f7f9fb] bg-white  w-[500px] xl:w-[700px]  rounded-tl-2xl overflow-hidden")}>
            <AgentWorkFlow />
          </div>
        )}

        <div
          className={cn(
            "h-full border-l-2 border-[#f7f9fb] bg-white w-[500px] xl:w-[600px] overflow-hidden",
            selectedWorkFlow ? "" : "rounded-tl-2xl",
          )}
        >
          <ProductTaskCard
            productActivity={productActivity}
            setProductActivity={setProductActivity}
            selectedWorkFlow={selectedWorkFlow}
            setSelectedWorkFlow={setSelectedWorkFlow}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkFlowAutomation;
