import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";
// eslint-disable-next-line import/no-unresolved
import { EffectCoverflow } from "swiper/modules";
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/no-unresolved
import { Swiper as SwiperClass } from "swiper/types";

import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";

import { useAI } from "shared/components/ai-panel/provider";
import { Typography } from "shared/components/Typography";

import LoadingOverlay from "pages/budget/BudgetIntelligence/components/LoadingOverlay";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
interface Data {
  competitive_outlook: string[];
  budget_recommendation: string[];
  competitor_insights: string[];
}
type Suggestions = Data;
interface Props {
  suggestions: Suggestions;
  isLoading: boolean;
}

const getSuggestionCardName = (key: string) => {
  const titleArray = key.split("_");

  return titleArray[0][0].toUpperCase() + titleArray[0].slice(1) + " " + titleArray[1][0].toUpperCase() + titleArray[1].slice(1);
};

export const BudgetSuggestionsSlider = ({ suggestions, isLoading }: Props) => {
  const { openPanel, closePanel, showAsSheet, isOpen, setIntelligence } = useAI();

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  // const loading = false; // Temp - TODO: Connnect it with a real state

  const handleRightClick = React.useCallback(() => {
    if (swiper === null || isLoading) return;
    if (swiper.activeIndex === 2) swiper.slideTo(0);
    else swiper.slideTo(swiper.activeIndex + 1);
  }, [swiper, isLoading]);

  const handleOpenAISheet = useCallback(
    (suggestion: string[]) => {
      if (isOpen) {
        closePanel();
        setIntelligence(false);
      } else {
        openPanel(true);
        setIntelligence(true, suggestion);
      }
    },
    [openPanel, showAsSheet, isOpen],
  );
  const getBgColor = (index: number) => {
    switch (index) {
      case 1:
        return "bg-[#E5E3FB]";
      case 2:
        return "bg-[#D5D1F7]";
      case 3:
        return "bg-[#C0BBF3]";
      default:
        return "bg-[#C0BBF3]";
    }
  };
  return (
    <div className='relative'>
      <Swiper
        allowTouchMove={false}
        autoHeight
        grabCursor
        effect='coverflow'
        slidesPerView='auto'
        centeredSlides
        loop
        initialSlide={2}
        noSwiping={false}
        coverflowEffect={{
          rotate: 0,
          stretch: "80%",
          depth: 400,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow]}
        className='mySwiper'
        onSwiper={setSwiper}
      >
        {Object.keys(suggestions).map((suggestionKey, index) => {
          const suggestion = suggestions[suggestionKey as keyof Data];
          // const activeIndex = swiper?.activeIndex;
          // const sliperIndex = activeIndex - 1;
          // const isActive = index === sliperIndex;

          // console.log("activeIndex", activeIndex, "index", index, "sliperIndex", sliperIndex, isActive);

          return (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <>
                  <LoadingOverlay isShown={isLoading && index == 0} />
                  <div
                    className={cn("coverflow-slide-content w-full h-[213px] break-all overflow-y-auto rounded-[22px] p-0.5")}
                    onClick={() => (isActive ? handleOpenAISheet(suggestion) : null)}
                  >
                    <div className={cn("py-5 px-6 h-full rounded-[20px]", isActive ? "bg-white" : getBgColor(index))}>
                      <Typography className='text-[#413B7D]' tag='h4' variant={TypographyVariants.H7}>
                        {getSuggestionCardName(suggestionKey)}:
                      </Typography>
                      <ul className='mt-4 space-y-[10px] text-[#413B7D] font-medium'>
                        {suggestion.map((item: string) => (
                          <li className='leading-default pl-2 text-base list flex' key={item}>
                            <div className='pr-2 mt-2'>
                              <div className='w-1 h-1 bg-black rounded-full' />
                            </div>{" "}
                            {isActive && item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </SwiperSlide>
          );
        })}
        <button
          onClick={handleRightClick}
          className='absolute top-[calc(50%-20px)] right-[5%] z-20 flex justify-center items-center rounded-full border w-10 h-10 bg-white border-[#E9EAEC]'
        >
          <ArrowDownIcon className='-rotate-90' />
        </button>
      </Swiper>
    </div>
  );
};
