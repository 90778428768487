export enum SidebarsWidth {
  ASIDE_CLOSED_WIDTH = 68,
  ASIDE_OPENED_WIDTH = 230,
  CHAT_CLOSED_WIDTH = 0 /*change to 60 when chat ready*/,
  CHAT_OPENED_WIDTH = 0 /*change to 331 when chat ready*/,
  MAIN_PADDINGS = 32,
  DEFAULT_SCREEN_WIDTH = 1440,
}


export enum Platforms {
  Slack ="Slack",
  WhatsApp = "WhatsApp",
  Gmail = "Gmail",
}

export enum LabelTypes {
  Inventory = "Inventory",
  Procurement = "Procurement",
}

