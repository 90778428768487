import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { apiGetSavedPersonalizationUrl, apiSavePersonalizationUrl, savePersonalizationUrl } from "api/personalization";

import { companyCreationEvent, extractUserDetails } from "utils/analytics.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetCompanyData, useGetPerviouslySavedCompanyData } from "hooks/queries/setup-company";

import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { Button } from "shared/components/buttons/Button";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as BackIcon } from "assets/icons/arrow-left-purple.svg";

import { cn } from "lib/utils";

import CompanyBrandTone from "./components/company-brand-tone";
import CompanyCompetitorLinks from "./components/company-competitor-links";
import CompanyDescription from "./components/company-description";
import Companyindustry from "./components/company-industry";
import CompanyPlatform from "./components/company-platform";
import CompanyType from "./components/company-type";
import InputContainer from "./components/InputContainer";
import CompanyTeamMembers from "./people/CompanyTeamMembers";

export const SetupCompanyProfileContext = createContext<undefined | any>(undefined);

const tabs = [
  { name: "general", label: "General" },
  { name: "people", label: "People" },
  // TODO: Uncomment when knowledge base is ready
  // { name: "knowledge-base", label: "Knowledge Base" },
];

const SetupCompanyProfile = () => {
  const [data, setData] = useState<any>(null);
  const [url, setUrl] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isUrlSet, setIsUrlSet] = useState(false);
  const [apiData, setApiData] = useState({
    description: "",
    company_type: "",
    industry: [],
    brandtone: [],
    competitor_links: [],
    platforms: [],
  });
  const [loading, setLoading] = useState(false);
  const [showRestartButton, setShowRestartButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const [view, setView] = useState("general");
  const user = useAppSelector(state => state.auth?.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleUpdateData = (response: any) => {
    if (response) {
      let business_models = [];

      if (response?.business_model != undefined) {
        business_models = response?.business_model;
      }
      setData({ ...response, business_model: business_models, platforms: response?.platforms });
      updateUrl(response?.website_url);
      setIsUrlSet(true);
      updateShowRestartButton(true);
    }
  };

  const handleUpdateApiData = (response: any) => {
    if (response) {
      if (!response?.website_url) {
        return;
      }

      setApiData({
        description: response?.description,
        company_type: response?.business_model,
        industry: response?.industries?.slice(0, 2),
        brandtone: response?.brand_tone?.slice(0, 4),
        competitor_links: response?.competitor_links,
        platforms: response?.platforms,
      });
      updateUrl(response?.website_url);
      setCompanyId(response?.company_id);
      setIsUrlSet(true);
      updateShowRestartButton(true);
    }
  };

  // const previousDataQuery = useGetPerviouslySavedCompanyData(handleUpdateData);

  const companyDataQuery = useGetCompanyData(handleUpdateApiData);

  const getAiGeneratedProfile = async (url: string) => {
    setLoading(true);
    try {
      const response = await apiGetSavedPersonalizationUrl(url.trim());
      setLoading(false);
      if (response) {
        let business_model = "";
        let business_models = [];

        if (response?.business_model != undefined) {
          // check if business_model is an array
          if (Array.isArray(response?.business_model)) {
            business_model = response?.business_model[0];
            business_models = response?.business_model;
          } else {
            business_model = response?.business_model;
          }
        }

        setData({ ...response, business_model: business_models });

        setApiData({
          description: response?.description,
          company_type: business_model,
          industry: response?.industries?.slice(0, 2),
          brandtone: response?.brand_tone?.slice(0, 4),
          competitor_links: response?.competitor_links,
          platforms: response?.company_platform,
        });
        setIsUrlSet(true);
      }
    } catch (err: unknown) {
      setLoading(false);
      if (err instanceof Error) Alert("error", err?.message, "Error");
    }
  };

  const handleImport = useCallback((url: string) => {
    getAiGeneratedProfile(url);
  }, []);

  const updateShowRestartButton = useCallback((value: boolean) => {
    setShowRestartButton(value);
  }, []);

  const updateApiData = useCallback((data: any) => {
    setApiData(data);
  }, []);

  const updateUrl = useCallback((value: string) => {
    setUrl(value);
  }, []);

  const resetData = useCallback(() => {
    setData(null);
    setApiData({
      description: "",
      company_type: "",
      industry: [],
      brandtone: [],
      competitor_links: [],
      platforms: [],
    });
  }, []);

  const handleSave = () => {
    const api = {
      ...data,
      description: apiData.description,
      business_model: apiData.company_type,
      competitor_links: apiData.competitor_links,
      platforms: apiData.platforms,
      industries: apiData.industry,
      brand_tone: apiData.brandtone,
    };

    // check if api.business_model is an array
    if (Array.isArray(api.business_model)) {
      Alert("error", "Please select a valid business model", "Error");
      return;
    }

    setSaving(true);

    savePersonalizationUrl(api)
      .then(res => {
        if (res) {
          companyDataQuery.refetch();
          Alert("success", "Company profile saved successfully", "Success");
          companyCreationEvent(extractUserDetails(user));
          navigate(-1);
        }
      })
      .catch(res => {
        Alert("error", res.message, "Error");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    //

    // if (previousDataQuery.data) {
    //   //
    //   handleUpdateData(previousDataQuery.data);
    // }

    if (companyDataQuery.data) {
      //
      handleUpdateApiData(companyDataQuery.data);
    }
  }, []);

  const continueBtnDisabled =
    !apiData.description || !apiData.company_type || !apiData.industry.length || !apiData.brandtone.length || loading || saving;
  return (
    <DashboardLayout title={apiData.description != "" ? "Company profile setup" : "Get Started"} isScrollable={false}>
      <div className='flex flex-col h-full'>
        <div className='bg-white border-b border-[#F7F9FB] px-6 pt-2'>
          <div className='flex justify-between items-center'>
            <div className={"flex gap-5 items-center"}>
              {tabs.map(({ name, label }, index) => (
                <button
                  key={index}
                  className={cn(
                    "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm capitalize",
                    view === name ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                  )}
                  onClick={() => {
                    setView(name);
                  }}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div
          className={cn(
            "flex h-full w-full bg-[#F7F9FB] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600",
          )}
        >
          <div className='px-6 pt-4 w-full'>
            <div className='flex flex-col gap-y-4 pb-8'>
              {/* --------------- Back Button --------------------- */}
              <button className='flex items-center ' onClick={handleBack}>
                <BackIcon className='mr-2.5 h-5 w-5' />
                <p className='text-base font-semibold text-purple'>Back</p>
              </button>
              {/* --------------- Input Container --------------------- */}
              <SetupCompanyProfileContext.Provider
                value={{
                  data,
                  url,
                  updateUrl,
                  handleImport,
                  loading: loading || companyDataQuery.isLoading,
                  apiData,
                  updateApiData,
                  updateShowRestartButton,
                  showRestartButton,
                  resetData,
                  isUrlSet,
                }}
              >
                {view === "general" && (
                  <>
                    <InputContainer />
                    <CompanyDescription />
                    <div className='grid gap-x-4 grid-cols-4'>
                      <CompanyType />
                      <Companyindustry />
                    </div>
                    <CompanyBrandTone />
                    <CompanyPlatform />
                    <CompanyCompetitorLinks />
                    <div className='flex justify-end mt-[5%] '>
                      <Button className='!w-fit px-[42px] rounded-xl' disabled={continueBtnDisabled} onClick={handleSave}>
                        <p>{saving ? "Saving..." : "Continue"}</p>
                      </Button>
                    </div>
                  </>
                )}
                {view === "people" && <CompanyTeamMembers companyId={String(companyId)} />}
              </SetupCompanyProfileContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SetupCompanyProfile;
