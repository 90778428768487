import { flexRender, Row, RowData } from "@tanstack/react-table"
import classNames from "classnames"
import React from "react"

import { TableRow } from "shared/components/table/TableRow"

type TableRowComponentProps = {
    row: Row<RowData>,
    isSelected: boolean
}

const TableRowComponent = ({row, isSelected}: TableRowComponentProps)=>{
  const fixedColumns = ["caption", "hashtags", "postideas","creative"]
    return <TableRow
    isSelected={isSelected ? true : undefined}
    key={row.id}
    
  >
    {row.getVisibleCells().map(cell => (
      <td
        key={cell.id}
        className={classNames(`text-[#1A212B] text-sm font-medium leading-[18px]  border border-[#EAECF0] py-2.5 ${cell.column.id === "channels" ? "pb-1.5" : ""}  bg-white ${cell.column.id === "select" ? "  w-6 px-3 text-center" : "px-3"} `,{"max-w-[140px]": fixedColumns.includes(cell.column.id)})}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    ))}
  </TableRow>
}


export default React.memo(TableRowComponent)