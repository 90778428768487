import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { persistor, store } from "storage/store";

import { Toaster } from "components/ui/sonner";
import { StreamProvider } from "providers/Stream";
import { ThreadProvider } from "providers/Thread";

import App from "./App";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID || ""}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ThreadProvider>
                <StreamProvider>
                  <App />
                </StreamProvider>
              </ThreadProvider>
            </QueryParamProvider>
            <Toaster />
          </BrowserRouter>
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </GoogleOAuthProvider>,
);
