import React, { createContext, useContext, useState } from "react";

interface UtilityTrayContextType {
  isOpen: boolean;
  openTray: () => void;
  closeTray: () => void;
  toggleTray: () => void;
}

const UtilityTrayContext = createContext<UtilityTrayContextType | undefined>(undefined);

export const UtilityTrayProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openTray = () => setIsOpen(true);
  const closeTray = () => setIsOpen(false);
  const toggleTray = () => setIsOpen((prev) => !prev);

  return (
    <UtilityTrayContext.Provider value={{ isOpen, openTray, closeTray, toggleTray }}>
      {children}
    </UtilityTrayContext.Provider>
  );
};

export const useUtilityTray = () => {
  const context = useContext(UtilityTrayContext);
  if (!context) {
    throw new Error("useUtilityTray must be used within a UtilityTrayProvider");
  }
  return context;
};
