import { flexRender, Table as TableType, Row } from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import TableRowComponent from "./TableRow";

interface Props<T> {
  table: TableType<T>;
  handleClickRow?: (value: T) => void;
  selectedId?: NumberOrNull;
  idKey?: string;
  className?: string;
  hover?: boolean;
  selectedRows: Row<any>[];
}

const ProductBasedTable = <T,>({ table, handleClickRow, selectedId, idKey, className, hover, selectedRows }: Props<T>) => {
  const rows = table.getRowModel().rows;

  const isSelectedRow = (rowId: string) => {
    for (let i = 0; i <= selectedRows?.length; i++) {
      if (selectedRows[i]?.id === rowId) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className={twMerge(" w-full overflow-auto scroll-w-1", className)}>
      <table className='whitespace-nowrap w-full border-separate rounded-[14px] overflow-hidden border-spacing-0'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className='bg-[#F7F7F8]'>
              {headerGroup.headers.map(header => (
                <tr
                  key={header.id}
                  className={`table-cell py-3 text-sm text-[#1F2228] font-bold text-left border border-[#EAECF0]   ${header.id === "select" ? "w-6 px-3 text-center" : "px-3"}`}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </tr>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(row => {
            return <TableRowComponent row={row} key={row.id} isSelected={isSelectedRow(row.id)} />;
          })}
          {rows.length === 0 && (
            <tr>
              <td className='text-center text-base font-medium leading-[18px] py-3' colSpan={table.getHeaderGroups()[0].headers.length}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default ProductBasedTable;
