import { Icons } from "shared/components/icons";

export const SOCIAL_PLATFORM_ICONS = (iconName: string, className?: string) => {
  let icon: React.ReactNode | null = null;

  if (iconName === "facebook") {
    icon = <Icons.Facebook className={className} />;
  } else if (iconName === "instagram") {
    icon = <Icons.Instagram className={className} />;
  } else if (iconName.includes("tiktok")) {
    icon = <Icons.TikTok className={className} />;
  } else if (iconName.includes("google")) {
    icon = <Icons.GoogleAds className={className} />;
  } else if (iconName.includes("twitter")) {
    icon = <Icons.TwitterOrX className={className} />;
  } else if (iconName.includes("bing")) {
    icon = <Icons.BingAds className={className} />;
  } else if (iconName.includes("linkedin")) {
    icon = <Icons.LinkedIn className={className} />;
  } else if (iconName.includes("email")) {
    icon = <Icons.Email className={className} />;
  } else if (iconName.includes("pinterest")) {
    icon = <Icons.Pinterest className={className} />;
  }

  return icon;
};
