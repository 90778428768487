import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga4";
import { Bounce, ToastContainer } from "react-toastify";

import ErrorBoundaryComponent from "pages/error-boundary/ErrorBoundary";

import { PagesWrapper } from "./page-components/PagesWrapper";

import "react-toastify/dist/ReactToastify.css";

const googleAnalyticsMeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;

function App() {
  useEffect(() => {
    if (!localStorage.getItem("watchedInfo")) {
      localStorage.setItem("watchedInfo", "false");
    }
    if (googleAnalyticsMeasurementId) {
      ReactGA.initialize(googleAnalyticsMeasurementId);
      ReactGA.send("pageview");
    }
  }, []);

  const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API
    console.log("error", error);
  };

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        theme='light'
        transition={Bounce}
      />
      <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
        <PagesWrapper />
      </ErrorBoundary>
    </>
  );
}

export default App;
