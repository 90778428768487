import { type Message } from "@langchain/langgraph-sdk";
import { useStream } from "@langchain/langgraph-sdk/react";
import { uiMessageReducer, type UIMessage, type RemoveUIMessage } from "@langchain/langgraph-sdk/react-ui";
//import { ArrowRight } from "lucide-react";
import React, { createContext, useContext, ReactNode, useState, useEffect, useCallback } from "react";
import { toast } from "sonner";
import { useQueryParam, StringParam } from "use-query-params";

//import { Button } from "../components/ui/button";
//import { Input } from "../components/ui/input";
//import { Label } from "../components/ui/label";
//import { PasswordInput } from "../components/ui/password-input";
//import { getApiKey } from "../lib/api-key";

import { useThreads } from "./Thread";


export type StateType = { messages: Message[]; ui?: UIMessage[] };

const useTypedStream = useStream<
  StateType,
  {
    UpdateType: {
      messages?: Message[] | Message | string;
      ui?: (UIMessage | RemoveUIMessage)[] | UIMessage | RemoveUIMessage;
    };
    CustomEventType: UIMessage | RemoveUIMessage;
  }
>;

type StreamContextType = ReturnType<typeof useTypedStream>;
const StreamContext = createContext<StreamContextType | undefined>(undefined);

async function sleep(ms = 4000) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function checkGraphStatus(apiUrl: string, apiKey: string | null): Promise<boolean> {
  try {
    const res = await fetch(`${apiUrl}/info`, {
      ...(apiKey && {
        headers: {
          "X-Api-Key": apiKey,
        },
      }),
    });

    return res.ok;
  } catch (e) {
    console.error(e);
    return false;
  }
}

const StreamSession = ({
  children,
  apiKey,
  apiUrl,
  assistantId,
}: {
  children: ReactNode;
  apiKey: string | null;
  apiUrl: string;
  assistantId: string;
}) => {
  const [threadId, setThreadId] = useQueryParam("threadId", StringParam);
  const { getThreads, setThreads } = useThreads();
  const streamValue = useTypedStream({
    apiUrl,
    apiKey: apiKey ?? undefined,
    assistantId,
    threadId: threadId ?? null,
    onCustomEvent: (event, options) => {
      options.mutate(prev => {
        const ui = uiMessageReducer(prev.ui ?? [], event);
        return { ...prev, ui };
      });
    },
    onThreadId: id => {
      setThreadId(id);
      // Refetch threads list when thread ID changes.
      // Wait for some seconds before fetching so we're able to get the new thread that was created.
      sleep().then(() => getThreads().then(setThreads).catch(console.error));
    },
  });


  useEffect(() => {
    checkGraphStatus(apiUrl, apiKey).then(ok => {
      if (!ok) {
        toast.error("Failed to connect to LangGraph server", {
          description: () => (
            <p>
              Please ensure your graph is running at <code>{apiUrl}</code> and your API key is correctly set (if connecting to a deployed
              graph).
            </p>
          ),
          duration: 10000,
          richColors: true,
          closeButton: true,
        });
      }
    });
  }, [apiKey, apiUrl]);

  

  return <StreamContext.Provider value={streamValue}>{children}</StreamContext.Provider>;
};

export const StreamProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const LG_API_URL = process.env.REACT_APP_OPS_AGENT_API_URL || "undefined";
  const [apiUrl] = useState(LG_API_URL);

  const LG_API_KEY = process.env.REACT_APP_LANGCHAIN_API_KEY || "undefined";
/*   const [apiKey, _setApiKey] = useState(() => {
    return getApiKey();
  }); */
  const [apiKey, _setApiKey] = useState(LG_API_KEY);

  const setApiKey = (key: string) => {
    window.localStorage.setItem("lg:chat:apiKey", key);
    _setApiKey(key);
  };

  const LG_ASSISTANT_ID = "ops_alert_setup_agent";
  const [assistantId] = useState(LG_ASSISTANT_ID);

  return (
    <StreamSession apiKey={apiKey} apiUrl={apiUrl} assistantId={assistantId}>
      {children}
    </StreamSession>
  );
};

// Create a custom hook to use the context
export const useStreamContext = (): StreamContextType => {
  const context = useContext(StreamContext);
  if (context === undefined) {
    throw new Error("useStreamContext must be used within a StreamProvider");
  }
  return context;
};

export default StreamContext;
