import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "hooks/appHooks";

import { selectUser } from "storage/selectors/user";

import IntegrationsOnlyModal from "./IntegrationsOnlyModal";

const IntegrationsOnlyModalWrapper: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const user = useAppSelector(selectUser);
  // No need for preferences anymore

  useEffect(() => {
    // Check if user has integrations_only plan
    const isIntegrationsOnlyPlan = user?.plan?.name_tech === "integrations_only" as any;

    // Check current route
    const currentPath = location.pathname;

    // Define allowed routes (don't show modal for these)
    const allowedRoutes = [
      "/integrations",
      "/dashboard",
      "/memberships",
      "/personalize",
      "/sign-in",
      "/sign-up",
      "/reset-password",
      "/confirm-password",
      "/setup-password",
      "/verify-code",
      "/verify-email",
    ];

    // Check if current path matches any allowed route
    const isAllowedRoute = allowedRoutes.some(route =>
      currentPath === route || currentPath.startsWith(`${route}/`)
    );

    // Show modal if user has integrations_only plan and is on a restricted route
    setShowModal(isIntegrationsOnlyPlan && !isAllowedRoute);
  }, [location.pathname, user]);

  if (!showModal) return null;

  return <IntegrationsOnlyModal />;
};

export default IntegrationsOnlyModalWrapper;
